import React, { useState } from "react";
import firebase from "firebaseConfig";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
  Badge,
  FormGroup,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import Header from "components/Headers/Header.jsx";
import { Link } from "react-router-dom";

import { Redirect } from "react-router-dom";
import RealTime from "../services/RealTime";
import StaticCard from "../components/statisticsCard/StatisticsCard.jsx";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "./styles/Dashboard.css";
import { Bar } from "react-chartjs-2";
import PageLoader from "../components/PageLoader/PageLoader";

// total Orders count
var totalOrders = 0;
// active Orders count
var activeOrders = 0;
// Pending Orders
var pendingOrders = 0;
// active vehicle count
var totalVehicles = 0;
// completed Orders
var completedOrders = 0;
// total service providers
var totalSPs = 0;
// total earning
var totalEarnings = 0;
// current emergency
var currentEmergency = [];
// past emergency
var pastEmergency = [];
// service providers details
var monthArray = [];
var spDetails;
var allOrders;
var newAllOrders = [];
var earningsPerMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var orderPerMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
firebase.auth().onAuthStateChanged(async function (user) {
  if (user) {
    var tempDate;
    spDetails = await RealTime.getDetails("/service_providers/");
    if (spDetails) {
      var index = 0;
      Object.keys(spDetails).map((sp, keySP) => {
        if (spDetails[sp].past_emergency) {
          Object.keys(spDetails[sp].past_emergency).map((line, key) => {
            tempDate = line.split(" ")[0];
            pastEmergency[index] = {
              details: spDetails[sp].past_emergency[line],
              date: tempDate,
              spRelated: sp,
            };
            index = index + 1;
          });
        }
      });
      if (pastEmergency.length > 0) {
        pastEmergency.sort(function (a, b) {
          return b.date - a.date;
        });
      }
    }
    // current emergency notification
    var tempTime;
    if (spDetails) {
      var index = 0;
      Object.keys(spDetails).map((sp, keySP) => {
        if (spDetails[sp].emergency) {
          Object.keys(spDetails[sp].emergency).map((line, key) => {
            // tempDate = line.split(' ')[0];
            // "12 Jun 2020 06:46PM"
            tempDate = spDetails[sp].emergency[line].time_request_raised;
            tempDate = tempDate.slice(4);
            tempDate = tempDate.replace(/-/g, " ");
            tempDate = tempDate.replace(",", "");
            tempTime = 0;
            if (tempDate[17] === "P") {
              tempTime = tempDate.slice(12, 14);
              tempTime = parseInt(tempTime) + 12;
              tempTime = tempTime.toString();
              tempDate = tempDate.slice(0, 12) + tempTime + tempDate.slice(14);
            }
            tempDate = tempDate.slice(0, 17) + ":00";
            tempDate = new Date(tempDate);
            tempDate = tempDate.getTime();
            currentEmergency[index] = {
              details: spDetails[sp].emergency[line],
              date: tempDate,
              spRelated: sp,
            };
            index = index + 1;
          });
        }
      });
      if (currentEmergency.length > 0) {
        currentEmergency.sort(function (a, b) {
          return b.date - a.date;
        });
      }
    }
  } else {
    // No user is signed in.
  }
});
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      totalOrders: Number(0),
      totalEarnings: Number(0),
      activeOrders: Number(0),
      pendingOrders: Number(0),
      totalVehiclesWashed: Number(0),
      totalSPs: Number(0),
      totalVehicles: Number(0),
      allOrders: [],
      allOrdersByDate: [],
      allOrdersByDateAndEmirate: [],
      allSPs: [],
      allSPsByDate: [],
      allSPsByDateAndEmirate: [],
      allVehicles: [],
      allVehiclesByDate: [],
      allVehiclesByDateAndEmirate: [],
      earningsPerMonth: [],
      orderPerMonth: [],
      isLoaded: false,
      labels: [],
      monthArray: [],
      addservices: 0,
      newcenter: 0,
      addVehicles: 0,
      addDriver: 0,
      addservices: 0,
      addAddOn: 0,
      date: "",
      updateTime: "",
      allDrivers: "",
      play: false,
    };
    this.toggle = this.toggle.bind(this);
    this.navigateBooking = this.navigateBooking.bind(this);
    this.navigateAddVehicles = this.navigateAddVehicles.bind(this);
    this.navigateAddServices = this.navigateAddServices.bind(this);
    this.navigateAddAddOn = this.navigateAddAddOn.bind(this);
    this.navigateSpCenter = this.navigateSpCenter.bind(this);
  }
  audio = new Audio(
    "https://notificationsounds.com/storage/sounds/file-09_dings.mp3"
  );
  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  navigateSpCenter = () => {
    this.setState({
      newcenter: 1,
    });
  };
  navigateBooking = () => {
    this.setState({
      booking: 1,
    });
  };
  navigateAddVehicles = () => {
    this.setState({
      addVehicles: 1,
    });
  };
  navigateAdddriver = () => {
    this.setState({
      addDriver: 1,
    });
  };
  navigateAddServices = () => {
    this.setState({
      addservices: 1,
    });
  };
  navigateAddAddOn = () => {
    this.setState({
      addAddOn: 1,
    });
  };
  handleCancel = (event, picker) => {
    picker.element.val("");
    this.clearFilter();
  };

  dateRangeHandler = async (event, picker) => {
    let data = await this.getFilterData();
    this.setState({
      date:
        picker.startDate.format("DD/MM/YYYY") +
        " - " +
        picker.endDate.format("DD/MM/YYYY"),
    });
    picker.element.val(this.state.date);
    this.setState({ isLoaded: false });
    let result;
    let totalSPsByDate = Number(0);
    let totalVehiclesByDate = Number(0);
    let newOrders = this.state.allOrders.filter((order) => {
      let timeOrderPlaced = moment(order.time_order_placed)
        .tz("Asia/Dubai")
        .format("MM/DD/YYYY hh:mm a");
      return (
        moment(timeOrderPlaced) >= picker.startDate.toDate() &&
        moment(timeOrderPlaced) <= picker.endDate.toDate()
      );
    });
    // let newSPs = this.state.allSPs.filter((sp) => {
    //   if (sp.personal_information) {
    //     if (sp.personal_information.registeredDate) {
    //       let registeredDate = moment(sp.personal_information.registeredDate)
    //         .tz("Asia/Dubai")
    //         .format("MM/DD/YYYY hh:mm a");
    //       return (
    //         moment(registeredDate) >= picker.startDate.toDate() &&
    //         moment(registeredDate) <= picker.endDate.toDate()
    //       );
    //     }
    //   }
    // });
    let newVehicles = this.state.allVehicles.filter((vehicle) => {
      if (vehicle.registeredDate) {
        let registeredDate = moment(vehicle.registeredDate)
          .tz("Asia/Dubai")
          .format("MM/DD/YYYY hh:mm a");
        return (
          moment(registeredDate) >= picker.startDate.toDate() &&
          moment(registeredDate) <= picker.endDate.toDate()
        );
      }
    });
    if (this.state.allSPs) totalSPsByDate = this.state.allSPs.length;
    if (newVehicles) totalVehiclesByDate = newVehicles.length;
    if (newOrders) {
      result = await this.getOrderDetails(newOrders);
    }
    this.setState({
      selectedEmirate: { label: "All", value: "All" },
      allOrdersByDate: result.newAllOrders,
      allSPsByDate: this.state.allSPs,
      allVehiclesByDate: newVehicles,
      totalOrders: result.totalOrders,
      totalEarnings: result.totalEarnings,
      totalVehiclesWashed: result.totalVehiclesWashed,
      allDriversByEmirate: this.state.allDrivers,
      activeOrders: result.activeOrders,
      pendingOrders: result.pendingOrders,
      canceledOrders: result.canceledOrders,
      completedOrders: result.completedOrders,
      totalSPs: this.state.totalSPs,
      totalVehicles: totalVehiclesByDate,
      earningsPerMonth: result.totalMonthEarnings,
      orderPerMonth: result.totalMonthOrders,
      labels: result.totalMonthLabel,
      isLoaded: true,
    });
  };
  handleEmirateChange = async (event) => {
    this.setState({ isLoaded: false, date: "" });
    let result;
    let totalSPsByDateAndEmirate = Number(0);
    let totalVehiclesByDateAndEmirate = Number(0);
    let newOrders = [];
    var newSPs = [];
    var newVehicles = [];
    let newDrivers = [];
    let value = event.target.value;
    let data = await this.getFilterData();

    if (value === "All") {
      newOrders = this.state.allOrdersByDate;
      newSPs = this.state.allSPs;
      newVehicles = this.state.allVehiclesByDate;
      newDrivers = this.state.allDrivers;
    } else {
      newOrders = this.state.allOrdersByDate.filter((order) => {
        if (order.emirate) return value === order.emirate;
      });
      newSPs = this.state.allSPsByDate.filter((sp) => {
        if (sp.personal_information) {
          if (sp.personal_information.emirate) {
            return value === sp.personal_information.emirate;
          }
        }
      });
      this.state.allVehiclesByDate.map((vehicle) => {
        if (vehicle.sp_related)
          vehicle.sp_related.map((item) => {
            if (value === item.emirate) {
              newVehicles.push(vehicle);
            }
          });
      });
      this.state.allDrivers.map((driver) => {
        if (driver.sp_related)
          driver.sp_related.map((item) => {
            if (value === item.emirate) {
              newDrivers.push(driver);
            }
          });
      });
    }

    if (newSPs) totalSPsByDateAndEmirate = newSPs.length;
    if (newVehicles) totalVehiclesByDateAndEmirate = newVehicles.length;
    if (newOrders) {
      result = await this.getOrderDetails(newOrders);
    }
    this.setState({
      selectedEmirate: value,
      allSPsByDate: this.state.allSPs,
      allOrdersByDateAndEmirate: result.newAllOrders,
      allDriversByEmirate: newDrivers,
      totalOrders: result.totalOrders,
      totalEarnings: result.totalEarnings,
      totalVehiclesWashed: result.totalVehiclesWashed,
      activeOrders: result.activeOrders,
      pendingOrders: result.pendingOrders,
      canceledOrders: result.canceledOrders,
      completedOrders: result.completedOrders,
      totalSPs: totalSPsByDateAndEmirate,
      totalVehicles: totalVehiclesByDateAndEmirate,
      earningsPerMonth: result.totalMonthEarnings,
      orderPerMonth: result.totalMonthOrders,
      labels: result.totalMonthLabel,
      isLoaded: true,
    });
  };
  getEmirates = async () => {
    let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");
    let newEmirates = [];
    newEmirates.push({
      label: "All",
      value: "All",
    });
    Object.entries(emirates).forEach(([key, emirate]) => {
      newEmirates.push({
        label: emirate.name,
        value: emirate.name,
      });
    });
    return newEmirates;
  };
  clearFilter = async () => {
    let data = await this.getFilterData();
    this.setState({
      date: "",
    });
    let result;
    let newAllVehicles = [];
    let emirates = await this.getEmirates();
    totalSPs = Number(0);
    totalVehicles = Number(0);
    if (this.state.allSPs) {
      totalSPs = this.state.allSPs.length;
      this.state.allSPs.map((sp) => {
        if (sp.vehicles) {
          totalVehicles = totalVehicles + Object.keys(sp.vehicles).length;
          Object.entries(sp.vehicles).forEach(([key, vehicles]) => {
            newAllVehicles.push(vehicles);
          });
        }
      });
    }
    if (this.state.allOrders) {
      result = await this.getOrderDetails(this.state.allOrders);
    }
    this.setState({
      allOrders: this.state.allOrders,
      allOrdersByDate: this.state.allOrders,
      allOrdersByDateAndEmirate: this.state.allOrders,
      allSPs: this.state.allSPs,
      allSPsByDate: this.state.allSPs,
      allSPsByDateAndEmirate: this.state.allSPs,
      allVehicles: this.state.allVehicles,
      allVehiclesByDate: this.state.allVehicles,
      allVehiclesByDateAndEmirate: this.state.allVehicles,
      allDriversByEmirate: this.state.allDrivers,
      emirates: emirates,
      totalOrders: result.totalOrders,
      totalEarnings: result.totalEarnings,
      activeOrders: result.activeOrders,
      totalVehiclesWashed: result.totalVehiclesWashed,
      canceledOrders: result.canceledOrders,
      completedOrders: result.completedOrders,
      pendingOrders: result.pendingOrders,
      totalSPs: totalSPs,
      totalVehicles: totalVehicles,
      earningsPerMonth: result.totalMonthEarnings,
      orderPerMonth: result.totalMonthOrders,
      labels: result.totalMonthLabel,
      isLoaded: true,
      selectedEmirate: {
        label: "All",
        value: "All",
      },
    });
  };
  getDriverLastSeen = (driver_activity_tracking, status) => {
    let lastDayKey = 0;
    let lastDayTimeStamp = 0;
    let lastTimeKey = 0;
    let lastAction = {};
    if (driver_activity_tracking) {
      Object.entries(driver_activity_tracking).forEach(([keyDay, day]) => {
        if (moment(keyDay).format("x") > lastDayTimeStamp) {
          lastDayKey = keyDay;
          lastDayTimeStamp = moment(keyDay).format("x");
        }
      });

      Object.entries(
        driver_activity_tracking[lastDayKey].time_tracking
      ).forEach(([keyTime, action]) => {
        if (keyTime > lastTimeKey) {
          lastTimeKey = keyTime;
          lastAction = action;
        }
      });
    }
    if (lastAction && lastAction.state === 1 && status === 1)
      return "AVAILABILE";
    else
      return lastAction.time
        ? String(moment(lastAction.time).format("DD/MM/YYYY hh:mm"))
        : "NO DATA";
  };
  mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  };
  async componentDidMount() {
    this.setState({
      updateTime: new Date().toLocaleTimeString(),
    });
    let user = localStorage.getItem("admin_email");
    let newAllSPs = [];
    let allOrders = [];
    let newAllVehicles = [];
    totalSPs = Number(0);
    totalVehicles = Number(0);
    let result;
    let emirates;
    let date = new Date();
    // real-time order details current day
    var orderRef = await firebase
      .database()
      .ref(
        `/order_day_node/${date.getFullYear()}/${date.getMonth()}/${date.getDate()}/`
      );
    orderRef.on("value", async (snapshot) => {
      const data = snapshot.val();

      if (data) {
        result = await this.getOrderDetails(Object.values(data));
        this.setState({
          allVehicles: newAllVehicles,
          allVehiclesByDate: newAllVehicles,
          allVehiclesByDateAndEmirate: newAllVehicles,
          allDrivers: allDrivers,
          allDriversByEmirate: allDrivers,
          totalVehicles: totalVehicles,
          totalOrders: result.totalOrders,
          totalEarnings: result.totalEarnings,
          totalVehiclesWashed: result.totalVehiclesWashed,
          activeOrders: result.activeOrders,
          pendingOrders: result.pendingOrders,
          canceledOrders: result.canceledOrders,
          completedOrders: result.completedOrders,
          allOrdersByDate: result.newAllOrders,
          allOrdersByDateAndEmirate: result.newAllOrders,
          earningsPerMonth: result.totalMonthEarnings,
          orderPerMonth: result.totalMonthOrders,
          labels: result.totalMonthLabel,
          date: `${new Date().toLocaleDateString()}-${new Date().toLocaleDateString()}`,
        });
      }
      if (this.state.pendingOrders > 0) {
        this.audio.play();
      }
    });
    // active sp details and total sp count
    spDetails = await RealTime.getDetails("/service_providers/");
    if (spDetails) {
      Object.entries(spDetails).forEach(([key, sp]) => {
        if (sp.state === 1 && !sp.isSuspended) {
          totalSPs++;
          newAllSPs.push(sp);
        }
      });
    }
    var allDrivers = [];
    var allDrivers1 = [];
    var allDrivers2 = [];
    var driverRef = await firebase.database().ref("/drivers/");
    driverRef.on("value", async (snapshot) => {
      const data = snapshot.val();
      allDrivers = [];
      allDrivers1 = [];
      allDrivers2 = [];

      Object.entries(data).forEach(([key, driver]) => {
        if (
          !spDetails[driver.sp_related[0].sp_id].isSuspended &&
          spDetails[driver.sp_related[0].sp_id].state === 1
        ) {
          if (driver?.status === 1) {
            allDrivers1.unshift({
              driverName: driver?.personal_information?.name,
              driverNumber: driver?.personal_information?.phone_number,
              status: driver.status,
              sp:
                driver.sp_related.length > 1
                  ? spDetails[driver.sp_related[0].sp_id]?.personal_information
                      ?.name + ",..."
                  : spDetails[driver.sp_related[0].sp_id]?.personal_information
                      ?.name,
              emirate: driver.sp_related[0].emirate,
              emirateArea: driver?.personal_information?.emiateArea
                ? driver?.personal_information?.emiateArea
                : driver.sp_related[0].emirate,
              vehicleNumber: driver?.personal_information?.vehicle_number,
              sp_related: driver.sp_related,
              lastSeen: this.getDriverLastSeen(
                driver.driver_activity_tracking,
                driver.status
              ),
              emirateArea: driver?.personal_information?.emiateArea
                ? driver?.personal_information?.emiateArea
                : driver.sp_related[0].emirate,
            });
          } else {
            allDrivers2.push({
              driverName: driver?.personal_information?.name,
              driverNumber: driver?.personal_information?.phone_number,
              status: driver.status,
              sp:
                driver.sp_related.length > 1
                  ? spDetails[driver.sp_related[0].sp_id]?.personal_information
                      ?.name + ",..."
                  : spDetails[driver.sp_related[0].sp_id]?.personal_information
                      ?.name,
              emirate: driver.sp_related[0].emirate,
              emirateArea: driver?.personal_information?.emiateArea
                ? driver?.personal_information?.emiateArea
                : driver.sp_related[0].emirate,
              vehicleNumber: driver?.personal_information?.vehicle_number,
              sp_related: driver.sp_related,
              lastSeen: this.getDriverLastSeen(
                driver.driver_activity_tracking,
                driver.status
              ),
              emirateArea: driver?.personal_information?.emiateArea
                ? driver?.personal_information?.emiateArea
                : driver.sp_related[0].emirate,
            });
          }
        }
      });
      let A2 = ["Abu Dhabi", "Ajman", "Dubai", "Sharjah", "Umm al-Quwain"];
      var ordered_array1 = this.mapOrder(allDrivers1, A2, "emirate");
      var ordered_array2 = this.mapOrder(allDrivers2, A2, "emirate");
      let allOrders = ordered_array1.concat(ordered_array2);

      this.setState({
        allDrivers: allOrders,
        allDriversByEmirate: allOrders,
      });
    });
    emirates = await this.getEmirates();

    this.setState({
      allSPs: newAllSPs,
      allSPsByDate: newAllSPs,
      allSPsByDateAndEmirate: newAllSPs,
      emirates: emirates,
      totalSPs: totalSPs,
      updateTime: new Date().toLocaleTimeString(),
      isLoaded: true,
    });
  }
  getFilterData = async () => {
    this.setState({
      isLoaded: false,
    });
    let allOrders = [];
    let order_day_node = await RealTime.getDetails("/order_day_node/");
    if (order_day_node) {
      Object.entries(order_day_node).forEach(([keyYear, year]) => {
        Object.entries(year).forEach(([keyMonth, month]) => {
          Object.entries(month).forEach(([keyDay, day]) => {
            Object.entries(day).forEach(([keyOrder, order]) => {
              allOrders.push(order);
            });
          });
        });
      });
      let result = await this.getOrderDetails(allOrders);
      this.setState({
        allOrders: allOrders,
        totalOrders: result.totalOrders,
        totalEarnings: result.totalEarnings,
        totalVehiclesWashed: result.totalVehiclesWashed,
        activeOrders: result.activeOrders,
        pendingOrders: result.pendingOrders,
        canceledOrders: result.canceledOrders,
        completedOrders: result.completedOrders,
        allOrdersByDate: result.newAllOrders,
        allOrdersByDateAndEmirate: result.newAllOrders,
        earningsPerMonth: result.totalMonthEarnings,
        orderPerMonth: result.totalMonthOrders,
        labels: result.totalMonthLabel,
        isLoaded: true,
      });
    }
    return true;
  };
  getOrderDetails = async (allOrders) => {
    var newAllOrders = [];
    var totalMonthEarnings = [];
    var totalMonthLabel = [];
    var totalMonthOrders = [];
    var totalEarnings = 0;
    var totalOrders = 0;
    var totalVehiclesWashed = 0;
    var pendingOrders = 0;
    var activeOrders = 0;
    var completedOrders = 0;
    var canceledOrders = 0;
    var result = {};
    var yearCount = moment().tz("Asia/Dubai").year() - 2020 + 1; // 2020 twaddan starting year
    var currentMonth = moment(new Date()).tz("Asia/Dubai").month() + 1;
    var monthDiffrence = 12 - currentMonth;
    var monthLength = 12 * yearCount - monthDiffrence;
    var labels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    for (let month = 0; month < monthLength; month++) {
      totalMonthEarnings.push(0);
      totalMonthOrders.push(0);
      totalMonthLabel.push(
        `${labels[month % 12]} - ${month / 12 >= 1 ? "21" : "20"}`
      );
    }
    allOrders.map((order) => {
      if (order.status >= 0) {
        newAllOrders.push(order);
        // calculating month and year of order
        let month = moment(order.time_order_placed).tz("Asia/Dubai").month();
        let year = moment(order.time_order_placed).tz("Asia/Dubai").year();
        let currentYear = moment().tz("Asia/Dubai").year();
        // finding index to store earnings and order according to month and year
        for (let index = 1; index <= yearCount; index++) {
          if (currentYear - year === yearCount - index && order.total_due) {
            totalMonthEarnings[12 * (index - 1) + month] =
              totalMonthEarnings[12 * (index - 1) + month] + order.total_due;
            totalMonthOrders[12 * (index - 1) + month] =
              totalMonthOrders[12 * (index - 1) + month] + 1;
          }
        }
        if (order.status === 0 || order.status === "") {
          pendingOrders += 1;
        } else if (order.status < 5 && order.status > 0) {
          activeOrders += 1;
        } else {
          completedOrders += 1;
        }
        if (order.total_due) {
          totalEarnings += order.total_due;
        }
        if (order.total_number_of_vehicles && order.status === 5) {
          totalVehiclesWashed += order.total_number_of_vehicles;
        }
      } else {
        canceledOrders++;
      }
    });

    totalOrders = pendingOrders + activeOrders + completedOrders;
    result = {
      totalMonthEarnings: totalMonthEarnings,
      totalMonthOrders: totalMonthOrders,
      totalEarnings: totalEarnings,
      totalVehiclesWashed: totalVehiclesWashed,
      totalOrders: totalOrders,
      totalMonthLabel: totalMonthLabel,
      newAllOrders: newAllOrders,
      pendingOrders: pendingOrders,
      activeOrders: activeOrders,
      canceledOrders: canceledOrders,
      completedOrders: completedOrders,
    };
    console.log("result: ", result);
    return result;
  };

  render() {
    if (this.state.booking == 1) {
      return <Redirect to="/admin/newBooking" />;
    }
    if (this.state.addVehicles == 1) {
      return <Redirect to="/admin/addVehicle" />;
    }
    if (this.state.addDriver == 1) {
      return <Redirect to="/admin/addDriver" />;
    }
    if (this.state.addservices == 1) {
      return <Redirect to="/admin/addService_new" />;
    }
    if (this.state.addAddOn == 1) {
      return <Redirect to="/admin/addAddonService_new" />;
    }
    if (this.state.newcenter === 1) {
      return <Redirect to="/admin/addServiceCenters" />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isLoaded}></PageLoader>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <br />

          <Container fluid>
            <div className="header-body">
              <Row className="mt-6 visibilitySmallDevice">
                <div className=" mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <DateRangePicker
                        block
                        autoApply={true}
                        onApply={this.dateRangeHandler}
                        onCancel={this.handleCancel}
                        showOneCalendar
                        initialSettings={{
                          autoUpdateInput: false,
                          locale: {
                            format: "DD/MM/YYYY",
                            cancelLabel: "Clear",
                          },
                          startDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          endDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          ranges: {
                            Today: [
                              moment().tz("Asia/Dubai").toDate(),
                              moment().tz("Asia/Dubai").toDate(),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").toDate(),
                              moment().subtract(1, "days").toDate(),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").toDate(),
                              moment().toDate(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").toDate(),
                              moment().toDate(),
                            ],
                            "This Month": [
                              moment().startOf("month").toDate(),
                              moment().endOf("month").toDate(),
                            ],
                            "Last Month": [
                              moment()
                                .subtract(1, "month")
                                .startOf("month")
                                .toDate(),
                              moment()
                                .subtract(1, "month")
                                .endOf("month")
                                .toDate(),
                            ],
                          },
                        }}
                      >
                        <input
                          type="text"
                          style={{ display: "inline-block" }}
                          className="form-control"
                          defaultValue=""
                          value={this.state.date}
                          placeholder="Select Date Range"
                        />
                      </DateRangePicker>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={this.state.selectedEmirate}
                        onChange={this.handleEmirateChange}
                      >
                        <option selected disabled value="">
                          Select Emirate
                        </option>
                        {this.state.emirates &&
                          this.state.emirates.length > 0 &&
                          this.state.emirates.map((emirate, index) => {
                            return (
                              <option key={index} value={emirate.value}>
                                {emirate.value}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-2">
                  <FormGroup row>
                    <Col>
                      <Button color="primary" block onClick={this.clearFilter}>
                        CLEAR FILTER
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
                {/* <div className=" mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-2">
                  <FormGroup row>
                    <Col>
                      <ButtonDropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        style={{ display: "inline" }}
                      >
                        <DropdownToggle caret block color="primary">
                          <i className={"fas fa-fat-add"} />
                          ADD NEW
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem disabled>
                            Select Add Options
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAdddriver}>
                            NEW DRIVER
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddVehicles}>
                            NEW VEHICLE
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateSpCenter}>
                            NEW SERVICE CENTER
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddServices}>
                            NEW SERVICE
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddAddOn}>
                            NEW ADD-ON SERVICE
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>{" "}
                    </Col>
                  </FormGroup>
                </div> */}
              </Row>
              {/* Card stats */}
              <Row>
                <Col xs={12} sm={6} md={6} lg={4} xl={4} key={0}>
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="danger"
                    icon="address-card"
                    statTitle={
                      <span>
                        <br></br>PENDING<br></br>ORDERS
                      </span>
                    }
                    stat={this.state.pendingOrders}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={4} key={1}>
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="success"
                    icon="address-card"
                    statTitle={
                      <span>
                        <br></br>ACTIVE<br></br>ORDERS
                      </span>
                    }
                    stat={this.state.activeOrders}
                  />
                </Col>
                <Col xs={12} sm={6} md={6} lg={4} xl={4} key={2}>
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="info"
                    icon="address-book"
                    statTitle={
                      <span>
                        <br></br>TOTAL<br></br>ORDERS
                      </span>
                    }
                    stat={this.state.totalOrders}
                  />
                </Col>
                <Col
                  className="dashboard_cards"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={3}
                >
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="warning"
                    icon="taxi"
                    statTitle={
                      <span>
                        <br></br>TOTAL<br></br>VEHICLES WASHED
                      </span>
                    }
                    stat={this.state.totalVehiclesWashed}
                  />
                </Col>
                <Col
                  className="dashboard_cards"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={4}
                >
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="primary"
                    icon="address-book"
                    statTitle={
                      <span>
                        <br></br>ACTIVE SERVICE<br></br>PROVIDERS
                      </span>
                    }
                    stat={this.state.totalSPs}
                  />
                </Col>
                <Col
                  className="dashboard_cards"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  xl={4}
                  key={5}
                >
                  <StaticCard
                    iconRight={true}
                    hideChart={true}
                    iconBg="dark"
                    icon="gift"
                    statTitle={
                      <span>
                        <br></br>TOTAL<br></br>EARNINGS
                      </span>
                    }
                    stat={this.state.totalEarnings}
                  />
                </Col>
              </Row>
            </div>
          </Container>
          <Container fluid>
            <div className="header-body">
              <Row className="mt-4 visibilityMediumDevice">
                <div className=" mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <DateRangePicker
                        block
                        autoApply={true}
                        onApply={this.dateRangeHandler}
                        onCancel={this.handleCancel}
                        showOneCalendar
                        initialSettings={{
                          autoUpdateInput: false,
                          locale: {
                            format: "DD/MM/YYYY",
                            cancelLabel: "Clear",
                          },
                          startDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          endDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          ranges: {
                            Today: [
                              moment().tz("Asia/Dubai").toDate(),
                              moment().tz("Asia/Dubai").toDate(),
                            ],
                            Yesterday: [
                              moment().subtract(1, "days").toDate(),
                              moment().subtract(1, "days").toDate(),
                            ],
                            "Last 7 Days": [
                              moment().subtract(6, "days").toDate(),
                              moment().toDate(),
                            ],
                            "Last 30 Days": [
                              moment().subtract(29, "days").toDate(),
                              moment().toDate(),
                            ],
                            "This Month": [
                              moment().startOf("month").toDate(),
                              moment().endOf("month").toDate(),
                            ],
                            "Last Month": [
                              moment()
                                .subtract(1, "month")
                                .startOf("month")
                                .toDate(),
                              moment()
                                .subtract(1, "month")
                                .endOf("month")
                                .toDate(),
                            ],
                          },
                        }}
                      >
                        <input
                          type="text"
                          style={{ display: "inline-block" }}
                          className="form-control"
                          defaultValue=""
                          value={this.state.date}
                          placeholder="Select Date Range"
                        />
                      </DateRangePicker>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-4">
                  <FormGroup row>
                    <Col>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={this.state.selectedEmirate}
                        onChange={this.handleEmirateChange}
                      >
                        <option selected disabled value="">
                          Select Emirate
                        </option>
                        {this.state.emirates &&
                          this.state.emirates.length > 0 &&
                          this.state.emirates.map((emirate, index) => {
                            return (
                              <option key={index} value={emirate.value}>
                                {emirate.value}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                  </FormGroup>
                </div>
                <div className="mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-2">
                  <FormGroup row>
                    <Col>
                      <Button color="primary" block onClick={this.clearFilter}>
                        CLEAR FILTER
                      </Button>
                    </Col>
                  </FormGroup>
                </div>
                <div className=" mt-3 col-xs-12  col-sm-12 col-md-6 col-lg-3 col-xl-2">
                  <FormGroup row>
                    <Col>
                      <ButtonDropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={this.toggle}
                        style={{ display: "inline" }}
                      >
                        <DropdownToggle caret block color="primary">
                          <i className={"fas fa-fat-add"} />
                          ADD NEW
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem disabled>
                            Select Add Options
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAdddriver}>
                            NEW DRIVER
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddVehicles}>
                            NEW VEHICLE
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateSpCenter}>
                            NEW SERVICE CENTER
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddServices}>
                            NEW SERVICE
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.navigateAddAddOn}>
                            NEW ADD-ON SERVICE
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>{" "}
                    </Col>
                  </FormGroup>
                </div>
              </Row>

              <Row className="mt-2">
                <Col xs={12} lg={6}>
                  <Card>
                    <CardBody>
                      <h2>Orders</h2>
                      <div id="chart">
                        <Bar
                          data={{
                            labels: this.state.labels,
                            datasets: [
                              {
                                label: "Orders",
                                data: this.state.orderPerMonth,
                                backgroundColor: "rgba(255, 99, 132, 0.2)",
                                borderColor: "rgba(255, 99, 132, 1)",
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={100}
                          height={200}
                          options={{
                            maintainAspectRatio: false,
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card>
                    <CardBody>
                      <h2>Earnings</h2>
                      <div id="chart">
                        <Bar
                          data={{
                            labels: this.state.labels,
                            datasets: [
                              {
                                label: "Earnings",
                                data: this.state.earningsPerMonth,
                                backgroundColor: "rgba(54, 162, 235, 0.2)",
                                borderColor: "rgba(54, 162, 235, 1)",
                                borderWidth: 1,
                              },
                            ],
                          }}
                          width={100}
                          height={200}
                          options={{
                            maintainAspectRatio: false,
                            scales: {
                              yAxes: [
                                {
                                  ticks: {
                                    beginAtZero: true,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                  <p>last updated : {this.state.updateTime}</p>
                </Col>
                <Col className="mt-5">
                  <Card className="shadow" style={{ minHeight: "450px" }}>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h2 className="mb-0">Driver status</h2>
                        </div>
                        {/* <div className="col text-right">
                          <Button
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            size="sm"
                          >
                            See all
                          </Button>
                        </div> */}
                      </Row>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Emirate</th>
                          <th scope="col">Region </th>
                          <th scope="col">Driver name</th>
                          <th scope="col">Mobile No</th>
                          <th scope="col">Service Provider</th>
                          <th scope="col">Status</th>
                          <th scope="col">Last Seen</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.allDriversByEmirate &&
                          this.state.allDriversByEmirate.length > 0 &&
                          this.state.allDriversByEmirate.map((driver) => {
                            return (
                              <>
                                <tr>
                                  <th scope="row">{driver.emirate}</th>
                                  <th scope="row">{driver.emirateArea}</th>
                                  <th scope="row">{driver.driverName}</th>
                                  <td>{driver.driverNumber}</td>
                                  <td>{driver.sp}</td>
                                  <td>
                                    {driver.status === 1 && (
                                      <Badge color="success">
                                        <big>ONLINE</big>
                                      </Badge>
                                    )}
                                    {driver.status === 0 && (
                                      <Badge color="warning">
                                        <big>OFFLINE</big>
                                      </Badge>
                                    )}
                                    {driver.status === -1 && (
                                      <Badge color="info">
                                        <big>BUSY</big>
                                      </Badge>
                                    )}
                                    {driver.status === -2 && (
                                      <Badge color="danger">
                                        <big>CLOSED</big>
                                      </Badge>
                                    )}
                                  </td>
                                  <td>{driver.lastSeen}</td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
                {/* <Col className="mt-5">
                  <Card className="shadow" style={{ minHeight: "450px" }}>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h2 className="mb-0">Emergency Notification</h2>
                        </div>
                        {/* <div className="col text-right">
                          <Button
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            size="sm"
                          >
                            See all
                          </Button>
                        </div> 
                      </Row>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Driver name</th>
                          <th scope="col">Driver phone</th>
                          <th scope="col">Service Provider</th>
                          <th scope="col">Description</th> 
                          <th scope="col">Status</th>
                          <th scope="col">Request raised</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentEmergency &&
                          currentEmergency.length > 0 &&
                          currentEmergency.map((line, key) => {
                            return (
                              <>
                                <tr>
                                  <th scope="row">
                                    {currentEmergency[key].details.name}
                                  </th>
                                  <td>
                                    {currentEmergency[key].details.phone_number}
                                  </td>
                                  <td>{currentEmergency[key].spRelated}</td>
                                   <td>{currentEmergency[key].details.emergency_description}</td> 
                                  <td>
                                    {currentEmergency[key].details
                                      .time_request_resolved && (
                                      <Badge color="" className="badge-dot">
                                        <i className="bg-success" />
                                        completed
                                      </Badge>
                                    )}
                                    {!currentEmergency[key].details
                                      .time_request_resolved && (
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                      >
                                        <i className="bg-warning" />
                                        pending
                                      </Badge>
                                    )}
                                  </td>
                                  <td>
                                    <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                                    {currentEmergency[key].details
                                      .time_request_raised && (
                                      <span>
                                        {
                                          currentEmergency[key].details
                                            .time_request_raised
                                        }
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
                <Col className="mt-5">
                  <Card className="shadow" style={{ minHeight: "450px" }}>
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Past Emergency Notification</h3>
                        </div>
                        {/* <div className="col text-right">
                          <Button
                            color="primary"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                            size="sm"
                          >
                            See all
                          </Button>
                        </div> *
                      </Row>
                    </CardHeader>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Driver name</th>
                          <th scope="col">Driver phone</th>
                          <th scope="col">Service Provider</th>
                          {/* <th scope="col">Description</th> 
                          <th scope="col">Status</th>
                          <th scope="col">Request raised</th>
                          <th scope="col">Request resolved</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pastEmergency &&
                          pastEmergency.length > 0 &&
                          pastEmergency.map((line, key) => {
                            return (
                              <>
                                <tr>
                                  <th scope="row">
                                    {pastEmergency[key].details.name}
                                  </th>
                                  <td>
                                    {pastEmergency[key].details.phone_number}
                                  </td>
                                  <td>{pastEmergency[key].spRelated}</td>
                                  {/* <td>{pastEmergency[key].details.emergency_description}</td> 
                                  <td>
                                    {pastEmergency[key].details
                                      .time_request_resolved && (
                                      <Badge color="" className="badge-dot">
                                        <i className="bg-success" />
                                        completed
                                      </Badge>
                                    )}
                                    {!pastEmergency[key].details
                                      .time_request_resolved && (
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                      >
                                        <i className="bg-warning" />
                                        pending
                                      </Badge>
                                    )}
                                  </td>
                                  <td>
                                    <i className="fas fa-arrow-down text-danger mr-3" />{" "}
                                    {pastEmergency[key].details
                                      .time_request_raised && (
                                      <span>
                                        {
                                          pastEmergency[key].details
                                            .time_request_raised
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <i className="fas fa-arrow-up text-success mr-3" />{" "}
                                    {pastEmergency[key].details
                                      .time_request_resolved && (
                                      <span>
                                        {
                                          pastEmergency[key].details
                                            .time_request_resolved
                                        }
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Card>
                </Col> */}
              </Row>
            </div>
          </Container>
        </Container>
      </>
    );
  }
}

export default Index;
