const ConvertArabic = async (text, value) => {
  const axios = require("axios").default;
  const url = "https://translation.googleapis.com/language/translate/v2";
  const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
  const reply = await axios.get(url, {
    params: {
      q: text,
      target: "ar",
      key: apiKey
    }
  });
  console.log(reply.data.data.translations[0].translatedText);
  //   this.setState({
  //     [value]: reply.data.data.translations[0].translatedText
  //   });
  return reply.data.data.translations[0].translatedText;
};

export default ConvertArabic;
