import Dashboard from "views/Index.jsx";
import Booking from "views/pages/Booking.jsx";
// import Earnings from "views/pages/Earnings.jsx";
import Vehicles from "views/pages/Vehicles.jsx";
import Drivers from "views/pages/Drivers.jsx";
import NewBooking from "views/pages/newBooking.jsx";
import Profile from "views/pages/Profile.jsx";
// import Report from "views/pages/Report.jsx";
import Register from "views/pages/Register.jsx";
import Login from "views/pages/Login.jsx";
// import Services from "views/pages/Services.jsx";
import ServicesNew from "views/pages/ServiceNew.jsx";
import ServiceCenters from "views/pages/ServiceCenters.jsx";
import AddServiceCenters from "views/pages/AddServiceCenters.jsx";
// import BannerImages from "views/pages/BannerImages";
// import VehicleType from "views/pages/vehicleType.jsx";
import VehicleTypeNew from "views/pages/vehicleTypeNew.jsx";
import AddVehicleType from "views/pages/AddVehicleType";
import AddService from "views/pages/AddService";
import AddServiceNew from "views/pages/AddServiceNew";
import AddDrivers from "views/pages/AddDriver";
import AddVehicles from "views/pages/AddVehicle";
import AddonService from "views/pages/AddonService";
import AddOnServiceNew from "views/pages/AddOnServiceNew";
import AddUser from "views/pages/AddUser";
// import UserManagement from "views/pages/userManagement";
// import RestrictedLocations from "views/pages/restrictedLoactions";
import AddRestrictedLocation from "views/pages/AddRestrictedLocation";
// import EmployeeLogs from "views/pages/EmployeeLogs";
import PageNotFound from "views/pages/PageNotFound";
import ServingArea from "views/pages/ServingArea";
// import Notification from "views/pages/Notification";
import AddNotification from "views/pages/AddNotification";
import Reviews from "views/pages/customerReview";
import Users from "views/pages/Users";
import UserDetails from "views/pages/UserDetails";
import Discount from "views/pages/discount";
import AddDiscount from "views/pages/AddDiscount";
import Ledger from "views/pages/ledger";
import Orders1 from "views/pages/Orders1";
import ActiveOrderTracking from "views/pages/ActiveOrderTracking";
import CompletedDetail from "views/pages/CompletedDetail";
import CancelledDetail from "views/pages/CancelledDetail";
import UsersNotification from "views/pages/UsersNotification";
import ServiceableArea from "views/pages/ServiceableAreas";
import CreateOrder from "views/pages/CreateOrder";
import ActualUsers from "views/pages/ActualUsers";
import MapTab from "views/pages/MapTab";
import OrderReport from "views/pages/OrderReport";
import AssIgnSpToVehicle from "views/pages/AssIgnSpToVehicle";
import AllOrdersHidden from "views/pages/AllOrdersHidden";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
    permission: [
      "addDriver",
      "addServiceCenter",
      "addVehicle",
      "addBooking",
      "addServices",
      "addDiscount",
    ],
  },

  {
    path: "/Orders1",
    name: "Orders",
    icon: "ni ni-planet text-green",
    component: Orders1,
    layout: "/admin",
    permission: ["addBooking"],
  },
  {
    path: "/createOrder",
    name: "Create Order",
    icon: "ni ni-planet text-green",
    component: CreateOrder,
    layout: "/admin",
    permission: ["addBooking"],
  },

  // {
  //   path: "/earnings",
  //   name: "Earnings",
  //   icon: "ni ni-money-coins text-yellow",
  //   component: Earnings,
  //   layout: "/admin",
  //   permission: ["NIL"],
  // },
  {
    path: "/HeatMap",
    name: "Map",
    icon: "ni ni-square-pin",
    component: MapTab,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    icon: "ni ni-bus-front-12 text-blue",
    component: Vehicles,
    layout: "/admin",
    permission: ["addVehicle"],
  },
  {
    path: "/assignSpToVehicle",
    name: "Add Vehicle",
    icon: "ni ni-bus-front-12 text-blue",
    component: AssIgnSpToVehicle,
    layout: "/admin",
    permission: ["addVehicle_Create"],
  },
  // {
  //   path: "/vehicleType",
  //   name: "VehicleType",
  //   icon: "ni ni-delivery-fast text-green",
  //   component: VehicleType,
  //   layout: "/admin",
  //   permission: ["addVehicle"],
  // },
  {
    path: "/vehicleTypeNew",
    name: "Vehicle Type New",
    icon: "ni ni-delivery-fast text-green",
    component: VehicleTypeNew,
    layout: "/admin",
    permission: ["addVehicle"],
  },
  {
    path: "/ServingArea",
    name: "Serviceable Areas SP",
    icon: "ni ni-map-big text-purple",
    component: ServingArea,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/ServiceableAreas",
    name: "Serviceable Areas",
    icon: "ni ni-compass-04",
    component: ServiceableArea,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/addVehicleType",
    name: "Add VehicleType",
    icon: "ni ni-bus-front-12 text-blue",
    component: AddVehicleType,
    layout: "/admin",
    permission: ["addVehicle_Create"],
  },
  {
    path: "/addService",
    name: "Add Service",
    icon: "ni ni-bus-front-12 text-blue",
    component: AddService,
    layout: "/admin",
    permission: ["addServices_Create"],
  },
  {
    path: "/addService_new",
    name: "Add Service",
    icon: "ni ni-bus-front-12 text-blue",
    component: AddServiceNew,
    layout: "/admin",
    permission: ["addServices_Create"],
  },
  {
    path: "/discount",
    name: "Discount",
    icon: "ni ni-diamond text-orange",
    component: Discount,
    layout: "/admin",
    permission: ["addDiscount"],
  },
  {
    path: "/addDiscount",
    name: "Add Discount",
    icon: "ni ni-fat-add text-blue",
    component: AddDiscount,
    layout: "/admin",
    permission: ["addDiscount_Create"],
  },

  {
    path: "/addVehicle",
    name: "Add Vehicle",
    icon: "ni ni-bus-front-12 text-blue",
    component: AddVehicles,
    layout: "/admin",
    permission: ["addVehicle_Create"],
  },
  {
    path: "/addDriver",
    name: "Add Driver",
    icon: "ni ni-bus-front-12 text-blue",
    component: AddDrivers,
    layout: "/admin",
    permission: ["addDriver_Create"],
  },
  {
    path: "/addAddonService",
    name: "Add Addon Service",
    icon: "ni ni-bus-front-12 text-blue",
    component: AddonService,
    layout: "/admin",
    permission: ["addServices_Create"],
  },
  {
    path: "/addAddonService_new",
    name: "Add Addon Service",
    icon: "ni ni-bus-front-12 text-blue",
    component: AddOnServiceNew,
    layout: "/admin",
    permission: ["addServices_Create"],
  },
  {
    path: "/activeordertracking",
    name: "Active Order Tracking",
    component: ActiveOrderTracking,
    layout: "/admin",
    permission: ["addBooking"],
  },
  {
    path: "/completeddetail",
    name: "Completed Order Detail",
    component: CompletedDetail,
    layout: "/admin",
    permission: ["addBooking"],
  },
  {
    path: "/cancelleddetail",
    name: "Cancelled Order Detail",
    component: CancelledDetail,
    layout: "/admin",
    permission: ["addBooking"],
  },
  {
    path: "/drivers",
    name: "Drivers",
    icon: "ni ni-single-02 text-orange",
    component: Drivers,
    layout: "/admin",
    permission: ["addDriver"],
  },
  // {
  //   path: "/services",
  //   name: "Services",
  //   icon: "ni ni-settings text-red",
  //   component: Services,
  //   layout: "/admin",
  //   permission: ["addServices"],
  // },
  {
    path: "/services_new",
    name: "Services New",
    icon: "ni ni-settings text-red",
    component: ServicesNew,
    layout: "/admin",
    permission: ["addServices"],
  },
  {
    path: "/serviceCenters",
    name: "Service Centers",
    icon: "ni ni-basket text-info",
    component: ServiceCenters,
    layout: "/admin",
    permission: ["addServiceCenter"],
  },
  {
    path: "/addServiceCenters",
    name: "Add Service Centers",
    icon: "ni ni-single-02 text-orange",
    component: AddServiceCenters,
    layout: "/admin",
    permission: ["addServiceCenter_Create"],
  },
  {
    path: "/users",
    name: "Customers",
    icon: "ni ni-user-run text-purple",
    component: Users,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/actualUsers",
    name: "Actual Customers",
    icon: "ni ni-user-run text-purple",
    component: ActualUsers,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/userdetails",
    name: "Customers Details",
    component: UserDetails,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/ordersReport",
    name: "Order Report",
    icon: "ni ni-planet text-green",
    component: OrderReport,
    layout: "/admin",
    permission: ["addBooking"],
  },
  // {
  //   path: "/restrictedLocations",
  //   name: "Restricted Locations",
  //   icon: "ni ni-world-2 text-red",
  //   component: RestrictedLocations,
  //   layout: "/admin",
  //   permission: ["NIL"],
  // },
  {
    path: "/customerReviews",
    name: "Customer Reviews",
    icon: "ni ni-single-copy-04 text-green",
    component: Reviews,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/addRestrictedLocations",
    name: "Add Restricted Locations",
    icon: "ni ni-world-2 text-red",
    component: AddRestrictedLocation,
    layout: "/admin",
    permission: ["NIL"],
  },

  {
    path: "/newBooking",
    name: "New booking",
    icon: "ni ni-calendar-grid-58 text-red",
    component: NewBooking,
    layout: "/admin",
    permission: ["NIL"],
  },
  // {
  //   path: "/userManagement",
  //   name: "User Management",
  //   icon: "ni ni-badge text-purple",
  //   component: UserManagement,
  //   layout: "/admin",
  //   permission: ["NIL"],
  // },
  // {
  //   path: "/Notification",
  //   name: "Notification",
  //   icon: "ni ni-time-alarm text-yellow",
  //   component: Notification,
  //   layout: "/admin",
  //   permission: ["NIL"],
  // },
  {
    path: "/addNotification",
    name: "Add Notification",
    icon: "ni ni-time-alarm text-yellow",
    component: AddNotification,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/addUser",
    name: "Add User",
    icon: "ni ni-single-02 text-orange",
    component: AddUser,
    layout: "/admin",
    permission: ["NIL"],
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    layout: "/admin",
    permission: ["All"],
  },
  // {
  //   path: "/report",
  //   name: "Report",
  //   icon: "ni ni-collection text-green",
  //   component: Report,
  //   layout: "/admin",
  //   permission: ["NIL"]
  // },
  {
    path: "/ledger",
    name: "Ledger",
    icon: "ni ni-collection text-green",
    component: Ledger,
    layout: "/admin",
    permission: ["NIL"],
  },
  // {
  //   path: "/bannerImages",
  //   name: "Banner Images",
  //   icon: "ni ni-album-2 text-red",
  //   component: BannerImages,
  //   layout: "/admin",
  //   permission: ["NIL"],
  // },
  // {
  //   path: "/employeeLogs",
  //   name: "Employee Logs",
  //   icon: "ni ni-ui-04 text-purple",
  //   component: EmployeeLogs,
  //   layout: "/admin",
  //   permission: ["NIL"],
  // },
  {
    path: "/pageNotFound",
    name: "Page Not Found",
    icon: "ni ni-fat-remove text-red",
    component: PageNotFound,
    layout: "/admin",
    permission: ["ALL"],
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/usersNotification",
    name: "Users Notification",
    component: UsersNotification,
    icon: "ni ni-time-alarm text-red",
    layout: "/admin",
    permission: ["addBooking"],
  },
  {
    path: "/OrdersX",
    name: "",
    // icon: "ni ni-planet text-green",
    component: AllOrdersHidden,
    layout: "/admin",
    permission: ["NIL"],
  },
];
export default routes;
