import "./Orders.css";
import React, { Component } from "react";
import Header from "components/Headers/Header.jsx";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Row,
  BreadcrumbItem,
  Breadcrumb,
} from "reactstrap";

import userIcon from "../../assets/img/icons/orders/user.svg";
import briefCase from "../../assets/img/icons/orders/briefcase.svg";
import Driver from "../../assets/img/icons/orders/driver.svg";
import confirm from "../../assets/img/icons/orders/confirm.svg";
import car from "../../assets/img/icons/orders/car.svg";
import reached from "../../assets/img/icons/orders/reached.svg";
import started from "../../assets/img/icons/orders/started.svg";
import finished from "../../assets/img/icons/orders/finished.svg";

import firebase from "firebaseConfig";
import moment from "moment";
import StarRatings from "react-star-ratings";
import { Link, Redirect } from "react-router-dom";

const BreadCrumb = (props) => {
  return (
    <div>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to="/index">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="Orders1">Orders</Link>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

var completedOrderDetails = [];
var completedServiceDetails = [];
var serviceDetails = [];
var serviceTotalPrice = 0;
var addonTotalPrice = 0;
var arabic = /[\u0600-\u06FF]/;
const translateToEn = async (text, value) => {
  if (arabic.test(text)) {
    console.log("text ", arabic.test, text);
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "en",
        key: apiKey,
      },
    });
    // console.log('reply ', reply);
    // console.log("transated ",reply.data.data.translations[0].translatedText);
    return reply.data.data.translations[0].translatedText || "";
  } else return text;
};

class CompletedDetail extends Component {
  constructor() {
    super();
    this.state = {
      orderData: "",
      seviceData: "",
    };
  }

  getOrderDetails = async () => {
    var orderDetails;
    console.log(this.props.location.state.orderId);
    await firebase
      .database()
      .ref("/orders/all_orders/" + this.props.location.state.orderId)
      .once("value", async (snapshot) => {
        orderDetails = snapshot.val();
        completedOrderDetails["id"] = this.props.location.state.orderId.split(
          " "
        )[0];
        completedOrderDetails["status"] = orderDetails.status;
        completedOrderDetails["customer_name"] = orderDetails.customer_name;
        completedOrderDetails[
          "customer_name_english"
        ] = orderDetails.customer_name_english
          ? orderDetails.customer_name_english
          : null;
        completedOrderDetails["customer_id"] = orderDetails.customer_id;
        completedOrderDetails["customer_latitude"] =
          orderDetails.customer_latitude;
        completedOrderDetails["customer_longitude"] =
          orderDetails.customer_longitude;
        completedOrderDetails["sp_id"] = orderDetails.sp_id;
        completedOrderDetails["sp_name"] = orderDetails.sp_name;
        completedOrderDetails["orderRecievedDate"] = moment(
          orderDetails.time_order_accepted
        ).format("DD MM  YYYY hh:mm a");
        completedOrderDetails["driver_id"] = orderDetails.driver_id;
        completedOrderDetails["customer_raw_address"] =
          orderDetails.customer_raw_address;
        completedOrderDetails["customer_mobile_number"] =
          orderDetails.customer_mobile_number;
        completedOrderDetails[
          "order_id_number"
        ] = orderDetails.order_id_number.includes("_")
          ? orderDetails.order_id_number
              .split(" ")[2]
              .replace("_", "@")
              .replace("_", ".")
              .replace("_", ".")
          : "";
        completedOrderDetails["payment_mode"] =
          orderDetails.payment_mode === 0 ? "cash" : "online";
        completedOrderDetails["payment_method"] = orderDetails.payment_mode;
        completedOrderDetails["service_charge"] = orderDetails.serviceCharge
          ? orderDetails.serviceCharge
          : 0;
        completedOrderDetails["total_due"] = orderDetails.total_due;
        completedOrderDetails["coupon"] = "";
        completedOrderDetails["rating"] = Number(orderDetails.rating);

        await firebase
          .database()
          .ref("/drivers/" + orderDetails.driver_id + "/personal_information/")
          .once("value", (snapshot) => {
            var driverData = snapshot.val();
            completedOrderDetails["driver_name"] = driverData.name;
            completedOrderDetails["driver_phone"] = driverData.phone_number;
          });
      });
    {
      // completedOrderDetails.customer_name = await translateToEn(
      //   completedOrderDetails.customer_name
      // );
      completedOrderDetails.sp_name = await translateToEn(
        completedOrderDetails.sp_name
      );
      completedOrderDetails.customer_raw_address = await translateToEn(
        completedOrderDetails.customer_raw_address
      );
    }

    return completedOrderDetails;
  };

  getServiceDetails = async () => {
    await firebase
      .database()
      .ref(
        "/orders/all_orders/" + this.props.location.state.orderId + "/services/"
      )
      .once("value", (snapshot) => {
        completedServiceDetails = snapshot.val();
      });
    return completedServiceDetails;
  };

  async componentDidMount() {
    serviceDetails = [];
    serviceTotalPrice = 0;
    addonTotalPrice = 0;
    // this.getOrderDetails().then(response => {
    this.setState({
      orderData: await this.getOrderDetails(),
    });
    // });
    this.getServiceDetails().then((response) => {
      for (var key in response) {
        serviceDetails.push({
          ...response[key],
          vehicle: key,
        });
        for (var services in response[key].services) {
          serviceTotalPrice += response[key].services[services].price;
        }
        if (response[key].add_on_services) {
          for (var addonServices in response[key].add_on_services) {
            addonTotalPrice +=
              response[key].add_on_services[addonServices].price;
          }
        }
      }
      this.setState({
        seviceData: response,
      });
    });
  }

  render() {
    return (
      <div className="trackingComponent">
        <Header />

        <BreadCrumb />

        <Col md="12">
          <div className="orderId">
            <h3>{this.state.orderData.id}</h3>
            <p>(Completed Orders)</p>
          </div>
        </Col>

        <Col md="12">
          <div className="allCards">
            <Card>
              <CardHeader className="secondCard">
                <Row>
                  <Col md="4">
                    <p>Customer info</p>
                    <Row>
                      <Col md="2">
                        <img height={40} src={userIcon} alt="" />
                      </Col>
                      <Col md="6">
                        <h4>{this.state.orderData.customer_name}</h4>{" "}
                        {this.state.orderData.customer_name_english && (
                          <h4 style={{ textAlign: "center" }}>
                            {this.state.orderData.customer_name_english}
                          </h4>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <p>Service Provider info</p>
                    <Row>
                      <Col md="2">
                        <img height={40} src={briefCase} alt="" />
                      </Col>
                      <Col md="6">
                        <h4>{this.state.orderData.sp_name}</h4>
                        <h5>{this.state.orderData.orderRecievedDate}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <p>Technician info</p>
                    <Row>
                      <Col md="2">
                        <img height={40} src={Driver} alt="" />
                      </Col>
                      <Col md="4">
                        <h4>{this.state.orderData.driver_name}</h4>
                        <h5>{this.state.orderData.driver_phone}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <p>
                      <b>Address:</b>{" "}
                      {this.state.orderData.customer_raw_address}
                    </p>
                    <p>
                      <b>Phone:</b>{" "}
                      {this.state.orderData.customer_mobile_number}
                    </p>
                    <p>
                      <b>Email:</b> {this.state.orderData.order_id_number}
                    </p>
                  </Col>
                  <Col md="4">
                    <p>
                      <b>Mode of Payment::</b>{" "}
                      {this.state.orderData.payment_mode}
                    </p>
                    <p>
                      <b>Amount:</b> {this.state.orderData.total_due}
                    </p>
                    <p>
                      <b>Coupon:</b> Get50
                    </p>
                  </Col>
                  <Col md="4">
                    <p>
                      <b>Rating</b>
                      <StarRatings
                        rating={this.state.orderData.rating}
                        starRatedColor="#12ABEA"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="5px"
                        name="rating"
                      />
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>

        <Col md="12">
          <div className="allCards">
            {serviceDetails.map((serviceDetail) => (
              <Card>
                <CardHeader className="main-service-vehicle-header">
                  <h1>
                    {serviceDetail.vehicle
                      .replace("&", ", Vehicle No:")
                      .replace("_", " ")
                      .replace("“", " ")
                      .replace("”", " ")}
                  </h1>
                </CardHeader>
                <CardHeader>
                  <Row>
                    <p>Main services</p>
                    {(() => {
                      let servicesList = [];
                      for (var service in serviceDetail.services)
                        servicesList.push(
                          <tr className="col-md-12">
                            <td className="col-md-2">
                              {serviceDetail.services[service].name}
                            </td>
                            <td className="col-md-8">
                              {serviceDetail.services[service].price}
                            </td>
                          </tr>
                        );
                      return servicesList;
                    })()}
                  </Row>
                  {serviceDetail.add_on_services ? (
                    <Row className="AddOn">
                      <tr className="col-md-12">
                        <p>Add on’s</p>
                        <div>
                          {(() => {
                            let addonList = [];
                            for (var addon in serviceDetail.add_on_services) {
                              addonList.push(
                                <tr className="col-md-12">
                                  <td className="col-md-2">
                                    {serviceDetail.add_on_services[addon].name}
                                  </td>
                                  <td className="col-md-8">
                                    {serviceDetail.add_on_services[addon].price}
                                  </td>
                                </tr>
                              );
                            }
                            return addonList;
                          })()}
                        </div>
                      </tr>
                    </Row>
                  ) : (
                    ""
                  )}
                </CardHeader>
              </Card>
            ))}
            <Card>
              <CardBody>
                <tr className="col-md-12">
                  <th className="col-md-2">Total Amount</th>
                  <td className="col-md-8">
                    {serviceTotalPrice + addonTotalPrice}
                  </td>
                </tr>
              </CardBody>
            </Card>
          </div>
        </Col>
      </div>
    );
  }
}

export default CompletedDetail;
