import "./Orders.css";
import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "components/Headers/Header.jsx";
import OrderMap from "./OrderMap.jsx";
import DriverMap from "./DriverMap.jsx";

class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header />

        <Tabs defaultIndex={0} className="customTabs">
          <TabList>
            <Tab>
              <p>Driver Map</p>
            </Tab>
            <Tab>
              <p>Order Map</p>
            </Tab>
          </TabList>

          <TabPanel>
            <DriverMap />
          </TabPanel>
          <TabPanel>
            <OrderMap />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default Orders;
