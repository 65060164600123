import './Orders.css';
import React, { Component } from 'react';
import firebase from 'firebaseConfig';
import moment from 'moment';
import 'moment-timezone';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link, Redirect } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';
import logo from './twaddan-react.0a3a7e5e.jpg';
import PageLoader from '../../components/PageLoader/PageLoader';
import Select from 'react-select';
import Stepper from 'react-stepper-horizontal';
import userIcon from '../../assets/img/icons/orders/user.svg';
import briefCase from '../../assets/img/icons/orders/briefcase.svg';
import Driver from '../../assets/img/icons/orders/driver.svg';
import StarRatings from 'react-star-ratings';
import '../styles/Order.css';
import {
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    Container,
    CardHeader,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    CardBody,
    ModalHeader,
} from 'reactstrap';
import { WhatsappShareButton } from 'react-share';
import { WhatsappIcon } from 'react-share';
import { now } from 'moment';
import RealTime from 'services/RealTime';
import Switch from 'react-switch';
import Header from 'components/Headers/Header.jsx';
const columns = [
    {
        text: 'Si No',
        dataField: 'i',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        footer: ' ',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Order Id',
        dataField: 'orderId',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        footer: '    TOTAL',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Customer Name',
        dataField: 'customerName',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Phone',
        dataField: 'customer_mobile_number',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Date & Time',
        dataField: 'dateTimeString',
        footer: false,
        align: 'center',
        headerAlign: 'center',
        sort: true,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'ETC',
        dataField: 'etc',
        footer: false,
        align: 'center',
        headerAlign: 'center',
        sort: true,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Service Provider',
        dataField: 'serviceProvider',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
        style: {},
    },
    {
        text: 'Amount',
        dataField: 'amount',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
        footerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Mode of Payment',
        dataField: 'paymentMode',
        sort: true,
        align: 'center',
        filter: textFilter(),
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Status',
        dataField: 'status',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
];

const rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
        style.backgroundColor = '#e6e6e6';
    }
    style.cursor = 'pointer';
    return style;
};

const defaultSorted = [
    {
        dataField: 'orderId',
        order: 'desc',
    },
];
var activeOrderDetails = [];
var activeServiceDetails = [];
var serviceDetails = [];
var serviceTotalPrice = 0;
var addonTotalPrice = 0;
var completedOrderDetails = [];
var cancelledOrderDetails = [];
var completedServiceDetails = [];
const translateToEn = async (text, value) => {
    if (arabic.test(text)) {
        const axios = require('axios').default;
        const url = 'https://translation.googleapis.com/language/translate/v2';
        const apiKey = 'AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g';
        const reply = await axios.get(url, {
            params: {
                q: text,
                target: 'en',
                key: apiKey,
            },
        });
        // console.log('reply ', reply);
        // console.log("transated ",reply.data.data.translations[0].translatedText);
        return reply.data.data.translations[0].translatedText || '';
    } else return text;
};

var allOrderDetails = [];
var arabic = /[\u0600-\u06FF]/;

const getOrderDetails = async () => {
    await firebase
        .database()
        .ref('/orders/all_orders')
        .once('value', (snapshot) => {
            let i = 1;
            snapshot.forEach(function (childSnapshot) {
                var orderDetails = childSnapshot.val();
                if (orderDetails.order_id_number) {
                    allOrderDetails.push({
                        i: i++,
                        id: orderDetails.order_id_number,
                        orderId: orderDetails.order_id_number.split(' ')[0],
                        dateTime: moment(orderDetails.time_order_placed).tz('Asia/Dubai').format('MM/DD/YYYY hh:mm a'),
                        etc: moment(orderDetails.etc).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm a'),

                        dateTimeString: moment(orderDetails.time_order_placed)
                            .tz('Asia/Dubai')
                            .format('DD/MM/YYYY hh:mm a'),
                        // customerName: orderDetails.customer_name,
                        // serviceProvider: orderDetails.sp_name,
                        requestedTime: moment(orderDetails.time_order_placed)
                            .tz('Asia/Dubai')
                            .format('DD/MM/YYYY hh:mm a'),
                        customerName: orderDetails.customer_name_english
                            ? orderDetails.customer_name_english
                            : orderDetails.customer_name,
                        customer_mobile_number: orderDetails.customer_mobile_number
                            ? orderDetails.customer_mobile_number
                            : 'Nill',
                        serviceProvider: orderDetails.sp_name_english
                            ? orderDetails.sp_name_english
                            : orderDetails.sp_name,
                        serviceProviderId: orderDetails.sp_id,
                        amount: orderDetails.total_due,
                        totalVehicles: orderDetails.total_number_of_vehicles,
                        paymentMode: orderDetails.payment_mode === 0 ? 'cash' : 'online',
                        location: 'Dubai',
                        status:
                            orderDetails.status === -1
                                ? 'cancelled'
                                : orderDetails.status === 0
                                ? 'pending'
                                : orderDetails.status <= 4
                                ? 'active'
                                : 'completed',
                    });
                }
            });
        });
    let j = 1;
    allOrderDetails.map((obj) => (obj.i = allOrderDetails.length - obj.i + 1));
    return allOrderDetails;
};

const getOrderSpList = async () => {
    const dr = firebase.database().ref('service_providers/');
    let newService = [];
    newService.push({
        label: 'All',
        value: 'All',
    });
    dr.on('value', (snapshot) => {
        var drs = snapshot.val();
        if (drs != null) {
            var keys = Object.keys(drs);
            for (var dr = 0; dr < keys.length; dr++) {
                var k = keys[dr];
                newService.push({
                    label: drs[k]?.personal_information?.name,
                    value: k,
                });
            }
        }
    });
    return newService;
};
const getSum = async (orderList) => {
    let sumOFCash = 0,
        sumOfOnline = 0,
        sumOfTotal = 0,
        sumRefund = 0,
        sumRefundOnline = 0,
        totalVehiclesWashed = 0;
    orderList.forEach((element) => {
        if (element.paymentMode == 'cash') sumOFCash += element.amount;
        if (element.paymentMode == 'online') sumOfOnline += element.amount;
        if (element.status == 'cancelled' && element.paymentMode == 'cash') sumRefund += element.amount;
        if (element.status == 'cancelled' && element.paymentMode == 'online') sumRefundOnline += element.amount;
        if (element.totalVehicles && element.status === 'completed') totalVehiclesWashed += element.totalVehicles;
    });

    return {
        sumOFCash,
        sumOfOnline,
        sumRefund,
        sumRefundOnline,
        totalVehiclesWashed,
    };
};
class AllOrders extends React.Component {
    async componentDidMount() {
        if (this.state.isAll) {
            this.getAllOrderDetails();
        } else {
            this.getCurrentDayOrderDetails();
        }
    }
    getCurrentDayOrderDetails = async () => {
        this.setState({
            isLoaded: false,
        });
        console.log('TODAYS ORDERS FETCHING');

        let date = new Date();
        let allOrderDetails = [];

        var data = await RealTime.getDetails(
            `/order_day_node/${date.getFullYear()}/${date.getMonth()}/${date.getDate()}/`
        );
        if (data) {
            console.log('data', data);
            let i = Object.keys(data).length;
            for (const orderId in data) {
                let orderDetails = await RealTime.getDetails(`/orders/all_orders/${orderId}/`);
                allOrderDetails.push({
                    i: i--,
                    id: orderDetails.order_id_number,
                    orderId: orderDetails.order_id_number.split(' ')[0],
                    dateTime: moment(orderDetails.time_order_placed).tz('Asia/Dubai').format('MM/DD/YYYY hh:mm a'),
                    etc: moment(orderDetails.etc).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm a'),

                    dateTimeString: moment(orderDetails.time_order_placed)
                        .tz('Asia/Dubai')
                        .format('DD/MM/YYYY hh:mm a'),
                    // customerName: orderDetails.customer_name,
                    // serviceProvider: orderDetails.sp_name,
                    requestedTime: moment(orderDetails.time_order_placed).tz('Asia/Dubai').format('DD/MM/YYYY hh:mm a'),
                    customerName: orderDetails.customer_name_english
                        ? orderDetails.customer_name_english
                        : orderDetails.customer_name,
                    customer_mobile_number: orderDetails.customer_mobile_number
                        ? orderDetails.customer_mobile_number
                        : 'Nill',
                    serviceProvider: orderDetails.sp_name_english ? orderDetails.sp_name_english : orderDetails.sp_name,
                    serviceProviderId: orderDetails.sp_id,
                    amount: orderDetails.total_due,
                    totalVehicles: orderDetails.total_number_of_vehicles,
                    paymentMode: orderDetails.payment_mode === 0 ? 'cash' : 'online',
                    location: 'Dubai',
                    status:
                        orderDetails.status === -1
                            ? 'cancelled'
                            : orderDetails.status === 0
                            ? 'pending'
                            : orderDetails.status <= 4
                            ? 'active'
                            : 'completed',
                });
            }
        }
        let response2 = await getOrderSpList();
        let { sumOFCash, sumOfOnline, sumRefund, sumRefundOnline, totalVehiclesWashed } = await getSum(allOrderDetails);
        this.setState({
            allOrdersData: allOrderDetails,
            AllOrdersData: allOrderDetails,
            AAllOrdersData: allOrderDetails,
            spList: response2,
            selectedSp: response2[0],
            dateRange: 'From 01/01/2020  To ' + moment(new Date()).format('DD-MM-YYYY'),
            sumOFCash: sumOFCash,
            sumRefund: sumRefund,
            sumOfOnline: sumOfOnline,
            sumRefundOnline: sumRefundOnline,
            totalVehiclesWashed: totalVehiclesWashed,
            totalSum: sumOFCash + sumOfOnline - sumRefund,
            isLoaded: true,
        });
    };

    getAllOrderDetails = async () => {
        this.setState({
            isLoaded: false,
        });
        console.log('ALL ORDERS FETCHING');
        allOrderDetails = [];
        let response1 = await getOrderDetails();
        console.log('response1: ', response1);
        let response2 = await getOrderSpList();
        let { sumOFCash, sumOfOnline, sumRefund, sumRefundOnline, totalVehiclesWashed } = await getSum(response1);
        this.setState({
            allOrdersData: response1,
            AllOrdersData: response1,
            AAllOrdersData: response1,
            spList: response2,
            selectedSp: response2[0],
            dateRange: 'From 01/01/2020  To ' + moment(new Date()).format('DD-MM-YYYY'),
            sumOFCash: sumOFCash,
            sumRefund: sumRefund,
            sumOfOnline: sumOfOnline,
            sumRefundOnline: sumRefundOnline,
            totalVehiclesWashed: totalVehiclesWashed,
            totalSum: sumOFCash + sumOfOnline - sumRefund,
            isLoaded: true,
        });
    };
    constructor(props) {
        super(props);
        this.state = {
            orderId: '',
            allOrdersData: [],
            AllOrdersData: [],
            AAllOrdersData: [],
            selectedSp: 'selectedSp name',
            dateRange: 'date Range',
            isLoaded: true,
            ActiveOrderpopupToggle: false,
            orderData: '',
            isAll: false,
            steps: [
                {
                    title: 'Confirm',
                    key: 1,
                    icon: ['/static/media/confirm.776e6845.svg'],
                },
                {
                    title: 'Heading',
                    key: 2,
                    icon: ['/static/media/car.fd2ef083.svg'],
                },
                {
                    title: 'Reached',
                    key: 3,
                    icon: ['/static/media/reached.6b96f276.svg'],
                },
                {
                    title: 'Started',
                    key: 4,
                    icon: ['/static/media/started.feff6e69.svg'],
                },
                {
                    title: 'Finished',
                    key: 6,
                    icon: ['/static/media/finished.bc60195a.svg'],
                },
            ],
            currentStep: 0,
            orderData: '',
            modal: false,
            modals: false,
            seviceData: '',
            cancelOrderRedirect: false,
            showReasonTextfield: false,
            cancel_reason: null,
            cancelOrderRedirect: false,
            popupToggleCompletedOrder: false,
            reOrderStatus: false,
        };
        this.toggle = this.toggle.bind(this);
        this.toggles = this.toggles.bind(this);
        this.toggleDetailActiveOrder = this.toggleDetailActiveOrder.bind(this);
    }

    dateRangeHandler = async (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));
        let dateRange = 'date Range';

        if (picker)
            if (picker.startDate.format('DD/MM/YYYY') == picker.endDate.format('DD/MM/YYYY'))
                dateRange = picker.startDate.format('DD/MM/YYYY');
            else
                dateRange =
                    'From ' + picker.startDate.format('DD/MM/YYYY') + ' To ' + picker.endDate.format('DD/MM/YYYY');
        let i = 0;
        let newArray = [];

        this.state.AllOrdersData.map((odr) => {
            var nerOdr = Object.assign({}, odr);
            if (
                moment(nerOdr.dateTime).toDate() >= picker.startDate.toDate() &&
                moment(nerOdr.dateTime).toDate() <= picker.endDate.toDate()
            ) {
                i++;
                nerOdr.i = i;
                newArray.push(nerOdr);
            }
        });
        let j = -1;
        newArray = newArray.map((obj) => {
            j++;
            obj.i = newArray.length - j;
            return obj;
        });
        let { sumOFCash, sumOfOnline, sumRefund, sumRefundOnline, totalVehiclesWashed } = await getSum(newArray);
        this.setState({
            selectedSp: { label: 'All', value: 'All' },
            allOrdersData: newArray,
            AAllOrdersData: newArray,
            dateRange: dateRange,
            sumOFCash: sumOFCash,
            sumRefund: sumRefund,
            sumOfOnline: sumOfOnline,
            sumRefundOnline: sumRefundOnline,
            totalVehiclesWashed: totalVehiclesWashed,
            totalSum: sumOFCash + sumOfOnline - sumRefund,
        });
    };

    pdfGenerator = async () => {
        let sp = this.state.selectedSp;
        let dateRange = this.state.dateRange;
        const doc = new jsPDF();
        let outerArray = [];
        let i = 0;
        for (var keyOut in this.state.allOrdersData) {
            i++;
            let elementOut = await this.state.allOrdersData[keyOut];
            elementOut.i = i;
            let innerArray = [];
            for (var keyIn in elementOut)
                if (
                    !(
                        keyIn === 'id' ||
                        keyIn === 'dateTime' ||
                        keyIn === 'location' ||
                        keyIn === 'dateTimeString' ||
                        keyIn === 'etc' ||
                        keyIn === 'customer_mobile_number' ||
                        keyIn === 'serviceProviderId'
                    )
                ) {
                    // if( keyIn == "requestedTime"){
                    // console.log('elementOut[keyIn] ', elementOut[keyIn]);
                    // console.log(' ', moment(elementOut[keyIn])
                    // .tz("Asia/Dubai")
                    // .format("DD/MM/YYYY hh:mm a"));
                    // }
                    // innerArray.push(
                    //   keyIn == "requestedTime"
                    //     ? moment(elementOut[keyIn])
                    //         .tz("Asia/Dubai")
                    //         .format("DD/MM/YYYY hh:mm a")
                    //     : elementOut[keyIn]
                    // );
                    innerArray.push(elementOut[keyIn]);
                }
            outerArray.push(innerArray);
        }
        var header = function (data) {
            doc.setFontSize(10);
            doc.addImage(logo, 'JPEG', 13, 3, 32, 12);
            doc.setLineWidth(1);
            doc.setDrawColor(62, 142, 222);
            doc.line(14, 14, 196, 14);
            doc.text('Service Provider Sales Report', 14, 19);
            doc.text(dateRange, 14, 24);
            doc.text(sp.label == 'All' ? '' : sp.label, 197 - sp.label.length * 1.8, 19);
        };

        var options = {
            beforePageContent: header,
            margin: {
                top: 100,
            },
            startY: doc.autoTableEndPosY() + 20,
        };
        doc.autoTable([], [], options);
        doc.autoTable({
            head: [
                [
                    'SL.No',
                    'ORDER ID ',
                    'DATE & TIME',
                    'CUSTOMER NAME',
                    'SERVICE PROVIDER',
                    'AMOUNT',
                    'MODE OF PAYMENT',
                    'STATUS',
                ],
            ],
            body: outerArray,
        });
        var res = doc.autoTableHtmlToJson(document.getElementById('summery'));
        var option2 = {
            columnStyles: {
                0: { valign: 'top' },
                1: {
                    halign: 'center',
                },
                2: {
                    halign: 'right',
                },
            },
            headStyles: { halign: 'right' },
            willDrawCell: (data) => {
                if (data.section === 'head' && data.column.index === 0) {
                    data.halign = 'left';
                    data.cell.halign = 'left';
                    data.cell.styles.halign = 'left';
                    data.column.halign = 'left';
                }
            },
        };
        doc.autoTable(res.columns, res.data, option2);

        doc.save('All Order.pdf');
    };
    handleCancel = (event, picker) => {
        picker.element.val('');
        // this.clearFilter();
    };
    handleSpChange = async (event) => {
        if (event.value === 'All') {
            var newArray = this.state.AAllOrdersData;
        } else {
            var newArray = [];
            let i = 0;
            this.state.AAllOrdersData.filter(function (odr) {
                var nerOdr = Object.assign({}, odr);
                if (nerOdr.serviceProviderId == event.value) {
                    i++;
                    nerOdr.i = i;
                    newArray.push(nerOdr);
                }
            });
        }
        let j = -1;
        newArray = newArray.map((obj) => {
            j++;
            obj.i = newArray.length - j;
            return obj;
        });
        let { sumOFCash, sumOfOnline, sumRefund, sumRefundOnline, totalVehiclesWashed } = await getSum(newArray);
        this.setState({
            selectedSp: event,
            allOrdersData: newArray,
            sumOFCash: sumOFCash,
            sumRefund: sumRefund,
            sumOfOnline: sumOfOnline,
            sumRefundOnline: sumRefundOnline,
            totalVehiclesWashed: totalVehiclesWashed,
            totalSum: sumOFCash + sumOfOnline - sumRefund,
        });
    };

    //!=====ACTIVE ORDERS=====

    setdataActiveOrder = async () => {
        this.setState({
            isLoaded: false,
        });
        console.log('Set data');
        serviceDetails = [];
        serviceTotalPrice = 0;
        addonTotalPrice = 0;
        // this.getOrderDetails().then((response) => {
        this.setState(
            {
                orderData: await this.getOrderDetails(),
            },
            () => {
                console.log(this.state.orderData);
            }
        );
        // });
        this.getServiceDetails().then((response) => {
            console.log(response);
            for (var key in response) {
                serviceDetails.push({
                    ...response[key],
                    vehicle: key,
                });
                for (var services in response[key].services) {
                    serviceTotalPrice += response[key].services[services].price;
                }
                if (response[key].add_on_services) {
                    for (var addonServices in response[key].add_on_services) {
                        addonTotalPrice += response[key].add_on_services[addonServices].price;
                    }
                }
            }

            this.setState({
                seviceData: response,
                ActiveOrderpopupToggle: true,
            });
        });
        this.setState({
            isLoaded: true,
        });
    };
    getOrderDetails = async () => {
        console.log('getOrderDetails');
        console.log('getOrderDetails', this.state.orderId);
        var orderDetails;
        var vehicleTypes = [];

        if (true) {
            await firebase
                .database()
                .ref('/orders/all_orders/' + this.state.orderId)
                .once('value', async (snapshot) => {
                    orderDetails = snapshot.val();
                    this.setState({ orderDetails: orderDetails });
                    Object.entries(orderDetails.services).forEach(([key, veh_type]) => {
                        var res = key.split('&');
                        var vehicle_type = res[0].trim();
                        if (vehicleTypes.length > 0) {
                            let exist = false;
                            vehicleTypes.map((item) => {
                                if (item.id === vehicle_type) {
                                    item.count += 1;
                                    exist = true;
                                }
                            });
                            if (!exist)
                                vehicleTypes.push({
                                    id: vehicle_type,
                                    name: vehicle_type,
                                    count: 1,
                                });
                        } else {
                            vehicleTypes.push({
                                id: vehicle_type,
                                name: vehicle_type,
                                count: 1,
                            });
                        }
                    });
                    this.setState({ vehicleTypes: vehicleTypes });
                    console.log('orderDetails', orderDetails);
                    activeOrderDetails['id'] = this.state.orderId.split(' ')[0];
                    activeOrderDetails['status'] = orderDetails.status;
                    activeOrderDetails['customer_name'] = orderDetails.customer_name;
                    activeOrderDetails['customer_name_english'] = orderDetails.customer_name_english
                        ? orderDetails.customer_name_english
                        : null;
                    activeOrderDetails['customer_id'] = orderDetails.customer_id;
                    activeOrderDetails['customer_latitude'] = orderDetails.customer_latitude;
                    activeOrderDetails['customer_longitude'] = orderDetails.customer_longitude;
                    activeOrderDetails['sp_id'] = orderDetails.sp_id;
                    activeOrderDetails['sp_name'] = orderDetails.sp_name;
                    activeOrderDetails['orderRecievedDate'] = moment(orderDetails.time_order_accepted).format(
                        'DD MM  YYYY hh:mm a'
                    );
                    activeOrderDetails['driver_id'] = orderDetails.driver_id || '';
                    var res = orderDetails.customer_raw_address.split('Address from Location:');
                    var res2 = orderDetails.customer_raw_address.split('Address from Location :');
                    console.log('res details ->  ', res);
                    console.log('res details 2 ->', res2);
                    console.log('orderDetails.customer_raw_address', orderDetails.customer_raw_address);

                    if (res[1]) {
                        activeOrderDetails['customer_raw_address'] = res[1];
                        console.log('res', res[1]);
                    } else {
                        activeOrderDetails['customer_raw_address'] = res2[1];
                        console.log('res', res2[1]);
                    }
                    activeOrderDetails['customer_mobile_number'] = '0' + orderDetails.customer_mobile_number;
                    activeOrderDetails['customer_gmail_id'] = orderDetails.customer_gmail_id;
                    activeOrderDetails['order_id_number'] = orderDetails.order_id_number?.includes('_')
                        ? orderDetails.order_id_number
                              .split(' ')[2]
                              .replace('_', '@')
                              .replace('_', '.')
                              .replace('_', '.')
                        : '';
                    activeOrderDetails['payment_mode'] = orderDetails.payment_mode === 0 ? 'cash' : 'online';
                    activeOrderDetails['payment_method'] = orderDetails.payment_mode;
                    activeOrderDetails['service_charge'] = orderDetails.serviceCharge ? orderDetails.serviceCharge : 0;
                    activeOrderDetails['total_due'] = orderDetails.total_due;
                    activeOrderDetails['coupon_code'] = orderDetails.coupon_code;
                    activeOrderDetails['total_discount'] = orderDetails.total_discount;
                    activeOrderDetails['total_price_of_order'] = orderDetails.total_price_of_order;
                    activeOrderDetails['rating'] = Number(orderDetails.rating);
                    activeOrderDetails['orderRecievedTime'] = orderDetails.time_order_received
                        ? moment(orderDetails.time_order_received).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['orderAcceptedTime'] = orderDetails.time_order_accepted
                        ? moment(orderDetails.time_order_accepted).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['expectedTimeDriverStart'] = orderDetails.ets
                        ? moment(orderDetails.ets).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['timeDriverStarted'] = orderDetails.time_order_navigation_started
                        ? moment(orderDetails.time_order_navigation_started).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['expectedTimeArrival'] = orderDetails.eta
                        ? moment(orderDetails.eta).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['driverReachedTime'] = orderDetails.time_order_reached_location
                        ? moment(orderDetails.time_order_reached_location).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['expectedJobStartedTime'] = orderDetails.eta
                        ? moment(Number(orderDetails.eta) + 300000)
                              .tz('Asia/Dubai')
                              .format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['jobStartedTime'] = orderDetails.time_order_job_started
                        ? moment(orderDetails.time_order_job_started).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['expectedCompleteTime'] = orderDetails.etc
                        ? moment(orderDetails.etc).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    activeOrderDetails['completedTime'] = orderDetails.time_order_job_finished
                        ? moment(orderDetails.time_order_job_finished).tz('Asia/Dubai').format('hh:mm')
                        : 'Nil';
                    await firebase
                        .database()
                        .ref('/drivers/' + orderDetails.driver_id + '/personal_information/')
                        .once('value', (snapshot) => {
                            var driverData = snapshot.val();
                            activeOrderDetails['driver_name'] = driverData ? driverData.name : '';
                            activeOrderDetails['driver_phone'] = driverData ? driverData.phone_number : '';
                        });
                });
            {
                activeOrderDetails.customer_name = await translateToEn(activeOrderDetails.customer_name);
                activeOrderDetails.sp_name = await translateToEn(activeOrderDetails.sp_name);
                activeOrderDetails.customer_raw_address = await translateToEn(activeOrderDetails.customer_raw_address);
            }
            return activeOrderDetails;
        } else return false;
    };
    getServiceDetails = async () => {
        console.log('getServiceDetails');
        await firebase
            .database()
            .ref('/orders/all_orders/' + this.state.orderId + '/services/')
            .once('value', (snapshot) => {
                activeServiceDetails = snapshot.val();
            });

        return activeServiceDetails;
    };
    handleReasonChange = (event) => {
        console.log(event);
        if (event.value === 'other') {
            this.setState({
                showReasonTextfield: true,
                cancel_reason: null,
            });
            console.log(event.value);
        } else {
            this.setState({
                cancel_reason: event.value,
                showReasonTextfield: false,
            });
        }
    };
    checkCancel = () => {
        console.log(this.state.cancel_reason);
    };
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    toggles() {
        this.setState({
            modals: !this.state.modals,
        });
    }
    toggleDetailActiveOrder() {
        this.setState({
            ActiveOrderpopupToggle: !this.state.ActiveOrderpopupToggle,
            isLoaded: true,
        });
    }
    handleReasonText = (e) => {
        this.setState({
            cancel_reason: e.target.value,
        });
    };
    cancelOrder = async () => {
        console.log('this.props.location.state.orderId ', this.state.orderId);
        console.log('this.state.cancel_reason ', this.state.cancel_reason);
        const callableReturnMessage = await firebase.functions().httpsCallable('cancelOrderV2');
        callableReturnMessage({
            orderId: this.state.orderId,
            cancel_reason: this.state.cancel_reason,
        })
            .then((result) => {
                console.log('result ', result);
                console.log(result.data.output);
            })
            .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
            });
        this.setState({
            ActiveOrderpopupToggle: false,
            modals: false,
            isLoaded: true,
        });
    };
    moveOrderToCancel = async (orderId) => {
        console.log(`moving ${orderId} to cancelled orders`);
        const callableReturnMessage = await firebase.functions().httpsCallable('moveOrderToCancel');
        callableReturnMessage({
            orderId: orderId,
        })
            .then((result) => {
                console.log('result ', result);
                console.log(result.data.output);
            })
            .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
            });
        this.popupToggleCompletedOrder();
        this.getAllOrderDetails();
    };
    moveOrderToComplete = async (orderId) => {
        console.log(`moving ${orderId} to complete orders`);
        const callableReturnMessage = await firebase.functions().httpsCallable('moveOrderToComplete');
        callableReturnMessage({
            orderId: orderId,
        })
            .then((result) => {
                console.log('result ', result);
                console.log(result.data.output);
            })
            .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
            });
        this.popupToggleCompletedOrder();
        this.getAllOrderDetails();
    };
    cancelOrderAndReorder = async () => {
        console.log('this.props.location.state.orderId ', this.state.orderId);
        console.log('this.state.cancel_reason ', this.state.cancel_reason);
        const callableReturnMessage = await firebase.functions().httpsCallable('cancelOrderV2');
        callableReturnMessage({
            orderId: this.state.orderId,
            cancel_reason: this.state.cancel_reason,
        })
            .then((result) => {
                console.log('result ', result);
                console.log(result.data.output);
            })
            .catch((error) => {
                console.log(`error: ${JSON.stringify(error)}`);
            });
        this.setState({
            popupToggle: false,
            modals: false,
            isLoadedCombo: true,
            reOrderStatus: true,
        });
    };
    //!=====COMPLETED ORDERS===

    popupToggleCompletedOrder = () => {
        this.setState({
            popupToggleCompletedOrder: !this.state.popupToggleCompletedOrder,
            isLoaded: true,
        });
    };
    getOrderDetailsCompletedOrder = async () => {
        var orderDetails;
        console.log(this.state.orderId);
        await firebase
            .database()
            .ref('/orders/all_orders/' + this.state.orderId)
            .once('value', async (snapshot) => {
                orderDetails = snapshot.val();
                completedOrderDetails['id'] = this.state.orderId.split(' ')[0];
                completedOrderDetails['orderId'] = this.state.orderId;
                completedOrderDetails['status'] = orderDetails.status;
                completedOrderDetails['customer_name'] = orderDetails.customer_name;
                completedOrderDetails['customer_name_english'] = orderDetails.customer_name_english
                    ? orderDetails.customer_name_english
                    : null;
                completedOrderDetails['customer_id'] = orderDetails.customer_id;
                completedOrderDetails['customer_latitude'] = orderDetails.customer_latitude;
                completedOrderDetails['customer_longitude'] = orderDetails.customer_longitude;
                completedOrderDetails['sp_id'] = orderDetails.sp_id;
                completedOrderDetails['sp_name'] = orderDetails.sp_name;
                completedOrderDetails['orderRecievedDate'] = moment(orderDetails.time_order_accepted).format(
                    'DD MM  YYYY hh:mm a'
                );
                completedOrderDetails['driver_id'] = orderDetails.driver_id;
                var res = orderDetails.customer_raw_address.split('Address from Location:');
                var res2 = orderDetails.customer_raw_address.split('Address from Location :');
                console.log('res details ->  ', res);
                console.log('res details 2 ->', res2);
                console.log('orderDetails.customer_raw_address', orderDetails.customer_raw_address);

                if (res[1]) {
                    completedOrderDetails['customer_raw_address'] = res[1];
                    console.log('res', res[1]);
                } else {
                    completedOrderDetails['customer_raw_address'] = res2[1];
                    console.log('res', res2[1]);
                }
                completedOrderDetails['customer_mobile_number'] = '0' + orderDetails.customer_mobile_number;
                completedOrderDetails['customer_gmail_id'] = orderDetails.customer_gmail_id;
                completedOrderDetails['order_id_number'] = orderDetails.order_id_number.includes('_')
                    ? orderDetails.order_id_number.split(' ')[2].replace('_', '@').replace('_', '.').replace('_', '.')
                    : '';
                completedOrderDetails['payment_mode'] = orderDetails.payment_mode === 0 ? 'cash' : 'online';
                completedOrderDetails['payment_method'] = orderDetails.payment_mode;
                completedOrderDetails['service_charge'] = orderDetails.serviceCharge ? orderDetails.serviceCharge : 0;
                completedOrderDetails['total_due'] = orderDetails.total_due;
                completedOrderDetails['coupon_code'] = orderDetails.coupon_code;
                completedOrderDetails['total_discount'] = orderDetails.total_discount;
                completedOrderDetails['total_price_of_order'] = orderDetails.total_price_of_order;

                completedOrderDetails['coupon'] = '';
                completedOrderDetails['rating'] = Number(orderDetails.rating);
                completedOrderDetails['orderRecievedTime'] = orderDetails.time_order_received
                    ? moment(orderDetails.time_order_received).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['orderAcceptedTime'] = orderDetails.time_order_accepted
                    ? moment(orderDetails.time_order_accepted).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedTimeDriverStart'] = orderDetails.ets
                    ? moment(orderDetails.ets).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['timeDriverStarted'] = orderDetails.time_order_navigation_started
                    ? moment(orderDetails.time_order_navigation_started).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedTimeArrival'] = orderDetails.eta
                    ? moment(orderDetails.eta).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['driverReachedTime'] = orderDetails.time_order_reached_location
                    ? moment(orderDetails.time_order_reached_location).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedJobStartedTime'] = orderDetails.eta
                    ? moment(Number(orderDetails.eta) + 300000)
                          .tz('Asia/Dubai')
                          .format('hh:mm')
                    : 'Nil';
                completedOrderDetails['jobStartedTime'] = orderDetails.time_order_job_started
                    ? moment(orderDetails.time_order_job_started).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['expectedCompleteTime'] = orderDetails.etc
                    ? moment(orderDetails.etc).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                completedOrderDetails['completedTime'] = orderDetails.time_order_job_finished
                    ? moment(orderDetails.time_order_job_finished).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                await firebase
                    .database()
                    .ref('/drivers/' + orderDetails.driver_id + '/personal_information/')
                    .once('value', (snapshot) => {
                        var driverData = snapshot.val();
                        completedOrderDetails['driver_name'] = driverData.name;
                        completedOrderDetails['driver_phone'] = driverData.phone_number;
                    });
            });
        {
            // completedOrderDetails.customer_name = await translateToEn(
            //   completedOrderDetails.customer_name
            // );
            completedOrderDetails.sp_name = await translateToEn(completedOrderDetails.sp_name);
            completedOrderDetails.customer_raw_address = await translateToEn(
                completedOrderDetails.customer_raw_address
            );
        }

        return completedOrderDetails;
    };

    getServiceDetailsCompletedOrder = async () => {
        await firebase
            .database()
            .ref('/orders/all_orders/' + this.state.orderId + '/services/')
            .once('value', (snapshot) => {
                completedServiceDetails = snapshot.val();
            });
        return completedServiceDetails;
    };

    setDataCompletedOrder = async () => {
        this.setState({
            isLoaded: false,
        });
        serviceDetails = [];
        serviceTotalPrice = 0;
        addonTotalPrice = 0;
        // this.getOrderDetails().then(response => {
        this.setState({
            orderData: await this.getOrderDetailsCompletedOrder(),
        });
        // });
        this.getServiceDetailsCompletedOrder().then((response) => {
            for (var key in response) {
                serviceDetails.push({
                    ...response[key],
                    vehicle: key,
                });
                for (var services in response[key].services) {
                    serviceTotalPrice += response[key].services[services].price;
                }
                if (response[key].add_on_services) {
                    for (var addonServices in response[key].add_on_services) {
                        addonTotalPrice += response[key].add_on_services[addonServices].price;
                    }
                }
            }
            this.setState({
                seviceData: response,
                popupToggleCompletedOrder: true,
            });
        });
        this.setState({
            isLoaded: true,
        });
    };

    //!===CANCELLED ORDERS=====

    getOrderDetailsCancelledOrder = async () => {
        var orderDetails;
        console.log(this.state.orderId);
        await firebase
            .database()
            .ref('/orders/all_orders/' + this.state.orderId)
            .once('value', (snapshot) => {
                orderDetails = snapshot.val();
                cancelledOrderDetails['id'] = this.state.orderId.split(' ')[0];
                cancelledOrderDetails['orderId'] = this.state.orderId;
                cancelledOrderDetails['status'] = orderDetails.status;
                cancelledOrderDetails['customer_name'] = orderDetails.customer_name;
                cancelledOrderDetails['customer_name_english'] = orderDetails.customer_name_english
                    ? orderDetails.customer_name_english
                    : null;
                cancelledOrderDetails['customer_id'] = orderDetails.customer_id;
                cancelledOrderDetails['customer_latitude'] = orderDetails.customer_latitude;
                cancelledOrderDetails['customer_longitude'] = orderDetails.customer_longitude;
                cancelledOrderDetails['sp_id'] = orderDetails.sp_id;
                cancelledOrderDetails['sp_name'] = orderDetails.sp_name;
                cancelledOrderDetails['orderRecievedDate'] = moment(orderDetails.time_order_accepted).format(
                    'DD MM  YYYY hh:mm a'
                );
                cancelledOrderDetails['driver_id'] = orderDetails.driver_id;
                console.log('orderDetails', orderDetails.customer_raw_address);
                var res = orderDetails.customer_raw_address.split('Address from Location:');
                var res2 = orderDetails.customer_raw_address.split('Address from Location :');
                console.log('res details ->  ', res);
                console.log('res details 2 ->', res2);
                console.log('orderDetails.customer_raw_address', orderDetails.customer_raw_address);
                if (res[1]) {
                    cancelledOrderDetails['customer_raw_address'] = res[1];
                    console.log('res', res[1]);
                } else {
                    cancelledOrderDetails['customer_raw_address'] = res2[1];
                    console.log('res', res2[1]);
                }
                cancelledOrderDetails['customer_mobile_number'] = '0' + orderDetails.customer_mobile_number;
                cancelledOrderDetails['customer_gmail_id'] = orderDetails.customer_gmail_id;
                cancelledOrderDetails['order_id_number'] = orderDetails.order_id_number.includes('_')
                    ? orderDetails.order_id_number.split(' ')[2].replace('_', '@').replace('_', '.').replace('_', '.')
                    : '';
                cancelledOrderDetails['payment_mode'] = orderDetails.payment_mode === 0 ? 'cash' : 'online';
                cancelledOrderDetails['payment_method'] = orderDetails.payment_mode;
                cancelledOrderDetails['service_charge'] = orderDetails.serviceCharge ? orderDetails.serviceCharge : 0;
                cancelledOrderDetails['total_due'] = orderDetails.total_due;
                cancelledOrderDetails['coupon_code'] = orderDetails.coupon_code;
                cancelledOrderDetails['total_discount'] = orderDetails.total_discount;
                cancelledOrderDetails['total_price_of_order'] = orderDetails.total_price_of_order;
                cancelledOrderDetails['coupon'] = '';
                cancelledOrderDetails['rating'] = Number(orderDetails.rating);
                cancelledOrderDetails['orderRecievedTime'] = orderDetails.time_order_received
                    ? moment(orderDetails.time_order_received).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['orderAcceptedTime'] = orderDetails.time_order_accepted
                    ? moment(orderDetails.time_order_accepted).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['expectedTimeDriverStart'] = orderDetails.ets
                    ? moment(orderDetails.ets).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['timeDriverStarted'] = orderDetails.time_order_navigation_started
                    ? moment(orderDetails.time_order_navigation_started).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['expectedTimeArrival'] = orderDetails.eta
                    ? moment(orderDetails.eta).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['driverReachedTime'] = orderDetails.time_order_reached_location
                    ? moment(orderDetails.time_order_reached_location).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['expectedJobStartedTime'] = orderDetails.eta
                    ? moment(Number(orderDetails.eta) + 300000)
                          .tz('Asia/Dubai')
                          .format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['jobStartedTime'] = orderDetails.time_order_job_started
                    ? moment(orderDetails.time_order_job_started).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['expectedCompleteTime'] = orderDetails.etc
                    ? moment(orderDetails.etc).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['completedTime'] = orderDetails.time_order_job_finished
                    ? moment(orderDetails.time_order_job_finished).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                cancelledOrderDetails['cancelledTime'] = orderDetails.time_order_cancelled
                    ? moment(orderDetails.time_order_cancelled).tz('Asia/Dubai').format('hh:mm')
                    : 'Nil';
                firebase
                    .database()
                    .ref('/drivers/' + orderDetails?.driver_id + '/personal_information/')
                    .once('value', (snapshot) => {
                        var driverData = snapshot.val();
                        cancelledOrderDetails['driver_name'] = driverData?.name;
                        cancelledOrderDetails['driver_phone'] = driverData?.phone_number;
                    });
            });
        {
            // cancelledOrderDetails.customer_name = await translateToEn(
            //   cancelledOrderDetails.customer_name
            // );
            cancelledOrderDetails.sp_name = await translateToEn(cancelledOrderDetails.sp_name);
            cancelledOrderDetails.customer_raw_address = await translateToEn(
                cancelledOrderDetails?.customer_raw_address
            );
        }
        return cancelledOrderDetails;
    };

    getServiceDetailsCancelledOrder = async () => {
        await firebase
            .database()
            .ref('/orders/all_orders/' + this.state.orderId + '/services/')
            .once('value', (snapshot) => {
                completedServiceDetails = snapshot.val();
            });
        return completedServiceDetails;
    };

    async setDataCancelledOrder() {
        this.setState({
            isLoaded: false,
        });
        serviceDetails = [];
        serviceTotalPrice = 0;
        addonTotalPrice = 0;
        // this.getOrderDetails().then((response) => {
        this.setState({
            orderData: await this.getOrderDetailsCancelledOrder(),
        });
        // });
        this.getServiceDetailsCancelledOrder().then((response) => {
            for (var key in response) {
                serviceDetails.push({
                    ...response[key],
                    vehicle: key,
                });
                for (var services in response[key].services) {
                    serviceTotalPrice += response[key].services[services].price;
                }
                if (response[key].add_on_services) {
                    for (var addonServices in response[key].add_on_services) {
                        addonTotalPrice += response[key].add_on_services[addonServices].price;
                    }
                }
            }
            this.setState({
                seviceData: response,
                popupToggleCancelledOrder: true,
            });
        });
    }

    popupToggleCancelledOrder = () => {
        this.setState({
            popupToggleCancelledOrder: !this.state.popupToggleCancelledOrder,
            isLoaded: true,
        });
    };

    render() {
        const optionsList = [
            { value: 'Driver Not Avialable', label: 'Driver not available' },
            { value: 'Order made by mistake', label: 'Order made by mistake' },
            { value: 'other', label: 'Others' },
        ];
        if (this.state.reOrderStatus)
            return (
                <Redirect
                    to={{
                        pathname: '/admin/createOrder',
                        state: {
                            orderId: this.state.orderId,
                            vehicleTypes: this.state.vehicleTypes,
                            customer_latitude: this.state.orderData.customer_latitude,
                            customer_longitude: this.state.orderData.customer_longitude,
                            orderDetails: this.state.orderDetails,
                        },
                    }}
                />
            );

        let data = this.state.allOrdersData;
        const tableData = {
            columns,
            data,
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState(
                    {
                        redirect: true,
                        order: row,
                        orderId: row.id,
                    },
                    () => {
                        if (this.state.order.status) {
                            if (this.state.order.status == 'active') this.setdataActiveOrder();
                            if (this.state.order.status == 'completed') this.setDataCompletedOrder();
                            if (this.state.order.status == 'cancelled') this.setDataCancelledOrder();
                            if (this.state.order.status == 'pending') this.setdataActiveOrder();
                        }
                    }
                );
            },
        };
        var options = {
            noDataText: 'Your_custom_text',
        };
        return (
            <Container className="mt--7" fluid>
                <Header />
                <PageLoader loading={!this.state.isLoaded}></PageLoader>

                <div>
                    <Row>
                        {this.state.isAll && (
                            <div className="h5 mt-8 col-md-2">
                                <DateRangePicker
                                    block
                                    autoApply={true}
                                    onApply={this.dateRangeHandler}
                                    onCancel={this.handleCancel}
                                    showOneCalendar
                                    disabled={true}
                                    initialSettings={{
                                        autoUpdateInput: false,
                                        locale: {
                                            format: 'DD/MM/YYYY',
                                            cancelLabel: 'Clear',
                                        },
                                        startDate: moment(new Date()).tz('Asia/Dubai').format('DD MM YYYY hh:mm a'),
                                        endDate: moment(new Date()).tz('Asia/Dubai').format('DD MM YYYY hh:mm a'),
                                        ranges: {
                                            Today: [
                                                moment().tz('Asia/Dubai').toDate(),
                                                moment().tz('Asia/Dubai').toDate(),
                                            ],
                                            Yesterday: [
                                                moment().subtract(1, 'days').toDate(),
                                                moment().subtract(1, 'days').toDate(),
                                            ],
                                            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                                            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                                            'This Month': [
                                                moment().startOf('month').toDate(),
                                                moment().endOf('month').toDate(),
                                            ],
                                            'Last Month': [
                                                moment().subtract(1, 'month').startOf('month').toDate(),
                                                moment().subtract(1, 'month').endOf('month').toDate(),
                                            ],
                                        },
                                    }}
                                >
                                    <input
                                        type="text"
                                        style={{ maxWidth: '300px', display: 'inline-block' }}
                                        className="form-control"
                                        defaultValue=""
                                        placeholder="Select Date Range"
                                    />
                                </DateRangePicker>
                            </div>
                        )}
                        <div className="h5 mt-8 col-md-2">
                            <Select
                                style={{
                                    width: '100%',
                                    minWidth: '300px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                }}
                                value={this.state.selectedSp}
                                onChange={this.handleSpChange}
                                options={this.state.spList}
                            />
                        </div>
                        <div className="h5 mt-8 col-md-2">
                            <Button
                                style={{
                                    maxWidth: '300px',
                                    display: 'inline-block',
                                    marginLeft: '20px',
                                }}
                                color="info"
                                onClick={this.pdfGenerator}
                            >
                                EXPORT
                            </Button>
                        </div>
                        <div className="h5 mt-8 col-md-2">
                            <h3>{this.state.isAll ? ' ALL ORDERS' : " TODAY'S ORDERS"}</h3>
                        </div>
                        <div className="h5 mt-8 col-md-2">
                            <Switch
                                onChange={() => {
                                    this.setState(
                                        {
                                            isAll: !this.state.isAll,
                                        },
                                        () => {
                                            if (this.state.isAll) {
                                                this.getAllOrderDetails();
                                            } else {
                                                this.getCurrentDayOrderDetails();
                                            }
                                        }
                                    );
                                }}
                                checked={this.state.isAll}
                            />
                        </div>
                    </Row>

                    <div>
                        {this.state.allOrdersData.length > 0 ? (
                            <BootstrapTable
                                keyField="orderId"
                                data={this.state.allOrdersData}
                                columns={columns}
                                rowStyle={rowStyle}
                                filter={filterFactory()}
                                filterPosition="top"
                                pagination={paginationFactory()}
                                defaultSorted={defaultSorted}
                                bordered={false}
                                hover
                                wrapperClasses="table-responsive"
                                condensed
                                rowEvents={rowEvents}
                            />
                        ) : (
                            <div>
                                <br />
                                <br />
                                <h3 color="red">{this.state.isLoaded ? 'No Records Found' : 'Please Wait...'}</h3>
                                <br />
                                <br />
                            </div>
                        )}
                    </div>
                </div>

                <table id="summery" style={{ paddingTop: '10px' }}>
                    <tbody>
                        <tr>
                            <td>Total cash orders </td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {this.state.sumOFCash}
                            </td>
                        </tr>
                        <tr>
                            <td>Total online payment</td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {' '}
                                {this.state.sumOfOnline}
                            </td>
                        </tr>
                        <tr>
                            <td>Total Cash Refund</td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {' '}
                                {this.state.sumRefund}
                            </td>
                        </tr>
                        <tr>
                            <td>Total Online Refund</td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {' '}
                                {this.state.sumRefundOnline}
                            </td>
                        </tr>
                        <tr>
                            <td>Total Vehicle Washed</td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {' '}
                                {this.state.totalVehiclesWashed}
                            </td>
                        </tr>
                        <tr>
                            <td>Net Amount Received</td>
                            <td>: </td>
                            <td
                                style={{
                                    paddingLeft: '40px',
                                    paddingTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                {this.state.sumOFCash +
                                    this.state.sumOfOnline -
                                    this.state.sumRefund -
                                    this.state.sumRefundOnline}
                            </td>
                        </tr>
                    </tbody>
                    <thead>
                        <tr>
                            <th>Order summary </th>
                            <td> </td>
                            <th></th>
                        </tr>
                    </thead>
                </table>

                {/* Active order popup  */}

                <Modal isOpen={this.state.ActiveOrderpopupToggle} toggle={this.toggleDetailActiveOrder} size="lg">
                    <ModalHeader toggle={this.toggleDetailActiveOrder}>
                        <h1>Active Order</h1>
                    </ModalHeader>
                    <ModalBody>
                        <Col md="12">
                            <div className="orderId">
                                <h3>{this.state.orderData?.id}</h3>
                                <p>
                                    <big>(Active Orders)</big>
                                </p>
                            </div>
                        </Col>
                        <Col md="12">
                            <div>
                                <Card>
                                    <CardHeader>Order Status</CardHeader>
                                    <CardBody>
                                        <div className="stepper">
                                            <Stepper
                                                steps={this.state.steps}
                                                activeStep={this.state.orderData.status}
                                                completeBarColor="#1D568E"
                                            />
                                        </div>
                                    </CardBody>
                                    {this.state.orderData.expectedTimeDriverStart !== 'Nil' ? (
                                        <div hidden={this.state.orderData.status > 3}>
                                            <CardBody className="text-center">
                                                <div className="trackButtons">
                                                    <Button
                                                        size="md"
                                                        outline
                                                        color="primary"
                                                        onClick={() =>
                                                            this.setState({
                                                                cancel_reason: null,
                                                                modals: true,
                                                            })
                                                        }
                                                    >
                                                        CANCEL ORDER
                                                    </Button>{' '}
                                                </div>
                                            </CardBody>
                                        </div>
                                    ) : (
                                        <div hidden={this.state.orderData.status > 3}>
                                            <CardBody className="text-center">
                                                <div className="trackButtons">
                                                    <p>Order Under Processing...</p>
                                                </div>
                                            </CardBody>
                                        </div>
                                    )}
                                </Card>
                            </div>
                        </Col>
                        <Col md="12">
                            <div class="table-responsive">
                                <table className="tableOrder">
                                    <tr>
                                        <th className="tableItem">Order Placed Time</th>
                                        <th className="tableItem">Expected Time Of Driver Start</th>
                                        <th className="tableItem">Expected Time Of Arrival</th>
                                        <th className="tableItem">Expected Time Of Job Start</th>
                                        <th className="tableItem">Expected Time Of Job Completion</th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderRecievedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeDriverStart}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeArrival}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedJobStartedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedCompleteTime}</td>
                                    </tr>
                                    <tr>
                                        <th className="tableItem">Order Accepted Time</th>
                                        <th className="tableItem">Driver Started Time</th>
                                        <th className="tableItem">Driver Arrived Time</th>
                                        <th className="tableItem">Job Started Time</th>
                                        <th className="tableItem">Job Completed Time</th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderAcceptedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.timeDriverStarted}</td>
                                        <td className="tableItem"> {this.state.orderData.driverReachedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.jobStartedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.completedTime}</td>
                                    </tr>
                                </table>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="allCards">
                                <Card>
                                    <CardHeader className="secondCard">
                                        <Row>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Customer info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={userIcon}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.customer_name}
                                                        </h4>
                                                        {this.state.orderData.customer_name_english &&
                                                            this.state.orderData.customer_name_english !==
                                                                this.state.orderData.customer_name && (
                                                                <h4 style={{ textAlign: 'center' }}>
                                                                    {this.state.orderData.customer_name_english}
                                                                </h4>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Service Provider info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={briefCase}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.sp_name}
                                                        </h4>
                                                        {/* <h5 style={{ textAlign: "center" }}>
                              {this.state.orderData.orderRecievedDate}
                            </h5> */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Technician info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={Driver}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_name}
                                                        </h4>
                                                        <h5 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_phone}
                                                        </h5>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <b>Address:</b> {this.state.orderData.customer_raw_address}
                                                </p>
                                                <p>
                                                    <b>Phone:</b> {this.state.orderData.customer_mobile_number}
                                                </p>
                                                <p>
                                                    <b>Email:</b> {this.state.orderData.customer_gmail_id}
                                                </p>
                                            </Col>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <p>
                                                        <b>Mode of Payment:</b> {this.state.orderData.payment_mode}
                                                    </p>
                                                    <p>
                                                        <b>Coupon:</b>{' '}
                                                        {this.state.orderData.coupon_code
                                                            ? this.state.orderData.coupon_code
                                                            : 'No Coupon Used'}
                                                    </p>
                                                    <p>
                                                        <b>Discount:</b>{' '}
                                                        {this.state.orderData.total_discount
                                                            ? this.state.orderData.total_discount
                                                            : 0}
                                                    </p>

                                                    <b>Rating</b>

                                                    <StarRatings
                                                        rating={this.state.orderData.rating}
                                                        starRatedColor="#12ABEA"
                                                        numberOfStars={5}
                                                        starDimension="20px"
                                                        starSpacing="5px"
                                                        name="rating"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="allCards">
                                {serviceDetails.map((serviceDetail) => (
                                    <Card>
                                        <CardHeader className="main-service-vehicle-header">
                                            <h1>
                                                {serviceDetail.vehicle
                                                    .replace('&', ', Vehicle No:')
                                                    .replace('_', ' ')
                                                    .replace('“', ' ')
                                                    .replace('”', ' ')}
                                            </h1>
                                        </CardHeader>
                                        <CardHeader>
                                            <Row>
                                                <p>Main services</p>
                                                {(() => {
                                                    let servicesList = [];
                                                    for (var service in serviceDetail.services)
                                                        servicesList.push(
                                                            <tr className="col-md-12">
                                                                <td className="col-md-2">
                                                                    {serviceDetail.services[service].name}
                                                                </td>
                                                                <td className="col-md-8">
                                                                    {serviceDetail.services[service].price}
                                                                </td>
                                                            </tr>
                                                        );
                                                    return servicesList;
                                                })()}
                                            </Row>
                                            {serviceDetail.add_on_services ? (
                                                <Row className="AddOn">
                                                    <tr className="col-md-12">
                                                        <p>Add on’s</p>
                                                        <div>
                                                            {(() => {
                                                                let addonList = [];
                                                                for (var addon in serviceDetail.add_on_services) {
                                                                    addonList.push(
                                                                        <tr className="col-md-12">
                                                                            <td className="col-md-2">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .name
                                                                                }
                                                                            </td>
                                                                            <td className="col-md-8">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .price
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return addonList;
                                                            })()}
                                                        </div>
                                                    </tr>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                        </CardHeader>
                                    </Card>
                                ))}
                                <br />
                                {this.state.orderData.total_price_of_order > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Sub Total </th>
                                                <td className="col-md-8">
                                                    {this.state.orderData.total_price_of_order}
                                                </td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.service_charge > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Service Charge</th>
                                                <td className="col-md-8">+{this.state.orderData.service_charge}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.total_discount > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Service Charge</th>
                                                <td className="col-md-8">-{this.state.orderData.total_discount}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}

                                <Card>
                                    <CardBody>
                                        <tr className="col-md-12">
                                            <th className="col-md-2">Total Amount</th>
                                            <td className="col-md-8">{this.state.orderData.total_due}</td>
                                        </tr>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.toggleDetailActiveOrder} color="danger">
                            CLOSE
                        </Button>
                        <Button style={{ backgroundColor: '#5CD354', color: '#ffffff' }}>
                            <WhatsappShareButton
                                title={`Location of *${this.state.orderData.customer_name}*(${this.state.orderData.customer_mobile_number})`}
                                separator=" Click link:  "
                                url={`https://maps.google.com/?q=${this.state.orderData.customer_latitude},${this.state.orderData.customer_longitude}`}
                            >
                                <WhatsappIcon size={25}></WhatsappIcon> SHARE LOCATION
                            </WhatsappShareButton>
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modals} toggle={this.toggles}>
                    <ModalBody>
                        <p>Select Reason of Cancelation</p>
                        <Select style={{}} onChange={this.handleReasonChange} options={optionsList} />
                        {this.state.showReasonTextfield ? (
                            <Input
                                placeholder="Enter your reason"
                                value={this.state.cancel_reason}
                                onChange={this.handleReasonText}
                            />
                        ) : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="info" onClick={this.toggles}>
                            N0
                        </Button>
                        <Button color="danger" disabled={!this.state.cancel_reason} onClick={this.cancelOrder}>
                            YES
                        </Button>
                        <Button
                            color="danger"
                            disabled={!this.state.cancel_reason}
                            onClick={this.cancelOrderAndReorder}
                        >
                            REORDER
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.popupToggleCompletedOrder} toggle={this.popupToggleCompletedOrder} size="lg">
                    <ModalHeader toggle={this.popupToggleCompletedOrder}>
                        <h1>Completed Order</h1>
                    </ModalHeader>
                    <ModalBody>
                        <Col md="12">
                            <div className="orderId">
                                <h3>{this.state.orderData.id}</h3>
                                <p>(Completed Orders)</p>
                            </div>
                        </Col>
                        <Col md="12">
                            <div class="table-responsive">
                                <table className="tableOrder">
                                    <tr>
                                        <th className="tableItem">Order Received Time</th>
                                        <th className="tableItem">Expected Time Of Driver Start</th>
                                        <th className="tableItem">Expected Time Of Arrival</th>
                                        <th className="tableItem">Expected Time Of Job Start</th>
                                        <th className="tableItem">Expected Time Of Job Completion</th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderRecievedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeDriverStart}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeArrival}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedJobStartedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedCompleteTime}</td>
                                    </tr>
                                    <tr>
                                        <th className="tableItem">Order Accepted Time</th>
                                        <th className="tableItem">Driver Started Time</th>
                                        <th className="tableItem">Driver Arrived Time</th>
                                        <th className="tableItem">Job Started Time</th>
                                        <th className="tableItem">Job Completed Time</th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderAcceptedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.timeDriverStarted}</td>
                                        <td className="tableItem"> {this.state.orderData.driverReachedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.jobStartedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.completedTime}</td>
                                    </tr>
                                </table>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="allCards">
                                <Card>
                                    <CardHeader className="secondCard">
                                        <Row>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Customer info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={userIcon}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.customer_name}
                                                        </h4>
                                                        {this.state.orderData.customer_name_english &&
                                                            this.state.orderData.customer_name_english !==
                                                                this.state.orderData.customer_name && (
                                                                <h4 style={{ textAlign: 'center' }}>
                                                                    {this.state.orderData.customer_name_english}
                                                                </h4>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Service Provider info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={briefCase}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.sp_name}
                                                        </h4>
                                                        {/* <h5 style={{ textAlign: "center" }}>
                              {this.state.orderData.orderRecievedDate}
                            </h5> */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Technician info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={Driver}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_name}
                                                        </h4>
                                                        <h5 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_phone}
                                                        </h5>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <b>Address:</b> {this.state.orderData.customer_raw_address}
                                                </p>
                                                <p>
                                                    <b>Phone:</b> {this.state.orderData.customer_mobile_number}
                                                </p>
                                                <p>
                                                    <b>Email:</b> {this.state.orderData.customer_gmail_id}
                                                </p>
                                            </Col>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <p>
                                                        <b>Mode of Payment:</b> {this.state.orderData.payment_mode}
                                                    </p>
                                                    <p>
                                                        <b>Coupon:</b>{' '}
                                                        {this.state.orderData.coupon_code
                                                            ? this.state.orderData.coupon_code
                                                            : 'No Coupon Used'}
                                                    </p>
                                                    <p>
                                                        <b>Discount:</b>{' '}
                                                        {this.state.orderData.total_discount
                                                            ? this.state.orderData.total_discount
                                                            : 0}
                                                    </p>
                                                    <b>Rating</b>

                                                    <StarRatings
                                                        rating={this.state.orderData.rating}
                                                        starRatedColor="#12ABEA"
                                                        numberOfStars={5}
                                                        starDimension="20px"
                                                        starSpacing="5px"
                                                        name="rating"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>

                        <Col md="12">
                            <div className="allCards">
                                {serviceDetails.map((serviceDetail) => (
                                    <Card>
                                        <CardHeader className="main-service-vehicle-header">
                                            <h1>
                                                {serviceDetail.vehicle
                                                    .replace('&', ', Vehicle No:')
                                                    .replace('_', ' ')
                                                    .replace('“', ' ')
                                                    .replace('”', ' ')}
                                            </h1>
                                        </CardHeader>
                                        <CardHeader>
                                            <Row>
                                                <p>Main services</p>
                                                {(() => {
                                                    let servicesList = [];
                                                    for (var service in serviceDetail.services)
                                                        servicesList.push(
                                                            <tr className="col-md-12">
                                                                <td className="col-md-2">
                                                                    {serviceDetail.services[service].name}
                                                                </td>
                                                                <td className="col-md-8">
                                                                    {serviceDetail.services[service].price}
                                                                </td>
                                                            </tr>
                                                        );
                                                    return servicesList;
                                                })()}
                                            </Row>
                                            {serviceDetail.add_on_services ? (
                                                <Row className="AddOn">
                                                    <tr className="col-md-12">
                                                        <p>Add on’s</p>
                                                        <div>
                                                            {(() => {
                                                                let addonList = [];
                                                                for (var addon in serviceDetail.add_on_services) {
                                                                    addonList.push(
                                                                        <tr className="col-md-12">
                                                                            <td className="col-md-2">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .name
                                                                                }
                                                                            </td>
                                                                            <td className="col-md-8">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .price
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return addonList;
                                                            })()}
                                                        </div>
                                                    </tr>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                        </CardHeader>
                                    </Card>
                                ))}
                                <br />
                                {this.state.orderData.total_price_of_order > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Sub Total </th>
                                                <td className="col-md-8">
                                                    {this.state.orderData.total_price_of_order}
                                                </td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.service_charge > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Service Charge</th>
                                                <td className="col-md-8">+{this.state.orderData.service_charge}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.total_discount > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Service Charge</th>
                                                <td className="col-md-8">-{this.state.orderData.total_discount}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}

                                <Card>
                                    <CardBody>
                                        <tr className="col-md-12">
                                            <th className="col-md-2">Total Amount</th>
                                            <td className="col-md-8">{this.state.orderData.total_due}</td>
                                        </tr>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.moveOrderToCancel(this.state.orderData.orderId);
                            }}
                            color="danger"
                        >
                            MOVE TO CANCEL
                        </Button>
                        <Button onClick={this.popupToggleCompletedOrder} color="danger">
                            CLOSE
                        </Button>
                        <Button style={{ backgroundColor: '#5CD354', color: '#ffffff' }}>
                            <WhatsappShareButton
                                title={`Location of *${this.state.orderData.customer_name}*(${this.state.orderData.customer_mobile_number})`}
                                separator=" Click link:  "
                                url={`https://maps.google.com/?q=${this.state.orderData.customer_latitude},${this.state.orderData.customer_longitude}`}
                            >
                                <WhatsappIcon size={25}></WhatsappIcon> SHARE LOCATION
                            </WhatsappShareButton>
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.popupToggleCancelledOrder} toggle={this.popupToggleCancelledOrder} size="lg">
                    <ModalHeader toggle={this.popupToggleCancelledOrder}>
                        <h1>Cancelled Orders</h1>
                    </ModalHeader>
                    <ModalBody>
                        <Col md="12">
                            <div className="orderId">
                                <h3>{this.state.orderData.id}</h3>
                                <p>
                                    <big>(Cancelled Orders)</big>
                                </p>
                            </div>
                        </Col>
                        <Col md="12">
                            <div class="table-responsive">
                                <table className="tableOrder">
                                    <tr>
                                        <th className="tableItem">Order Received Time</th>
                                        <th className="tableItem">Expected Time Of Driver Start</th>
                                        <th className="tableItem">Expected Time Of Arrival</th>
                                        <th className="tableItem">Expected Time Of Job Start</th>
                                        <th className="tableItem">Expected Time Of Job Completion</th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderRecievedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeDriverStart}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedTimeArrival}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedJobStartedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.expectedCompleteTime}</td>
                                    </tr>
                                    <tr>
                                        <th className="tableItem">Order Accepted Time</th>
                                        <th className="tableItem">Driver Started Time</th>
                                        <th className="tableItem">Driver Arrived Time</th>
                                        <th className="tableItem" colspan="2">
                                            Order Cancelled Time
                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="tableItem"> {this.state.orderData.orderAcceptedTime}</td>
                                        <td className="tableItem"> {this.state.orderData.timeDriverStarted}</td>
                                        <td className="tableItem"> {this.state.orderData.driverReachedTime}</td>
                                        <td className="tableItem" colspan="2">
                                            {' '}
                                            {this.state.orderData.cancelledTime}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="allCards">
                                <Card>
                                    <CardHeader className="secondCard">
                                        <Row>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Customer info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={userIcon}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.customer_name}
                                                        </h4>
                                                        {this.state.orderData.customer_name_english &&
                                                            this.state.orderData.customer_name_english !==
                                                                this.state.orderData.customer_name && (
                                                                <h4 style={{ textAlign: 'center' }}>
                                                                    {this.state.orderData.customer_name_english}
                                                                </h4>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Service Provider info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={briefCase}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.sp_name}
                                                        </h4>
                                                        {/* <h5 style={{ textAlign: "center" }}>
                              {this.state.orderData.orderRecievedDate}
                            </h5> */}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="12" lg="4">
                                                <p style={{ textAlign: 'center' }}>Technician info</p>
                                                <Row>
                                                    <Col md="12" lg="12" style={{ display: 'flex' }}>
                                                        <img
                                                            height={40}
                                                            src={Driver}
                                                            alt=""
                                                            style={{ margin: 'auto' }}
                                                        />
                                                    </Col>
                                                    <Col md="12" lg="12" style={{ margin: 'auto' }}>
                                                        <h4 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_name}
                                                        </h4>
                                                        <h5 style={{ textAlign: 'center' }}>
                                                            {this.state.orderData.driver_phone}
                                                        </h5>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <b>Address:</b> {this.state.orderData.customer_raw_address}
                                                </p>
                                                <p>
                                                    <b>Phone:</b> {this.state.orderData.customer_mobile_number}
                                                </p>
                                                <p>
                                                    <b>Email:</b> {this.state.orderData.customer_gmail_id}
                                                </p>
                                            </Col>
                                            <Col xs="12" sm="12" md="6">
                                                <p>
                                                    <p>
                                                        <b>Mode of Payment:</b> {this.state.orderData.payment_mode}
                                                    </p>
                                                    <p>
                                                        <b>Coupon:</b>{' '}
                                                        {this.state.orderData.coupon_code
                                                            ? this.state.orderData.coupon_code
                                                            : 'No Coupon Used'}
                                                    </p>
                                                    <p>
                                                        <b>Discount:</b>{' '}
                                                        {this.state.orderData.total_discount
                                                            ? this.state.orderData.total_discount
                                                            : 0}
                                                    </p>
                                                    <b>Rating</b>

                                                    <StarRatings
                                                        rating={this.state.orderData.rating}
                                                        starRatedColor="#12ABEA"
                                                        numberOfStars={5}
                                                        starDimension="20px"
                                                        starSpacing="5px"
                                                        name="rating"
                                                    />
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>

                        <Col md="12">
                            <div className="allCards">
                                {serviceDetails.map((serviceDetail) => (
                                    <Card>
                                        <CardHeader className="main-service-vehicle-header">
                                            <h1>
                                                {serviceDetail.vehicle
                                                    .replace('&', ', Vehicle No:')
                                                    .replace('_', ' ')
                                                    .replace('“', ' ')
                                                    .replace('”', ' ')}
                                            </h1>
                                        </CardHeader>
                                        <CardHeader>
                                            <Row>
                                                <p>Main services</p>
                                                {(() => {
                                                    let servicesList = [];
                                                    for (var service in serviceDetail.services)
                                                        servicesList.push(
                                                            <tr className="col-md-12">
                                                                <td className="col-md-2">
                                                                    {serviceDetail.services[service].name}
                                                                </td>
                                                                <td className="col-md-8">
                                                                    {serviceDetail.services[service].price}
                                                                </td>
                                                            </tr>
                                                        );
                                                    return servicesList;
                                                })()}
                                            </Row>
                                            {serviceDetail.add_on_services ? (
                                                <Row>
                                                    <tr className="col-md-12">
                                                        <p>Add on’s</p>
                                                        <div>
                                                            {(() => {
                                                                let addonList = [];
                                                                for (var addon in serviceDetail.add_on_services) {
                                                                    addonList.push(
                                                                        <tr className="col-md-12">
                                                                            <td className="col-md-2">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .name
                                                                                }
                                                                            </td>
                                                                            <td className="col-md-8">
                                                                                {
                                                                                    serviceDetail.add_on_services[addon]
                                                                                        .price
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                                return addonList;
                                                            })()}
                                                        </div>
                                                    </tr>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                        </CardHeader>
                                    </Card>
                                ))}
                                <br />
                                {this.state.orderData.total_price_of_order > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Sub Total </th>
                                                <td className="col-md-8">
                                                    {this.state.orderData.total_price_of_order}
                                                </td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.service_charge > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Service Charge</th>
                                                <td className="col-md-8">+{this.state.orderData.service_charge}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}
                                {this.state.orderData.total_discount > 0 && (
                                    <Card>
                                        <CardBody>
                                            <tr className="col-md-12">
                                                <th className="col-md-2">Service Charge</th>
                                                <td className="col-md-8">-{this.state.orderData.total_discount}</td>
                                            </tr>
                                        </CardBody>
                                    </Card>
                                )}

                                <Card>
                                    <CardBody>
                                        <tr className="col-md-12">
                                            <th className="col-md-2">Total Amount</th>
                                            <td className="col-md-8">{this.state.orderData.total_due}</td>
                                        </tr>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={() => {
                                this.moveOrderToComplete(this.state.orderData.orderId);
                            }}
                        >
                            MOVE TO COMPLETED
                        </Button>
                        <Button color="danger" onClick={this.popupToggleCancelledOrder}>
                            CLOSE
                        </Button>
                        <Button style={{ backgroundColor: '#5CD354', color: '#ffffff' }}>
                            <WhatsappShareButton
                                title={`Location of *${this.state.orderData.customer_name}*(${this.state.orderData.customer_mobile_number})`}
                                separator=" Click link:  "
                                url={`https://maps.google.com/?q=${this.state.orderData.customer_latitude},${this.state.orderData.customer_longitude}`}
                            >
                                <WhatsappIcon size={25}></WhatsappIcon> SHARE LOCATION
                            </WhatsappShareButton>
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

export default AllOrders;
