import React from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Card,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  CardImg,
  ModalHeader,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.jsx";
import { Zap, Delete } from "react-feather";
import StatisticsCard from "../../components/statisticsCard/StatisticsCard";
import firebase from "../../firebaseConfig";
import StarRatings from "react-star-ratings";
import RealTime from "services/RealTime";
import PageLoader from "components/PageLoader/PageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";

var spid = "";
const spRatingColumns = [
  {
    text: "Sl no",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider",
    dataField: "name",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Emirate",
    dataField: "emirate",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },

  {
    text: "Rating",
    dataField: "ratings",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const spReviewColumns = [
  {
    text: "timestamp",
    dataField: "timestamp",
    sort: true,
    hidden: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Sl No",
    dataField: "i",
    hidden: true,
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Order ID",
    dataField: "orderId",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Date & Time",
    dataField: "date",
    sort: false,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Customer Name",
    dataField: "customerName",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },

  {
    text: "Driver Name",
    dataField: "driverName",
    sort: true,
    filter: textFilter(),
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider",
    dataField: "spName",
    sort: true,
    filter: textFilter(),
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Rating",
    dataField: "ratings",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSorted = [
 
  {
    dataField: "timestamp",
    order: "desc",
  },
];
class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [],
      spemail: "",
      modal: false,
      CouponId: "",
      spRating: [],
      isloaded: true,
      spModal: false,
      selectedSP: "",
      fullReviewsOfSelectedSP: false,
      fullReviewsOfAllSP: false,
    };
  }
  componentDidMount() {
    this.spOverallRating();
  }
  spOverallRating = async () => {
    this.setState({
      isloaded: false,
    });
    let spRating = [];
    let i = 1;
    let service_providers = await RealTime.getDetails(`/service_providers/`);
    Object.entries(service_providers).forEach(([keySp, sp]) => {
      if (sp.personal_information) {
        spRating.push({
          i: i++,
          id: keySp,
          name: sp.personal_information.name,
          image_url: sp.personal_information.image,
          ratings: sp.personal_information.ratings,
          emirate: sp.personal_information.emirate,
          times_rated: sp.personal_information.times_rated,
          employeeBehaviourRating:
            sp.personal_information.employeeBehaviourRating,
          professionalAppearanceRating:
            sp.personal_information.professionalAppearanceRating,
          qualityOfServiceRating:
            sp.personal_information.qualityOfServiceRating,
          valueOfServiceRating: sp.personal_information.valueOfServiceRating,
          arrivalTimeRating: sp.personal_information.arrivalTimeRating,
        });
      }
    });
    this.setState({
      spRating: spRating,
      isloaded: true,
    });
  };

  formateDriverId = (id) => {
    if (id) {
      let driver_id = id.replace("_", "@");
      driver_id = driver_id.replace("_", ".");
      return driver_id;
    } else {
      return "";
    }
  };
  rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "#e6e6e6";
    }
    style.cursor = "pointer";
    return style;
  };
  spToggle = () => {
    this.setState({
      spModal: !this.state.spModal,
    });
  };
  details = (text, data) => {
    return (
      <>
        <Col xs="5" md="5">
          <p>
            <b>{text}</b>
          </p>
        </Col>
        <Col xs="7" md="7">
          <p>: {data}</p>
        </Col>
      </>
    );
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          selectedSP: row,
        });
        this.spToggle();
      },
    };
    if (this.state.fullReviewsOfSelectedSP) {
      return (
        <AllReviews
          selectedSP={this.state.selectedSP}
          isAll={this.state.fullReviewsOfAllSP}
        />
      );
    }

    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8 mb-5">
            <Col sm={12} md={7} lg={4} xl={6}>
              <h1 className="mb-0">Customer Reviews</h1>
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              {/* <Button
                block
                color="primary"
                style={{ right: "5px" }}
                onClick={this.navigateAssignSpToVehicle}
              >
                ASSIGN SP TO VEHICLE
              </Button> */}
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              <Button
                block
                color="primary"
                style={{ right: "5px" }}
                onClick={() => {
                  this.setState({
                    selectedSP: true,
                    fullReviewsOfAllSP: true,
                    fullReviewsOfSelectedSP: true,
                  });
                }}
              >
                ALL REVIEWS
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.spRating}
                columns={spRatingColumns}
                rowStyle={this.rowStyle}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory()}
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>
        {this.state.selectedSP && (
          <Modal
            isOpen={this.state.spModal}
            toggle={this.spToggle}
            className={this.props.className}
            size="md"
          >
            <ModalHeader toggle={this.toggle}>
              <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                  <h1> Rating Details</h1>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2">
                  {/* <Button
                    color={
                      this.state.selectedVehicle?.status ? "danger" : "success"
                    }
                    onClick={() => {
                      this.disableVehicle(
                        this.state.selectedVehicle?.number,
                        this.state.selectedVehicle?.status,
                        this.state.selectedVehicle?.serviceable_areas
                      );
                    }}
                  >
                    {this.state.selectedVehicle?.status ? "DISABLE" : "ENABLE"}
                  </Button> */}
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody className="p-0">
              {
                <Row>
                  <Col>
                    <Card>
                      <CardImg
                        top
                        className="img-fluid"
                        src={this.state.selectedSP?.image_url}
                        alt="card image cap"
                      />
                      <CardBody className="pb-0">
                        <Row>
                          {this.details(
                            "Total number of review",
                            this.state.selectedSP?.times_rated
                          )}
                          {this.details(
                            "Ratings",
                            <StarRatings
                              rating={this.state.selectedSP?.ratings}
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}

                          {this.details(
                            "Arrival Time Rating",

                            <StarRatings
                              rating={this.state.selectedSP?.arrivalTimeRating}
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}

                          {this.details(
                            "Quality Of Service Rating",
                            <StarRatings
                              rating={
                                this.state.selectedSP?.qualityOfServiceRating
                              }
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}
                          {this.details(
                            "Value Of Service Rating",

                            <StarRatings
                              rating={
                                this.state.selectedSP?.valueOfServiceRating
                              }
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
            </ModalBody>
            <ModalFooter className="py-1">
              <Button
                color="primary"
                onClick={() => {
                  this.setState({
                    fullReviewsOfSelectedSP: true,
                    fullReviewsOfAllSP: false,
                  });
                }}
              >
                ALL REVIEWS
              </Button>

              <Button color="danger" onClick={this.spToggle}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

class AllReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [],
      spemail: "",
      modal: false,
      CouponId: "",
      spRating: [],
      spRatingCopy: [],
      isloaded: true,
      reviewModal: false,
      selectedSP: "",
      completeReviews: "",
      allReviews: false,
      selectedReview: "",
      count: 0,
      selectedRating: "",
      RatingList: [
        {
          label: "All",
          value: 0,
        },
        {
          label: "1 Star Rating",
          value: 1,
        },
        {
          label: "2 Star Rating",
          value: 2,
        },
        {
          label: "3 Star Rating",
          value: 3,
        },
        {
          label: "4 Star Rating",
          value: 4,
        },
        {
          label: "5 Star Rating",
          value: 5,
        },
      ],
    };
  }
  async componentDidMount() {
    if (this.props.selectedSP) {
      let result = await this.spCustomerRating(
        this.props.selectedSP.id,
        this.props.isAll
      );

      this.setState({
        spRating: result,
        spRatingCopy: result,
        count: result.length,
        isloaded: true,
      });
    }
  }
  spCustomerRating = async (spID, isAll) => {
    this.setState({
      isloaded: false,
    });
    let allSpReviews = await RealTime.getDetails(`/service_providers_ratings/`);
    let spRating = [];
    let i = 1;
    Object.entries(allSpReviews).forEach(async ([keySp, sp]) => {
      if (keySp === spID || isAll) {
        Object.entries(sp).forEach(async ([keyRating, rating]) => {
          if (rating) {
            spRating.push({
              i: i++,
              timestamp: rating.timestamp,
              orderId: keyRating.split("&")[0],
              driverId: rating.driver_id,
              driverName: await this.getName(rating.driver_id, false),
              spId: keySp,
              spName: await this.getName(keySp, true),
              date: new Date(rating.timestamp).toLocaleString(),
              customerName: rating.customerName
                ? rating.customerName
                : "Not Available",
              valueOfServiceRating: rating.valueOfServiceRating,
              qualityOfServiceRating: rating.qualityOfServiceRating,
              arrivalTimeRating: rating.arrivalTimeRating,
              ratings: Number(rating.rating.toFixed(1)),
              review: rating.review,
            });
          }
          this.setState({
            count: spRating.length,
          });
        });
      }
    });

    return spRating;
  };
  getName = async (id, isSp) => {
    let name = await RealTime.getDetails(
      `${
        isSp ? "service_providers" : "drivers"
      }/${id}/personal_information/name`
    );
    return name;
  };

  handleRatingChange = (event) => {
    this.setState({
      selectedRating: event.label,
    });
    let newSpRating = [];
    if (event.value !== 0) {
      let spRating = this.state.spRatingCopy;
      spRating.map((rating) => {
        if (Math.floor(rating.ratings) === event.value) {
          newSpRating.push(rating);
        }
      });
    } else {
      newSpRating = this.state.spRatingCopy;
    }
    this.setState({
      spRating: newSpRating,
      count: newSpRating.length,
    });
  };

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "#e6e6e6";
    }
    style.cursor = "pointer";
    return style;
  };
  reviewToggle = () => {
    this.setState({
      reviewModal: !this.state.reviewModal,
    });
  };
  details = (text, data) => {
    return (
      <>
        <Col xs="5" md="5">
          <p>
            <b>{text}</b>
          </p>
        </Col>
        <Col xs="7" md="7">
          <p>: {data}</p>
        </Col>
      </>
    );
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log("row: ", row);
        this.setState({
          selectedReview: row,
        });
        this.reviewToggle();
      },
    };
    if (this.state.backToReviews) {
      return <Reviews />;
    }
    console.log(this.state.completeReviews);
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8 mb-5">
            <Col sm={12} md={7} lg={4} xl={6}>
              <h1 className="mb-0">{`Customer Reviews (${this.state.count})`}</h1>
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              {/* <Button
                block
                color="primary"
                style={{ right: "5px" }}
                onClick={this.navigateAssignSpToVehicle}
              >
                ASSIGN SP TO VEHICLE
              </Button> */}
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              <Button
                block
                color="primary"
                style={{ right: "5px" }}
                onClick={() => {
                  this.setState({
                    backToReviews: true,
                  });
                }}
              >
                BACK
              </Button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl="4" lg="4" md="6" sm="12" xs="12">
              <Select
                style={{
                  width: "100%",
                  minWidth: "300px",
                  display: "inline-block",
                  marginLeft: "20px",
                }}
                onChange={this.handleRatingChange}
                options={this.state.RatingList}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.spRating}
                columns={spReviewColumns}
                rowStyle={this.rowStyle}
                defaultSorted={defaultSorted}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory()}
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>
        {this.state.selectedReview && (
          <Modal
            isOpen={this.state.reviewModal}
            toggle={this.reviewToggle}
            className={this.props.className}
            size="md"
          >
            <ModalHeader toggle={this.reviewToggle}>
              <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                  <h1> Review Details</h1>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2">
                  {/* <Button
                    color={
                      this.state.selectedVehicle?.status ? "danger" : "success"
                    }
                    onClick={() => {
                      this.disableVehicle(
                        this.state.selectedVehicle?.number,
                        this.state.selectedVehicle?.status,
                        this.state.selectedVehicle?.serviceable_areas
                      );
                    }}
                  >
                    {this.state.selectedVehicle?.status ? "DISABLE" : "ENABLE"}
                  </Button> */}
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody className="p-0">
              {
                <Row>
                  <Col>
                    <Card>
                      {/* <CardImg
                        top
                        className="img-fluid"
                        src={this.state.selectedSP?.image_url}
                        alt="card image cap"
                      /> */}
                      <CardBody className="pb-0">
                        <Row>
                          {this.details(
                            "Review",
                            this.state.selectedReview?.review
                          )}
                          {this.details(
                            "Ratings",
                            <StarRatings
                              rating={this.state.selectedReview?.ratings}
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}

                          {this.details(
                            "Arrival Time Rating",

                            <StarRatings
                              rating={
                                this.state.selectedReview?.arrivalTimeRating
                              }
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}

                          {this.details(
                            "Quality Of Service Rating",
                            <StarRatings
                              rating={
                                this.state.selectedReview
                                  ?.qualityOfServiceRating
                              }
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}
                          {this.details(
                            "Value Of Service Rating",

                            <StarRatings
                              rating={
                                this.state.selectedReview?.valueOfServiceRating
                              }
                              starRatedColor="yellow"
                              starDimension="20px"
                              starSpacing="5px"
                            />
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
            </ModalBody>
            <ModalFooter className="py-1">
              {/* <Button
                color="primary"
                onClick={() => {
                  console.log("clicked delete review");
                }}
              >
                DELETE REVIEW
              </Button> */}

              <Button color="danger" onClick={this.reviewToggle}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

export default Reviews;
