import React, { Fragment } from "react";
import firebase from "firebaseConfig";
import storage from "firebaseConfig";
import { connect } from "react-redux";
import addStatCardAction from "actions/addStatCardAction";

import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  Multiselect,
  Table,
  CardImg,
  Row,
  Col,
  FormText,
  CardTitle,
  Badge,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";

// super admin id
var saID;

// sp details in firebase Auth
var superAdminDetails = firebase.auth().currentUser;
firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);
    superAdminDetails = user;

    saID = superAdminDetails.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");

    // console.log('The current user email: ', this.state.sp_email);
    console.log("The current user id: ", saID);
  } else {
    // No user is signed in.
  }
});

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      superAdminUserID: "",
      phoneNumber: "",
      password: "",
      passwordRetype: "",
      description: "",
      isChecked: false,
      downloadURL: "",
      file: "",
      imagePreviewUrl: "",
      modal: false,
      spemail: "",

      addservicecenter: false,
      addvehicle: false,
      adddriver: false,
      addBooking: false,
      addServices: false,
      createPermission: false,
      readPermission: true,
      updatePermission: false,
      deletePermission: false,

      disabledButton: true,
      additionalVehicleTypes: [],
    };

    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.createUserAccount = this.createUserAccount.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.navigate = this.navigate.bind(this);
    this.togglePermissionChange = this.togglePermissionChange.bind(this);
  }

  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };

  toggleChange() {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  togglePermissionChange = (stateName) => {
    if (stateName == "addservicecenter") {
      this.setState({
        addservicecenter: !this.state.addservicecenter,
      });
    } else if (stateName == "adddriver") {
      this.setState({
        adddriver: !this.state.adddriver,
      });
    } else if (stateName == "addvehicle") {
      this.setState({
        addvehicle: !this.state.addvehicle,
      });
    } else if (stateName == "addBooking") {
      this.setState({
        addBooking: !this.state.addBooking,
      });
    } else if (stateName == "addServices") {
      this.setState({
        addServices: !this.state.addServices,
      });
    } else if (stateName == "createPermission") {
      this.setState({
        createPermission: !this.state.createPermission,
      });
    } else if (stateName == "readPermission") {
      this.setState({
        readPermission: !this.state.readPermission,
      });
    } else if (stateName == "updatePermission") {
      this.setState({
        updatePermission: !this.state.updatePermission,
      });
    } else if (stateName == "deletePermission") {
      this.setState({
        deletePermission: !this.state.deletePermission,
      });
    }
  };

  createUserAccount = () => {
    var urlLink;
    const {
      username,
      email,
      superAdminUserID,
      phoneNumber,
      password,
      passwordRetype,
      description,
      isChecked,
      downloadURL,
      file,
      imagePreviewUrl,
      additionalNote,

      addservicecenter,
      addvehicle,
      adddriver,
      addBooking,
      addServices,
      createPermission,
      readPermission,
      updatePermission,
      deletePermission,
    } = this.state;

    const createUser = firebase.functions().httpsCallable("createUser");
    createUser({ email, password })
      .then(({ data: user }) => {
        this.toggle();
        var userId = email;
        userId = userId.replace("@", "_");
        userId = userId.replace(".", "_");

        console.log("sp account = ", email, " is created sucessfull");

        // we take the user id and it's name and we add it in our
        // user-details table
        firebase
          .database()
          .ref(
            "twaddan_admin/super_admin_users/" +
              superAdminUserID +
              "/personal_information/"
          )
          .set({
            userId: user.uid,
            name: username,
            description: description,
            email: email,
            phone_number: phoneNumber,
          });

        console.log("file: ", file, "name: ", file.name);
        const extension = file.name.split(".").pop();
        console.log("spspspspspspsp == ", saID);

        firebase
          .storage()
          .ref(
            "/twaddan_admin/super_admin_users/users/" +
              superAdminUserID +
              "profile_" +
              phoneNumber +
              "." +
              extension
          )
          .put(file)
          .then((snapshot) => {
            // Will return a promise with the download link
            return snapshot.ref.getDownloadURL();
          })
          .then((downloadURL) => {
            console.log(
              `Successfully uploaded file and got download link - ${downloadURL}`
            );
            // this.setState({
            //   downloadURL:downloadURL,
            // })
            urlLink = downloadURL;
            console.log("super admin user ID : ", superAdminUserID);

            firebase
              .database()
              .ref(
                "twaddan_admin/super_admin_users/" +
                  superAdminUserID +
                  "/personal_information/"
              )
              .update({
                name: username,
                email: email,
                phone_number: phoneNumber,
                image: urlLink,
                email_attachment: "",
              })
              .catch((error) => {
                console.log(
                  `Failed to update super admin user details - ${error}`
                );
              });

            firebase
              .database()
              .ref(
                "twaddan_admin/userPermission/" +
                  superAdminUserID +
                  "/page_permission"
              )
              .update({
                addServiceCenter: addservicecenter,
                addVehicle: addvehicle,
                addDriver: adddriver,
                addBooking: addBooking,
                addServices: addServices,
              });

            firebase
              .database()
              .ref(
                "twaddan_admin/userPermission/" +
                  superAdminUserID +
                  "/crud_permission"
              )
              .update({
                createPermission: createPermission,
                readPermission: readPermission,
                updatePermission: updatePermission,
                deletePermission: deletePermission,
              });
            this.setState({ disabledButton: false });

            var time = new Date();
            time = time.getTime();
            var logAction =
              "Added New User " +
              username +
              " with SuperAdmin User ID " +
              superAdminUserID +
              " as a description " +
              description;
            var logs = firebase
              .database()
              .ref(
                "/twaddan_admin/super_admin_users/" +
                  saID +
                  "/logs/" +
                  time +
                  "/"
              );
            logs.update({
              action: logAction,
            });
          })
          .catch((error) => {
            // Use to signal error if something goes wrong.
            console.log(`Failed to upload file and get link - ${error}`);
          });
      })
      .then(console.log)
      .catch(function (error) {
        // if we have any erros, we'll throw an allert with that error
        alert(error);
      });
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    // console.log(stateName,value);
    if (stateName == "email") {
      let superAdminUserID = value;
      superAdminUserID = superAdminUserID.replace("@", "_");
      superAdminUserID = superAdminUserID.replace(".", "_");

      this.setState({
        superAdminUserID: superAdminUserID,
      });
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  componentDidMount() {
    this.setState({
      spemail: localStorage.getItem("email"),
    });
  }

  uploadImage = () => {
    console.log("file: ", this.state.file, "name: ", this.state.file.name);
    const extension = this.state.file.name.split(".").pop();

    firebase
      .storage()
      .ref(
        "/service_provider/" +
          saID +
          "/drivers_info/" +
          "driver_" +
          this.state.phoneNumber +
          "." +
          extension
      )
      .put(this.state.file)
      .then((snapshot) => {
        // Will return a promise with the download link
        return snapshot.ref.getDownloadURL();
      })
      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadURL: downloadURL,
        });
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  navigate = () => {
    this.setState({
      usermanagement: 1,
    });
  };

  render() {
    if (this.state.usermanagement == 1) {
      return <Redirect to='/admin/userManagement' />;
    }

    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    const {
      username,
      email,
      password,
      passwordRetype,
      description,
      phoneNumber,
      isChecked,
      error,
      disabledButton,
    } = this.state;

    const isInvalid =
      password !== passwordRetype ||
      password === "" ||
      email === "" ||
      !isChecked ||
      username === "" ||
      phoneNumber === "" ||
      !/^\d{10}$/.test(phoneNumber);

    const passwordMisMatch =
      password !== passwordRetype && passwordRetype.length > 0;

    const incorrectPhoneNumber =
      phoneNumber !== "" && !/^\d{10}$/.test(phoneNumber);

    const passwordLength_LT6 = password.length < 6;
    const passwordLength_GT6_LT9 = password.length >= 6 && password.length < 9;
    const passwordLength_GT9 = password.length >= 9;

    return (
      <>
        <Header />
        {/* Page content */}

        <Container className='mt--7' fluid>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <div className='text-center text-muted mb-4'>
                <small>
                  Enter Super Admin User Credentials to create account
                </small>
              </div>
              <Form role='form'>
                <FormGroup>
                  <Label for='File'>Add image</Label>
                  <br></br>
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    onChange={this._handleImageChange}
                  />
                  <FormText color='muted'>
                    Select the picture of the User Profile you wanted to
                    display.
                  </FormText>
                  <Row>
                    <Col md={4} xs={2}>
                      <div style={imagestyle}>{$imagePreview}</div>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-circle-08' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='User Name'
                      type='text'
                      onChange={(e) =>
                        this.onChange("username", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-bullet-list-67' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Description'
                      type='text'
                      onChange={(e) =>
                        this.onChange("description", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-mobile-button' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Phone number'
                      type='text'
                      onChange={(e) =>
                        this.onChange("phoneNumber", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-email-83' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Email'
                      type='email'
                      onChange={(e) => this.onChange("email", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <hr className='my-4' />
                {/* Address */}
                <h3 className='heading-small text-muted mb-4'>
                  Permissions to Accessible Pages
                </h3>
                <div className='pl-lg-4'>
                  <Row>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='addservicecenter'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("addservicecenter");
                          }}
                          checked={this.state.addservicecenter}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='addservicecenter'>
                          Add Service Provider
                        </label>
                      </div>
                    </Col>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='adddriver'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("adddriver");
                          }}
                          checked={this.state.adddriver}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='adddriver'>
                          Add Driver
                        </label>
                      </div>
                    </Col>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='addvehicle'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("addvehicle");
                          }}
                          checked={this.state.addvehicle}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='addvehicle'>
                          Add Vehicle
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='addBooking'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("addBooking");
                          }}
                          checked={this.state.addBooking}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='addBooking'>
                          Add Booking
                        </label>
                      </div>
                    </Col>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='addServices'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("addServices");
                          }}
                          checked={this.state.addServices}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='addServices'>
                          Add Services
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr className='my-4' />

                {/* Address */}
                <h3 className='heading-small text-muted mb-4'>
                  Permissions to Create, Read, Update, and Delete
                </h3>
                <div className='pl-lg-4'>
                  <Row>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='createPermission'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("createPermission");
                          }}
                          checked={this.state.createPermission}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='createPermission'>
                          Create
                        </label>
                      </div>
                    </Col>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          disabled
                          className='custom-control-input'
                          id='readPermission'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("readPermission");
                          }}
                          checked={this.state.readPermission}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='readPermission'>
                          Read
                        </label>
                      </div>
                    </Col>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='updatePermission'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("updatePermission");
                          }}
                          checked={this.state.updatePermission}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='updatePermission'>
                          Update
                        </label>
                      </div>
                    </Col>
                    <Col lg='4'>
                      <div className='custom-control custom-control-alternative custom-checkbox'>
                        <input
                          className='custom-control-input'
                          id='deletePermission'
                          type='checkbox'
                          onClick={() => {
                            this.togglePermissionChange("deletePermission");
                          }}
                          checked={this.state.deletePermission}
                        />

                        <label
                          className='custom-control-label'
                          htmlFor='deletePermission'>
                          Delete
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>

                <hr className='my-4' />

                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Password'
                      type='password'
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Retype Password'
                      type='password'
                      onChange={(e) =>
                        this.onChange("passwordRetype", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <div className='text-muted font-italic'>
                  <small>
                    password strength:{" "}
                    {passwordLength_LT6 && (
                      <span className='text-danger font-weight-700'>Low</span>
                    )}
                    {passwordLength_GT6_LT9 && (
                      <span className='text-warning font-weight-700'>
                        Medium
                      </span>
                    )}
                    {passwordLength_GT9 && (
                      <span className='text-success font-weight-700'>High</span>
                    )}
                  </small>
                  <div>
                    <small>
                      {incorrectPhoneNumber && (
                        <span className='text-danger font-weight-700'>
                          Incorrect Phone Number
                        </span>
                      )}
                    </small>
                  </div>
                  <div>
                    <small>
                      {passwordMisMatch && (
                        <span className='text-danger font-weight-700'>
                          Password Mismatch
                        </span>
                      )}
                    </small>
                  </div>
                </div>

                <Row className='my-4'>
                  <Col xs='12'>
                    <div className='custom-control custom-control-alternative custom-checkbox'>
                      <input
                        className='custom-control-input'
                        id='customCheckRegister'
                        type='checkbox'
                        onClick={() => {
                          this.toggleChange();
                        }}
                        checked={this.state.isChecked}
                      />

                      <label
                        className='custom-control-label'
                        htmlFor='customCheckRegister'>
                        <span className='text-muted'>
                          I agree with the{" "}
                          <a href='#pablo' onClick={(e) => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>

                <div className='text-center'>
                  <Button
                    disabled={isInvalid}
                    className='mt-4'
                    color='primary'
                    type='button'
                    onClick={this.createUserAccount}>
                    CREATE USER ACCOUNT
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation new user added Successfully</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
