// QA ENV
import * as firebase from 'firebase';

var config = {
    apiKey: 'AIzaSyBWdQnKiLJkYQp8LH3BKtJBoMgHxN3Hy-Q',
    authDomain: 'twaddan-fcc39.firebaseapp.com',
    databaseURL: 'https://twaddan-fcc39-default-rtdb.firebaseio.com/',
    projectId: 'twaddan-fcc39',
    storageBucket: 'twaddan-fcc39',
    messagingSenderId: '604550818104',
    appId: '1:604550818104:web:cb8d39621fdb4f5964f9cd',
    measurementId: 'G-98JH00Z1XY',
};

let firebaseConfig = firebase.initializeApp(config);
export default firebaseConfig;
