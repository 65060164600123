import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import ReactExport from "react-data-export";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
} from "reactstrap";
import { Zap, Trash, Delete, Edit } from "react-feather";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import StatisticsCard from "../../components/statisticsCard/StatisticsCard";
import { Redirect } from "react-router-dom";
import RealTime from "services/RealTime";
import PageLoader from "components/PageLoader/PageLoader";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var spdetails = [];

var spDetailsCopy = [];

var spid = "";
var CouponId = "";
var couponDetailsBySp = [];
// CRUD permission details
var crudPermission = JSON.parse(localStorage.getItem("crudPermissions"));

// super admin ID
var saID = localStorage.getItem("admin_email");
const columns = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider",
    dataField: "name",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },

  {
    text: " Total discount",
    dataField: "offers",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      modal: false,
      modals: false,
      spid: "",
      offer: [],
      searchBarID: "",
      CouponId: "",
      spdetails: [],
      selectedSP: "",
      offerBySp: false,
      discountReport: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggles = this.toggles.bind(this);
    this.navigateAddDiscount = this.navigateAddDiscount.bind(this);
  }

  async componentDidMount() {
    const data = await RealTime.getDetails("service_providers/");
    this.getData(data);
  }
  rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "#e6e6e6";
    }
    style.cursor = "pointer";
    return style;
  };
  getData = (drs) => {
    spdetails = [];
    let i = 1;
    if (drs != null) {
      var keys = Object.keys(drs);
      for (var dr = 0; dr < keys.length; dr++) {
        var k = keys[dr];
        if (drs[k].offers) {
          spdetails.push({
            i: i++,
            offers: Object.keys(drs[k].offers).length,
            name: drs[k].personal_information.name,
            id: k,
          });
        }
        // else {
        //   spdetails.push({
        //     i: i++,
        //     offers: 0,
        //     name: drs[k].personal_information.name,
        //     id: k,
        //   });
        // }
      }
    }

    spDetailsCopy = spdetails;
    this.setState({
      isloaded: true,
      spdetails: spdetails,
    });
    console.log(spdetails);
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    if (stateName == "searchBarID") {
      if (value == "") {
        spdetails = spDetailsCopy;
      } else {
        spdetails = [];
        Object.keys(spDetailsCopy).map((line, key) => {
          var IDSearch = spDetailsCopy[line].name.toLowerCase();

          if (IDSearch.startsWith(value.toLowerCase())) {
            spdetails.push(spDetailsCopy[line]);
          }
        });
      }
    }
  };

  getofferDetails = (id) => {
    let offers = [];
    console.log(id);
    const dr1 = firebase.database().ref("service_providers/" + id + "/offers/");
    dr1.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var key = Object.keys(drs);
        for (var dr = 0; dr < key.length; dr++) {
          var k = key[dr];
          offers.push({
            description: drs[k].description,
            image: drs[k].image,
            min_cart_value: drs[k].min_cart_value,
            percentage: drs[k].percentage,
            promo_code: drs[k].promo_code,
            terms_and_conditions: drs[k].terms_and_conditions,
            id: k,
            status: drs[k].status,
          });
        }
      }
      this.setState({
        offer: offers,
        modal: true,
      });
    });
    return offers;
  };
  navigate = () => {
    this.setState({
      AddDiscount: 1,
    });
  };

  deleteCode = async () => {
    console.log(this.state.spid);

    console.log(this.state.CouponId);
    var img = this.state.CouponId + ".png";
    // Create a reference to the file to delete
    var storageRef = firebase.storage().ref();
    var desertRef = storageRef.child(
      "/service_provider/" + this.state.spid + "/offers/" + img
    );

    // Delete the file
    desertRef
      .delete()
      .then(function () {
        // File deleted successfully
      })
      .catch(function (error) {
        // Uh-oh, an error occurred!
      });
    let userRef = firebase
      .database()
      .ref(
        "service_providers/" +
          this.state.spid +
          "/offers/" +
          this.state.CouponId
      );
    userRef.remove();
    const data = await RealTime.getDetails("service_providers/");
    this.getData(data);
    this.toggles();
    this.toggle();
  };
  navigate = () => {
    this.setState({
      addDiscount: 1,
    });
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggles() {
    this.setState({
      modals: !this.state.modals,
    });
  }
  navigateAddDiscount = () => {
    this.setState({
      addoffer: 1,
    });
  };
  navigateDiscountReport = () => {
    this.setState({
      discountReport: true,
    });
  };
  toggleDiscountStatus = async (id, status, sp) => {
    const offers = await RealTime.getDetails(
      "service_providers/" + sp + "/offers"
    );
    if (offers) {
      Object.entries(offers).forEach(([keyOffer, offer]) => {
        if (keyOffer === id) {
          firebase
            .database()
            .ref("service_providers/" + sp + "/offers/" + keyOffer + "/")
            .update({
              status: !status,
            });
        }
      });
    }
    this.getofferDetails(sp);
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          selectedSP: row.id,
          offerBySp: true,
        });
        this.setState({ spid: [row.id] });
        couponDetailsBySp = this.getofferDetails(row.id);
        spid = row.id;
      },
    };
    if (this.state.addoffer === 1) {
      return <Redirect to="/admin/addDiscount" />;
    }
    if (this.state.offerBySp) {
      return <OfferBySp />;
    }
    if (this.state.discountReport) {
      return <DiscountReport />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8">
            <Col sm={12} md={12} lg={5} xl={7}>
              <h1 className="mb-0">Discount</h1>
            </Col>
            {true && (
              <>
                <Col sm={6} md={6} lg={3} xl={2}>
                  <Button
                    block
                    color="primary"
                    style={{ right: "5px" }}
                    onClick={this.navigateAddDiscount}
                  >
                    ADD DISCOUNT
                  </Button>
                </Col>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <Button
                    block
                    color="primary"
                    className="addOnButton"
                    onClick={this.navigateDiscountReport}
                  >
                    DISCOUNT REPORT
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.spdetails}
                columns={columns}
                rowStyle={this.rowStyle}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory()}
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>
            <h1>Coupons</h1>
          </ModalHeader>
          <ModalBody>
            {
              <Row>
                {this.state.offer.map((i) => {
                  return (
                    <Col sm="5">
                      <Card
                        style={{
                          minHeight: "300",
                        }}
                      >
                        <CardImg
                          top
                          className="img-fluid"
                          src={i.image}
                          style={{
                            height: "250px",
                            width: "400px",
                          }}
                          alt="card image cap"
                        />
                        <CardBody>
                          <h4>{i.promo_code}</h4>
                          <p className="mb-0">Percentage: {i.percentage}</p>
                          <p className="mb-0">Description: {i.description}</p>
                          <p className="mb-0">
                            Minimum Cart Value: {i.min_cart_value}
                          </p>
                          <p className="mb-0">
                            T n C: {i.terms_and_conditions}
                          </p>
                          <Button
                            color="danger"
                            style={{
                              position: "absolute",
                              bottom: "10px",
                              right: "10px",
                            }}
                            onClick={() =>
                              this.setState({
                                CouponId: i.id,
                                modals: true,
                              })
                            }
                          >
                            <Delete />
                          </Button>
                          <br></br>
                          <Button
                            color={i.status ? "danger" : "success"}
                            onClick={() => {
                              this.toggleDiscountStatus(
                                i.id,
                                i.status,
                                this.state.selectedSP
                              );
                            }}
                          >
                            {i.status ? "DISABLE" : "ENABLE"}
                          </Button>
                          <br></br>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
                {this.state.offer.length < 1 && (
                  <Col>
                    <h2>No coupons available</h2>
                  </Col>
                )}
              </Row>
            }
            <Modal isOpen={this.state.modals} toggle={this.toggles}>
              <ModalBody>Do you want to delete</ModalBody>
              <ModalFooter>
                <Button color="info" onClick={this.toggles}>
                  N0
                </Button>
                <Button color="danger" onClick={this.deleteCode}>
                  YES
                </Button>
              </ModalFooter>
            </Modal>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
const columnsOfferBySp = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Coupon Code",
    dataField: "promo_code",
    sort: true,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },

  {
    text: "Percentage",
    dataField: "percentage",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "status",
    dataField: "status",
    sort: true,
    hidden: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Min. Cart Value",
    dataField: "min_cart_value",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Coupon Type",
    dataField: "couponType",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
export class OfferBySp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      modal: false,
      spid: "",
      offer: [],
      searchBarID: "",
      CouponId: "",
      spdetails: [],
      selectedSP: "",
      backToDiscount: false,
      editDiscount: false,
      discountReport: false,
      selectedOffer: {},
    };
    // this.toggle = this.toggle.bind(this);
    // this.toggles = this.toggles.bind(this);
    // this.navigateAddDiscount = this.navigateAddDiscount.bind(this);
  }

  async componentDidMount() {
    this.setState({ isloaded: false });
    this.getofferDetails(spid);
  }
  rowStyle = (row, rowIndex) => {
    const style = {};
    if (row.status) {
      style.backgroundColor = "#9effc5";
    } else {
      style.backgroundColor = "#fc9f9f";
    }
    style.cursor = "pointer";
    return style;
  };
  getofferDetails = async (id) => {
    var offers = [];
    let i = 1;
    const offersOfSp = await RealTime.getDetails(
      "service_providers/" + id + "/offers/"
    );
    if (offersOfSp) {
      Object.entries(offersOfSp).forEach(([keyOffer, offer]) => {
        offers.push({
          i: i++,
          description: offer.description,
          description_ar: offer.description_ar,
          image: offer.image,
          min_cart_value: offer.min_cart_value,
          percentage: offer.percentage,
          promo_code: offer.promo_code,
          terms_and_conditions: offer.terms_and_conditions,
          terms_and_conditions_ar: offer.terms_and_conditions_ar,
          id: keyOffer,
          status: offer.status,
          service_provider: id,
          share_link: offer.share_link,
          start_date: offer.start_date
            ? offer.start_date
            : "Date Not Available",
          end_date: offer.end_date ? offer.end_date : "Date Not Available",
          couponType: offer.image ? "Normal Coupon" : "Special Coupon",
        });
      });
    }

    this.setState({ isloaded: true, offer: offers });
  };
  navigateToDiscount = () => {
    this.setState({ backToDiscount: true });
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  toggleDiscountStatus = async (id, status, sp) => {
    const offers = await RealTime.getDetails(
      "service_providers/" + sp + "/offers"
    );
    if (offers) {
      Object.entries(offers).forEach(([keyOffer, offer]) => {
        if (keyOffer === id) {
          firebase
            .database()
            .ref("service_providers/" + sp + "/offers/" + keyOffer + "/")
            .update({
              status: !status,
            });
        }
      });
    }
    this.getofferDetails(spid);
    this.toggle();
  };
  details = (text, data) => {
    return (
      <>
        <Col xs="5" md="5">
          <p>
            <b>{text}</b>
          </p>
        </Col>
        <Col xs="7" md="7">
          <p>: {data}</p>
        </Col>
      </>
    );
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          selectedOffer: row,
          modal: true,
        });
      },
    };
    if (this.state.backToDiscount) {
      return <Offers />;
    }
    if (this.state.editDiscount) {
      return <EditDiscount selectedOffer={this.state.selectedOffer} />;
    }
    if (this.state.discountReport) {
      return <DiscountReport selectedOffer={this.state.selectedOffer} />;
    }

    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8 mb-5">
            <Col sm={12} md={7} lg={8} xl={9}>
              <h1 className="mb-0">Discount</h1>
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              <Button
                block
                color="primary"
                style={{ right: "5px" }}
                onClick={this.navigateToDiscount}
              >
                BACK
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.offer}
                columns={columnsOfferBySp}
                rowStyle={this.rowStyle}
                filter={filterFactory()}
                filterPosition="top"
                pagination={paginationFactory()}
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>
        {this.state.selectedOffer && (
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
            size="md"
          >
            <ModalHeader toggle={this.toggle}>
              <Row>
                <Col lg="10" md="10" sm="10" xs="10">
                  <h1> Offer Details</h1>
                </Col>
                <Col lg="2" md="2" sm="2" xs="2">
                  <Button
                    color={
                      this.state.selectedOffer?.status ? "danger" : "success"
                    }
                    onClick={() => {
                      this.toggleDiscountStatus(
                        this.state.selectedOffer?.id,
                        this.state.selectedOffer?.status,
                        this.state.selectedOffer?.service_provider
                      );
                    }}
                  >
                    {this.state.selectedOffer?.status ? "DISABLE" : "ENABLE"}
                  </Button>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody className="p-0">
              {
                <Row>
                  <Col>
                    <Card>
                      {this.state.selectedOffer.image && (
                        <CardImg
                          top
                          className="img-fluid"
                          src={this.state.selectedOffer?.image}
                          alt="card image cap"
                        />
                      )}

                      <CardBody className="pb-0">
                        <Row>
                          {this.details(
                            "Coupon Code",
                            this.state.selectedOffer?.promo_code
                          )}
                          {this.details(
                            "Percentage",
                            this.state.selectedOffer?.percentage
                          )}
                          {this.details(
                            "Min. Cart Value",
                            this.state.selectedOffer?.min_cart_value
                          )}
                          {this.details(
                            "Description",
                            this.state.selectedOffer?.description
                          )}
                          {this.details(
                            " ",
                            this.state.selectedOffer?.description_ar
                          )}
                          {this.details(
                            "Terms and conditions",
                            this.state.selectedOffer?.terms_and_conditions
                          )}
                          {this.details(
                            "",
                            this.state.selectedOffer?.terms_and_conditions_ar
                          )}
                          {this.details(
                            "Start Date",
                            this.state.selectedOffer?.start_date
                          )}
                          {this.details(
                            "End Date",
                            this.state.selectedOffer?.end_date
                          )}
                          {this.details(
                            "Share URL",
                            this.state.selectedOffer?.share_link
                          )}
                          {this.details(
                            "Coupon Type",
                            this.state.selectedOffer?.couponType
                          )}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              }
            </ModalBody>
            <ModalFooter className="py-1">
              {/* <Button
                color="warning"
                onClick={() => {
                  this.setState({ discountReport: true });
                }}
              >
                REPORT
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.setState({ editDiscount: true });
                }}
              >
                <Edit />
              </Button> */}

              <Button color="danger" onClick={this.toggle}>
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

class EditDiscount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.selectedOffer.promo_code,
      percentage: this.props.selectedOffer.percentage,
      cartvalue: this.props.selectedOffer.min_cart_value,
      description: this.props.selectedOffer.description,
      description_ar: this.props.selectedOffer.description_ar,
      downloadurl: this.props.selectedOffer.image,
      file: "",
      imagePreviewUrl: this.props.selectedOffer.image,
      spemail: "",
      tnc: this.props.selectedOffer.terms_and_conditions,
      tnc_ar: this.props.selectedOffer.terms_and_conditions_ar,
      modal: false,
      discount: 0,
      CouponId: "",
      allSp: [],
      date:
        this.props.selectedOffer.start_date !== "Date Not Available"
          ? `${this.props.selectedOffer.start_date}-${this.props.selectedOffer.end_date}`
          : "Select Date Range",

      starting_date: this.props.selectedOffer.start_date,
      ending_date: this.props.selectedOffer.end_date,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateCheckbox = this.updateCheckbox.bind(this);
  }
  componentDidMount() {
    console.log("props:", this.props.selectedOffer.promo_code);
  }

  uploadimage = (spidFromParams) => {
    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    id = id + ".png";
    if (this.state.file) {
      var storageRef = firebase.storage().ref();
      var ImagesRef = storageRef.child(
        "/service_provider/" + spidFromParams + "/offers/" + id
      );
      ImagesRef.put(this.state.file)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
        })

        .then((downloadURL) => {
          console.log(
            `Successfully uploaded file and got download link - ${downloadURL}`
          );
          this.setState({
            downloadurl: downloadURL,
          });
          this.pushdata(spidFromParams);
        })

        .catch((error) => {
          // Use to signal error if something goes wrong.
        });
    } else {
      this.pushdata(spidFromParams);
    }
  };
  pushdata = (spidFromParams) => {
    var spName;
    firebase
      .database()
      .ref("service_providers/" + spidFromParams + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });

    var time = new Date();
    time = time.getTime();
    var logAction =
      "Edited coupon named " +
      this.state.name +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spidFromParams;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });

    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    var ref = firebase
      .database()
      .ref("service_providers/" + spidFromParams + "/offers/");
    ref.child(id).update({
      description: this.state.description,
      description_ar: this.state.description_ar,
      image: this.state.downloadurl ? this.state.downloadurl : "",
      percentage: Number(this.state.percentage),
      promo_code: this.state.name,
      terms_and_conditions: this.state.tnc,
      terms_and_conditions_ar: this.state.tnc_ar,
      min_cart_value: Number(this.state.cartvalue),
      start_date: this.state.starting_date,
      end_date: this.state.ending_date,
      status: true,
    });

    this.toggle();
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.selectedOffer.image === "") {
      this.pushdata(this.props.selectedOffer.service_provider);
      event.preventDefault();
    } else {
      this.uploadimage(this.props.selectedOffer.service_provider);
      event.preventDefault();
    }
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  onChange = (id, value) => {
    var price = this.state.priceFordiscount;
    price[id] = value;
    this.setState({
      priceFordiscount: price,
    });
  };
  updateCheckbox = (id, value) => {
    var status = this.state.statusFordiscount;
    status[id] = value;
    this.setState({
      statusFordiscount: status,
    });
  };
  getArabic = async (text, value) => {
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "ar",
        key: apiKey,
      },
    });

    this.setState({
      [value]: reply.data.data.translations[0].translatedText,
    });
  };
  convert = () => {
    this.getArabic(this.state.name, "name_ar");
    this.getArabic(this.state.description, "description_ar");
    this.getArabic(this.state.tnc, "tnc_ar");
  };
  navigate = () => {
    this.setState({
      offers: 1,
    });
  };
  dateRangeHandler = async (event, picker) => {
    if (
      moment(picker.startDate).toDate() <= moment().toDate() ||
      moment(picker.endDate).toDate() <= moment().toDate()
    ) {
      alert("Please choose a valid date");
    } else {
      this.setState({
        date:
          picker.startDate.format("DD/MM/YYYY") +
          " - " +
          picker.endDate.format("DD/MM/YYYY"),
        starting_date: picker.startDate.format("DD/MM/YYYY"),
        ending_date: picker.endDate.format("DD/MM/YYYY"),
      });
    }
  };
  handleCancel = (event, picker) => {
    picker.element.val("");
  };
  render() {
    if (this.state.offers == 1) {
      return <OfferBySp />;
    }
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          <Card>
            <CardHeader>
              <CardTitle>Update Promo code</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="name">Promo Code</Label>
                      <Input
                        type="name"
                        required
                        name="name"
                        id="name"
                        disabled={true}
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        placeholder="Name"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="price">Percentage</Label>
                      <Input
                        type="number"
                        required
                        name="percentage"
                        id="percentage"
                        placeholder="Percentage"
                        value={this.state.percentage}
                        onChange={(e) => {
                          this.setState({ percentage: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {this.props.selectedOffer.image && (
                  <FormGroup>
                    <h3 style={{ color: "red" }}>
                      Please try to add image of 400x400 pixels
                    </h3>
                    <Label for="File">Add image</Label>
                    <br></br>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={this._handleImageChange}
                    />
                    <Row>
                      <Col md={4} xs={2}>
                        <div style={imagestyle}>{$imagePreview}</div>
                      </Col>
                    </Row>
                  </FormGroup>
                )}
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="description">Description</Label>
                      <Input
                        type="text"
                        name="description"
                        required
                        id="description"
                        placeholder="Enter the decription"
                        value={this.state.description}
                        onChange={(e) => {
                          this.setState({ description: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="description_ar">Description in Arabic</Label>
                      <Input
                        type="text"
                        name="description_ar"
                        required
                        id="description_ar"
                        placeholder="Enter the decription"
                        value={this.state.description_ar}
                        onChange={(e) => {
                          this.setState({ description_ar: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="cartvalue">Minimun cart value</Label>
                      <Input
                        type="number"
                        required
                        name="cartvalue"
                        id="cartvalue"
                        placeholder="mincartvalue"
                        value={this.state.cartvalue}
                        onChange={(e) => {
                          this.setState({ cartvalue: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="cartvalue">Select Time Period</Label>
                      <DateRangePicker
                        block
                        autoApply={true}
                        onApply={this.dateRangeHandler}
                        onCancel={this.handleCancel}
                        showOneCalendar
                        initialSettings={{
                          autoUpdateInput: false,
                          locale: {
                            format: "DD/MM/YYYY",
                            cancelLabel: "Clear",
                          },
                          startDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          endDate: moment(new Date())
                            .tz("Asia/Dubai")
                            .format("DD MM YYYY hh:mm a"),
                          ranges: {
                            Tomorrow: [
                              moment().add(1, "days").toDate(),
                              moment().add(1, "days").toDate(),
                            ],
                            "Next 3 Days": [
                              moment().add(1, "days").toDate(),
                              moment().add(3, "days").toDate(),
                            ],
                            "Next 7 Days": [
                              moment().add(1, "days").toDate(),
                              moment().add(7, "days").toDate(),
                            ],
                            "Next 14 Days": [
                              moment().add(1, "days").toDate(),
                              moment().add(14, "days").toDate(),
                            ],
                            "Next 30 Days": [
                              moment().add(1, "days").toDate(),
                              moment().add(30, "days").toDate(),
                            ],

                            "Next Month": [
                              moment()
                                .add(1, "month")
                                .startOf("month")
                                .toDate(),
                              moment().add(1, "month").endOf("month").toDate(),
                            ],
                          },
                        }}
                      >
                        <input
                          type="text"
                          style={{ display: "inline-block" }}
                          className="form-control"
                          defaultValue=""
                          value={this.state.date}
                          placeholder="Select Date Range"
                        />
                      </DateRangePicker>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for="tnc">Terms and Conditions</Label>
                      <Input
                        type="text"
                        name="tnc"
                        required
                        id="tnc"
                        placeholder="Enter the Terms and Conditions"
                        value={this.state.tnc}
                        onChange={(e) => {
                          this.setState({ tnc: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for="tnc_ar">Terms and Conditions in Arabic</Label>
                      <Input
                        type="text"
                        name="tnc_ar"
                        required
                        id="tnc_ar"
                        placeholder="Enter the Terms and Conditions"
                        value={this.state.tnc_ar}
                        onChange={(e) => {
                          this.setState({ tnc_ar: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br></br>
                <Button color="primary" onClick={this.convert}>
                  CONVERT TO ARABIC
                </Button>
                <br></br>
                <br></br>
                <Button onClick={this.navigate}>CANCEL</Button>{" "}
                <Button color="success" value="Submit">
                  UPDATE
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation Promo Code updated Successfully</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
const columnsDiscountReport = [
  {
    text: "Order ID",
    dataField: "order_id_number",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Customer Name",
    dataField: "customer_name_english",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Mobile",
    dataField: "customer_mobile_number",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider",
    dataField: "sp_name_english",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Coupon Code",
    dataField: "coupon_code",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Order Date",
    dataField: "time_order_placed",
    sort: true,
    filter: textFilter(),
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Total Amount",
    dataField: "total_price_of_order",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footerAlign: "center",
    footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Charge",
    dataField: "serviceCharge",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footerAlign: "center",
    footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Total Discount",
    dataField: "total_discount",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footerAlign: "center",
    footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "After Discount",
    dataField: "total_due",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    footerAlign: "center",
    footer: (columnData) => columnData.reduce((acc, item) => acc + item, 0),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
let columnsExcel = [
  { title: "Order ID", width: { wch: 12 }, align: "center" },
  { title: "Customer Name", width: { wch: 20 } },
  { title: "Mobile", width: { wch: 20 } },
  { title: "Service Provider", width: { wch: 40 } },
  { title: "Coupon Code", width: { wch: 20 } },
  { title: "Order Date", width: { wch: 20 } },
  { title: "Total Amount", width: { wch: 20 } },
  { title: "Service Charge", width: { wch: 20 } },
  { title: "Total Discount", width: { wch: 20 } },
  { title: "After Discount", width: { wch: 20 } },
];
export class DiscountReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      modal: false,
      spid: "",
      offer: [],
      searchBarID: "",
      CouponId: "",
      spdetails: [],
      selectedSP: "",
      backToDiscount: false,
      editDiscount: false,
      discountReport: false,
      report: false,
      selectedOffer: {},
      orderDetails: [],
      excelData: [],
    };
    // this.toggle = this.toggle.bind(this);
    // this.toggles = this.toggles.bind(this);
    // this.navigateAddDiscount = this.navigateAddDiscount.bind(this);
  }

  async componentDidMount() {
    this.setState({ isloaded: false });
    this.getOrdeDetails();
  }
  getOrdeDetails = async () => {
    let orderDetails = [];
    let singleData = [];
    let completeData = [];
    let isCancelled = false;
    const all_orders = await RealTime.getDetails("discount_report");
    if (all_orders) {
      Object.entries(all_orders).forEach(async ([keyOrder, order]) => {
        isCancelled =
          (await RealTime.getDetails(
            `orders/all_orders/${order.order_id_number}/status`
          )) === -1
            ? true
            : false;
        if (order.coupon_code && order.order_id_number && !isCancelled) {
          order["order_id_number"] = order.order_id_number.split(" ")[0];
          order["time_order_placed"] = moment(order.time_order_placed).format(
            "DD/MM/YYYY"
          );
          order["customer_mobile_number"] = String(
            order.customer_mobile_number
          );
          orderDetails.push(order);

          singleData.push(
            {
              value: order?.order_id_number,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.customer_name_english,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.customer_mobile_number,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.sp_name_english,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.coupon_code,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.time_order_placed,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.total_price_of_order,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.serviceCharge,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.total_discount,
              style: { font: { sz: "10", bold: false } },
            },
            {
              value: order?.total_due,
              style: { font: { sz: "10", bold: false } },
            }
          );

          completeData.push(singleData);
          singleData = [];
        }
        this.setState({ isloaded: false });
        this.setState({ isloaded: true });
      });
    }
    let excelData = [
      {
        columns: columnsExcel,
        data: completeData,
      },
    ];
    this.setState({
      isloaded: true,
      orderDetails: orderDetails,
      excelData: excelData,
    });
    // console.log("orderDetails: ", this.state.orderDetails);
  };

  rowStyle = (row, rowIndex) => {
    const style = {};
    if (rowIndex % 2 === 0) {
      style.backgroundColor = "#e6e6e6";
    }
    style.cursor = "pointer";
    return style;
  };

  navigateToDiscount = () => {
    this.setState({ backToDiscount: true });
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  details = (text, data) => {
    return (
      <>
        <Col xs="5" md="5">
          <p>
            <b>{text}</b>
          </p>
        </Col>
        <Col xs="7" md="7">
          <p>: {data}</p>
        </Col>
      </>
    );
  };

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        console.log(row);
      },
    };
    if (this.state.backToDiscount) {
      return <Offers />;
    }

    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8">
            <Col sm={12} md={12} lg={5} xl={7}>
              <h1 className="mb-0">Discount Report</h1>
            </Col>
            {true && (
              <>
                <Col sm={6} md={6} lg={3} xl={2}>
                  <Button
                    block
                    color="primary"
                    style={{ right: "5px" }}
                    onClick={this.navigateToDiscount}
                  >
                    BACK
                  </Button>
                </Col>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <ExcelFile
                    element={
                      <Button block color="info" style={{ right: "5px" }}>
                        EXPORT
                      </Button>
                    }
                  >
                    <ExcelSheet
                      dataSet={this.state.excelData}
                      name="Discount Report"
                    />
                  </ExcelFile>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              <BootstrapTable
                keyField="id"
                data={this.state.orderDetails}
                columns={columnsDiscountReport}
                rowStyle={this.rowStyle}
                filter={filterFactory()}
                filterPosition="top"
                // pagination={paginationFactory()}
                bordered={false}
                hover
                wrapperClasses="table-responsive"
                condensed
                rowEvents={rowEvents}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Offers;
