import React from "react";
import firebase from "firebaseConfig";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

var adminData;

var superAdminID;
var superAdminDetails = firebase.auth().currentUser;
firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);
    superAdminDetails = user;

    superAdminID = superAdminDetails.email;
    superAdminID = superAdminID.replace("@", "_");
    superAdminID = superAdminID.replace(".", "_");

    firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          superAdminID +
          "/personal_information/"
      )
      .on("value", function (snapshot) {
        adminData = snapshot.val() || "Anonymous";
      });
  }
});

class UserHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: localStorage.getItem("admin_name"),
    };
  }
  render() {
    return (
      <>
        <div
          className='header pb-8 pt-5 pt-lg-8 d-flex align-items-center'
          style={{
            minHeight: "600px",
            backgroundImage:
              "url(" + require("assets/img/theme/car_wash.png") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}>
          {/* Mask */}
          <span className='mask bg-gradient-default opacity-8' />
          {/* Header container */}
          <Container className='d-flex align-items-center' fluid>
            <Row>
              {/* <Col lg="7" md="10"> */}
              <Col>
                <h1 className='display-2 text-white'>Hello</h1>
                <h1 className='display-2 text-white'>{this.state.username}</h1>
                {/* <Button
                  color="info"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  Edit profile
                </Button> */}
                <Container>
                  <Row>
                    <p className='text-white mt-0 mb-5'>
                      This is your profile page.
                    </p>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
