import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import { Edit } from "react-feather";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import RealTime from "services/RealTime";
import PageLoader from "components/PageLoader/PageLoader";
const firebaseCredential = require("firebase");

var toggle_vehicle = [];

var spdetails1 = [];

var spDetailsCopy = [];

var totalVehicle = [];

var spid = "";

const formateName = (name) => {
  if (name === "suv_ “5 seaters”") {
    return "suv 5 seaters";
  }
  if (name === "suv_“7 seaters”") {
    return "suv 7 seaters";
  }
  return name.replace("_", " ");
};

class EditVehicleTypeNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleSummary: false,
      All_VehicleType: [],
      active_vehicles: [],
      toggle_id: "",
      toggle_service: "",
      message_modal: false,
      toggle_modal: false,
      PasswordConfirmation: false,
      password: "",
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.togglePasswordConfirmation = this.togglePasswordConfirmation.bind(
      this
    );
    this.toggleMessage = this.toggleMessage.bind(this);
  }
  // fetching data
  getData = async () => {
    spdetails1.map((sp) => {
      var vehicletype = [];

      if (sp.id === spid) {
        sp.services.map((service) => {
          vehicletype.push({
            id: service.vehicleType,
            status: service.status,
          });
        });
        this.setState({
          active_vehicles: vehicletype,
        });
      }
    });
  };
  // function to set new status of vehicle
  toggleVehicleStatus = async (id, status) => {
    var arabic = [];
    var active_vehicles = this.state.active_vehicles;
    active_vehicles.map((item) => {
      if (item.id === id) {
        item.status = !status;
      }
    });
    this.setState({
      active_vehicles: active_vehicles,
    });
    const vehicle_type = await RealTime.getDetails(
      "twaddan_admin/vehicle_type"
    );
    Object.entries(vehicle_type).forEach(([keyVt, vt]) => {
      if (keyVt === id) {
        arabic.push({
          name: vt.name,
          name_ar: vt.name_ar,
        });
      }
    });

    firebase
      .database()
      .ref("service_providers/" + spid + "/new_services/" + id)
      .update({
        status: !status,
        name: arabic[0].name,
        name_ar: arabic[0].name_ar,
      });

    const vehicles = await RealTime.getDetails(
      "service_providers/" + spid + "/vehicles"
    );
    if (vehicles) {
      Object.entries(vehicles).forEach(([keyVh, vh]) => {
        if (vh.vehicle_type) {
          Object.entries(vh.vehicle_type).forEach(([keyVt, vt]) => {
            if (keyVt === id) {
              firebase
                .database()
                .ref(
                  "service_providers/" +
                    spid +
                    "/vehicles/" +
                    keyVh +
                    "/vehicle_type/" +
                    keyVt
                )
                .update({
                  status: !status,
                  name: arabic[0].name,
                  name_ar: arabic[0].name_ar,
                });
            }
          });
        }
      });
    }

    this.toggleMessage();
  };
  // to handle confirmation dialog
  toggleConfirmation() {
    this.setState({
      toggle_modal: !this.state.toggle_modal,
    });
  }
  // to handle password confirmation dialog
  togglePasswordConfirmation() {
    this.setState({
      PasswordConfirmation: !this.state.PasswordConfirmation,
    });
  }
  // to handle message dialog
  toggleMessage() {
    this.setState({
      message_modal: !this.state.message_modal,
    });
  }
  // to set re auth password
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };
  // function to re-auth the user with password
  PasswordConfirm() {
    const { password } = this.state;

    var user = firebase.auth().currentUser;
    var credential = firebaseCredential.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    // Prompt the user to re-provide their sign-in credentials
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        // User re-authenticated.
        this.toggleConfirmation();
        this.togglePasswordConfirmation();
        const toggle_item = toggle_vehicle.pop();

        this.toggleVehicleStatus(
          toggle_item.vehicle_id,
          toggle_item.currentState
        );
        // delete node and clear toggle vehicle
      })
      .catch(function (error) {
        // An error happened.
        alert(error);
      });
  }

  componentDidMount() {
    this.getData();
  }
  //! function set status and name of vehicle type
  setStatusOfVehicleTypeNew = async () => {
    if (true) {
      const service_providers = await RealTime.getDetails("service_providers");
      const vehicle_types = await RealTime.getDetails(
        "twaddan_admin/vehicle_type"
      );
      Object.entries(service_providers).forEach(([keySp, sp]) => {
        if (sp.new_services) {
          Object.entries(sp.new_services).forEach(([keyVt, vt]) => {
            if (vt.major_services) {
              Object.entries(vehicle_types).forEach(([keyAdminVt, adminVt]) => {
                if (keyVt === keyAdminVt) {
                  firebase
                    .database()
                    .ref(
                      "service_providers/" + keySp + "/new_services/" + keyVt
                    )
                    .update({
                      status: true,
                      name: adminVt.name,
                      name_ar: adminVt.name_ar,
                    });
                }
              });
            }
          });
        }
        if (sp.vehicles) {
          Object.entries(sp.vehicles).forEach(([keyVh, vh]) => {
            if (vh.vehicle_type) {
              Object.entries(vh.vehicle_type).forEach(([keyVhVt, vhvt]) => {
                if (vhvt.major_services) {
                  Object.entries(vehicle_types).forEach(
                    ([keyAdminVt, adminVt]) => {
                      if (keyVhVt === keyAdminVt) {
                        firebase
                          .database()
                          .ref(
                            "service_providers/" +
                              keySp +
                              "/vehicles/" +
                              keyVh +
                              "/vehicle_type/" +
                              keyVhVt
                          )
                          .update({
                            status: true,
                            name: adminVt.name,
                            name_ar: adminVt.name_ar,
                          });
                      }
                    }
                  );
                }
              });
            }
          });
        }
      });
    }
  };
  render() {
    if (this.state.vehicleSummary === true) {
      return <VehicleTypeNew />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className='mt--15' fluid>
          <Row className='mt-3 mb-3'>
            <Col sm={12} md={7} lg={8} xl={9}>
              <h1 className='mb-0'>Vehicle Type Status</h1>
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              <Button
                color='primary'
                style={{ right: "5px" }}
                block
                onClick={() => {
                  this.setState({
                    vehicleSummary: true,
                  });
                }}>
                BACK
              </Button>
              {/* <Button
                color='primary'
                style={{ right: "5px" }}
                block
                onClick={this.setStatusOfVehicleTypeNew}>
                SET STATUS
              </Button> */}
            </Col>
          </Row>
          <br></br>
          <h3> Vehicle Types</h3>
          <br></br>

          <Row>
            {this.state.active_vehicles.map((vehicle) => {
              return (
                <Col xl='4' lg='6' md='12' sm='12' xs='12'>
                  <Card>
                    <CardBody>
                      <Container>
                        <Row>
                          <Col>
                            <h4>{formateName(vehicle.id).toUpperCase()}</h4>
                          </Col>

                          <Col>
                            <Button
                              color={`${vehicle.status ? "danger" : "success"}`}
                              style={{
                                position: "absolute",
                                right: "5px",
                              }}
                              onClick={() => {
                                toggle_vehicle.push({
                                  vehicle_id: vehicle.id,
                                  currentState: vehicle.status,
                                });
                                this.toggleConfirmation();
                              }}>
                              {vehicle.status ? "DELETE" : "ENABLE"}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </CardBody>
                  </Card>
                  <br></br>
                </Col>
              );
            })}
          </Row>
        </Container>

        {/* dialog boxes  */}

        <Modal
          isOpen={this.state.toggle_modal}
          toggle={this.toggleConfirmation}>
          <ModalBody>
            Do you want to{" "}
            {`${
              toggle_vehicle[toggle_vehicle.length - 1]?.currentState
                ? "Delete"
                : "Enable"
            }`}
          </ModalBody>
          <ModalFooter>
            <Button
              color='info'
              onClick={() => {
                this.toggleConfirmation();
              }}>
              N0
            </Button>
            <Button
              color='danger'
              onClick={() => {
                this.togglePasswordConfirmation();
              }}>
              YES
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.PasswordConfirmation}
          toggle={this.togglePasswordConfirmation}>
          <ModalBody>Confirm Password</ModalBody>
          <ModalFooter>
            <CardBody className='px-lg-5 py-lg-5'>
              <div className='text-center text-muted mb-4'>
                <large>
                  Verify Password to Proceed with{" "}
                  {`${
                    toggle_vehicle[toggle_vehicle.length - 1]?.currentState
                      ? "Deletion"
                      : "Enabling"
                  }`}
                </large>
              </div>
              <Form role='form'>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-circle-08' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Password'
                      type='password'
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <Button
                  color='info'
                  onClick={() => {
                    this.togglePasswordConfirmation();
                  }}>
                  CANCEL
                </Button>
                <Button
                  color='danger'
                  onClick={() => {
                    this.PasswordConfirm();
                  }}>
                  PROCEED
                </Button>
              </Form>
            </CardBody>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.message_modal}
          toggle={this.toggleMessage}
          size='lg'>
          <ModalBody>Congratulation Updated Successfully</ModalBody>
          <ModalFooter>
            <Button
              color='primary'
              onClick={() => {
                this.toggleMessage();
              }}>
              CONTINUE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default class VehicleTypeNew extends React.Component {
  constructor() {
    super();
    this.state = {
      isloaded: false,
      modal: false,
      deletemodal: false,
      name: "",
      addVehicleType: 0,
      editVehicleType: 0,
      currentSpid: "",
      currentVehicleType: "",
      currentCount: 0,
      totalVehicle: "",
      searchBarID: "",
      deletePasswordConfirmation: "",
      password: "",
      emirates: "",
      selectedEmirate: "",
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
    this.toggle = this.toggle.bind(this);
    this.deletetoggle = this.deletetoggle.bind(this);

    this.deletePasswordConfirmationToggle = this.deletePasswordConfirmationToggle.bind(
      this
    );
    this.deletePasswordConfirm = this.deletePasswordConfirm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  deletePasswordConfirmationToggle() {
    this.setState({
      deletePasswordConfirmation: !this.state.deletePasswordConfirmation,
    });
  }

  deletePasswordConfirm() {
    const { password } = this.state;
    var user = firebase.auth().currentUser;
    var credential = firebaseCredential.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    // Prompt the user to re-provide their sign-in credentials
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        // User re-authenticated.
        this.deletePasswordConfirmationToggle();
        this.toggle();
      })
      .catch(function (error) {
        // An error happened.
        alert(error);
      });
  }

  async componentDidMount() {
    this.setState({
      isLoaded: true,
    });
    const emirates = await RealTime.getDetails("twaddan_admin/emirates");
    const vehicle_type = await RealTime.getDetails(
      "twaddan_admin/vehicle_type/"
    );

    if (emirates) {
      this.setState({
        emirates: Object.keys(emirates),
      });
    }

    this.getVehicle(vehicle_type);
  }

  //collect data
  getServices = () => {
    firebase
      .database()
      .ref("service_providers/")
      .on("value", (snapshot) => {
        var serviceList = [];
        snapshot.forEach((providers) => {
          // for each service_providers
          var vehicleType = [];
          if (providers.val().new_services) {
            // if there is new_services

            Object.entries(providers.val().new_services).forEach(
              ([key, vt]) => {
                // for each vehicle_type

                if (vt.status) {
                  vehicleType.push({
                    vehicleType: key,
                    status: true,
                  });
                } else {
                  vehicleType.push({
                    vehicleType: key,
                    status: false,
                  });
                }
              }
            );
          }
          serviceList.push({
            service_provider: providers.key,
            name: providers.val().personal_information.name,
            image: providers.val().personal_information.image,
            services: vehicleType,
          });
        });

        this.formateData(serviceList);
      });
  };

  formateData = (serviceList) => {
    var activeServices = [];
    for (let index = 0; index < serviceList.length; index++) {
      activeServices.push({
        id: serviceList[index].service_provider,
        name: serviceList[index].name,
        image: serviceList[index].image,
        services: this.acticeVehicle(serviceList[index].services),
      });
    }

    spdetails1 = activeServices;
    spDetailsCopy = activeServices;
    this.setState({
      isloaded: true,
    });
  };

  // to get list of vehicles
  acticeVehicle = (vehicles) => {
    var service = [];
    if (vehicles.length !== 0) {
      totalVehicle.map((i) => {
        var count = 0;
        for (var dr = 0; dr < vehicles.length; dr++) {
          if (i.id === vehicles[dr].vehicleType) {
            service.push({
              vehicleType: vehicles[dr].vehicleType,
              status: vehicles[dr].status,
            });
            count = 1;
          }
        }
        if (count === 0) {
          service.push({
            vehicleType: i.id,
            status: false,
          });
        }
      });
      return service;
    } else {
      totalVehicle.map((i) => {
        service.push({
          vehicleType: i.id,
          status: false,
        });
      });
      return service;
    }
  };
  // get all vehicle
  getVehicle = (drs) => {
    totalVehicle = [];
    if (drs != null) {
      var keys = Object.keys(drs);
      for (var dr = 0; dr < keys.length; dr++) {
        var k = keys[dr];
        totalVehicle.push({
          id: k,
          count: drs[k].index,
        });
      }
    }

    this.getServices();
  };
  // emirate filter
  getServiceProviders = async (emirate) => {
    this.setState({
      searchBarID: "",
    });
    var active_service_providers = [];
    if (emirate === "All") {
      spdetails1 = spDetailsCopy;
    } else {
      var sortedSP = [];
      const service_providers = await RealTime.getDetails(
        `twaddan_admin/emirates/${emirate}/service_providers`
      );

      if (service_providers) {
        active_service_providers = Object.keys(service_providers);
        spDetailsCopy.map((all_sp) => {
          active_service_providers.map((active_sp) => {
            if (all_sp.id === active_sp) {
              sortedSP.push(all_sp);
            }
          });
        });
      }
      spdetails1 = sortedSP;
      this.setState({
        isLoaded: false,
      });
    }
  };

  onChangeSearch = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
    this.setState({
      selectedEmirate: "All",
    });
    if (stateName == "searchBarID") {
      if (value == "") {
        spdetails1 = spDetailsCopy;
      } else {
        spdetails1 = [];
        Object.keys(spDetailsCopy).map((line, key) => {
          var IDSearch = spDetailsCopy[line].name.toLowerCase();

          if (IDSearch.startsWith(value.toLowerCase())) {
            spdetails1.push(spDetailsCopy[line]);
          }
        });
      }
    }
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  deletetoggle() {
    this.setState({
      deletemodal: !this.state.deletemodal,
    });
  }
  deleteVehicleType = () => {
    var time = new Date();
    time = time.getTime();
    var logAction = "Deleted Vehicle Type " + this.state.currentVehicleType;
    var logs = firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          this.state.saID +
          "/logs/" +
          time +
          "/"
      );
    logs.update({
      action: logAction,
    });

    var vehicle_type = this.state.currentVehicleType;
    const dr2 = firebase.database().ref("service_providers/");
    dr2.on("value", (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var id = childSnapshot.key;
        let userRef = firebase
          .database()
          .ref("service_providers/" + id + "/services/" + vehicle_type);
        userRef.remove();
      });
    });
    var self = this;
    const dr = firebase.database().ref("twaddan_admin/vehicle_type/");
    dr.once("value", (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var id = childSnapshot.key;
        var drs = childSnapshot.val();
        if (drs.index > self.state.currentCount) {
          var countt = drs.index;
          var ref1 = firebase
            .database()
            .ref("twaddan_admin/vehicle_type/" + id);
          ref1.update({
            index: countt - 1,
          });
        }
      });
    });
    let userRef = firebase
      .database()
      .ref("twaddan_admin/vehicle_type/" + this.state.currentVehicleType);
    userRef.remove();
    this.toggle();
  };
  navigate = () => {
    this.setState({
      addVehicleType: 1,
    });
  };
  edit_vehicle = (id) => {
    spid = id;
    this.setState({
      editVehicleType: 1,
    });
  };
  addVehicleType = () => {
    var vehicle_type = this.state.name;
    vehicle_type = vehicle_type.toLowerCase();
    vehicle_type = vehicle_type.trim();
    vehicle_type = vehicle_type.replace(" ", "_");
    var ref = firebase.database().ref("twaddan_admin/vehicle_type/");
    ref.update({
      [vehicle_type]: true,
    });
    this.toggle();
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  render() {
    let emiratesList =
      this.state.emirates.length > 0 &&
      this.state.emirates.map((i) => {
        return <option>{i}</option>;
      }, this);
    if (this.state.addVehicleType === 1) {
      return <Redirect to='/admin/addVehicleType' />;
    }
    if (this.state.editVehicleType === 1) {
      return <EditVehicleTypeNew />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>

        <Container className=' mt--10' fluid>
          <Row className='mt-5'>
            <Col sm={12} md={7} lg={8} xl={9}>
              <h1 className='mb-0'>Total Vehicle Types</h1>
            </Col>
            <Col sm={6} md={5} lg={4} xl={3}>
              {this.state.crudPermission.createPermission && (
                <Button color='primary' block onClick={this.navigate}>
                  ADD VEHICLE TYPE
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            {totalVehicle.map((i) => {
              return (
                <Col xl='4' lg='6' md='6' sm='12' xs='12' className='mb-3 mt-3'>
                  <Card borderRadius='1'>
                    <CardBody>
                      {false && (
                        <Button
                          color='danger'
                          style={{ position: "absolute", right: "10px" }}
                          onClick={() => {
                            this.setState({
                              currentVehicleType: i.id,
                              currentCount: i.count,
                              deletePasswordConfirmation: true,
                            });
                          }}>
                          DELETE
                        </Button>
                      )}
                      <h4>{formateName(i.id).toUpperCase()}</h4>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <h1>Service Providers</h1>
            </Col>
          </Row>
          <Row xs='2'>
            <Col xs='6'>
              <FormGroup>
                <Input
                  className='form-control-alternative'
                  defaultValue={this.state.searchBarID}
                  value={this.state.searchBarID}
                  id='input-price'
                  placeholder='Search Service Providers'
                  type='text'
                  onChange={(e) =>
                    this.onChangeSearch("searchBarID", e.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col xs='6'>
              <FormGroup>
                <Input
                  className='form-control-alternative'
                  id='input-price'
                  placeholder=' Select Emirate'
                  type='select'
                  value={this.state.selectedEmirate}
                  onChange={(e) => {
                    this.setState({ selectedEmirate: e.target.value });
                    this.getServiceProviders(e.target.value);
                  }}>
                  <option hidden>Select Emirate</option>
                  <option>All</option>
                  {emiratesList}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row xs='6' md='6' lg='6'>
            {spdetails1.map((i) => {
              return (
                <Col xl='4' lg='6' md='12' sm='12' xs='12'>
                  <Card>
                    <Row xs='6'>
                      <Col xs='4'>
                        <img
                          alt='...'
                          src={i.image}
                          style={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "50%",
                            margin: "15px",
                          }}
                        />
                      </Col>
                      <Col xs='6' className='mt-5'>
                        <h3
                          style={{
                            color: "red",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}>
                          {i.name}
                        </h3>
                      </Col>
                    </Row>

                    <CardBody>
                      <br></br>
                      <Table
                        responsive
                        className='dashboard-table table-hover-animation mb-0 mt-1'>
                        <thead>
                          <tr>
                            <th>Vehicle Type</th>
                            <th>Status </th>
                          </tr>
                        </thead>
                        <tbody>
                          {i.services.map((j) => {
                            //var idd = j.id+i.id;

                            if (j.status === true) {
                              return (
                                <tr>
                                  <td>
                                    {formateName(j.vehicleType).toUpperCase()}
                                  </td>
                                  <td>
                                    <div
                                      className='bg-success'
                                      style={{
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "50%",
                                        display: "inline-block",
                                        marginRight: "5px",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            } else {
                              return (
                                <tr>
                                  <td>
                                    {formateName(j.vehicleType).toUpperCase()}
                                  </td>
                                  <td>
                                    <div
                                      className='bg-danger'
                                      style={{
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "50%",
                                        display: "inline-block",
                                        marginRight: "5px",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                      <Col className='mb-3'>
                        {this.state.crudPermission.updatePermission && (
                          <Button
                            color='primary'
                            onClick={() => {
                              this.edit_vehicle(i.id);
                            }}
                            style={{}}>
                            <Edit />
                          </Button>
                        )}
                      </Col>
                    </CardFooter>
                  </Card>
                  <br></br>
                </Col>
              );
            })}
          </Row>
        </Container>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          size='lg'>
          <ModalBody>Do you want to delete</ModalBody>
          <ModalFooter>
            <Button color='info' onClick={this.toggle}>
              N0
            </Button>
            <Button color='danger' onClick={this.deleteVehicleType}>
              YES
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.deletemodal} toggle={this.deletetoggle}>
          <ModalBody>Do you want to delete</ModalBody>
          <ModalFooter>
            <Button color='info' onClick={this.deletetoggle}>
              N0
            </Button>
            <Button color='danger' onClick={this.deleteVehicleType}>
              YES
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.deletePasswordConfirmation}
          toggle={this.deletePasswordConfirmationToggle}>
          <ModalBody>Confirm Password</ModalBody>
          <ModalFooter>
            <CardBody className='px-lg-5 py-lg-5'>
              <div className='text-center text-muted mb-4'>
                <large>Verify Password to Proceed with Deletion</large>
              </div>
              <Form role='form'>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-circle-08' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Password'
                      type='password'
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <Button
                  color='info'
                  onClick={this.deletePasswordConfirmationToggle}>
                  CANCEL
                </Button>
                <Button color='danger' onClick={this.deletePasswordConfirm}>
                  PROCEED
                </Button>
              </Form>
            </CardBody>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
