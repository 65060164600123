import React, { Component } from 'react';
import Select from 'react-select';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { InfoWindow, withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import moment from 'moment';
import { compose, withProps } from 'recompose';
import Switch from 'react-switch';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Card,
    CardBody,
    CardImg,
    FormGroup,
    Input,
    CardHeader,
    Form,
    Label,
    FormText,
    Table,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Collapse,
    BreadcrumbItem,
    Breadcrumb,
} from 'reactstrap';
import '../styles/Vehicles.css';

// core components
import Header from 'components/Headers/Header.jsx';
import firebase from '../../firebaseConfig';
import { Redirect } from 'react-router-dom';
import { Edit } from 'react-feather';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ConvertArabic from '../../services/ConvertArabic';
import RealTime from 'services/RealTime';
import PageLoader from 'components/PageLoader/PageLoader';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Utility from 'services/Utility';
const axios = require('axios').default;

var spdetails = [];

var spDetailsCopy = [];
var detailViewVehicle;
var vehicleList = [];
var LocationList = [];
var serviceDetailsFromDB = null;
var actual_schedule = [];
// CRUD permission details
var crudPermission;
// super admin ID
var saID = localStorage.getItem('admin_email');
var editVehicle = {};
var spId;
crudPermission = JSON.parse(localStorage.getItem('crudPermissions'));

const vehicleColumns = [
    {
        text: 'Si No',
        dataField: 'i',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'spid',
        dataField: 'sp_id',
        sort: true,
        align: 'center',
        hidden: true,

        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Service Provider',
        dataField: 'sp_name',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter({
            style: {
                minWidth: '150px',
                placeholder: 'Enter Name ',
            },
        }),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Emirate',
        dataField: 'emirate',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter({
            style: {
                minWidth: '150px',
            },
        }),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Vehicle Name',
        dataField: 'vehicle_name',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter({
            style: {
                minWidth: '150px',
            },
        }),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Vehicle Number',
        dataField: 'vehicle_id',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter({
            style: {
                minWidth: '150px',
            },
        }),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
];
const table3Columns = [
    {
        text: 'Area',
        dataField: 'area',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Price',
        dataField: 'price',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },

    {
        text: 'Status',
        dataField: 'status',
        sort: true,
        hidden: true,
        align: 'center',
        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
];
var master_schedule = [
    {
        day: 'sunday',
        shift1_end: null,
        shift1_start: null,
        shift2_end: null,
        shift2_start: null,
        start_location: {
            lat: null,
            lng: null,
        },
    },
    {
        day: 'Monday',
        shift1_end: null,
        shift1_start: null,
        shift2_end: null,
        shift2_start: null,
        start_location: {
            lat: null,
            lng: null,
        },
    },
    {
        day: 'Tuesday',
        shift1_end: null,
        shift1_start: null,
        shift2_end: null,
        shift2_start: null,
        start_location: {
            lat: null,
            lng: null,
        },
    },
    {
        day: 'Wednesday',
        shift1_end: null,
        shift1_start: null,
        shift2_end: null,
        shift2_start: null,
        start_location: {
            lat: null,
            lng: null,
        },
    },
    {
        day: 'Thursday',
        shift1_end: null,
        shift1_start: null,
        shift2_end: null,
        shift2_start: null,
        start_location: {
            lat: null,
            lng: null,
        },
    },
    {
        day: 'Friday',
        shift1_end: null,
        shift1_start: null,
        shift2_end: null,
        shift2_start: null,
        start_location: {
            lat: null,
            lng: null,
        },
    },
    {
        day: 'Saturday',
        shift1_end: null,
        shift1_start: null,
        shift2_end: null,
        shift2_start: null,
        start_location: {
            lat: null,
            lng: null,
        },
    },
];

const MyMapComponent = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) => (
    <GoogleMap
        defaultZoom={props.zoomLevel}
        zoom={props.zoomLevel}
        defaultCenter={props.latLong}
        onClick={props.onMapClick}
        center={props.latLong}
    >
        {props.isMarkerShown && <Marker position={props.latLong} onClick={props.onMarkerClick} />}
    </GoogleMap>
));
class Vehicles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloaded: true,
            modal: false,
            modalArea: false,
            spid: 0,
            searchBarID: '',
            edit: false,
            addVehicle: false,
            assignSpToVehicle: false,
            vehicleDetails: '',
            selectedVehicle: '',
            map_preview: '',
            map_preview_name: '',
            mapModal: false,
            saID: localStorage.getItem('admin_email'),
            crudPermission: JSON.parse(localStorage.getItem('crudPermissions')),
            collapse: false,
        };
        this.toggle = this.toggle.bind(this);
        this.toggleArea = this.toggleArea.bind(this);
        this.navigateAddVehicle = this.navigateAddVehicle.bind(this);
    }

    async componentDidMount() {
        this.setState({
            isloaded: false,
        });
        this.getData();
    }
    toggleMapPreview = () => {
        this.setState({
            mapModal: !this.state.mapModal,
        });
    };
    getData = async () => {
        var VehicleDetails = [];
        var i = 1;
        const data = await RealTime.getDetails('service_providers/');
        const vehiclesFromDb = await RealTime.getDetails('vehicles/');

        if (vehiclesFromDb) {
            Object.entries(vehiclesFromDb).forEach(([key, vehicle]) => {
                console.log('key: ', key);

                vehicle.sp_related.map((sp) => {
                    VehicleDetails.push({
                        i: i++,
                        sp_id: sp.sp_id,
                        sp_name: data[sp.sp_id]?.personal_information?.name,
                        vehicle_id: key,
                        vehicle_name: vehicle?.name,
                        emirate: sp.emirate,
                        emirateArea: vehicle?.emiateArea,
                        vehicle_details: vehicle,
                        driver_id: vehicle.driver,
                        emirateArea: vehicle?.emiateArea,
                    });
                });
            });
            // Object.entries(data).map((sp, key) => {
            //   if (sp[1].vehicles) {
            //     Object.entries(sp[1].vehicles).map((vehicle, key) => {

            //   }
            // });
        }

        this.setState({
            vehicleDetails: VehicleDetails,
            isloaded: true,
        });
        return true;
    };
    // onChange = (stateName, value) => {
    //   this.setState({
    //     [stateName]: value,
    //   });

    //   if (stateName == "searchBarID") {
    //     if (value == "") {
    //       spdetails = spDetailsCopy;
    //     } else {
    //       spdetails = [];
    //       Object.keys(spDetailsCopy).map((line, key) => {
    //         var IDSearch = spDetailsCopy[line].name.toLowerCase();

    //         if (IDSearch.startsWith(value.toLowerCase())) {
    //           spdetails.push(spDetailsCopy[line]);
    //         }
    //       });
    //     }
    //   }
    // };
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    toggleArea() {
        this.setState({
            modalArea: !this.state.modalArea,
        });
    }
    getVehicleDetails = (idd) => {
        spId = idd;
        vehicleList = [];
        const dr1 = firebase.database().ref('service_providers/' + idd + '/vehicles/');
        dr1.on('value', (snapshot) => {
            var drs = snapshot.val();
            if (drs != null) {
                var keys = Object.keys(drs);
                for (var dr = 0; dr < keys.length; dr++) {
                    var k = keys[dr];
                    vehicleList.push({
                        detail: drs[k],
                    });
                }
            }
        });
    };

    getLocationDetails = (number) => {
        LocationList = [];
        const dr1 = firebase.database().ref('service_providers/' + spId + '/vehicles/' + number + '/serviceable_areas');
        dr1.on('value', (snapshot) => {
            var drs = snapshot.val();
            if (drs != null) {
                var keys = Object.keys(drs);
                for (var dr = 0; dr < keys.length; dr++) {
                    var k = keys[dr];
                    LocationList.push({
                        area: drs[k].area,
                        price: drs[k].price,
                        status: drs[k].status,
                    });
                }
            }
        });
    };
    getServiceSetails = async (number) => {
        serviceDetailsFromDB = null;
        serviceDetailsFromDB = await RealTime.getDetails(
            'service_providers/' + spId + '/vehicles/' + number + '/vehicle_type/'
        );
    };

    navigateAddVehicle = () => {
        this.setState({
            addVehicle: true,
        });
    };
    navigateAssignSpToVehicle = () => {
        this.setState({
            assignSpToVehicle: true,
        });
    };
    disableVehicle = (vehicle_id, status, serviceableAreas) => {
        RealTime.updateNode('/vehicles/' + vehicle_id, {
            status: !status,
        });
        let vehicleCopy = this.state.selectedVehicle;
        vehicleCopy.status = !status;
        serviceableAreas.map((item) => {
            if (status) {
                RealTime.updateNode(
                    '/twaddan_admin/emirates/' +
                        this.state.emirateSelectedId +
                        '/serviceable_areas/' +
                        item.area +
                        '/service_providers/' +
                        spId +
                        '/vehicles/' +
                        vehicle_id,
                    {
                        status: false,
                    }
                );
            } else {
                RealTime.updateNode(
                    '/twaddan_admin/emirates/' +
                        this.state.emirateSelectedId +
                        '/serviceable_areas/' +
                        item.area +
                        '/service_providers/' +
                        spId +
                        '/vehicles/' +
                        vehicle_id,
                    {
                        status: item.status,
                    }
                );
            }
        });

        this.setState({ selectedVehicle: vehicleCopy });
    };

    storeCurrentVehicle = (
        name,
        name_ar,
        number,
        description,
        description_ar,
        additionalNote,
        additionalNote_ar,
        helperName,
        helperName_ar,
        helperNumber,
        driver,
        driver_status,
        status,
        image_url,
        qrcode_url,
        master_schedule,
        actual_schedule
    ) => {
        // console.log("master_schedule: ", master_schedule);
        editVehicle['number'] = number;
        editVehicle['name'] = name;
        editVehicle['name_ar'] = name_ar;
        editVehicle['description'] = description;
        editVehicle['description_ar'] = description_ar;
        editVehicle['additionalNote'] = additionalNote;
        editVehicle['additionalNote_ar'] = additionalNote_ar;
        editVehicle['helperName'] = helperName;
        editVehicle['helperName_ar'] = helperName_ar;
        editVehicle['helperNumber'] = helperNumber;
        editVehicle['driver'] = driver;
        editVehicle['driver_status'] = driver_status;
        editVehicle['status'] = status;
        editVehicle['downloadURL'] = image_url;
        editVehicle['qrcode_url'] = qrcode_url;
        editVehicle['serviceableAreas'] = LocationList;
        editVehicle['master_schedule'] = master_schedule;
        editVehicle['actual_schedule'] = actual_schedule;
        this.setState({
            edit: true,
        });
    };
    rowStyle = (row, rowIndex) => {
        const style = {};
        if (rowIndex % 2 === 0) {
            style.backgroundColor = '#e6e6e6';
        }
        style.cursor = 'pointer';
        return style;
    };
    details = (text, data) => {
        return (
            <>
                <Col xs="5" md="5">
                    <p>
                        <b>{text}</b>
                    </p>
                </Col>
                <Col xs="7" md="7">
                    <p>: {data}</p>
                </Col>
            </>
        );
    };
    autoScheduleManage = async () => {
        await RealTime.updateNode('/vehicles/' + this.state.selectedVehicle.number + '/', {
            auto_schedule_status: !this.state.selectedVehicle?.auto_schedule_status,
        });
        this.setState(
            {
                selectedVehicle: {
                    ...this.state.selectedVehicle,
                    auto_schedule_status: !this.state.selectedVehicle?.auto_schedule_status,
                },
            },
            () => {
                console.log('this.state.selectedVehicle: ', this.state.selectedVehicle);
            }
        );
    };

    render() {
        const rowEventsArea = {
            onClick: (e, row, rowIndex) => {
                Object.entries(this.state.emirateSelected.serviceable_areas).forEach(([key, value]) => {
                    if (key === row.area) {
                        this.setState({
                            map_preview: value.map_preview,
                            map_preview_name: row.area,
                            mapModal: true,
                        });
                    }
                });
            },
        };
        const rowEvents = {
            onClick: async (e, row, rowIndex) => {
                this.setState({ isLoaded: false }, () => {
                    console.log('this.state.isLoaded', this.state.isLoaded);
                });
                let selectedVehicle = row.vehicle_details;
                let emirates = await RealTime.getDetails('/twaddan_admin/emirates/');
                if (row.driver_id) {
                    let drievr = await RealTime.getDetails('/drivers/' + row.driver_id);
                    if (drievr.personal_information?.name)
                        selectedVehicle['driver_name'] = drievr.personal_information?.name;
                }
                const spVehicleFromDB = await RealTime.getDetails(
                    '/service_providers/' + row.sp_id + '/vehicles/' + row.vehicle_id
                );
                console.log('selectedVehicle: ', selectedVehicle);
                selectedVehicle['serviceable_areas'] = spVehicleFromDB?.serviceable_areas;
                selectedVehicle['vehicle_type'] = spVehicleFromDB.vehicle_type;
                let emirateSelectedId = '';
                let emirateSelected = null;
                detailViewVehicle = selectedVehicle;

                Object.entries(emirates).forEach(([key, emirate]) => {
                    if (emirate.service_providers)
                        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
                            if (key2 === row.sp_id) {
                                this.setState({ emirate: key });
                                //console.log("emirate", key);
                                emirateSelectedId = key;
                                emirateSelected = emirate;
                                return emirateSelected;
                            }
                            //console.log("key2", key2);
                        });
                });
                this.setState(
                    {
                        modal: true,
                        selectedVehicle: row.vehicle_details,
                        emirateSelectedId: emirateSelectedId,
                        emirateSelected: emirateSelected,
                        isLoaded: true,
                    },
                    () => {
                        spId = row.sp_id;
                    }
                );
            },
        };
        if (this.state.addVehicle) {
            return <Redirect to="/admin/addVehicle" />;
        }
        if (this.state.assignSpToVehicle) {
            return <Redirect to="/admin/assignSpToVehicle" />;
        }
        if (this.state.edit === true) {
            return <EditVehicle />;
        }
        if (this.state.view === true) {
            return <DetailViewVehicle />;
        }
        return (
            <>
                <Header />
                <PageLoader loading={!this.state.isloaded}></PageLoader>
                <Container className="mt--7" fluid>
                    <Row className="mt-8 mb-5">
                        <Col sm={12} md={7} lg={4} xl={6}>
                            <h1 className="mb-0">Vehicles</h1>
                        </Col>
                        <Col sm={6} md={5} lg={4} xl={3}>
                            <Button
                                block
                                color="primary"
                                style={{ right: '5px' }}
                                onClick={this.navigateAssignSpToVehicle}
                            >
                                ASSIGN SP TO VEHICLE
                            </Button>
                        </Col>
                        <Col sm={6} md={5} lg={4} xl={3}>
                            <Button block color="primary" style={{ right: '5px' }} onClick={this.navigateAddVehicle}>
                                ADD VEHICLE
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3">
                            <BootstrapTable
                                keyField="id"
                                data={this.state.vehicleDetails}
                                columns={vehicleColumns}
                                rowStyle={this.rowStyle}
                                filter={filterFactory()}
                                filterPosition="top"
                                pagination={paginationFactory()}
                                bordered={false}
                                hover
                                wrapperClasses="table-responsive"
                                condensed
                                rowEvents={rowEvents}
                            />
                        </Col>
                    </Row>
                </Container>

                {this.state.selectedVehicle && (
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="md">
                        <ModalHeader toggle={this.toggle}>
                            <Row>
                                <Col lg="10" md="10" sm="10" xs="10">
                                    <h1> Vehicle Details</h1>
                                </Col>
                                <Col lg="2" md="2" sm="2" xs="2"></Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody className="p-0">
                            {
                                <>
                                    <Row>
                                        <Col lg="4" md="4" sm="4" xs="4">
                                            <Button
                                                color={this.state.selectedVehicle?.status ? 'danger' : 'success'}
                                                onClick={() => {
                                                    this.disableVehicle(
                                                        this.state.selectedVehicle?.number,
                                                        this.state.selectedVehicle?.status,
                                                        this.state.selectedVehicle?.serviceable_areas
                                                    );
                                                }}
                                                block
                                            >
                                                {this.state.selectedVehicle?.status ? 'DISABLE' : 'ENABLE'}
                                            </Button>
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="4">
                                            <Button
                                                block
                                                color={
                                                    this.state.selectedVehicle?.auto_schedule_status
                                                        ? 'danger'
                                                        : 'success'
                                                }
                                                onClick={() => {
                                                    this.autoScheduleManage();
                                                }}
                                            >
                                                {this.state.selectedVehicle?.auto_schedule_status
                                                    ? 'SCHEDULE OFF'
                                                    : 'SCHEDULE ON'}
                                            </Button>
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="4">
                                            <Button
                                                block
                                                color="warning"
                                                onClick={() => {
                                                    this.setState({ collapse: !this.state.collapse });
                                                }}
                                            >
                                                SERVICE AREAS
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card>
                                                {/* <CardImg
                        top
                        className="img-fluid"
                        src={this.state.selectedVehicle?.image_url}
                        alt="card image cap"
                      /> */}
                                                <CardBody className="pb-0">
                                                    <Row>
                                                        {this.details('Name', this.state.selectedVehicle?.name)}
                                                        {this.details(
                                                            'Name Arabic',
                                                            this.state.selectedVehicle?.name_ar
                                                        )}
                                                        {/* {this.details(
                            "Description",
                            this.state.selectedVehicle?.description
                          )}
                          {this.details(
                            " ",
                            this.state.selectedVehicle?.description_ar
                          )} */}
                                                        {this.details('Number', this.state.selectedVehicle?.number)}
                                                        {this.details(
                                                            'Driver Name',
                                                            detailViewVehicle.driver_name || 'Driver not assigned'
                                                        )}

                                                        {this.details(
                                                            'Status',
                                                            this.state.selectedVehicle?.status ? 'Active' : 'Disabled'
                                                        )}
                                                        {this.details(
                                                            'Emirate Area',
                                                            this.state.selectedVehicle?.emiateArea
                                                                ? this.state.selectedVehicle?.emiateArea
                                                                : 'NO DATA'
                                                        )}
                                                    </Row>
                                                    <Row>
                                                        <Collapse isOpen={this.state.collapse}>
                                                            <Col>
                                                                {this.state.selectedVehicle?.serviceable_areas && (
                                                                    <BootstrapTable
                                                                        keyField="id"
                                                                        data={
                                                                            this.state.selectedVehicle
                                                                                ?.serviceable_areas
                                                                        }
                                                                        columns={table3Columns}
                                                                        filter={filterFactory()}
                                                                        filterPosition="top"
                                                                        rowStyle={this.rowStyle}
                                                                        bordered={false}
                                                                        hover
                                                                        wrapperClasses="table-responsive"
                                                                        condensed
                                                                        rowEvents={rowEventsArea}
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Collapse>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </ModalBody>
                        <ModalFooter className="py-1">
                            <a href={this.state.selectedVehicle.qrcode_url} target="_blank" rel="noopener noreferrer">
                                <Button block color="warning">
                                    QR CODE
                                </Button>
                            </a>

                            <Button
                                color="info"
                                onClick={() => {
                                    this.setState({ view: true });
                                }}
                            >
                                DETAIL VIEW
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.getLocationDetails(this.state.selectedVehicle?.number);
                                    this.getServiceSetails(this.state.selectedVehicle?.number);
                                    this.storeCurrentVehicle(
                                        this.state.selectedVehicle?.name,
                                        this.state.selectedVehicle?.name_ar ? this.state.selectedVehicle?.name_ar : '',
                                        this.state.selectedVehicle?.number,
                                        this.state.selectedVehicle?.description,
                                        this.state.selectedVehicle?.description_ar
                                            ? this.state.selectedVehicle?.description_ar
                                            : '',
                                        this.state.selectedVehicle?.additionalNote
                                            ? this.state.selectedVehicle?.additionalNote
                                            : '',
                                        this.state.selectedVehicle?.additionalNote_ar
                                            ? this.state.selectedVehicle?.additionalNote_ar
                                            : '',
                                        this.state.selectedVehicle?.helperName
                                            ? this.state.selectedVehicle?.helperName
                                            : '',
                                        this.state.selectedVehicle?.helperName_ar
                                            ? this.state.selectedVehicle?.helperName_ar
                                            : '',
                                        this.state.selectedVehicle?.helperNumber
                                            ? this.state.selectedVehicle?.helperNumber
                                            : '',
                                        this.state.selectedVehicle?.driver,
                                        this.state.selectedVehicle?.driver_status
                                            ? this.state.selectedVehicle?.driver_status
                                            : null,
                                        this.state.selectedVehicle?.status ? this.state.selectedVehicle?.status : null,
                                        this.state.selectedVehicle?.image_url,
                                        this.state.selectedVehicle?.qrcode_url,
                                        this.state.selectedVehicle?.master_schedule,
                                        this.state.selectedVehicle?.actual_schedule
                                    );
                                }}
                            >
                                <Edit />
                            </Button>

                            <Button color="danger" onClick={this.toggle}>
                                CLOSE
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
                <Modal isOpen={this.state.mapModal} toggle={this.toggleMapPreview} size="lg">
                    <ModalHeader toggle={this.toggleMapPreview}>{this.state.map_preview_name}</ModalHeader>
                    <ModalBody>
                        <>
                            <iframe
                                width="740"
                                height="400"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.map_preview}`}
                                allowfullscreen
                            ></iframe>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.toggleMapPreview}>CLOSE</Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
var locationEdit = false;
const columns = [
    {
        text: 'Vehicle Type',
        dataField: 'vehicle_type',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Service ID',
        dataField: 'service_id',
        footer: false,
        align: 'center',
        filter: textFilter(),
        headerAlign: 'center',
        sort: true,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
        style: {
            minWidth: '300px',
        },
    },
    {
        text: 'Service Name',
        dataField: 'service_name',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Price',
        dataField: 'price',
        footer: false,
        sort: true,
        align: 'center',
        filter: textFilter(),
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
        style: {},
    },
];
const defaultSorted = [
    {
        dataField: 'vehicle_type',
        order: 'desc',
    },
];
class EditVehicle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            number: editVehicle['number'],
            name: editVehicle['name'],
            name_ar: editVehicle['name_ar'],
            description: editVehicle['description'],
            description_ar: editVehicle['description_ar'],
            additionalNote: editVehicle['additionalNote'],
            additionalNote_ar: editVehicle['additionalNote_ar'],
            helperName: editVehicle['helperName'],
            helperName_ar: editVehicle['helperName_ar'],
            helperNumber: editVehicle['helperNumber'],
            selected_driver_id: editVehicle['driver'],
            driver: editVehicle['driver'],
            driver_status: editVehicle['driver_status'],
            status: editVehicle['status'],
            downloadURL: editVehicle['downloadURL'],
            qrURL: editVehicle['qrcode_url'],
            file: '',
            imagePreviewUrl: '',
            spemail: '',
            additionalNote: '',
            modal: false,
            qrCodeLink: '',
            disableVehicleField: false,
            edit: true,
            area: '',
            price: '',
            locationDetails: LocationList,
            serviceableAreas: '',
            selected: [],
            selectedId: [],
            selectedAddOn: [],
            selectedIdAddOn: [],
            driver_ids: [],
            previewModal: false,
            map_preview: '',
            map_preview_name: '',
            editToggle: false,
            editArea: '',
            editPrice: '',
            checked: true,
            locationModal: false,
            scheduleInde: null,
            isMarkerShown: true,
            zoomLevel: 7,
            latLong: { lat: 25.547944, lng: 55.54828 },
            mapArea: '',
            master_schedule: master_schedule,
            actual_schedule: [],
            isloaded: false,
            majorAreas: [],
            selected_major_area: '',
        };

        this.toggle = this.toggle.bind(this);
        this._handleImageChange = this._handleImageChange.bind(this);
        this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
        this.toggleBack = this.toggleBack.bind(this);
    }
    getData = async () => {
        this.setState({
            services: null,
            servicesFromDB: null,
            addOnService: null,
            servicesAreasFromDB: null,
            selected: null,
            selectedId: null,
            selectedAddOn: null,
            selectedIdAddOn: null,
            selectedIdStored: null,
            selectedStored: null,
            selectedIdAddOnStored: null,
            selectedAddOnStored: null,
        });
        let servicesFromDB = await RealTime.getDetails('/service_providers/' + spId + '/new_services/');
        let vehcile = await RealTime.getDetails('vehicles/' + editVehicle['number'] + '/');
        // actual_schedule = vehcile?.actual_schedule;

        let emirates = await RealTime.getDetails('/twaddan_admin/emirates/');
        this.setState({
            selected_major_area: vehcile.sp_related[0].emiateArea || '',
        });
        let emirateSelected = '';
        Object.entries(emirates).forEach(([key, emirate]) => {
            if (emirate.service_providers)
                Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
                    if (key2 === spId) {
                        this.setState({
                            emirate: key,
                            majorAreas: emirate.majorAreas ? Object.keys(emirate.majorAreas) : [],
                        });
                        emirateSelected = key;
                        return emirateSelected;
                    }
                });
        });
        let drivers = await RealTime.getDetails('drivers');
        let driver_ids = [{ id: '', name: 'Select Driver' }];
        if (drivers) {
            Object.entries(drivers).forEach(([key, driver]) => {
                driver.sp_related.map((sp) => {
                    if (
                        (driver.personal_information &&
                            driver.personal_information.vehicle_number === '' &&
                            sp.sp_id === spId) ||
                        editVehicle['driver'] === key
                    ) {
                        var status = false;
                        if (driver_ids.length > 0) {
                            driver_ids.map((item) => {
                                if (item.id === key) status = true;
                            });
                        }
                        if (!status)
                            driver_ids.push({
                                id: key,
                                name: driver.personal_information.name,
                            });
                    }
                });
            });
        }

        let servicesAreasFromDB = await RealTime.getDetails(
            '/twaddan_admin/emirates/' + emirateSelected + '/serviceable_areas'
        );
        let addOnService = [];
        let services = [];
        let selected = [];
        let selectedStored = [];
        let selectedId = [];
        let selectedIdStored = [];
        let selectedAddOn = [];
        let selectedAddOnStored = [];
        let selectedIdAddOn = [];
        let selectedIdAddOnStored = [];
        if (servicesFromDB) {
            Object.entries(servicesFromDB).forEach(([key, vehicle_type]) => {
                if (vehicle_type.major_services)
                    Object.entries(vehicle_type.major_services).forEach(([key2, service]) => {
                        if (service.status)
                            services.push({
                                id: key + '_' + key2,
                                vehicle_type: key,
                                service_id: key2,
                                service_name: service.name,
                                price: service.price,
                            });
                    });
                if (vehicle_type.add_on_services)
                    Object.entries(vehicle_type.add_on_services).forEach(([key2, service]) => {
                        if (service.status)
                            addOnService.push({
                                id: key + '_' + key2,
                                vehicle_type: key,
                                service_id: key2,
                                service_name: service.name,
                                price: service.price,
                            });
                    });
            });
        }

        if (serviceDetailsFromDB) {
            Object.entries(serviceDetailsFromDB).forEach(([key, vehicle_type]) => {
                if (vehicle_type.major_services)
                    Object.entries(vehicle_type.major_services).forEach(([key2, service]) => {
                        selected.push({
                            id: key + '_' + key2,
                            vehicle_type: key,
                            service_id: key2,
                            service_name: service.name,
                            price: service.price,
                        });
                        selectedStored.push({
                            id: key + '_' + key2,
                            vehicle_type: key,
                            service_id: key2,
                            service_name: service.name,
                            price: service.price,
                        });
                        selectedId.push(key + '_' + key2);
                        selectedIdStored.push(key + '_' + key2);
                    });
                if (vehicle_type.add_on_services)
                    Object.entries(vehicle_type.add_on_services).forEach(([key2, service]) => {
                        selectedAddOn.push({
                            id: key + '_' + key2,
                            vehicle_type: key,
                            service_id: key2,
                            service_name: service.name,
                            price: service.price,
                        });
                        selectedAddOnStored.push({
                            id: key + '_' + key2,
                            vehicle_type: key,
                            service_id: key2,
                            service_name: service.name,
                            price: service.price,
                        });
                        selectedIdAddOn.push(key + '_' + key2);
                        selectedIdAddOnStored.push(key + '_' + key2);
                    });
            });
        }

        this.setState({
            driver_ids: driver_ids,
            services: services,
            servicesFromDB: servicesFromDB,
            addOnService: addOnService,
            servicesAreasFromDB: servicesAreasFromDB,
            selected: selected,
            selectedId: selectedId,
            selectedAddOn: selectedAddOn,
            selectedIdAddOn: selectedIdAddOn,
            selectedIdStored: selectedIdStored,
            selectedStored: selectedStored,
            selectedIdAddOnStored: selectedIdAddOnStored,
            selectedAddOnStored: selectedAddOnStored,
            // actual_schedule: vehcile?.actual_schedule,
            checked: vehcile?.auto_schedule_status,
            isloaded: true,
        });
    };
    getCurrentDateKey = async () => {
        var errors = false;
        const url = `https://us-central1-${firebase.options.projectId}.cloudfunctions.net/getCurrentDateKey`;
        const reply = await axios.post(url).catch((error) => {
            console.log('error: ', error);
            errors = true;
        });
        if (errors) {
            this.getCurrentDateKey();
        }
        console.log('reply: ', reply);
        if (reply) return reply.data;
    };
    async componentDidMount() {
        this.getData();
        var dateKey = await this.getCurrentDateKey();
        console.log('dateKey: ', dateKey);
        //Converting actual schedule to array
        actual_schedule = [];
        Object.entries(editVehicle['actual_schedule']).forEach(([key, schedule]) => {
            console.log('schedule: ', schedule);
            console.log('(key) > new Number(dateKey.timeStamp): ', new Number(key) > new Number(dateKey.timeStamp));
            if (new Number(key) > new Number(dateKey.timeStamp)) {
                schedule['key'] = key;
                actual_schedule.push(schedule);
            }
        });
        if (editVehicle['master_schedule'] !== undefined) master_schedule = editVehicle['master_schedule'];
        this.setState({
            master_schedule: master_schedule,
            actual_schedule: actual_schedule,
        });
        this.getServiceableAreas();
    }
    getServiceableAreas = async () => {
        let availableArea = [];
        let vehicle_serviceable_area = [];
        let sp_serviceable_area = [];
        let SpServiceableAreas = await RealTime.getDetails('service_providers/' + spId + '/serviceable_areas');
        Object.entries(SpServiceableAreas).forEach(([key, value]) => {
            if (value) {
                sp_serviceable_area.push(key);
            }
        });

        let VehicleServiceableAreas = await RealTime.getDetails(
            'service_providers/' + spId + '/vehicles/' + this.state.number + '/serviceable_areas'
        );
        Object.entries(VehicleServiceableAreas).forEach(([key, area]) => {
            vehicle_serviceable_area.push(area.area);
        });

        sp_serviceable_area.map((spArea) => {
            let flag = true;
            vehicle_serviceable_area.map((vhArea) => {
                if (spArea === vhArea) {
                    flag = false;
                }
            });
            if (flag) {
                availableArea.push(spArea);
            }
        });
        this.setState({
            serviceableAreas: availableArea,
        });
    };

    selectDriver = (e) => {
        this.setState({ selected_driver_id: e.target.value });
    };
    convert = async () => {
        this.setState({
            name_ar: await ConvertArabic(this.state.name),
            description_ar: await ConvertArabic(this.state.description),
            additionalNote_ar: await ConvertArabic(this.state.additionalNote),
            helperName_ar: await ConvertArabic(this.state.helperName),
        });
    };
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }

    toggleBack() {
        this.setState({
            edit: !this.state.edit,
        });
    }

    onChange = (stateName, value) => {
        this.setState({
            [stateName]: value,
        });
    };

    _handleImageChange(e) {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    }
    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row],
                selectedId: [...this.state.selectedId, row.id],
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter((x) => x.id !== row.id),
                selectedId: this.state.selectedId.filter((x) => x !== row.id),
            }));
        }
    };
    handleOnSelectAll = (isSelect, rows) => {
        const ids = rows.map((r) => r.id);
        const row = rows.map((r) => r);
        if (isSelect) {
            this.setState(() => ({
                selected: row,
                selectedId: ids,
            }));
        } else {
            this.setState(() => ({
                selected: [],
                selectedId: [],
            }));
        }
    };
    handleOnSelectAddOn = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selectedAddOn: [...this.state.selectedAddOn, row],
                selectedIdAddOn: [...this.state.selectedIdAddOn, row.id],
            }));
        } else {
            this.setState(() => ({
                selectedAddOn: this.state.selectedAddOn.filter((x) => x.id !== row.id),
                selectedIdAddOn: this.state.selectedIdAddOn.filter((x) => x !== row.id),
            }));
        }
    };
    handleOnSelectAllAddOn = (isSelect, rows) => {
        const ids = rows.map((r) => r.id);
        const row = rows.map((r) => r);
        if (isSelect) {
            this.setState(() => ({
                selectedAddOn: row,
                selectedIdAddOn: ids,
            }));
        } else {
            this.setState(() => ({
                selectedAddOn: [],
                selectedIdAddOn: [],
            }));
        }
    };
    handleProfileSubmit(event) {
        const extension = this.state.file.name.split('.').pop();

        firebase
            .storage()
            .ref('/service_providers/' + spId + '/vehicles/' + 'vehicle_' + this.state.number + '.' + extension)
            .put(this.state.file)
            .then((snapshot) => {
                // Will return a promise with the download link
                return snapshot.ref.getDownloadURL();
            })
            .then((downloadURL) => {
                console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
                this.setState({
                    downloadURL: downloadURL,
                });
                this.toggle();
            })
            .catch((error) => {
                // Use to signal error if something goes wrong.
                console.log(`Failed to upload file and get link - ${error}`);
            });
        event.preventDefault();
    }
    validate = () => {
        console.log('validate');

        var scheduleStatus = false;
        master_schedule.map((item) => {
            if (
                item.shift1_end === null ||
                item.shift1_start === null ||
                item.shift2_end === null ||
                item.shift2_start === null ||
                item.start_location.lat === null
            ) {
                scheduleStatus = true;
                return 0;
            }
        });
        if (scheduleStatus) {
            alert('Please Fill up master schedules completly');
            return;
        } else {
            this.pushData();
        }
        // this.pushData();
    };
    vehicleUpdateActualShiftScheduler = async () => {
        var errors = false;
        const url = `https://us-central1-${firebase.options.projectId}.cloudfunctions.net/vehicleUpdateActualShiftScheduler`;
        const reply = await axios
            .post(url, {
                vehicleId: this.state.number,
                actual_schedule: actual_schedule,
            })
            .catch((error) => {
                console.log('error: ', error);
                errors = true;
            });
        if (errors) {
            this.vehicleUpdateActualShiftScheduler();
        }
        console.log('reply: ', reply);
        if (reply) return reply.data;
    };
    pushData = async (event) => {
        console.log('pushdata');
        await this.vehicleUpdateActualShiftScheduler();
        let servicesFromDB = this.state.servicesFromDB;
        let driver_assigned = this.state.selected_driver_id !== '';

        let vts = {};
        this.state.selected.map(async (item) => {
            if (!(item.vehicle_type in vts)) {
                vts[item.vehicle_type] = {
                    name: servicesFromDB[item.vehicle_type].name,
                    name_ar: servicesFromDB[item.vehicle_type].name_ar,
                    status: servicesFromDB[item.vehicle_type].status,
                    major_services: {
                        [item.service_id]: servicesFromDB[item.vehicle_type].major_services[item.service_id],
                    },
                };
            } else {
                if (vts[item.vehicle_type].major_services)
                    if (!(item.service_id in vts[item.vehicle_type].major_services)) {
                        vts[item.vehicle_type].major_services[item.service_id] =
                            servicesFromDB[item.vehicle_type].major_services[item.service_id];
                    }
            }
        });
        this.state.selectedAddOn.map((item) => {
            if (vts[item.vehicle_type])
                if (!('add_on_services' in vts[item.vehicle_type])) {
                    vts[item.vehicle_type].add_on_services = {
                        [item.service_id]: servicesFromDB[item.vehicle_type].add_on_services[item.service_id],
                    };
                } else {
                    vts[item.vehicle_type].add_on_services[item.service_id] =
                        servicesFromDB[item.vehicle_type].add_on_services[item.service_id];
                }
        });

        let servicesAreasFromDB = this.state.servicesAreasFromDB;
        this.state.locationDetails.map((location) => {
            Object.entries(servicesAreasFromDB).forEach(([key, locationFromDB]) => {
                if (location.area === key) {
                    if (!('service_providers' in locationFromDB)) {
                        servicesAreasFromDB[location.area].service_providers = {
                            [spId]: {
                                status: true,
                                vehicles: {
                                    [this.state.number]: {
                                        status: location.status && driver_assigned,
                                        price: Number(location.price),
                                    },
                                },
                            },
                        };
                    } else {
                        if (spId in servicesAreasFromDB[location.area].service_providers) {
                            servicesAreasFromDB[location.area].service_providers[spId].vehicles[this.state.number] = {
                                status: location.status && driver_assigned,
                                price: Number(location.price),
                            };
                        } else {
                            servicesAreasFromDB[location.area].service_providers[spId] = {
                                status: true,
                                vehicles: {
                                    [this.state.number]: {
                                        status: location.status && driver_assigned,
                                        price: Number(location.price),
                                    },
                                },
                            };
                        }
                    }
                }
            });
        });

        firebase
            .database()
            .ref('/twaddan_admin/emirates/' + this.state.emirate + '/serviceable_areas')
            .update(servicesAreasFromDB);
        firebase
            .database()
            .ref('vehicles/' + this.state.number + '/')
            .update({
                name: this.state.name,
                name_ar: this.state.name_ar,
                number: this.state.number,
                helperName: this.state.helperName,
                helperName_ar: this.state.helperName_ar,
                helperNumber: this.state.helperNumber,
                driver: this.state.selected_driver_id,
                master_schedule: master_schedule,
                status: this.state.status,
                image_url: this.state.downloadURL,
                qrcode_url: this.state.qrURL,
                emiateArea: this.state.selected_major_area,
            });
        firebase
            .database()
            .ref('service_providers/' + spId + '/vehicles/' + this.state.number + '/')
            .update({
                serviceable_areas: LocationList,
                vehicle_type: vts,
            });
        var spName;
        //updating vehicle number in driver node
        if (this.state.selected_driver_id !== '') {
            RealTime.updateNode('drivers/' + this.state.selected_driver_id + '/personal_information/', {
                vehicle_number: this.state.number,
                emiateArea: this.state.selected_major_area,
            });
        } else if (this.state.driver !== '') {
            RealTime.updateNode('drivers/' + this.state.driver + '/personal_information/', {
                vehicle_number: '',
                emiateArea: this.state.selected_major_area,
            });
        }

        firebase
            .database()
            .ref('service_providers/' + spId + '/personal_information/')
            .on('value', function (snapshot) {
                spName = snapshot.val();
                spName = spName.name;
            });
        var time = new Date();
        time = time.getTime();
        var logAction =
            'Update vehicle named ' +
            this.state.name +
            ' with Vehicle Number ' +
            this.state.number +
            ' under ' +
            spName +
            ' Service Provider with SP ID ' +
            spId;
        var logs = firebase
            .database()
            .ref('/twaddan_admin/super_admin_users/' + this.state.saID + '/logs/' + time + '/');
        logs.update({
            action: logAction,
        });
        this.toggleBack();
        // event.preventDefault();
    };

    toggleMapPreview = () => {
        this.setState({
            previewModal: !this.state.previewModal,
        });
    };

    handleMapPreview = async (area) => {
        if (area) {
            this.setState({
                map_preview: '',
                map_preview_name: '',
            });
            const emirates = await RealTime.getDetails('twaddan_admin/emirates');
            if (emirates) {
                Object.entries(emirates).map(([key, value]) => {
                    if (value.service_providers) {
                        Object.keys(value.service_providers).map((value1, key1) => {
                            if (value1 === spId) {
                                if (value.serviceable_areas) {
                                    Object.entries(value.serviceable_areas).map(([key2, value2]) => {
                                        if (key2 === area) {
                                            this.setState({
                                                map_preview: value2.map_preview,
                                                map_preview_name: area,
                                                previewModal: true,
                                            });
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }
        console.log(this.state.map_preview);
    };

    handleSubmitLocation = (event) => {
        var flag = true;

        LocationList.map((loc) => {
            if (loc.area === this.state.area) {
                flag = false;
            }
        });

        if (this.state.area !== '' && this.state.price !== '') {
            if (flag) {
                LocationList.unshift({
                    area: this.state.area,
                    price: Number(this.state.price),
                    status: true,
                });
                this.setState({
                    locationDetails: LocationList,
                    isAddedSingle: true,
                });
            }

            locationEdit = false;
        }
        let dropDownItem = this.state.serviceableAreas;
        let index = dropDownItem.indexOf(this.state.area);
        dropDownItem.splice(index, 1);
        this.setState({
            area: '',
            price: 0,
            serviceableAreas: dropDownItem,
        });
        event.preventDefault();
    };

    removeLocation = (area) => {
        LocationList.map((loc) => {
            if (loc.area === area) {
                loc.status = !loc.status;
            }
        });

        // var locationDetails = LocationList;
        // const filteredLocation = locationDetails.filter(
        //   (item) => item.area !== area
        // );
        // LocationList = filteredLocation;
        this.setState({
            locationDetails: LocationList,
        });
    };

    editToggle = (location) => {
        this.setState({
            editToggle: !this.state.editToggle,
            editArea: location?.area,
            editPrice: location?.price,
        });
    };

    editLocationPrice = () => {
        LocationList.map((loc) => {
            if (loc.area === this.state.editArea) {
                loc.price = this.state.editPrice;
            }
        });
        this.setState({
            locationDetails: LocationList,
            editToggle: false,
        });
    };
    handleSelectAllAreas = () => {
        this.state.serviceableAreas.map((item) => {
            LocationList.push({
                area: item,
                price: Number(0),
                status: true,
            });
        });
        this.setState({
            locationDetails: LocationList,
            serviceableAreas: [],
            isAddedAll: true,
        });
    };
    onScheduleChange = (time, index, ob) => {
        master_schedule[index][ob] = time;
        console.log('master_schedule: ', master_schedule);
        this.setState({ master_schedule: master_schedule });
    };

    handleSetLocation = (e, index) => {
        e.preventDefault();
        console.log('master_schedule[index]: ', master_schedule[index]);
        this.setState({ locationModal: true, scheduleInde: index });
        if (master_schedule[index].start_location.lng !== null)
            this.setState({
                latLong: {
                    lat: master_schedule[index].start_location.lat,
                    lng: master_schedule[index].start_location.lng,
                },
                master_schedule: master_schedule,
            });
    };
    onActualScheduleChange = (time, index, ob) => {
        actual_schedule[index][ob] = time;
        console.log('actual_schedule[index]: ', actual_schedule[index].key);
        // var timeStampTemp = Number(actual_schedule[index].key);
        // var timestamp_key = ob.concat("_timestamp");
        // var shiftTimeSplit = time.split(":");
        // console.log("shiftTimeSplit: ", shiftTimeSplit);
        // //if 2nd shift end time is 1am then the  calculatin of timestamp should be pass next day time stamp
        // //for that just add 86400000 (1 day in millisecond) with current timestamp
        // if (
        //   ob === "shift2_end" &&
        //   shiftTimeSplit[0] >= 0 &&
        //   shiftTimeSplit[0] < 10
        // ) {
        //   console.log("shift2_end: ");
        //   actual_schedule[index][timestamp_key] = Utility.getTimestampWithTime(
        //     timeStampTemp + 86400000,
        //     shiftTimeSplit[0],
        //     shiftTimeSplit[1],
        //     "00"
        //   );
        // } else {
        //   console.log("shift2_end: not");
        //   actual_schedule[index][timestamp_key] = Utility.getTimestampWithTime(
        //     timeStampTemp,
        //     shiftTimeSplit[0],
        //     shiftTimeSplit[1],
        //     "00"
        //   );
        // }
        // console.log("timestamp_key: ", timestamp_key);
        // console.log("actual_schedule: ", actual_schedule);
        this.setState({ actual_schedule: actual_schedule });
    };
    onActualScheduleDataChange = (data, index, ob, schedule) => {
        actual_schedule[index][schedule][ob] = data;
        this.setState({ actual_schedule: actual_schedule }, () => {
            console.log('this.setState({ actual_schedule: ', this.state.actual_schedule);
        });
    };
    handleActualSetLocation = (e, index) => {
        e.preventDefault();
        console.log('master_schedule[index]: ', master_schedule[index]);
        this.setState({ locationModal: true, scheduleInde: index });
        if (master_schedule[index].start_location.lng !== null)
            this.setState({
                latLong: {
                    lat: master_schedule[index].start_location.lat,
                    lng: master_schedule[index].start_location.lng,
                },
                actual_schedule: actual_schedule,
            });
    };
    toggleLocationModal = () => {
        this.setState({ locationModal: false });
    };

    handleMapClick = async (e) => {
        this.fetchAddress(e.latLng.lat(), e.latLng.lng());
        this.setState({
            zoomLevel: 14,
            isMarkerShown: true,
        });
    };
    handleChangePlace = (mapArea) => {
        this.setState({
            mapArea,
        });
    };
    handleSelectPlace = async (mapArea) => {
        this.setState({
            isLoaded: false,
        });
        this.setState({
            mapArea: mapArea,
            mapPreview: mapArea,
        });
        await geocodeByAddress(mapArea)
            .then(async (results) => await getLatLng(results[0]))
            .then((latLng) => {
                this.setState(
                    {
                        latLng: latLng,
                        latLong: { lat: latLng.lat, lng: latLng.lng },
                        zoomLevel: 14,
                        isMarkerShown: true,
                    },
                    () => {
                        this.fetchAddress(this.state.latLng.lat, this.state.latLng.lng);
                    }
                );
            });
    };
    fetchAddress = async (lat, lng) => {
        this.setState({
            isLoaded: false,
        });
        var address = null;
        const url =
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            lng +
            '&key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0';
        const reply = await axios.get(url);
        if (reply.data.results.length === 0) {
            this.setState({
                isLoaded: true,
                showSnackbar: true,
                snackbarMessage: 'Select a valid place',
                snackBarColor: '#e3331b',
            });
        } else {
            address = 'Address from Location :' + reply.data.results[0].formatted_address;
        }
        this.setState({
            isLoaded: true,
            customer_raw_address: address,
            latLong: { lat: lat, lng: lng },
        });
        return address;
    };
    setSameLocation = (e) => {
        e.preventDefault();
        if (this.state.scheduleInde !== null) {
            master_schedule[this.state.scheduleInde].start_location.lat = this.state.latLong.lat;
            master_schedule[this.state.scheduleInde].start_location.lng = this.state.latLong.lng;
            this.setState({ locationModal: false });
        }
    };
    render() {
        let { driver_ids } = this.state;
        let areaList = [];
        this.state.serviceableAreas.length > 0 &&
            this.state.serviceableAreas.map((i) => {
                areaList.push({ value: i, label: i });
            }, this);

        const imagestyle = {
            textalign: 'center',
            margin: '5px 15px',
            height: '200px',
            width: '300px',
            borderleft: '1px solid',
        };
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selectedId,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
        };
        const selectRowAddOn = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selectedIdAddOn,
            onSelect: this.handleOnSelectAddOn,
            onSelectAll: this.handleOnSelectAllAddOn,
        };
        let { imagePreviewUrl, majorAreas } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
        }
        console.log(this.state.majorAreas);
        let major_areas =
            majorAreas.length > 0 &&
            majorAreas.map((i) => {
                return <option value={i}>{i}</option>;
            }, this);
        if (this.state.edit === false) {
            return <Vehicles />;
        }

        let driver_list =
            driver_ids.length > 0 &&
            driver_ids.map((i) => {
                return <option value={i.id}>{i.name}</option>;
            }, this);
        major_areas =
            majorAreas.length > 0 &&
            majorAreas.map((i) => {
                return <option value={i}>{i}</option>;
            }, this);
        return (
            <>
                <Header />
                {/* <PageLoader loading={!this.state.isloaded}></PageLoader> */}
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                                                <h3 className="mb-0">Edit Vehicle Details</h3>
                                            </Col>
                                            <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                                                <Button
                                                    block
                                                    color="primary"
                                                    className="button_back"
                                                    onClick={this.toggleBack}
                                                >
                                                    BACK
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <CardImg
                                            top
                                            className="img-fluid"
                                            style={{ width: '250px', height: '250px' }}
                                            src={this.state.downloadURL}
                                            alt="card image cap"
                                        />
                                        <div className="d-flex justify-content-between">
                                            <Button
                                                className="mr-4"
                                                color="info"
                                                href="#pablo"
                                                onClick={this.toggle}
                                                size="sm"
                                            >
                                                CHANGE IMAGE
                                            </Button>
                                        </div>
                                        <Form>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col xl="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Vehicle Name
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                placeholder="Name"
                                                                defaultValue={editVehicle['name']}
                                                                type="textarea"
                                                                onChange={(e) => this.onChange('name', e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Vehicle Name Arabic
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                placeholder="Name"
                                                                defaultValue={editVehicle['name_ar']}
                                                                value={this.state.name_ar}
                                                                type="textarea"
                                                                onChange={(e) =>
                                                                    this.onChange('name_ar', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Vehicle Number
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                placeholder=" Number"
                                                                disabled
                                                                defaultValue={editVehicle['number']}
                                                                type="textarea"
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    {false && (
                                                        <>
                                                            {' '}
                                                            <Col xl="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-text"
                                                                    >
                                                                        Description
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-number"
                                                                        placeholder="Description"
                                                                        defaultValue={editVehicle['description']}
                                                                        type="textarea"
                                                                        onChange={(e) =>
                                                                            this.onChange('description', e.target.value)
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-text"
                                                                    >
                                                                        Description Arabic
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-number"
                                                                        placeholder="Description Arabic"
                                                                        defaultValue={editVehicle['description_ar']}
                                                                        type="textarea"
                                                                        value={this.state.description_ar}
                                                                        onChange={(e) =>
                                                                            this.onChange(
                                                                                'description_ar',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-text"
                                                                    >
                                                                        Additional Note
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-number"
                                                                        placeholder="Additional Note"
                                                                        defaultValue={editVehicle['additionalNote']}
                                                                        type="textarea"
                                                                        onChange={(e) =>
                                                                            this.onChange(
                                                                                'additionalNote',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-text"
                                                                    >
                                                                        Additional Note Arabic
                                                                    </label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        id="input-number"
                                                                        placeholder="Additional Note Arabic"
                                                                        defaultValue={editVehicle['additionalNote_ar']}
                                                                        type="textarea"
                                                                        value={this.state.additionalNote_ar}
                                                                        onChange={(e) =>
                                                                            this.onChange(
                                                                                'additionalNote_ar',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </>
                                                    )}
                                                    <Col xl="12">
                                                        <FormGroup>
                                                            <Label for="spid">Select Driver</Label>
                                                            <Input
                                                                type="select"
                                                                name="spid"
                                                                id="spid"
                                                                value={this.state.selected_driver_id}
                                                                onChange={(e) => this.selectDriver(e)}
                                                            >
                                                                {driver_list}
                                                            </Input>
                                                        </FormGroup>
                                                        {major_areas.length > 0 && (
                                                            <FormGroup>
                                                                <Label for="spid">Select Major Area</Label>
                                                                <Input
                                                                    type="select"
                                                                    name="spid"
                                                                    id="spid"
                                                                    value={this.state.selected_major_area}
                                                                    onChange={(e) =>
                                                                        this.setState({
                                                                            selected_major_area: e.target.value,
                                                                        })
                                                                    }
                                                                >
                                                                    <option hidden>Select</option>
                                                                    {major_areas}
                                                                </Input>
                                                            </FormGroup>
                                                        )}
                                                    </Col>
                                                    <Col xl="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Helper Name
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                placeholder="Helper Name"
                                                                defaultValue={editVehicle['helperName']}
                                                                type="textarea"
                                                                onChange={(e) =>
                                                                    this.onChange('helperName', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Helper Name Arabic
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                placeholder="Helper Name Arabic"
                                                                value={this.state.helperName_ar}
                                                                defaultValue={editVehicle['helperName_ar']}
                                                                type="textarea"
                                                                onChange={(e) =>
                                                                    this.onChange('helperName_ar', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Helper Number
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                placeholder="Helper Number"
                                                                defaultValue={editVehicle['helperNumber']}
                                                                type="textarea"
                                                                onChange={(e) =>
                                                                    this.onChange('helperNumber', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl="12">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Select Services
                                                        </h6>
                                                    </Col>
                                                    <Col xl="12">
                                                        {this.state.services && (
                                                            <BootstrapTable
                                                                keyField="id"
                                                                data={this.state.services}
                                                                columns={columns}
                                                                filter={filterFactory()}
                                                                filterPosition="top"
                                                                //pagination={paginationFactory()}
                                                                defaultSorted={defaultSorted}
                                                                bordered={false}
                                                                selectRow={selectRow}
                                                                hover
                                                                wrapperClasses="table-responsive"
                                                                condensed
                                                                // rowEvents={rowEvents}
                                                            />
                                                        )}
                                                    </Col>{' '}
                                                    <Col xl="12">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            Select Add On Services
                                                        </h6>
                                                    </Col>
                                                    <Col xl="12">
                                                        {this.state.addOnService && (
                                                            <BootstrapTable
                                                                keyField="id"
                                                                data={this.state.addOnService}
                                                                columns={columns}
                                                                filter={filterFactory()}
                                                                filterPosition="top"
                                                                //pagination={paginationFactory()}
                                                                defaultSorted={defaultSorted}
                                                                bordered={false}
                                                                selectRow={selectRowAddOn}
                                                                hover
                                                                wrapperClasses="table-responsive"
                                                                condensed
                                                                // rowEvents={rowEvents}
                                                            />
                                                        )}
                                                    </Col>
                                                    {/* Scheduler on or off status */}
                                                    <Col xl={5} md={8} sm={10}>
                                                        <h2>{`Auto Schedule ${this.state.checked ? 'On' : 'Off'}`}</h2>
                                                    </Col>
                                                    <Col xl={7} md={4} sm={2}>
                                                        <Switch
                                                            onChange={() => {
                                                                this.setState({
                                                                    checked: !this.state.checked,
                                                                });
                                                            }}
                                                            checked={this.state.checked}
                                                        />
                                                    </Col>
                                                    {/* Scheduler on or off status end */}
                                                    <br />
                                                    <hr />
                                                    {this.state.checked && (
                                                        <>
                                                            {/* -----Actual scheduler------------- */}
                                                            <Col xl={12} lg={12} sm={12} md={12} xs={12}>
                                                                <h2>Actual schedule</h2>
                                                            </Col>
                                                            {this.state.actual_schedule.map((schedule, index) => {
                                                                return (
                                                                    <Col xl={12}>
                                                                        <Row>
                                                                            <Col
                                                                                xl={4}
                                                                                style={{
                                                                                    marginTop: '44px',
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            >
                                                                                <h4>
                                                                                    {moment(Number(schedule.key))
                                                                                        .tz('Asia/Dubai')
                                                                                        .format('DD/MM/YYYY')}
                                                                                </h4>
                                                                            </Col>
                                                                            <Col xl={4} style={{ marginTop: '34px' }}>
                                                                                <FormGroup>
                                                                                    <Button
                                                                                        type="button"
                                                                                        color={
                                                                                            schedule.start_location
                                                                                                .lat === null
                                                                                                ? 'danger'
                                                                                                : 'info'
                                                                                        }
                                                                                        onClick={(e) => {
                                                                                            this.handleSetLocation(
                                                                                                e,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        disabled={
                                                                                            schedule.schedule1
                                                                                                .orders !== undefined ||
                                                                                            schedule.schedule1
                                                                                                .is_shift_full
                                                                                        }
                                                                                        block
                                                                                    >
                                                                                        {schedule.start_location.lat ===
                                                                                        null
                                                                                            ? ' Set start location'
                                                                                            : ' Change start location'}
                                                                                    </Button>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={4}></Col>
                                                                            <Col
                                                                                xl={4}
                                                                                style={{
                                                                                    marginTop: '44px',
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            >
                                                                                <h4>Schedule 1</h4>
                                                                            </Col>
                                                                            <Col xl={2}>
                                                                                <FormGroup>
                                                                                    <Label>Start Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="Star_Time1"
                                                                                        id="Star_Time1"
                                                                                        disabled={
                                                                                            schedule.schedule1
                                                                                                ?.orders !==
                                                                                                undefined ||
                                                                                            schedule.schedule1
                                                                                                .is_shift_full
                                                                                        }
                                                                                        required
                                                                                        value={schedule.shift1_start}
                                                                                        onChange={(e) =>
                                                                                            this.onActualScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift1_start'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={2}>
                                                                                <FormGroup>
                                                                                    <Label>End Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="end_time1"
                                                                                        id="end_time1"
                                                                                        required
                                                                                        value={schedule.shift1_end}
                                                                                        disabled={
                                                                                            schedule.schedule1
                                                                                                .is_shift_full
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            this.onActualScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift1_end'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={2}>
                                                                                <FormGroup>
                                                                                    <Label>Buffer</Label>
                                                                                    <Input
                                                                                        type="number"
                                                                                        name="buffer_1"
                                                                                        id="buffer_1"
                                                                                        required
                                                                                        value={
                                                                                            schedule.schedule1
                                                                                                .buffer_time
                                                                                        }
                                                                                        disabled={
                                                                                            schedule.schedule1
                                                                                                .is_shift_full
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            this.onActualScheduleDataChange(
                                                                                                Number(e.target.value),
                                                                                                index,
                                                                                                'buffer_time',
                                                                                                'schedule1'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={2} style={{ marginTop: '34px' }}>
                                                                                <FormGroup>
                                                                                    <Button
                                                                                        type="button"
                                                                                        color={
                                                                                            schedule.schedule1
                                                                                                .active_status
                                                                                                ? 'danger'
                                                                                                : 'success'
                                                                                        }
                                                                                        onClick={(e) =>
                                                                                            this.onActualScheduleDataChange(
                                                                                                !schedule.schedule1
                                                                                                    .active_status,
                                                                                                index,
                                                                                                'active_status',
                                                                                                'schedule1'
                                                                                            )
                                                                                        }
                                                                                        disabled={
                                                                                            schedule.schedule1
                                                                                                ?.orders !==
                                                                                                undefined ||
                                                                                            schedule.schedule1
                                                                                                .is_shift_full
                                                                                        }
                                                                                        block
                                                                                    >
                                                                                        {schedule.schedule1
                                                                                            .active_status
                                                                                            ? 'Off'
                                                                                            : 'On'}
                                                                                    </Button>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col
                                                                                xl={4}
                                                                                style={{
                                                                                    marginTop: '44px',
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            >
                                                                                <h4>Schedule 2</h4>
                                                                            </Col>
                                                                            <Col xl={2}>
                                                                                <FormGroup>
                                                                                    <Label>Start Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="start_time2"
                                                                                        id="start_time2"
                                                                                        disabled={
                                                                                            schedule.schedule2
                                                                                                ?.orders !==
                                                                                                undefined ||
                                                                                            schedule.schedule2
                                                                                                .is_shift_full
                                                                                        }
                                                                                        required
                                                                                        value={schedule.shift2_start}
                                                                                        onChange={(e) =>
                                                                                            this.onActualScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift2_start'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>{' '}
                                                                            <Col xl={2}>
                                                                                <FormGroup>
                                                                                    <Label>End Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="end_time2"
                                                                                        id="end_time2"
                                                                                        required
                                                                                        value={schedule.shift2_end}
                                                                                        disabled={
                                                                                            schedule.schedule2
                                                                                                .is_shift_full
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            this.onActualScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift2_end'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={2}>
                                                                                <FormGroup>
                                                                                    <Label>Buffer</Label>
                                                                                    <Input
                                                                                        type="number"
                                                                                        name="buffer_2"
                                                                                        id="buffer_2"
                                                                                        required
                                                                                        value={
                                                                                            schedule.schedule2
                                                                                                .buffer_time
                                                                                        }
                                                                                        disabled={
                                                                                            schedule.schedule2
                                                                                                .is_shift_full
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            this.onActualScheduleDataChange(
                                                                                                Number(e.target.value),
                                                                                                index,
                                                                                                'buffer_time',
                                                                                                'schedule2'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={2} style={{ marginTop: '34px' }}>
                                                                                <FormGroup>
                                                                                    <Button
                                                                                        type="button"
                                                                                        color={
                                                                                            schedule.schedule2
                                                                                                .active_status
                                                                                                ? 'danger'
                                                                                                : 'success'
                                                                                        }
                                                                                        onClick={(e) =>
                                                                                            this.onActualScheduleDataChange(
                                                                                                !schedule.schedule2
                                                                                                    .active_status,
                                                                                                index,
                                                                                                'active_status',
                                                                                                'schedule2'
                                                                                            )
                                                                                        }
                                                                                        disabled={
                                                                                            schedule.schedule2
                                                                                                ?.orders !==
                                                                                                undefined ||
                                                                                            schedule.schedule2
                                                                                                .is_shift_full
                                                                                        }
                                                                                        block
                                                                                    >
                                                                                        {schedule.schedule2
                                                                                            .active_status
                                                                                            ? 'Off'
                                                                                            : 'On'}
                                                                                    </Button>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                );
                                                            })}
                                                            {/* -----Actual scheduler end------------- */}

                                                            {/* ------Master schedule-------------------- */}
                                                            <Row form>
                                                                <Col xl={12}>
                                                                    <hr />
                                                                </Col>
                                                            </Row>
                                                            <Row form>
                                                                <Col xl={12}>
                                                                    <h2 style={{ textAlign: 'left' }}>
                                                                        Master schedule
                                                                    </h2>
                                                                </Col>
                                                            </Row>
                                                            {this.state.master_schedule.map((schedule, index) => {
                                                                return (
                                                                    <Col xl={12}>
                                                                        <Row form>
                                                                            <Col
                                                                                xl={4}
                                                                                style={{
                                                                                    marginTop: '44px',
                                                                                    textAlign: 'center',
                                                                                }}
                                                                            >
                                                                                <h4>{schedule.day}</h4>
                                                                            </Col>
                                                                            <Col xl={4}>
                                                                                <FormGroup>
                                                                                    <Label>Schedule 1 Start Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="Star_Time1"
                                                                                        id="Star_Time1"
                                                                                        required
                                                                                        value={schedule.shift1_start}
                                                                                        onChange={(e) =>
                                                                                            this.onScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift1_start'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={4}>
                                                                                <FormGroup>
                                                                                    <Label>Schedule 1 End Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="end_time1"
                                                                                        id="end_time1"
                                                                                        required
                                                                                        value={schedule.shift1_end}
                                                                                        onChange={(e) =>
                                                                                            this.onScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift1_end'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={4} style={{ marginTop: '34px' }}>
                                                                                <FormGroup>
                                                                                    <Button
                                                                                        type="button"
                                                                                        color={
                                                                                            schedule.start_location
                                                                                                .lat === null
                                                                                                ? 'danger'
                                                                                                : 'info'
                                                                                        }
                                                                                        onClick={(e) => {
                                                                                            this.handleSetLocation(
                                                                                                e,
                                                                                                index
                                                                                            );
                                                                                        }}
                                                                                        block
                                                                                    >
                                                                                        {master_schedule[index]
                                                                                            .start_location.lat === null
                                                                                            ? ' Set start location'
                                                                                            : ' Change start location'}
                                                                                    </Button>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col xl={4}>
                                                                                <FormGroup>
                                                                                    <Label>Schedule 2 Start Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="start_time2"
                                                                                        id="start_time2"
                                                                                        required
                                                                                        value={schedule.shift2_start}
                                                                                        onChange={(e) =>
                                                                                            this.onScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift2_start'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>{' '}
                                                                            <Col xl={4}>
                                                                                <FormGroup>
                                                                                    <Label>Schedule 2 End Time</Label>
                                                                                    <Input
                                                                                        type="time"
                                                                                        name="end_time2"
                                                                                        id="end_time2"
                                                                                        required
                                                                                        value={schedule.shift2_end}
                                                                                        onChange={(e) =>
                                                                                            this.onScheduleChange(
                                                                                                e.target.value,
                                                                                                index,
                                                                                                'shift2_end'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                );
                                                            })}
                                                            <Modal isOpen={this.state.locationModal}>
                                                                <ModalBody>
                                                                    <Row>
                                                                        <Col md={12} hidden={true}>
                                                                            <PlacesAutocomplete
                                                                                value={this.state.area}
                                                                                onChange={this.handleChangePlace}
                                                                                searchOptions={{
                                                                                    componentRestrictions: {
                                                                                        country: ['ae'],
                                                                                    },
                                                                                }}
                                                                                // searchOptions={searchOptions}

                                                                                onSelect={this.handleSelectPlace}
                                                                            >
                                                                                {({
                                                                                    getInputProps,
                                                                                    getLatLng,
                                                                                    suggestions,
                                                                                    getSuggestionItemProps,
                                                                                    loading,
                                                                                }) => (
                                                                                    <div>
                                                                                        <FormGroup>
                                                                                            <InputGroup className="input-group-alternative mb-3">
                                                                                                <InputGroupAddon addonType="prepend">
                                                                                                    <InputGroupText>
                                                                                                        <i className="ni ni-square-pin" />
                                                                                                    </InputGroupText>
                                                                                                </InputGroupAddon>
                                                                                                <Input
                                                                                                    value={
                                                                                                        this.state.area
                                                                                                    }
                                                                                                    onChange={(e) =>
                                                                                                        this.setState({
                                                                                                            area: e
                                                                                                                .target
                                                                                                                .value,
                                                                                                        })
                                                                                                    }
                                                                                                    {...getInputProps({
                                                                                                        placeholder:
                                                                                                            'Search Places ...',
                                                                                                        className:
                                                                                                            'location-search-input',
                                                                                                    })}
                                                                                                />
                                                                                            </InputGroup>
                                                                                        </FormGroup>

                                                                                        <div className="autocomplete-dropdown-container">
                                                                                            {loading && (
                                                                                                <div>Loading...</div>
                                                                                            )}
                                                                                            {suggestions.map(
                                                                                                (suggestion) => {
                                                                                                    const className =
                                                                                                        suggestion.active
                                                                                                            ? 'suggestion-item--active'
                                                                                                            : 'suggestion-item';
                                                                                                    // inline style for demonstration purpose
                                                                                                    const style =
                                                                                                        suggestion.active
                                                                                                            ? {
                                                                                                                  backgroundColor:
                                                                                                                      '#88ebfc',
                                                                                                                  cursor: 'pointer',
                                                                                                              }
                                                                                                            : {
                                                                                                                  backgroundColor:
                                                                                                                      '#ffffff',
                                                                                                                  cursor: 'pointer',
                                                                                                              };
                                                                                                    return (
                                                                                                        <div
                                                                                                            {...getSuggestionItemProps(
                                                                                                                suggestion,
                                                                                                                {
                                                                                                                    className,
                                                                                                                    style,
                                                                                                                }
                                                                                                            )}
                                                                                                        >
                                                                                                            <span>
                                                                                                                {
                                                                                                                    suggestion.description
                                                                                                                }
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </PlacesAutocomplete>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            <MyMapComponent
                                                                                isMarkerShown={this.state.isMarkerShown}
                                                                                latLong={this.state.latLong}
                                                                                zoomLevel={this.state.zoomLevel}
                                                                                onMarkerClick={this.handleMarkerClick}
                                                                                onMapClick={this.handleMapClick}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </ModalBody>
                                                                <ModalFooter>
                                                                    <Button
                                                                        color="primary"
                                                                        disabled={!this.state.isMarkerShown}
                                                                        onClick={(e) => {
                                                                            this.setSameLocation(e);
                                                                        }}
                                                                    >
                                                                        Set location
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={this.toggleLocationModal}
                                                                    >
                                                                        CLOSE
                                                                    </Button>
                                                                </ModalFooter>
                                                            </Modal>
                                                            {/* ------Master shedule-------------------- */}
                                                        </>
                                                    )}
                                                    {/* -----Area and price management */}
                                                    <Col md={4}>
                                                        <Select
                                                            onChange={(area) => {
                                                                this.setState({ area: area.label });
                                                            }}
                                                            options={areaList}
                                                            isSearchable={true}
                                                        />
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <InputGroup className="input-group-alternative mb-3">
                                                                <InputGroupAddon addonType="prepend">
                                                                    <InputGroupText>
                                                                        <i className="ni ni-credit-card" />
                                                                    </InputGroupText>
                                                                </InputGroupAddon>
                                                                <Input
                                                                    type="number"
                                                                    name="price"
                                                                    id="price"
                                                                    placeholder="Enter price"
                                                                    value={this.state.price}
                                                                    onChange={(e) =>
                                                                        this.onChange('price', e.target.value)
                                                                    }
                                                                />
                                                            </InputGroup>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Row>
                                                                <Col md={4}>
                                                                    <Button
                                                                        type="button"
                                                                        color="primary"
                                                                        disabled={this.state.area ? false : true}
                                                                        onClick={() => {
                                                                            this.handleMapPreview(this.state.area);
                                                                        }}
                                                                        block
                                                                    >
                                                                        PREVIEW
                                                                    </Button>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Button
                                                                        type="button"
                                                                        color="success"
                                                                        onClick={this.handleSubmitLocation}
                                                                        block
                                                                    >
                                                                        {locationEdit ? 'UPDATE' : 'ADD'}
                                                                    </Button>
                                                                </Col>
                                                                <Col md={4}>
                                                                    <Button
                                                                        type="button"
                                                                        color="info"
                                                                        onClick={this.handleSelectAllAreas}
                                                                        block
                                                                        disabled={
                                                                            this.state.isAddedAll ||
                                                                            this.state.isAddedSingle
                                                                        }
                                                                    >
                                                                        ADD ALL
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>
                                                    </Col>
                                                    {this.state.locationDetails.length > 0 && (
                                                        <Col xl="12">
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Place</th>
                                                                        <th>Charge</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                {this.state.locationDetails.map((location) => (
                                                                    <tbody key={location}>
                                                                        <tr>
                                                                            <td>{location.area}</td>
                                                                            <td>{location.price}</td>
                                                                            <td>
                                                                                <Button
                                                                                    color={
                                                                                        location?.status
                                                                                            ? 'danger'
                                                                                            : 'success'
                                                                                    }
                                                                                    onClick={() => {
                                                                                        this.removeLocation(
                                                                                            location.area
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    {location?.status
                                                                                        ? 'DISABLE'
                                                                                        : 'ENABLE'}
                                                                                </Button>
                                                                                <Button
                                                                                    color="info"
                                                                                    onClick={() => {
                                                                                        this.editToggle(location);
                                                                                    }}
                                                                                >
                                                                                    EDIT
                                                                                </Button>
                                                                                <Button
                                                                                    type="button"
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                        this.handleMapPreview(
                                                                                            location.area
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    PREVIEW
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                ))}{' '}
                                                            </Table>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                            <div></div>
                                            <br></br>
                                            <br></br>
                                            <Row>
                                                <Col xs={12} sm={12} md={5} xl={4} xl={2}>
                                                    <Button
                                                        className="buttonConvert"
                                                        color="primary"
                                                        block
                                                        onClick={this.convert}
                                                    >
                                                        CONVERT TO ARABIC
                                                    </Button>
                                                </Col>
                                                <Col
                                                    className="buttonCancelSubmitCol"
                                                    xs={6}
                                                    sm={6}
                                                    md={4}
                                                    xl={3}
                                                    xl={2}
                                                >
                                                    <Button block onClick={this.toggleBack}>
                                                        CANCEL
                                                    </Button>
                                                </Col>
                                                <Col
                                                    className="buttonCancelSubmitCol"
                                                    xs={6}
                                                    sm={6}
                                                    md={3}
                                                    xl={3}
                                                    xl={2}
                                                >
                                                    <Button
                                                        block
                                                        color="success"
                                                        onClick={this.validate}
                                                        value="button"
                                                    >
                                                        UPDATE
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <br></br>
                        <br></br>
                        <br></br>
                    </Container>

                    <Container className="mt--7" fluid>
                        <Row>
                            <Card className="shadow">
                                <Modal
                                    isOpen={this.state.modal}
                                    toggle={this.toggle}
                                    className={this.props.className}
                                    size="lg"
                                >
                                    <ModalHeader toggle={this.toggle}>
                                        <h1>Update Image Below</h1>
                                    </ModalHeader>
                                    <ModalBody>
                                        <>
                                            <Card className="bg-secondary shadow border-0">
                                                <CardBody className="px-lg-5 py-lg-5">
                                                    <Form onSubmit={this.handleProfileSubmit}>
                                                        <FormGroup>
                                                            <Label for="File">Add image</Label>
                                                            <br></br>
                                                            <input
                                                                type="file"
                                                                accept="image/png, image/jpeg"
                                                                onChange={this._handleImageChange}
                                                            />
                                                            <FormText color="muted">
                                                                Select the Image that you wanted to display.
                                                            </FormText>
                                                            <Row>
                                                                <Col md={4} xs={2}>
                                                                    <div style={imagestyle}>{$imagePreview}</div>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <Button color="primary" value="Submit">
                                                            UPDATE IMAGE
                                                        </Button>
                                                    </Form>
                                                </CardBody>
                                            </Card>
                                        </>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.toggle}>
                                            CANCEL
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            </Card>
                        </Row>
                        <Modal isOpen={this.state.previewModal} toggle={this.toggleMapPreview} size="lg">
                            <ModalHeader toggle={this.toggleMapPreview}>{this.state.map_preview_name}</ModalHeader>
                            <ModalBody>
                                <>
                                    <iframe
                                        width="740"
                                        height="400"
                                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.map_preview}`}
                                        allowfullscreen
                                    ></iframe>
                                </>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={this.toggleMapPreview}>CLOSE</Button>
                            </ModalFooter>
                        </Modal>
                        <Modal isOpen={this.state.editToggle} toggle={this.editToggle}>
                            <ModalHeader toggle={this.editToggle}>
                                <h2>{this.state.editArea}</h2>
                            </ModalHeader>
                            <ModalBody>
                                <Label>Enter charge for {this.state.editArea}</Label>
                                <Input
                                    defaultValue={this.state.editPrice}
                                    value={this.state.editPrice}
                                    placeholder="Enter Price"
                                    onChange={(e) => {
                                        this.setState({
                                            editPrice: e.target.value,
                                        });
                                    }}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={this.editToggle}>CANCEL</Button>
                                <Button onClick={this.editLocationPrice}>UPDATE</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </Container>
            </>
        );
    }
}
const details = (text, data) => {
    return (
        <>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <p>
                    <b>{text}</b>
                </p>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                <p>: {data}</p>
            </Col>
        </>
    );
};
class DetailViewVehicle extends Component {
    constructor() {
        super();
        this.state = {
            view: true,
            mapModal: false,
            map_preview_name: '',
            addOnService: [],
            services: [],
        };
    }
    async componentDidMount() {
        await this.setData();
    }
    setData = async () => {
        var services = [];
        var addOnService = [];
        console.log('detailViewVehicle.vehicle_type: ', detailViewVehicle.vehicle_type);
        if (detailViewVehicle.vehicle_type) {
            Object.entries(detailViewVehicle.vehicle_type).forEach(([key, vehicle_type]) => {
                if (vehicle_type.major_services)
                    Object.entries(vehicle_type.major_services).forEach(([key2, service]) => {
                        console.log('key2, service: ', key2, service);

                        if (service.status)
                            services.push({
                                id: key + '_' + key2,
                                vehicle_type: key,
                                service_id: key2,
                                service_name: service.name,
                                price: service.price,
                            });
                    });
                if (vehicle_type.add_on_services)
                    Object.entries(vehicle_type.add_on_services).forEach(([key2, service]) => {
                        if (service.status)
                            addOnService.push({
                                id: key + '_' + key2,
                                vehicle_type: key,
                                service_id: key2,
                                service_name: service.name,
                                price: service.price,
                            });
                    });
            });
        }
        this.setState({ addOnService: addOnService, services: services });
        console.log('services: ', services);
        return 0;
    };

    toggleBack = () => {
        this.setState({
            view: !this.state.view,
        });
    };
    toggleMapPreview = () => {
        this.setState({
            mapModal: !this.state.mapModal,
        });
    };
    toggleServiceCollapse = () => {
        this.setState({ collapseService: !this.state.collapseService });
    };
    toggleAddOnServiceCollapse = () => {
        this.setState({ collapseAddOnService: !this.state.collapseAddOnService });
    };
    toggleServiceAreaCollapse = () => {
        this.setState({ collapseServiceArea: !this.state.collapseServiceArea });
    };

    render() {
        if (this.state.view === false) {
            return <Vehicles />;
        }
        const rowEventsArea = {
            onClick: (e, row, rowIndex) => {
                Object.entries(detailViewVehicle.serviceable_areas).forEach(([key, value]) => {
                    if (key === row.area) {
                        this.setState({
                            map_preview: value.map_preview,
                            map_preview_name: row.area,
                            mapModal: true,
                        });
                    }
                });
            },
        };
        return (
            <div className="trackingComponent">
                <Header />
                <Col md="12">
                    <div className="allCards">
                        <Card>
                            <CardHeader className="secondCard">
                                <Row>
                                    <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                                        <h3>{detailViewVehicle.name}</h3>
                                    </Col>
                                    <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                                        <Button block color="primary" className="button_back" onClick={this.toggleBack}>
                                            BACK
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="12" md="6" sm="12" lg="6" xl="6">
                                        <img
                                            src={detailViewVehicle.image_url}
                                            class="img-fluid"
                                            alt="Responsive image"
                                        />
                                    </Col>
                                    <Col xs="12" md="6" sm="12" lg="6" xl="6">
                                        <Row>
                                            {details('Name', detailViewVehicle.name)}
                                            {details('Name Arabic', detailViewVehicle.name_ar)}
                                            {details('Number', detailViewVehicle.number)}
                                            {details(
                                                'Driver Name',
                                                detailViewVehicle.driver_name !== ''
                                                    ? detailViewVehicle.driver_name
                                                    : 'Driver not assigned'
                                            )}
                                            {details('Status', detailViewVehicle.status ? 'Active' : 'Disabled')}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                                        <h3> Services</h3>
                                    </Col>
                                    <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                                        <Button
                                            block
                                            color="primary"
                                            className="button_back"
                                            onClick={this.toggleServiceCollapse}
                                        >
                                            Collapse Services
                                        </Button>
                                    </Col>
                                    <Col xs="12" md="12" sm="12" lg="12" xl="12">
                                        <Collapse isOpen={this.state.collapseService}>
                                            {console.log('this.state.service: ', this.state.services)}
                                            {this.state.services.length > 0 && (
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={this.state.services}
                                                    columns={columns}
                                                    filter={filterFactory()}
                                                    filterPosition="top"
                                                    rowStyle={this.rowStyle}
                                                    bordered={false}
                                                    hover
                                                    wrapperClasses="table-responsive"
                                                    condensed
                                                />
                                            )}
                                        </Collapse>
                                    </Col>
                                    {this.state.addOnService.length > 0 && (
                                        <>
                                            <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                                                <h3> AddOn Services</h3>
                                            </Col>
                                            <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                                                <Button
                                                    block
                                                    color="primary"
                                                    className="button_back"
                                                    onClick={this.toggleAddOnServiceCollapse}
                                                >
                                                    Collapse AddOn Services
                                                </Button>
                                            </Col>
                                            <Col xs="12" md="12" sm="12" lg="12" xl="12">
                                                <Collapse isOpen={this.state.collapseAddOnService}>
                                                    <BootstrapTable
                                                        keyField="id"
                                                        data={this.state.addOnService}
                                                        columns={columns}
                                                        filter={filterFactory()}
                                                        filterPosition="top"
                                                        rowStyle={this.rowStyle}
                                                        bordered={false}
                                                        hover
                                                        wrapperClasses="table-responsive"
                                                        condensed
                                                    />
                                                </Collapse>
                                            </Col>
                                        </>
                                    )}

                                    <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                                        <h3> Serviceable Area</h3>
                                    </Col>
                                    <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                                        <Button
                                            block
                                            color="primary"
                                            className="button_back"
                                            onClick={this.toggleServiceAreaCollapse}
                                        >
                                            Collapse Serviceable Area
                                        </Button>
                                    </Col>
                                    <Col xs="12" md="12" sm="12" lg="12" xl="12">
                                        <Collapse isOpen={this.state.collapseServiceArea}>
                                            {detailViewVehicle?.serviceable_areas && (
                                                <BootstrapTable
                                                    keyField="id"
                                                    data={detailViewVehicle?.serviceable_areas}
                                                    columns={table3Columns}
                                                    filter={filterFactory()}
                                                    filterPosition="top"
                                                    rowStyle={this.rowStyle}
                                                    bordered={false}
                                                    hover
                                                    wrapperClasses="table-responsive"
                                                    condensed
                                                    rowEvents={rowEventsArea}
                                                />
                                            )}
                                        </Collapse>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Modal isOpen={this.state.mapModal} toggle={this.toggleMapPreview} size="lg">
                            <ModalHeader toggle={this.toggleMapPreview}>{this.state.map_preview_name}</ModalHeader>
                            <ModalBody>
                                <>
                                    <iframe
                                        width="740"
                                        height="400"
                                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.map_preview}`}
                                        allowfullscreen
                                    ></iframe>
                                </>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={this.toggleMapPreview}>CLOSE</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </Col>
            </div>
        );
    }
}
export default Vehicles;
