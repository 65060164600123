import React, { Component } from 'react';
import firebase from 'firebaseConfig';
import moment from 'moment';
import 'moment-timezone';
import jsPDF from 'jspdf';
import logo from './twaddan-react.0a3a7e5e.jpg';
// reactstrap components
import { Container, Row, Button, Table, Col, Input, FormGroup, Label } from 'reactstrap';
// core components
import Header from 'components/Headers/Header.jsx';
import DatePicker from 'react-datepicker';
import RealTime from '../../services/RealTime';
import 'react-datepicker/dist/react-datepicker.css';
import PageLoader from '../../components/PageLoader/PageLoader';
import './Ledger.css';
var drivers = [];
var refundDetails = {
    totalOnlinePaymentOrders: Number(0),
    totalCashPaymentOrders: Number(0),
    totalOrders: Number(0),
    totalCashPaymentAmount: Number(0),
    totalOnlinePaymentAmount: Number(0),
    totalPaymentAmount: Number(0),
};
var startDate;
var ledgerDetails = {
    totalOnlinePaymentOrders: Number(0),
    totalCashPaymentOrders: Number(0),
    totalOrders: Number(0),
    totalCashPaymentAmount: Number(0),
    totalOnlinePaymentAmount: Number(0),
    totalPaymentAmount: Number(0),
    totalDiscountAmount: Number(0),
    totalTwaddanDiscountShare: Number(0),
    totalSpDiscountShare: Number(0),
};
class Ledger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            isLoaded: false,
            isLoadedCombo: false,
            tableDataByDriver: [],
            selectedEmirate: {},
            emirates: {},
            allSP: [],
            allOrders: [],
            drivers: {},
            selectedSP: null,
        };
    }
    getEmirates = async () => {
        let emirates = await RealTime.getDetails('/twaddan_admin/emirates/');
        let newEmirates = [];
        newEmirates.push({
            label: 'All',
            value: 'All',
        });
        await Object.entries(emirates).forEach(([key, emirate]) => {
            newEmirates.push({
                label: emirate.name,
                value: emirate.name,
            });
        });
        return newEmirates;
    };
    handleEmirateChange = async (event) => {
        var sp = [];
        let i = 0;
        if (event.target.value === 'All') {
            sp = this.state.allSP;
        } else {
            sp = await this.state.allSP.filter(function (sp) {
                return sp.emirate === event.target.value || sp.emirate === 'null';
            });
        }
        this.setState({
            selectedEmirate: event.value,
            spByEmirates: sp,
        });
    };
    getAllSP = async () => {
        let allSP = await RealTime.getDetails('/service_providers/');
        let emirates = await RealTime.getDetails('/twaddan_admin/emirates/');
        let newAllSP = [];
        await Object.entries(allSP).forEach(async ([key, sp]) => {
            let emiratefind = 'no emirate';
            Object.entries(emirates).forEach(([key2, emirate]) => {
                if (emirate.service_providers && emirate.service_providers[key]) emiratefind = key2;
            });
            newAllSP.push({
                label: sp.personal_information.name,
                value: key,
                emirate: emiratefind,
            });
        });
        return newAllSP;
    };
    async componentDidMount() {
        let user = localStorage.getItem('admin_name');
        let allOrders = await RealTime.getDetails('/orders/all_orders/');
        if (user) {
            console.log('cmdm- user');
            let allSP = await this.getAllSP();
            this.setState({
                allSP: allSP,
                spByEmirates: allSP,
                emirates: await this.getEmirates(),
                allOrders: allOrders,
                isLoadedCombo: true,
            });
        }
    }
    handleSPChange = async (e) => {
        let sp = e.target.value;
        let drivers = await RealTime.getDetails('/drivers/');
        let selectedDriver = {};
        Object.entries(drivers).forEach(([key, driver]) => {
            driver.sp_related.map((driver_sp) => {
                if (driver_sp.sp_id == sp) {
                    selectedDriver[key] = driver;
                }
            });
        });
        this.setState({ drivers: selectedDriver, selectedSP: sp });
    };
    handleDateChange = (date) => {
        this.setState({
            startDate: date,
        });
    };
    groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
        }, {});
    };
    pdfGenerator = () => {
        const doc = new jsPDF();
        let sp = this.state.selectedSP;
        let spLabel = '';
        this.state.spByEmirates.map((sps) => {
            if (sps.value === sp) {
                spLabel = sps.label;
            }
        });
        var dateRange = moment(this.state.startDate).format('MMM-YYYY');
        var header = function (data) {
            doc.setFontSize(10);
            doc.addImage(logo, 'JPEG', 13, 3, 32, 12);
            doc.setLineWidth(1);
            doc.setDrawColor(62, 142, 222);
            doc.line(14, 14, 196, 14);
            doc.text('Service Provider Ledger Report', 14, 19);
            doc.text(dateRange, 14, 24);
            doc.text(spLabel, 197 - spLabel.length * 1.8, 19);
        };
        var options = {
            beforePageContent: header,
            margin: {
                top: 100,
            },
            startY: doc.autoTableEndPosY() + 20,
        };
        doc.autoTable([], [], options);

        var columns = [
            'Driver',
            'Month - Year',
            'Total Orders',
            'Total Sale',
            'Total Discount',
            'Payment Method',
            'Twaddan Charges',
        ];
        var option2 = {
            columnStyles: {
                0: { valign: 'top' },
                1: {
                    halign: 'right',
                },
                2: {
                    halign: 'right',
                },
                3: {
                    halign: 'right',
                },
                4: {
                    halign: 'right',
                },
                5: {
                    halign: 'right',
                },
                6: {
                    halign: 'right',
                },
            },
            headStyles: { fillColor: [62, 142, 222], halign: 'right' },
            tableLineWidth: 0.3,
            willDrawCell: (data) => {
                if (data.section === 'head' && data.column.index === 0) {
                    data.halign = 'left';
                    data.cell.halign = 'left';
                    data.cell.styles.halign = 'left';
                    data.column.halign = 'left';
                }
            },
        };
        var res = doc.autoTableHtmlToJson(document.getElementById('tbl1'));
        doc.autoTable(res.columns, res.data, option2);
        var res2 = doc.autoTableHtmlToJson(document.getElementById('tbl2'));
        doc.autoTable(res2.columns, res2.data, option2);
        var res = doc.autoTableHtmlToJson(document.getElementById('tbl3'));
        doc.autoTable(res.columns, res.data, option2);
        var res = doc.autoTableHtmlToJson(document.getElementById('tbl4'));
        doc.autoTable(res.columns, res.data, option2);
        var option4 = {
            columnStyles: {
                0: { valign: 'top' },
                1: {
                    halign: 'right',
                },
                2: {
                    halign: 'right',
                },
            },
            headStyles: { fillColor: [62, 142, 222], halign: 'right' },
            tableLineWidth: 0.3,
            willDrawCell: (data) => {
                if (data.section === 'head' && data.column.index === 0) {
                    data.halign = 'left';
                    data.cell.halign = 'left';
                    data.cell.styles.halign = 'left';
                    data.column.halign = 'left';
                }
            },
        };
        var res = doc.autoTableHtmlToJson(document.getElementById('tbl5'));
        doc.autoTable(res.columns, res.data, option4);
        doc.save('Ledger.pdf');
    };
    onSubmit = async () => {
        ledgerDetails.totalCashPaymentOrders = Number(0);
        ledgerDetails.totalOnlinePaymentOrders = Number(0);
        ledgerDetails.totalOrders = Number(0);

        ledgerDetails.totalCashPaymentAmount = Number(0);
        ledgerDetails.totalOnlinePaymentAmount = Number(0);
        ledgerDetails.totalPaymentAmount = Number(0);
        ledgerDetails.totalDiscountAmount = Number(0);
        ledgerDetails.totalTwaddanDiscountShare = Number(0);
        ledgerDetails.totalSpDiscountShare = Number(0);

        refundDetails.totalOnlinePaymentOrders = Number(0);
        refundDetails.totalCashPaymentOrders = Number(0);
        refundDetails.totalOrders = Number(0);

        refundDetails.totalCashPaymentAmount = Number(0);
        refundDetails.totalOnlinePaymentAmount = Number(0);
        refundDetails.totalPaymentAmount = Number(0);

        var month = this.state.startDate.getMonth();
        var year = this.state.startDate.getFullYear();
        var firstDay = new Date(year, month, 1);
        var lastDay = new Date(year, month + 1, 1);
        drivers = [];
        let newOrder = [];
        let canceledOrders = [];
        Object.entries(this.state.allOrders).forEach(([key, order]) => {
            let orderCancelledTime = order?.time_order_cancelled;
            if (orderCancelledTime)
                orderCancelledTime = moment(order.orderCancelledTime).tz('Asia/Dubai').format('MM/DD/YYYY hh:mm a');
            let orderFinishedTime = order?.time_order_job_finished;
            if (orderFinishedTime)
                orderFinishedTime = moment(order.time_order_job_finished).tz('Asia/Dubai').format('MM/DD/YYYY hh:mm a');
            if (
                order.sp_id === this.state.selectedSP &&
                ((orderFinishedTime && moment(orderFinishedTime) >= moment(firstDay)) ||
                    (orderCancelledTime && moment(orderCancelledTime) >= moment(firstDay))) &&
                ((orderFinishedTime && moment(orderFinishedTime) <= moment(lastDay)) ||
                    (orderCancelledTime && moment(orderCancelledTime) <= moment(lastDay)))
            ) {
                if (order.payment_mode === 0 && order.status >= 0) {
                    newOrder.push(order);
                    ledgerDetails.totalCashPaymentOrders += 1;
                    ledgerDetails.totalCashPaymentAmount += order.total_due;
                    ledgerDetails.totalOrders += 1;
                    ledgerDetails.totalPaymentAmount += order.total_due;
                    ledgerDetails.totalDiscountAmount += order.total_discount;
                    ledgerDetails.totalTwaddanDiscountShare += order.discountShare
                        ? order.discountShare.twaddanShare
                        : 0;
                    ledgerDetails.totalSpDiscountShare += order.discountShare ? order.discountShare.spShare : 0;
                } else if (order.payment_mode === 1 && order.status >= 0) {
                    newOrder.push(order);
                    ledgerDetails.totalOnlinePaymentOrders += 1;
                    ledgerDetails.totalOnlinePaymentAmount += order.total_due;
                    ledgerDetails.totalOrders += 1;
                    ledgerDetails.totalPaymentAmount += order.total_due;
                    ledgerDetails.totalDiscountAmount += order.total_discount;
                    ledgerDetails.totalTwaddanDiscountShare += order?.discountShare
                        ? order?.discountShare?.twaddanShare
                        : 0;
                    ledgerDetails.totalSpDiscountShare += order?.discountShare ? order?.discountShare?.spShare : 0;
                } else if (order.payment_mode === 0 && order.status === -1) {
                    canceledOrders.push(order);
                    refundDetails.totalCashPaymentOrders += 1;
                    refundDetails.totalCashPaymentAmount += order.total_due;
                    refundDetails.totalOrders += 1;
                    refundDetails.totalPaymentAmount += order.total_due;
                } else if (order.payment_mode === 1 && order.status === -1) {
                    canceledOrders.push(order);
                    refundDetails.totalOnlinePaymentOrders += 1;
                    refundDetails.totalOnlinePaymentAmount += order.total_due;
                    refundDetails.totalOrders += 1;
                    refundDetails.totalPaymentAmount += order.total_due;
                }
            }
        });
        let orderIdWithOutDriverID = [];
        if (this.state.drivers)
            Object.entries(this.state.drivers).forEach(([key, driver]) => {
                let driverName = driver.personal_information.name;
                let orders = Number(0);
                let amount = Number(0);
                let discountAmount = Number(0);
                orderIdWithOutDriverID = [];
                if (newOrder.length > 0) {
                    newOrder.map((order) => {
                        if (order.driver_id) {
                            if (order.driver_id === key) {
                                orders += 1;
                                amount += order.total_due;
                                discountAmount += order.total_discount;
                            }
                        } else {
                            console.log('order with out driver id', order);
                            orderIdWithOutDriverID.push(order.order_id_number);
                        }
                    });
                    canceledOrders.map((order) => {
                        if (order.driver_id) {
                            if (order.driver_id === key) {
                                orders += 1;
                                amount += order.total_due;
                                discountAmount += order.total_discount;
                            }
                        } else {
                            console.log('order with out driver id', order);
                            orderIdWithOutDriverID.push(order.order_id_number);
                        }
                    });
                }
                if (orders > 0) {
                    drivers.push({
                        driver: driverName,
                        orders: orders,
                        amount: amount,
                        discountAmount: discountAmount,
                    });
                }
            });
        console.log('orderIdWithOutDriverID', orderIdWithOutDriverID);
        this.setState({
            tableDataByDriver: drivers,
            isLoaded: true,
            orderIdWithOutDriverID: orderIdWithOutDriverID,
        });
    };
    // addDriverId = () => {
    //   console.log("this.stste.driver_id", this.state.driver_id);
    //   this.state.orderIdWithOutDriverID.map((id) => {
    //     console.log("id", id);
    //     firebase
    //       .database()
    //       .ref("orders/all_orders/" + id)
    //       .update({
    //         driver_name: this.state.driver_id,
    //       });
    //   });
    // };

    render() {
        return (
            <>
                <Header />
                <PageLoader loading={!this.state.isLoadedCombo}></PageLoader>
                {/* Page content */}
                <Container className="mt-3" fluid>
                    <div className="h5 mt-3">
                        <h1>Ledger Report</h1>
                    </div>
                    <FormGroup row>
                        <Col className="mt-3" xs={12} sm={12} md={3} lg={3}>
                            <Input
                                type="select"
                                name="selectEmirate"
                                id="exampleSelect1"
                                value={this.state.selectedEmirate}
                                onChange={this.handleEmirateChange}
                            >
                                <option selected disabled>
                                    Select Emirate
                                </option>
                                {this.state.emirates.length > 0 &&
                                    this.state.emirates.map((emirate) => {
                                        return (
                                            <option value={emirate.value} key={emirate.value}>
                                                {emirate.value}
                                            </option>
                                        );
                                    })}
                            </Input>
                        </Col>
                        <Col className="mt-3" xs={12} sm={12} md={3} lg={3}>
                            <Input
                                type="select"
                                name="selectSp"
                                id="exampleSelect"
                                value={this.state.selectedSP}
                                onChange={this.handleSPChange}
                            >
                                <option selected disabled>
                                    Select Service provider
                                </option>
                                {this.state.spByEmirates &&
                                    this.state.spByEmirates.map((sp) => {
                                        return (
                                            <option key={sp.value} value={sp.value}>
                                                {sp.label}
                                            </option>
                                        );
                                    })}
                            </Input>
                        </Col>
                        <Col className="mt-3" xs={6} sm={12} md={3} lg={3} xl={2}>
                            <FormGroup>
                                <DatePicker
                                    className="date"
                                    selected={this.state.startDate}
                                    onChange={this.handleDateChange}
                                    showMonthYearPicker
                                    dateFormat="MMM yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    minDate={new Date(new Date().getFullYear, 0)}
                                    maxDate={new Date()}
                                    value={this.state.startDate}
                                />
                            </FormGroup>
                        </Col>
                        <Col className="mt-3 " xs={6} sm={12} md={3} lg={3} xl={2}>
                            <Button
                                className=""
                                color="info"
                                block
                                disabled={this.state.selectedSP === null}
                                href="#pablo"
                                onClick={this.onSubmit}
                                size="bm"
                            >
                                SEARCH
                            </Button>
                        </Col>
                    </FormGroup>
                    <br></br>
                    {this.state.isLoaded && ledgerDetails.totalOrders > 0 ? (
                        <>
                            <h1>Sales Report Summary</h1>
                            <h5>of {moment(this.state.startDate).format('MMM YYYY')}</h5>
                            <Row>
                                <Col className="mt-4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <Table responsive className="dashboard-table table-hover-animation mb-0 mt-1">
                                        <table style={({ tableLayout: 'fixed' }, { width: '100%' })} id="tbl1">
                                            <thead class="bg-info">
                                                <tr>
                                                    <th>Driver</th>
                                                    <th style={{ textAlign: 'right' }}>Month - Year</th>
                                                    <th style={{ textAlign: 'right' }}>Total Orders</th>
                                                    <th style={{ textAlign: 'right' }}>Total Sale</th>
                                                    <th style={{ textAlign: 'right' }}>Total Discount</th>
                                                    <th style={{ textAlign: 'right' }}>Payment Method</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.tableDataByDriver.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{data.driver}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {moment(this.state.startDate).format('MMM-YYYY')}
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>{data.orders}</td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {data.amount.toFixed(2)}
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {data.discountAmount.toFixed(2)}
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>Cash/Online</td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td>Total</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalOrders + refundDetails.totalOrders}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {(
                                                            ledgerDetails.totalPaymentAmount +
                                                            refundDetails.totalPaymentAmount
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalDiscountAmount.toFixed(2)}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <br></br>
                                    <h3>Order Details</h3>
                                    <Table responsive className="dashboard-table table-hover-animation mb-0 mt-1">
                                        <table id="tbl2" style={({ tableLayout: 'fixed' }, { width: '100%' })}>
                                            <thead class="bg-info">
                                                <tr style={{ marginLeft: '300px' }}>
                                                    <th>Order Type</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th style={{ textAlign: 'right' }}>Total Orders</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Cash Orders</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalCashPaymentOrders}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Online Payment Orders</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalOnlinePaymentOrders}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Canceled Cash Orders</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {refundDetails.totalCashPaymentOrders}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Canceled Online Orders</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {refundDetails.totalOnlinePaymentOrders}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Grand Total</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalOrders + refundDetails.totalOrders}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <br></br>
                                    <h3>Refund Details</h3>
                                    <Table responsive className="dashboard-table table-hover-animation mb-0 mt-1">
                                        <table id="tbl3" style={({ tableLayout: 'fixed' }, { width: '100%' })}>
                                            <thead class="bg-info">
                                                <tr>
                                                    <th>Mode Of Payment</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th style={{ textAlign: 'right' }}>Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Online Payment Refunds</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {refundDetails.totalOnlinePaymentAmount.toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Cash Payment Refunds</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {refundDetails.totalCashPaymentAmount.toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Grand Total Refunds</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {refundDetails.totalPaymentAmount.toFixed(2)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <br></br>
                                    <h3>Sales Summary</h3>
                                    <Table responsive className="dashboard-table table-hover-animation mb-0 mt-1">
                                        <table id="tbl4" style={({ tableLayout: 'fixed' }, { width: '100%' })}>
                                            <thead class="bg-info">
                                                <tr>
                                                    <th>Type Of Sales</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                    <th style={{ textAlign: 'right' }}>Total Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Cash Sales</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalCashPaymentAmount.toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Online Payment Sales</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalOnlinePaymentAmount.toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Grand Total Sales</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalPaymentAmount.toFixed(2)}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-4 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <br></br>
                                    <h3>Sales Details</h3>
                                    <Table responsive className="dashboard-table table-hover-animation mb-0 mt-1">
                                        <table id="tbl5" style={({ tableLayout: 'fixed' }, { width: '100%' })}>
                                            <thead class="bg-info">
                                                <tr>
                                                    <th>Summary</th>
                                                    <th style={{ textAlign: 'right' }}>Due To Partners</th>
                                                    <th style={{ textAlign: 'right' }}>Due From Partners</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Remaining Online Payment Balance</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalOnlinePaymentAmount.toFixed(2)}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>0.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Online Payment Service Charges For Third Party</td>
                                                    <td style={{ textAlign: 'right' }}>0.00</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {(
                                                            (ledgerDetails.totalOnlinePaymentAmount / 100) *
                                                            2.25
                                                        ).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>VAT On Online Payment Service Charges (Third Party)</td>
                                                    <td style={{ textAlign: 'right' }}>0.00</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {(
                                                            ledgerDetails.totalOnlinePaymentAmount *
                                                            0.0225 *
                                                            0.05
                                                        ).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Discount Charges</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {ledgerDetails.totalTwaddanDiscountShare.toFixed(2)}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {(ledgerDetails.totalSpDiscountShare * 0.1).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Twaddan Charges</td>
                                                    <td style={{ textAlign: 'right' }}>0.00</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {(ledgerDetails.totalPaymentAmount * 0.1).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Total</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {(
                                                            ledgerDetails.totalOnlinePaymentAmount +
                                                            ledgerDetails.totalTwaddanDiscountShare
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {(
                                                            ledgerDetails.totalOnlinePaymentAmount * 0.0225 +
                                                            ledgerDetails.totalOnlinePaymentAmount * 0.0225 * 0.05 +
                                                            ledgerDetails.totalPaymentAmount * 0.1 +
                                                            ledgerDetails.totalSpDiscountShare * 0.1
                                                        ).toFixed(2)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Service Provider Net Balance</td>
                                                    {ledgerDetails.totalOnlinePaymentAmount >
                                                    ledgerDetails.totalOnlinePaymentAmount * 0.0225 +
                                                        ledgerDetails.totalOnlinePaymentAmount * 0.0225 * 0.05 +
                                                        ledgerDetails.totalPaymentAmount * 0.1 +
                                                        ledgerDetails.totalSpDiscountShare * 0.1 ? (
                                                        <>
                                                            <td style={{ textAlign: 'right' }}>
                                                                {(
                                                                    ledgerDetails.totalOnlinePaymentAmount +
                                                                    ledgerDetails.totalTwaddanDiscountShare -
                                                                    (ledgerDetails.totalOnlinePaymentAmount * 0.0225 +
                                                                        ledgerDetails.totalOnlinePaymentAmount *
                                                                            0.0225 *
                                                                            0.05 +
                                                                        ledgerDetails.totalPaymentAmount * 0.1 +
                                                                        ledgerDetails.totalSpDiscountShare * 0.1)
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td style={{ textAlign: 'right' }}>0.00</td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td style={{ textAlign: 'right' }}>0.00</td>

                                                            <td style={{ textAlign: 'right' }}>
                                                                {(
                                                                    ledgerDetails.totalOnlinePaymentAmount * 0.0225 +
                                                                    ledgerDetails.totalOnlinePaymentAmount *
                                                                        0.0225 *
                                                                        0.05 +
                                                                    ledgerDetails.totalPaymentAmount * 0.1 +
                                                                    ledgerDetails.totalSpDiscountShare * 0.1 -
                                                                    (ledgerDetails.totalOnlinePaymentAmount +
                                                                        ledgerDetails.totalTwaddanDiscountShare)
                                                                ).toFixed(2)}
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            </tbody>
                                            <button class="btn btn-success" onClick={this.pdfGenerator}>
                                                TO PDF
                                            </button>
                                        </table>
                                    </Table>
                                </Col>
                                {/* <Input
                  placeholder="Description"
                  type="text"
                  onChange={(e) => this.setState({ driver_id: e.target.value })}
                />
                <button class="btn btn-success" onClick={this.addDriverId}>
                  Add Driver ID in order
                </button> */}
                            </Row>
                        </>
                    ) : (
                        <>
                            {ledgerDetails.totalOrders <= 0 && this.state.isLoaded ? (
                                <>
                                    <br></br>
                                    <h4>No Records Found in {moment(this.state.startDate).format('MMM YYYY')}</h4>
                                </>
                            ) : (
                                <>
                                    <br></br>
                                    <h4>Please select a service provider</h4>
                                </>
                            )}
                        </>
                    )}
                </Container>
            </>
        );
    }
}
export default Ledger;
