import React, { useState } from 'react';
import firebase from 'firebaseConfig';
import { Edit } from 'react-feather';
import Select from 'react-select';
import Switch from 'react-switch';
import { connect } from 'react-redux';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    CardTitle,
    Badge,
    CardImg,
    Label,
    FormGroup,
    Input,
    Form,
    CustomInput,
    Modal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    FormText,
    Table,
    CardFooter,
} from 'reactstrap';

import Header from 'components/Headers/Header.jsx';
import { Redirect } from 'react-router-dom';
import RealTime from 'services/RealTime';
import PageLoader from 'components/PageLoader/PageLoader';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';

// total booking count
var totalBooking = 0;
// active booking count
var activeBooking = 0;
// active vehicle count
var totalVehicle = 0;
// completed booking
var completedBooking = 0;
// total service providers
var totalSP = 0;
// total earning
var totalEarning = 0;
// CRUD permission details
var crudPermission = JSON.parse(localStorage.getItem('crudPermissions'));

// super admin ID
var saID = localStorage.getItem('admin_email');

var editService = {};
var spid = '';

// service providers details
var spDetails = [];
var spDetailsCopy = [];
firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
        firebase
            .database()
            .ref('/service_providers/')
            .on('value', function (snapshot) {
                spDetails = snapshot.val() || 'Anonymous';
                spDetailsCopy = spDetails;
                // active booking

                // total service providers
                totalSP = 0;
                if (spDetails) {
                    totalSP = Object.keys(spDetails).length;
                }

                // active booking
                activeBooking = 0;
                if (spDetails) {
                    Object.keys(spDetails).map((sp, keySP) => {
                        if (spDetails[sp].orders) {
                            if (spDetails[sp].orders.active_orders) {
                                activeBooking = activeBooking + Object.keys(spDetails[sp].orders.active_orders).length;
                            }
                        }
                    });
                }

                // completed booking
                completedBooking = 0;
                if (spDetails) {
                    Object.keys(spDetails).map((sp, keySP) => {
                        if (spDetails[sp].orders) {
                            if (spDetails[sp].orders.completed_orders) {
                                completedBooking =
                                    activeBooking + Object.keys(spDetails[sp].orders.completed_orders).length;
                            }
                        }
                    });
                }

                totalBooking = activeBooking + completedBooking;

                // total vehicles
                totalVehicle = 0;
                if (spDetails) {
                    Object.keys(spDetails).map((sp, keySP) => {
                        if (spDetails[sp].vehicles) {
                            totalVehicle = Object.keys(spDetails[sp].vehicles).length;
                        }
                    });
                }

                // total earning
                totalEarning = 0;
                if (spDetails) {
                    Object.keys(spDetails).map((sp, keySP) => {
                        if (spDetails[sp].earnings) {
                            Object.keys(spDetails[sp].earnings).map((year, keyYear) => {
                                Object.keys(spDetails[sp].earnings[year]).map((month, keyMonth) => {
                                    totalEarning = totalEarning + spDetails[sp].earnings[year][month].totalEarnings;
                                });
                            });
                        }
                    });
                }

                // ...
            });
    } else {
        // No user is signed in.
    }
});
const spColumns = [
    {
        text: 'Si No',
        dataField: 'i',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Service Provider',
        dataField: 'sp_name',
        sort: true,
        align: 'center',
        hidden: false,
        filter: textFilter({
            style: {
                minWidth: '150px',
            },
        }),
        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'sp_id',
        dataField: 'sp_id',
        sort: true,
        align: 'center',
        hidden: true,

        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Emirate',
        dataField: 'sp_emirate',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter({
            style: {
                minWidth: '150px',
                placeholder: 'Enter Name ',
            },
        }),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Email',
        dataField: 'sp_email',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter({
            style: {
                minWidth: '150px',
                placeholder: 'Enter Emirate ',
            },
        }),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Phone Number',
        dataField: 'sp_phone_number',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter({
            style: {
                minWidth: '150px',
                placeholder: 'Enter Emirate ',
            },
        }),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
];
class EditServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            sp_id: editService['id'],
            name: editService['name'],
            name_ar: editService['name_ar'],
            description: editService['description'],
            description_ar: editService['description_ar'],
            address: editService['address'],
            address_ar: editService['address_ar'],
            postal_code: editService['postal_code'],
            phone_number: editService['phone_number'],
            phone_number_owner: editService['phone_number_owner'],
            phone_number_manager: editService['phone_number_manager'],
            image: editService['image'],
            currentScheduledOnTime: editService['scheduledOnTime'],
            currentScheduledOffTime: editService['scheduledOffTime'],
            ownerName: editService['ownerName'],
            ownerEmail: editService['ownerEmail'],
            scheduledOnTime: '',
            scheduledOffTime: '',
            checked: editService['isAutoScheduled'],
            lat: '',
            lng: '',
            file: '',
            imagePreviewUrl: '',
            image_url: '',
            downloadURL: editService['image'],
            edit: 1,
            timeRange: editService['timeRange'],
            saID: localStorage.getItem('admin_email'),
            crudPermission: JSON.parse(localStorage.getItem('crudPermissions')),
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this._handleImageChange = this._handleImageChange.bind(this);
        this.getArabic = this.getArabic.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
    }

    onChange = (stateName, value) => {
        this.setState({
            [stateName]: value,
        });
    };

    pushData = () => {
        var id = editService['id'];

        var spName;
        firebase
            .database()
            .ref('service_providers/' + spid + '/personal_information/')
            .on('value', function (snapshot) {
                spName = snapshot.val();
                spName = spName.name;
            });
        var time = new Date();
        time = time.getTime();
        var logAction = 'Edited Service named ' + id + ' under ' + spName + ' Service Provider with SP ID ' + spid;
        var logs = firebase
            .database()
            .ref('/twaddan_admin/super_admin_users/' + this.state.saID + '/logs/' + time + '/');
        logs.update({
            action: logAction,
        });
        firebase
            .database()
            .ref('service_providers/' + spid + '/personal_information/')
            .update({
                name: this.state.name,
                name_ar: this.state.name_ar,
                description: this.state.description,
                description_ar: this.state.description_ar,
                address: this.state.address,
                address_ar: this.state.address_ar,
                postalcode: this.state.postal_code,
                phone_number: this.state.phone_number,
                phone_number_manager: this.state.phone_number_manager,
                phone_number_owner: this.state.phone_number_owner,
                ownerEmail: this.state.ownerEmail,
                ownerName: this.state.ownerName,
            });

        firebase
            .database()
            .ref('service_providers/' + spid + '/location')
            .update({
                lat: this.state.lat,
                lng: this.state.lng,
                address: this.state.address,
            });
        this.setState({
            edit: 0,
        });
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            isLoading: true,
        });
    };

    uploadImage = () => {
        var id = editService['id'];
        var imgId = id + '.png';
        var storageRef = firebase.storage().ref();
        var ImagesRef = storageRef.child('/service_provider/' + spid + '/personal_information/' + imgId);
        ImagesRef.put(this.state.file)
            .then((snapshot) => {
                return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
            })

            .then((downloadURL) => {
                console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
                this.setState({
                    downloadurl: downloadURL,
                });
                editService['image'] = downloadURL;

                firebase
                    .database()
                    .ref('service_providers/' + spid + '/personal_information/')
                    .update({
                        image: downloadURL,
                    });

                // this.toggle();
                this.setState({
                    isLoading: true,
                });
            })

            .catch((error) => {
                // Use to signal error if something goes wrong.
            });
    };
    _handleImageChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    };

    handleProfileSubmit = (event) => {
        this.setState({
            isLoading: false,
            modal: !this.state.modal,
        });
        this.uploadImage();
        event.preventDefault();
    };

    handleSubmit = (event) => {
        if (
            (this.state.scheduledOffTime && this.state.scheduledOnTime) ||
            (this.state.currentScheduledOffTime && this.state.currentScheduledOnTime)
        ) {
            this.getlatLng(this.state.address);
        } else {
            alert('Set Both Start & End Time');
        }
        event.preventDefault();
    };
    getArabic = async (text, value) => {
        const axios = require('axios').default;
        const url = 'https://translation.googleapis.com/language/translate/v2';
        const apiKey = 'AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g';
        const reply = await axios.get(url, {
            params: {
                q: text,
                target: 'ar',
                key: apiKey,
            },
        });
        this.setState({
            [value]: reply.data.data.translations[0].translatedText,
        });
    };
    convert = () => {
        this.getArabic(this.state.name, 'name_ar');
        this.getArabic(this.state.description, 'description_ar');
        this.getArabic(this.state.address, 'address_ar');
    };
    getlatLng = async (address) => {
        const axios = require('axios').default;
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?';
        const apiKey = 'AIzaSyC5s08MMDW8tAu5a90NO7rmPGLamyHkmsY';
        const reply = await axios.get(url, {
            params: {
                address: address,
                key: apiKey,
            },
        });
        if (
            reply.data.status === 'OK' &&
            reply.data &&
            reply.data.results[0] &&
            reply.data.results[0].geometry.location &&
            reply.data.results[0].geometry.location.lat
        ) {
            this.setState({
                lat: reply.data.results[0].geometry.location.lat,
                lng: reply.data.results[0].geometry.location.lng,
            });
        } else {
            this.setState({
                lat: '',
                lng: '',
            });
        }
        this.pushData(); // function to create SP and push data to DB
    };

    handleAutoScheduleSwitch = () => {
        this.setState(
            {
                checked: !this.state.checked,
            },
            () => {
                firebase
                    .database()
                    .ref('service_providers/' + spid + '/')
                    .update({
                        isAutoScheduled: this.state.checked,
                    });
            }
        );
    };

    render() {
        const imagestyle = {
            textalign: 'center',
            margin: '5px 15px',
            height: '200px',
            width: '300px',
            borderleft: '1px solid',
        };
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
        }
        if (this.state.edit === 0) {
            return <ServiceCenters />;
        }
        return (
            <>
                <Header />
                {/* Page content */}
                <PageLoader loading={!this.state.isLoading}></PageLoader>

                <Container className="mt--7" fluid>
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="order-xl-1" xl="12">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <Row className="align-items-center">
                                            <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                                                <h3 className="mb-0">Edit Service Provider Details</h3>
                                            </Col>
                                            <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                                                <Button
                                                    block
                                                    color="primary"
                                                    className="button_back"
                                                    onClick={() => {
                                                        this.setState({
                                                            edit: 0,
                                                        });
                                                    }}
                                                >
                                                    BACK
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <CardImg
                                            top
                                            className="img-fluid"
                                            style={{ width: '250px', height: '250px' }}
                                            src={editService['image']}
                                            alt="card image cap"
                                        />
                                        <div className="d-flex justify-content-between">
                                            <Button
                                                className="mr-4"
                                                color="info"
                                                href="#pablo"
                                                onClick={this.toggle}
                                                size="sm"
                                            >
                                                CHANGE IMAGE
                                            </Button>
                                        </div>
                                        <Form onSubmit={this.handleSubmit}>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Name
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Name"
                                                                defaultValue={this.state.name}
                                                                type="text"
                                                                onChange={(e) => this.onChange('name', e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Name in Arabic
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder=" Name in Arabic"
                                                                defaultValue={this.state.name_ar}
                                                                value={this.state.name_ar}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('name_ar', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Description
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Description"
                                                                defaultValue={this.state.description}
                                                                value={this.state.description}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('description', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Description in Arabic
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Description in Arabic"
                                                                defaultValue={this.state.description_ar}
                                                                value={this.state.description_ar}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('description_ar', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Address
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Address"
                                                                defaultValue={this.state.address}
                                                                value={this.state.address}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('address', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Address in Arabic
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Address in Arabic"
                                                                defaultValue={this.state.address_ar}
                                                                value={this.state.address_ar}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('address_ar', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Postal Code
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Postal Code"
                                                                defaultValue={this.state.postal_code}
                                                                value={this.state.postal_code}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('postal_code', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Owner Name
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Owner Name"
                                                                defaultValue={this.state.ownerName}
                                                                value={this.state.ownerName}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('ownerName', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Owner Email
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                placeholder="Owner Email"
                                                                defaultValue={this.state.ownerEmail}
                                                                value={this.state.ownerEmail}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('ownerEmail', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Owner Phone Number
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                maxLength="13"
                                                                minLength="10"
                                                                placeholder="Owner Phone Number"
                                                                defaultValue={this.state.phone_number_owner}
                                                                value={this.state.phone_number_owner}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('phone_number_owner', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Company Phone Number
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                maxLength="13"
                                                                minLength="10"
                                                                placeholder="Company Phone Number"
                                                                defaultValue={this.state.phone_number}
                                                                value={this.state.phone_number}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange('phone_number', e.target.value)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-text">
                                                                Manager Phone Number
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-number"
                                                                required
                                                                maxLength="13"
                                                                minLength="10"
                                                                placeholder="Manager Phone Number"
                                                                defaultValue={this.state.phone_number_manager}
                                                                value={this.state.phone_number_manager}
                                                                type="text"
                                                                onChange={(e) =>
                                                                    this.onChange(
                                                                        'phone_number_manager',
                                                                        e.target.value
                                                                    )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={2} md={6} sm={12}>
                                                        {this.state.currentScheduledOnTime &&
                                                        this.state.currentScheduledOffTime ? (
                                                            <h2>{`On Time ${this.state.currentScheduledOnTime}:00`}</h2>
                                                        ) : (
                                                            <Select
                                                                onChange={(time) => {
                                                                    this.setState({
                                                                        scheduledOnTime: time.value,
                                                                    });
                                                                }}
                                                                options={this.state.timeRange}
                                                                placeholder="Select On Time"
                                                                isSearchable={false}
                                                            />
                                                        )}
                                                    </Col>

                                                    <Col lg={2} md={6} sm={12}>
                                                        {this.state.currentScheduledOffTime &&
                                                        this.state.currentScheduledOnTime ? (
                                                            <h2>{`Off Time ${this.state.currentScheduledOffTime}:00`}</h2>
                                                        ) : (
                                                            <Select
                                                                onChange={(time) => {
                                                                    this.setState({
                                                                        scheduledOffTime: time.value,
                                                                    });
                                                                }}
                                                                options={this.state.timeRange}
                                                                placeholder="Select Off Time"
                                                                isSearchable={false}
                                                            />
                                                        )}
                                                    </Col>

                                                    <Col lg={2} md={6} sm={12}>
                                                        {this.state.currentScheduledOnTime &&
                                                        this.state.currentScheduledOffTime ? (
                                                            <Button
                                                                color="danger"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        currentScheduledOffTime: null,
                                                                        currentScheduledOnTime: null,
                                                                        scheduledOffTime: null,
                                                                        scheduledOnTime: null,
                                                                    });
                                                                }}
                                                            >
                                                                RESET SCHEDULE
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                color="success"
                                                                onClick={() => {
                                                                    if (
                                                                        this.state.scheduledOffTime !==
                                                                            this.state.scheduledOnTime &&
                                                                        this.state.scheduledOffTime &&
                                                                        this.state.scheduledOnTime
                                                                    ) {
                                                                        this.setState({
                                                                            scheduledOffTime:
                                                                                this.state.scheduledOffTime,
                                                                            scheduledOnTime: this.state.scheduledOnTime,
                                                                            currentScheduledOffTime:
                                                                                this.state.scheduledOffTime,
                                                                            currentScheduledOnTime:
                                                                                this.state.scheduledOnTime,
                                                                        });
                                                                        firebase
                                                                            .database()
                                                                            .ref('service_providers/' + spid + '/')
                                                                            .update({
                                                                                scheduledOnTime:
                                                                                    this.state.scheduledOnTime,
                                                                                scheduledOffTime:
                                                                                    this.state.scheduledOffTime,
                                                                            });
                                                                    } else {
                                                                        alert('Set Both Start & End Time');
                                                                    }
                                                                }}
                                                            >
                                                                UPDATE SCHEDULE
                                                            </Button>
                                                        )}
                                                    </Col>
                                                    <Col lg={2} md={6} sm={12}></Col>
                                                    <Col lg={2} md={6} sm={12}>
                                                        <h2>{`Auto Schedule ${this.state.checked ? 'On' : 'Off'}`}</h2>
                                                    </Col>

                                                    <Col lg={2} md={6} sm={12}>
                                                        <Switch
                                                            onChange={() => {
                                                                this.handleAutoScheduleSwitch();
                                                            }}
                                                            checked={this.state.checked}
                                                        />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col xs={12} sm={12} md={4} lg={3} xl={2}>
                                                        <Button
                                                            className="buttonConvert"
                                                            color="primary"
                                                            onClick={this.convert}
                                                        >
                                                            CONVERT TO ARABIC
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        className="buttonCancelSubmitCol"
                                                        xs={6}
                                                        sm={6}
                                                        md={4}
                                                        lg={3}
                                                        xl={2}
                                                    >
                                                        <Button
                                                            block
                                                            onClick={() => {
                                                                this.setState({
                                                                    edit: 0,
                                                                });
                                                            }}
                                                        >
                                                            CANCEL
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        className="buttonCancelSubmitCol"
                                                        xs={6}
                                                        sm={6}
                                                        md={3}
                                                        lg={3}
                                                        xl={2}
                                                    >
                                                        <Button block color="success" value="Submit">
                                                            UPDATE
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container className="mt--7" fluid>
                    <Row>
                        <Card className="shadow">
                            <Modal
                                isOpen={this.state.modal}
                                toggle={this.toggle}
                                className={this.props.className}
                                size="lg"
                            >
                                <ModalHeader toggle={this.toggle}>
                                    <h1>Update Image Below</h1>
                                </ModalHeader>
                                <ModalBody>
                                    <>
                                        <Card className="bg-secondary shadow border-0">
                                            <CardBody className="px-lg-5 py-lg-5">
                                                <Form onSubmit={this.handleProfileSubmit}>
                                                    <FormGroup>
                                                        <Label for="File">Add image</Label>
                                                        <br></br>
                                                        <input
                                                            type="file"
                                                            accept="image/png, image/jpeg"
                                                            onChange={this._handleImageChange}
                                                        />
                                                        <FormText color="muted">
                                                            Select the Image that you wanted to display.
                                                        </FormText>
                                                        <Row>
                                                            <Col md={4} xs={2}>
                                                                <div style={imagestyle}>{$imagePreview}</div>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>

                                                    <Button color="primary" value="Submit">
                                                        UPDATE IMAGE
                                                    </Button>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={this.toggle}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </Card>
                    </Row>
                </Container>
            </>
        );
    }
}

class ServiceCenters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            spName: '',
            downloadURL: '',
            file: '',
            imagePreviewUrl: '',
            spemail: '',
            edit: 0,
            additionalNote: '',
            number: '',
            modal: false,
            searchBarID: '',
            spStatus: [],
            suspendStatus: [],
            adminStatus: [],
            service_provider_details: [],
            selectedSp: {},
            scheduledOnTime: null,
            scheduledOffTime: null,
            currentScheduledOnTime: '',
            currentScheduledOffTime: '',
            timeRange: [],
            saID: localStorage.getItem('admin_email'),
            crudPermission: JSON.parse(localStorage.getItem('crudPermissions')),
        };

        this.onChange = this.onChange.bind(this);
        this.navigateSPCenter = this.navigateSPCenter.bind(this);
        this.toggleSwitch = this.toggleSwitch.bind(this);
        this.getSPStatus = this.getSPStatus.bind(this);
    }
    // to store selected sp details for editing
    storeCurrentService = (
        id,
        name,
        name_ar,
        description,
        description_ar,
        address,
        address_ar,
        postal_code,
        phone_number,
        phone_number_owner,
        phone_number_manager,
        image,
        scheduledOnTime,
        scheduledOffTime,
        isAutoScheduled,
        timeRange,
        ownerName,
        ownerEmail
    ) => {
        spid = id;
        editService['id'] = id;
        editService['name'] = name;
        editService['name_ar'] = name_ar;
        editService['name_ar'] = name_ar;
        editService['description'] = description;
        editService['description_ar'] = description_ar;
        editService['address'] = address;
        editService['address_ar'] = address_ar;
        editService['postal_code'] = postal_code;
        editService['postal_code'] = postal_code;
        editService['phone_number'] = phone_number;
        editService['phone_number_manager'] = phone_number_manager;
        editService['phone_number_owner'] = phone_number_owner;
        editService['image'] = image;
        editService['scheduledOnTime'] = scheduledOnTime;
        editService['scheduledOffTime'] = scheduledOffTime;
        editService['isAutoScheduled'] = isAutoScheduled;
        editService['timeRange'] = timeRange;
        editService['ownerName'] = ownerName;
        editService['ownerEmail'] = ownerEmail;
        this.setState({
            edit: 1,
        });
    };
    // componentDidMount
    async componentDidMount() {
        spDetails = spDetailsCopy;
        this.setState({ searchBarID: '' });
        this.onChange('searchBarID', '');
        let timeRange = [];
        for (let index = 0; index < 24; index++)
            timeRange.push({
                value: index,
                label: `${index}:00`,
            });
        this.setState({
            timeRange: timeRange,
        });

        this.getdata();
    }

    getScheduleTime = async () => {
        const service_providers = await RealTime.getDetails('/service_providers/');
        let scheduledOffTime = [];
        let scheduledOnTime = [];
        Object.entries(service_providers).forEach(([keySp, sp]) => {
            if (sp.scheduledOnTime && sp.scheduledOffTime) {
                scheduledOffTime.push(sp.scheduledOffTime);
                scheduledOnTime.push(sp.scheduledOnTime);
            }
        });

        if (new Set(scheduledOffTime).size == 1 && new Set(scheduledOnTime).size == 1) {
            this.setState({
                currentScheduledOnTime: scheduledOnTime[0],
                currentScheduledOffTime: scheduledOffTime[0],
            });
        }
    };
    // fetching sp data
    getdata = async () => {
        this.setState({
            isLoading: false,
        });
        await this.getScheduleTime();
        const service_providers = await RealTime.getDetails('/service_providers/');
        const emirate_details = await RealTime.getDetails('twaddan_admin/emirates');
        spDetailsCopy = service_providers;
        var service_provider = [];
        Object.entries(service_providers).forEach(([keysSp, sp]) => {
            service_provider.push({
                id: keysSp,
                name: sp.personal_information?.name,
                email: sp.personal_information?.email,
                phone_number: sp.personal_information?.phone_number,
                state: sp.state,
                personal_information: sp.personal_information,
                isSuspended: sp.isSuspended,
                admin_status: sp?.admin_status,
                scheduledOnTime: sp.scheduledOnTime ? sp.scheduledOnTime : null,
                scheduledOffTime: sp.scheduledOffTime ? sp.scheduledOffTime : null,
                isAutoScheduled: sp.isAutoScheduled ? sp.isAutoScheduled : false,
                emailNotification: sp.emailNotification ? sp.emailNotification : false,
                smsNotification: sp.smsNotification ? sp.smsNotification : false,
            });
        });
        var service_provider_details = [];
        var i = 1;
        Object.entries(emirate_details).forEach(([key, emirate]) => {
            if (emirate.service_providers)
                Object.entries(emirate.service_providers).forEach(([sp, value]) => {
                    service_provider.map((spd) => {
                        if (spd.id === sp) {
                            service_provider_details.push({
                                i: i++,
                                sp_name: spd.name,
                                sp_id: spd.id,
                                sp_state: spd.state,
                                sp_email: spd.email,
                                sp_phone_number: spd.phone_number,
                                sp_emirate: emirate.name,
                                sp_personal_information: spd.personal_information,
                                isSuspended: spd.isSuspended,
                                admin_status: spd?.admin_status,
                                scheduledOnTime: spd.scheduledOnTime,
                                scheduledOffTime: spd.scheduledOffTime,
                                isAutoScheduled: spd.isAutoScheduled,
                                emailNotification: spd.emailNotification,
                                smsNotification: spd.smsNotification,
                            });
                        }
                    });
                });
        });
        this.setState({
            service_provider_details: service_provider_details,
        });

        // total service providers
        totalSP = 0;
        if (spDetails) {
            totalSP = Object.keys(spDetails).length;
        }
        this.setState({
            isLoading: true,
        });
        this.getSPStatus();
    };
    // sp status storing
    getSPStatus = () => {
        let status = [];
        let suspendStatus = [];
        let adminStatus = [];

        if (this.state.service_provider_details) {
            Object.entries(this.state.service_provider_details).forEach(([key, sp]) => {
                if (sp.sp_state === 1) {
                    status[key] = true;
                } else {
                    status[key] = false;
                }
                suspendStatus[key] = sp.isSuspended;
                adminStatus[key] = sp.admin_status;
            });
        }
        this.setState({
            spStatus: status,
            suspendStatus: suspendStatus,
            adminStatus: adminStatus,
        });
    };
    onChange = (stateName, value) => {
        this.setState({
            [stateName]: value,
        });

        if (stateName == 'searchBarID') {
            if (value == '') {
                spDetails = spDetailsCopy;
            } else {
                spDetails = [];
                Object.keys(spDetailsCopy).map((line, key) => {
                    var IDSearch = spDetailsCopy[line].personal_information.name;

                    if (IDSearch.startsWith(value)) {
                        spDetails.push(spDetailsCopy[line]);
                    }
                });
            }
        }
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };
    // to toggle sp status
    toggleSwitch = async (key, spID) => {
        console.log('spID: ', spID);
        console.log('key: ', key);
        var spStatus = this.state.spStatus;
        console.log('this.state.spStatus: ', this.state.spStatus);

        spStatus[key] = !spStatus[key];

        this.setState({
            spStatus: spStatus,
        });

        let stateValue = (spStatus[key] && 1) || (!spStatus[key] && 0);
        console.log('stateValue: ', stateValue);

        firebase
            .database()
            .ref('service_providers/' + spID + '/')
            .update({
                state: stateValue,
            });

        firebase
            .database()
            .ref('service_providers/' + spID + '/')
            .update({
                admin_status: stateValue === 1 ? true : false,
            });
        this.getdata();
        this.toggle();
    };
    //SP ADMIN CONDROL STATUS ENABLING AND DISABLING

    acEnableisable = async (key, spID) => {
        console.log('spID: ', spID);
        console.log('key: ', key);
        var adminStatus = this.state.adminStatus;
        console.log('this.state.adminStatus: ', this.state.adminStatus);

        adminStatus[key] = !adminStatus[key];

        this.setState({
            adminStatus: adminStatus,
        });

        firebase
            .database()
            .ref('service_providers/' + spID + '/')
            .update({
                admin_status: adminStatus[key],
            });
        this.getdata();
        // this.toggle();
    };
    suspendSp = async (key, spid) => {
        console.log('key: ', key);
        console.log('spid: ', spid);
        let suspendStatus = [];
        suspendStatus = this.state.suspendStatus;
        suspendStatus[key] = !suspendStatus[key];

        firebase
            .database()
            .ref('service_providers/' + spid + '/')
            .update({
                isSuspended: suspendStatus[key],
            });
        this.setState({
            suspendStatus: suspendStatus,
        });
        if (this.state.spStatus[key]) {
            this.toggleSwitch(key, spid);
        }
    };

    navigateSPCenter = () => {
        this.setState({
            spCenter: 1,
        });
    };
    // to set scheduled on and off time of sp
    setSchedule = async () => {
        this.setState({
            isLoading: false,
        });
        if (Math.abs(this.state.scheduledOffTime - this.state.scheduledOnTime) !== 0) {
            this.state.service_provider_details.map((sp) => {
                if (true) {
                    firebase
                        .database()
                        .ref('service_providers/' + sp.sp_id + '/')
                        .update({
                            scheduledOffTime: this.state.scheduledOffTime,
                            scheduledOnTime: this.state.scheduledOnTime,
                        });
                }
            });
            await this.getScheduleTime();
            this.setState({
                isLoading: true,
            });
            this.getdata();
        }
    };
    // to clear scheduled on and off time of sp

    clearSchedule = () => {
        this.setState({
            isLoading: false,
        });
        this.state.service_provider_details.map((sp) => {
            if (sp.sp_id) {
                firebase
                    .database()
                    .ref('service_providers/' + sp.sp_id + '/')
                    .update({
                        scheduledOffTime: null,
                        scheduledOnTime: null,
                    });
            }
        });
        this.setState({
            isLoading: true,
        });
    };
    rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.sp_state === 1) {
            style.backgroundColor = '#9effc5';
        } else {
            style.backgroundColor = '#fc9f9f';
        }
        style.cursor = 'pointer';
        return style;
    };
    details = (text, data) => {
        return (
            <>
                <Col md="5">
                    <p>
                        <b>{text}</b>
                    </p>
                </Col>
                <Col md="7">
                    <p>: {data}</p>
                </Col>
            </>
        );
    };
    render() {
        if (this.state.spCenter == 1) {
            return <Redirect to="/admin/addServiceCenters" />;
        }
        const imagestyle = {
            textalign: 'center',
            // margin : "5px 15px",
            height: '300px',
            width: '500px',
            // borderleft: "1px solid"
        };
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
        }
        if (this.state.edit == 1) {
            return <EditServices />;
        }
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log('row: ', row);
                this.setState({
                    selectedSp: row,
                });
                this.toggle();
            },
        };
        return (
            <>
                <Header />
                <PageLoader loading={!this.state.isLoading}></PageLoader>
                <Container className="mt--7" fluid>
                    <Row className="mt-8 mb-5">
                        <Col sm={12} md={7} lg={8} xl={9}>
                            <h1 className="mb-0">Service Providers</h1>
                        </Col>
                        <Col sm={6} md={5} lg={4} xl={3}>
                            <Button block color="primary" style={{ right: '5px' }} onClick={this.navigateSPCenter}>
                                ADD SERVICE CENTER
                            </Button>
                        </Col>
                    </Row>
                    {false && (
                        <>
                            <Row>
                                <Col lg={4} md={4} sm={12}>
                                    {this.state.currentScheduledOnTime ? (
                                        <h2>{`Scheduled On Time is ${this.state.currentScheduledOnTime}:00`}</h2>
                                    ) : (
                                        <Select
                                            onChange={(time) => {
                                                this.setState({ scheduledOnTime: time.value });
                                            }}
                                            options={this.state.timeRange}
                                            isSearchable={true}
                                            placeholder="Select On Time"
                                        />
                                    )}
                                </Col>

                                <Col lg={4} md={4} sm={12}>
                                    {this.state.currentScheduledOffTime ? (
                                        <h2>{`Scheduled Off Time is ${this.state.currentScheduledOffTime}:00`}</h2>
                                    ) : (
                                        <Select
                                            onChange={(time) => {
                                                this.setState({ scheduledOffTime: time.value });
                                            }}
                                            options={this.state.timeRange}
                                            isSearchable={true}
                                            placeholder="Select Off Time"
                                        />
                                    )}
                                </Col>
                                <Col>
                                    {this.state.currentScheduledOffTime && this.state.currentScheduledOnTime ? (
                                        <Button
                                            color="danger"
                                            onClick={async () => {
                                                this.setState({
                                                    currentScheduledOnTime: false,
                                                    currentScheduledOffTime: false,
                                                });
                                            }}
                                        >
                                            RESET SCHEDULE
                                        </Button>
                                    ) : (
                                        <Button color="success" onClick={this.setSchedule}>
                                            SET SCHEDULE
                                        </Button>
                                    )}
                                    {false && (
                                        <Button color="danger" onClick={this.clearSchedule}>
                                            CLEAR SCHEDULE
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </>
                    )}

                    <Row>
                        <Col className="mt-3">
                            <BootstrapTable
                                keyField="id"
                                data={this.state.service_provider_details}
                                columns={spColumns}
                                rowStyle={this.rowStyle}
                                filter={filterFactory()}
                                filterPosition="top"
                                bordered={false}
                                hover
                                wrapperClasses="table-responsive"
                                condensed
                                rowEvents={rowEvents}
                            />
                        </Col>
                    </Row>
                </Container>

                {this.state.selectedSp && (
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="md">
                        <ModalHeader>
                            <Row>
                                <Col lg="12" md="12" sm="12" xs="12">
                                    <h1> Service Provider Details</h1>
                                </Col>
                            </Row>
                        </ModalHeader>
                        <ModalBody className="p-0">
                            <>
                                <Row>
                                    <Col lg="4" md="4" sm="4" xs="4">
                                        <Button
                                            block
                                            color={
                                                this.state.suspendStatus[this.state.selectedSp.i - 1]
                                                    ? 'success'
                                                    : 'danger'
                                            }
                                            onClick={() => {
                                                this.suspendSp(
                                                    this.state.selectedSp.i - 1,
                                                    this.state.selectedSp.sp_id
                                                );
                                            }}
                                        >
                                            {this.state.suspendStatus[this.state.selectedSp.i - 1]
                                                ? 'UNSUSPEND'
                                                : 'SUSPEND'}
                                        </Button>
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="4">
                                        <Button
                                            block
                                            color={
                                                this.state.spStatus[this.state.selectedSp.i - 1] ? 'danger' : 'success'
                                            }
                                            onClick={() => {
                                                this.toggleSwitch(
                                                    this.state.selectedSp.i - 1,
                                                    this.state.selectedSp.sp_id
                                                );
                                            }}
                                        >
                                            {this.state.spStatus[this.state.selectedSp.i - 1] ? 'DISABLE' : 'ENABLE'}
                                        </Button>
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="4">
                                        <Button
                                            block
                                            color={
                                                this.state.adminStatus[this.state.selectedSp.i - 1]
                                                    ? 'danger'
                                                    : 'success'
                                            }
                                            onClick={() => {
                                                this.acEnableisable(
                                                    this.state.selectedSp.i - 1,
                                                    this.state.selectedSp.sp_id
                                                );
                                            }}
                                        >
                                            {this.state.adminStatus[this.state.selectedSp.i - 1]
                                                ? 'AC DISABLE'
                                                : 'AC ENABLE'}
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardImg
                                                top
                                                className="img-fluid"
                                                src={this.state.selectedSp.sp_personal_information?.image}
                                                alt="card image cap"
                                            />
                                            <CardBody className="pb-0">
                                                <Row>
                                                    {this.details(
                                                        'Name',
                                                        this.state.selectedSp.sp_personal_information?.name
                                                    )}
                                                    {this.details(
                                                        '',
                                                        this.state.selectedSp.sp_personal_information?.name_ar
                                                    )}
                                                    {this.details(
                                                        'Description',
                                                        this.state.selectedSp.sp_personal_information?.description
                                                    )}
                                                    {this.details(
                                                        ' ',
                                                        this.state.selectedSp.sp_personal_information?.description_ar
                                                    )}
                                                    {this.details(
                                                        'Address',
                                                        this.state.selectedSp.sp_personal_information?.address
                                                    )}
                                                    {this.details(
                                                        ' ',
                                                        this.state.selectedSp.sp_personal_information?.address_ar
                                                    )}
                                                    {this.details(
                                                        'Postal Code',
                                                        this.state.selectedSp.sp_personal_information?.postalcode
                                                    )}
                                                    {this.details(
                                                        'Company Phone Number',
                                                        this.state.selectedSp.sp_personal_information?.phone_number
                                                    )}
                                                    {this.details(
                                                        'Manager Phone Number',
                                                        this.state.selectedSp.sp_personal_information
                                                            ?.phone_number_manager
                                                    )}
                                                    {this.details(
                                                        'Owner Phone Number',
                                                        this.state.selectedSp.sp_personal_information
                                                            ?.phone_number_owner
                                                    )}
                                                    {this.details(
                                                        'Owner Email',
                                                        this.state.selectedSp.sp_personal_information?.ownerEmail
                                                    )}
                                                    {this.details(
                                                        'Email Notification',
                                                        <Switch
                                                            onChange={() => {
                                                                RealTime.updateNode(
                                                                    `service_providers/${this.state.selectedSp.sp_id}/`,
                                                                    {
                                                                        emailNotification:
                                                                            !this.state.selectedSp?.emailNotification,
                                                                    }
                                                                );
                                                                this.toggle();
                                                                this.getdata();
                                                            }}
                                                            checked={this.state.selectedSp?.emailNotification}
                                                        />
                                                    )}
                                                    {this.details(
                                                        'SMS Notification',
                                                        <Switch
                                                            onChange={() => {
                                                                RealTime.updateNode(
                                                                    `service_providers/${this.state.selectedSp.sp_id}/`,
                                                                    {
                                                                        smsNotification:
                                                                            !this.state.selectedSp?.smsNotification,
                                                                    }
                                                                );
                                                                this.toggle();
                                                                this.getdata();
                                                            }}
                                                            checked={this.state.selectedSp?.smsNotification}
                                                        />
                                                    )}
                                                    {this.details(
                                                        'Start Time',
                                                        this.state.selectedSp.scheduledOnTime
                                                            ? `${this.state.selectedSp.scheduledOnTime}:00`
                                                            : `--:--`
                                                    )}
                                                    {this.details(
                                                        'End Time',
                                                        this.state.selectedSp.scheduledOffTime
                                                            ? `${this.state.selectedSp.scheduledOffTime}:00`
                                                            : `--:--`
                                                    )}
                                                    {this.details(
                                                        'Status',
                                                        this.state.spStatus[this.state.selectedSp.i - 1]
                                                            ? 'Online'
                                                            : 'Offline'
                                                    )}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        </ModalBody>
                        <ModalFooter className="py-1">
                            <a
                                href={this.state.selectedSp.sp_personal_information?.qr_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button color="warning">QR CODE</Button>
                            </a>
                            <Button
                                color="primary"
                                onClick={() => {
                                    this.storeCurrentService(
                                        this.state.selectedSp.sp_id,
                                        this.state.selectedSp.sp_personal_information?.name,
                                        this.state.selectedSp.sp_personal_information?.name_ar,
                                        this.state.selectedSp.sp_personal_information?.description,
                                        this.state.selectedSp.sp_personal_information?.description_ar,
                                        this.state.selectedSp.sp_personal_information?.address,
                                        this.state.selectedSp.sp_personal_information?.address_ar,
                                        this.state.selectedSp.sp_personal_information?.postalcode,
                                        this.state.selectedSp.sp_personal_information?.phone_number,
                                        this.state.selectedSp.sp_personal_information?.phone_number_owner,
                                        this.state.selectedSp.sp_personal_information?.phone_number_manager,
                                        this.state.selectedSp.sp_personal_information?.image,
                                        this.state.selectedSp.scheduledOnTime,
                                        this.state.selectedSp.scheduledOffTime,
                                        this.state.selectedSp.isAutoScheduled,
                                        this.state.timeRange,
                                        this.state.selectedSp.sp_personal_information?.ownerName,
                                        this.state.selectedSp.sp_personal_information?.ownerEmail
                                    );
                                }}
                            >
                                <Edit />
                            </Button>

                            <Button color="danger" onClick={this.toggle}>
                                CLOSE
                            </Button>
                        </ModalFooter>
                    </Modal>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceCenters);
