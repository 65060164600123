/*eslint-disable*/
import React from "react";
import moment from "moment";

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    var year = moment().tz("Asia/Dubai").year();
    return (
      <>
        <br></br>
        <br></br>
        <br></br>
        <footer className="footer">
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {year}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="/admin/index"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Twaddan
                </a>
              </div>
            </Col>

            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  {/* <NavLink
                  href=""
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Twaddan
                </NavLink> */}
                </NavItem>

                <NavItem>
                  {/* <NavLink
                  href=""
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  About Us
                </NavLink> */}
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </footer>
      </>
    );
  }
}

export default Footer;
