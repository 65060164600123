import React from "react";
import { connect } from "react-redux";
import registerAction from "actions/registerAction";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class Register extends React.Component {
  state = {
    username: "",
    email: "",
    password: "",
    passwordRetype: "",
    phoneNumber: "",
    isChecked: false,
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  toggleChange() {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  render() {
    const {
      username,
      email,
      password,
      passwordRetype,
      description,
      phoneNumber,
      isChecked,
      error,
    } = this.state;

    const isInvalid =
      password !== passwordRetype ||
      password === "" ||
      email === "" ||
      !isChecked ||
      username === "" ||
      phoneNumber === "" ||
      !/^\d{10}$/.test(phoneNumber);

    const passwordMisMatch =
      password !== passwordRetype && passwordRetype.length > 0;

    const incorrectPhoneNumber =
      phoneNumber !== "" && !/^\d{10}$/.test(phoneNumber);

    const passwordLength_LT6 = password.length < 6;
    const passwordLength_GT6_LT9 = password.length >= 6 && password.length < 9;
    const passwordLength_GT9 = password.length >= 9;

    return (
      <>
        <Col lg='6' md='8'>
          <Card className='bg-secondary shadow border-0'>
            <CardBody className='px-lg-5 py-lg-5'>
              <div className='text-center text-muted mb-4'>
                <small>Sign up with Super Admin Credentials</small>
              </div>
              <Form role='form'>
                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-circle-08' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='User Name'
                      type='text'
                      onChange={(e) =>
                        this.onChange("username", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-bullet-list-67' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Description'
                      type='text'
                      onChange={(e) =>
                        this.onChange("description", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-mobile-button' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Phone number'
                      type='text'
                      onChange={(e) =>
                        this.onChange("phoneNumber", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative mb-3'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-email-83' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Email'
                      type='email'
                      onChange={(e) => this.onChange("email", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Password'
                      type='password'
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className='input-group-alternative'>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText>
                        <i className='ni ni-lock-circle-open' />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Retype Password'
                      type='password'
                      onChange={(e) =>
                        this.onChange("passwordRetype", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <div className='text-muted font-italic'>
                  <small>
                    password strength:{" "}
                    {passwordLength_LT6 && (
                      <span className='text-danger font-weight-700'>Low</span>
                    )}
                    {passwordLength_GT6_LT9 && (
                      <span className='text-warning font-weight-700'>
                        Medium
                      </span>
                    )}
                    {passwordLength_GT9 && (
                      <span className='text-success font-weight-700'>High</span>
                    )}
                  </small>
                  <div>
                    <small>
                      {incorrectPhoneNumber && (
                        <span className='text-danger font-weight-700'>
                          Incorrect Phone Number
                        </span>
                      )}
                    </small>
                  </div>
                  <div>
                    <small>
                      {passwordMisMatch && (
                        <span className='text-danger font-weight-700'>
                          Password Mismatch
                        </span>
                      )}
                    </small>
                  </div>
                </div>

                <Row className='my-4'>
                  <Col xs='12'>
                    <div className='custom-control custom-control-alternative custom-checkbox'>
                      <input
                        className='custom-control-input'
                        id='customCheckRegister'
                        type='checkbox'
                        onClick={() => {
                          this.toggleChange();
                        }}
                        checked={this.state.isChecked}
                      />

                      <label
                        className='custom-control-label'
                        htmlFor='customCheckRegister'>
                        <span className='text-muted'>
                          I agree with the{" "}
                          <a href='#pablo' onClick={(e) => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>

                <div className='text-center'>
                  <Button
                    disabled={isInvalid}
                    className='mt-4'
                    color='primary'
                    type='button'
                    onClick={() =>
                      this.props.registerAction(
                        this.state.username,
                        this.state.phoneNumber,
                        this.state.description,
                        this.state.email,
                        this.state.password
                      )
                    }>
                    CREATE ACCOUNT
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  registerAction: (username, phoneNumber, description, email, password) =>
    dispatch(
      registerAction(username, phoneNumber, description, email, password)
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(Register);
