import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";
import { Redirect } from "react-router-dom";

// core components
import Header from "components/Headers/Header.jsx";

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard: 0,
    };

    this.navigate = this.navigate.bind(this);
  }
  navigate = () => {
    this.setState({
      dashboard: 1,
    });
  };

  render() {
    if (this.state.dashboard == 1) {
      return <Redirect to='/admin/index' />;
    }
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        <Container className='mt--7' fluid>
          <br />
          <br />
          <br />
          <div
            className='header pb-8 pt-5 pt-lg-8 d-flex align-items-center'
            style={{
              minHeight: "700px",
              backgroundImage:
                "url(" + require("assets/img/theme/pageNotFound.png") + ")",
              backgroundSize: "cover",
              backgroundPosition: "center top",
            }}></div>
          <div className='text-center'>
            <br />
            <h1>
              {" "}
              Either You Does'nt have Permission or Page Not Found. Please
              contact Twaddan Administration.
            </h1>
            <Button color='danger' onClick={this.navigate}>
              RETURN HOME
            </Button>
          </div>
        </Container>
      </>
    );
  }
}

export default PageNotFound;
