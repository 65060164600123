import React from "react";
import RealTime from "../../services/RealTime";
import firebase from "firebaseConfig";
import firebaseConfig from "../../firebaseConfig";
import moment from "moment";
import "moment-timezone";
import Select from "react-select";
import jsPDF from "jspdf";
import logo from "./twaddan-react.0a3a7e5e.jpg";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import Header from "components/Headers/Header.jsx";
import { Redirect } from "react-router-dom";
import StaticCard from "../../components/statisticsCard/StatisticsCard.jsx";
import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/ActualUser.css";
const columns = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Emirate",
    dataField: "emirate",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Completed Order",
    dataField: "completed",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Cancelled Order",
    dataField: "cancelled",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Total Orders",
    dataField: "total",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSorted = [
  {
    dataField: "Name",
    order: "desc",
  },
];
const columns1 = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Area",
    dataField: "area",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Completed Order",
    dataField: "completed",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Cancelled Order",
    dataField: "cancelled",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Total Orders",
    dataField: "total",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSorted1 = [
  {
    dataField: "Emirate",
    order: "desc",
  },
];
class OrderReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionID: "",
      popUp: false,
      emirates: [],
      loaderStatus: true,
      date_type: "year",
      years: [],
      year: "null",
      month: "null",
      week: "null",
      day: "null",
      submitStatus: true,
    };
  }

  async componentDidMount() {
    var d = new Date();
    var years = [];
    for (let index = 2020; index <= d.getFullYear(); index++) {
      years.push(index);
    }
    this.setState({ years: years });
  }
  submitStatus = () => {
    console.log(this.state.date_type, this.state.year);
    console.log(this.setStatus());
    this.setState({ submitStatus: this.setStatus() });
  };
  setStatus = () => {
    if (this.state.date_type === "year") return this.state.year === "null";
    if (this.state.date_type === "month")
      return this.state.month === "null" || this.state.year === "null";
    if (this.state.date_type === "week")
      return this.state.week === "null" || this.state.year === "null";
    if (this.state.date_type === "day")
      return (
        this.state.day === "null" ||
        this.state.year === "null" ||
        this.state.month === "null"
      );
  };
  dateValue = () => {
    if (this.state.date_type === "year") return this.state.year;
    if (this.state.date_type === "month")
      return this.state.year + "_" + this.state.month;
    if (this.state.date_type === "week")
      return this.state.year + "_" + this.state.week;
    if (this.state.date_type + "day")
      return this.state.year + "_" + this.state.month + "_" + this.state.day;
  };
  orderReport = async (e) => {
    this.setState({
      loaderStatus: false,
      emirateSelect: false,
      emirates: [],
    });
    let user = localStorage.getItem("admin_name");
    const axios = require("axios").default;
    var errors = false;
    let emirates = [];
    if (user) {
      const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/orderReport`;
      try {
        var reply = await axios.post(url, {
          type: this.state.date_type,
          value: this.dateValue(),
        });
      } catch (error) {
        console.log(error.response); // this is the main part. Use the response property from the error object
        errors = true;
      }
      if (errors) {
        this.orderReport();
      } else {
        var i = 0;
        Object.entries(reply.data).forEach(([key, emirate]) => {
          if (!(key === "cancelled" || key === "completed" || key === "date")) {
            i += 1;
            emirates.push({
              i: i,
              emirate: key,
              cancelled: emirate?.cancelled ? emirate?.cancelled : 0,
              completed: emirate?.completed ? emirate?.completed : 0,
              total:
                (emirate?.cancelled ? emirate?.cancelled : 0) +
                (emirate?.completed ? emirate?.completed : 0),
              data: emirate,
            });
          }
        });
        this.setState({
          data: reply.data,
          emirates: emirates,
          loaderStatus: true,
        });
      }
    }
  };

  toggleDetail = () => {
    this.setState({
      popUp: false,
    });
  };

  render() {
    const areas = [];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        var i = 0;
        Object.entries(row.data).forEach(([key, area]) => {
          if (!(key === "cancelled" || key === "completed" || key === "date")) {
            i += 1;
            areas.push({
              i: i,
              area: key,
              cancelled: area?.cancelled ? area?.cancelled : 0,
              completed: area?.completed ? area?.completed : 0,
              total:
                (area?.cancelled ? area?.cancelled : 0) +
                (area?.completed ? area?.completed : 0),
            });
          }
        });
        this.setState({
          emirateSelect: true,
          selectedEmirate: row,
          areas: areas,
        });
      },
    };
    return (
      <>
        <PageLoader loading={!this.state.loaderStatus}></PageLoader>
        <Header />
        <Container className="mt-1" fluid>
          <br />
          <Container fluid>
            <div className="header-body">
              <br></br>
              <Row>
                <Col md="9">
                  <h1>Order Report</h1>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <FormGroup>
                    <Label for="exampleSelect">Date Type</Label>
                    <Input
                      type="select"
                      onChange={(e) => {
                        this.setState(
                          {
                            date_type: e.target.value,
                            year: "null",
                            month: "null",
                            week: "null",
                            day: "null",
                          },
                          () => {
                            this.submitStatus();
                          }
                        );
                      }}
                      name="select"
                      id="exampleSelect"
                    >
                      <option value="year">Year</option>
                      <option value="month">Month</option>
                      <option value="week">Week</option>
                      <option value="day">Day</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="exampleSelect">Year</Label>
                    <Input
                      type="select"
                      onChange={(e) => {
                        this.setState({ year: e.target.value }, () => {
                          this.submitStatus();
                        });
                      }}
                      value={this.state.year}
                      name="year"
                      required
                      id="exampleyear"
                    >
                      <option value="null">Select</option>
                      {this.state.years.map((item) => {
                        return <option value={item}>{item}</option>;
                      })}
                    </Input>
                  </FormGroup>
                </Col>
                {(this.state.date_type === "month" ||
                  this.state.date_type === "day") && (
                  <Col md={2}>
                    <FormGroup>
                      <Label for="exampleSelect">Month</Label>
                      <Input
                        type="select"
                        onChange={(e) => {
                          this.setState({ month: e.target.value }, () => {
                            this.submitStatus();
                          });
                        }}
                        value={this.state.month}
                        name="month"
                        required
                        id="examplemonth"
                      >
                        <option value="null">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                {this.state.date_type === "week" && (
                  <Col md={2}>
                    <FormGroup>
                      <Label for="exampleSelect">Week</Label>
                      <Input
                        type="select"
                        onChange={(e) => {
                          this.setState({ week: e.target.value }, () => {
                            this.submitStatus();
                          });
                        }}
                        value={this.state.week}
                        required
                        name="week"
                        id="exampleweek"
                      >
                        <option value="null">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="44">44</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                        <option value="32">32</option>
                        <option value="33">33</option>
                        <option value="34">34</option>
                        <option value="35">35</option>
                        <option value="36">36</option>
                        <option value="37">37</option>
                        <option value="38">38</option>
                        <option value="39">39</option>
                        <option value="40">40</option>
                        <option value="41">41</option>
                        <option value="42">42</option>
                        <option value="43">43</option>
                        <option value="44">44</option>
                        <option value="45">45</option>
                        <option value="46">46</option>
                        <option value="47">47</option>
                        <option value="48">48</option>
                        <option value="49">49</option>
                        <option value="50">50</option>
                        <option value="51">51</option>
                        <option value="52">52</option>
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                {this.state.date_type === "day" && (
                  <Col md={2}>
                    <FormGroup>
                      <Label for="exampleSelect">Day</Label>
                      <Input
                        type="select"
                        onChange={(e) => {
                          this.setState({ day: e.target.value }, () => {
                            this.submitStatus();
                          });
                        }}
                        value={this.state.day}
                        required
                        name="day"
                        id="exampleday"
                      >
                        <option value="null">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="44">44</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                        <option value="24">24</option>
                        <option value="25">25</option>
                        <option value="26">26</option>
                        <option value="27">27</option>
                        <option value="28">28</option>
                        <option value="29">29</option>
                        <option value="30">30</option>
                        <option value="31">31</option>
                      </Input>
                    </FormGroup>
                  </Col>
                )}
                <Col md="2" mt={4}>
                  <Button
                    color="success"
                    disabled={this.state.submitStatus}
                    onClick={this.orderReport}
                    style={{ marginTop: "33px" }}
                  >
                    SUBMIT
                  </Button>
                </Col>
                {this.state.areas && this.state.emirateSelect && (
                  <Col md="2">
                    <Button
                      color="danger"
                      style={{ marginTop: "33px" }}
                      onClick={(e) => {
                        this.setState({ emirateSelect: false });
                      }}
                    >
                      BACK
                    </Button>
                  </Col>
                )}
              </Row>
              <Row>
                {this.state.emirates && !this.state.emirateSelect && (
                  <BootstrapTable
                    keyField="id"
                    data={this.state.emirates}
                    columns={columns}
                    filter={filterFactory()}
                    filterPosition="top"
                    pagination={paginationFactory()}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    hover
                    wrapperClasses="table-responsive"
                    condensed
                    rowEvents={rowEvents}
                  />
                )}
              </Row>
              <Row>
                {this.state.areas && this.state.emirateSelect && (
                  <BootstrapTable
                    keyField="id"
                    data={this.state.areas}
                    columns={columns1}
                    filter={filterFactory()}
                    filterPosition="top"
                    pagination={paginationFactory()}
                    defaultSorted={defaultSorted1}
                    bordered={false}
                    hover
                    wrapperClasses="table-responsive"
                    condensed
                  />
                )}
              </Row>
            </div>
          </Container>

          {
            <Container className="mt--7" fluid>
              <div className="h5 mt-8">
                <Row></Row>
              </div>
            </Container>
          }
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default OrderReport;
