import React from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Container,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  CardTitle,
  CardBody,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import Resizer from "react-image-file-resizer";

class AddNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      description_ar: "",
      downloadurl: "",
      file: "",
      imagePreviewUrl: "",
      link: "",
      modal: false,
      discount: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    this.setState({
      spemail: localStorage.getItem("email"),
    });
  }
  uploadimage = () => {
    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    id = id + ".png";
    console.log(id);
    var storageRef = firebase.storage().ref();
    var ImagesRef = storageRef.child("/twaddan_admin/notification/" + id);
    ImagesRef.put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })

      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadurl: downloadURL,
        });
        this.pushdata();
      })

      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  pushdata = () => {
    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    var ref = firebase.database().ref("twaddan_admin/notification/");
    ref.child(id).set({
      description: this.state.description,
      description_ar: this.state.description_ar,
      image: this.state.downloadurl,
      name: this.state.name,
      link: this.state.link,
    });
    this.toggle();
  };
  _handleImageChange(e) {
    e.preventDefault();
    Resizer.imageFileResizer(
      e.target.files[0],
      400,
      400,
      "PNG",
      100,
      0,
      (uri) => {
        console.log(uri);
        let reader = new FileReader();
        let file = uri;
        reader.onloadend = () => {
          this.setState({
            file: file,
            imagePreviewUrl: reader.result,
          });
        };

        reader.readAsDataURL(file);
      },
      "blob"
    );
  }
  handleSubmit(event) {
    console.log(this.state.file);
    this.uploadimage();
    event.preventDefault();
  }
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  getArabic = async (text, value) => {
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "ar",
        key: apiKey,
      },
    });
    console.log(reply.data.data.translations[0].translatedText);
    this.setState({
      [value]: reply.data.data.translations[0].translatedText,
    });
  };
  convert = () => {
    this.getArabic(this.state.description, "description_ar");
  };
  navigate = () => {
    this.setState({
      discount: 1,
    });
  };
  render() {
    if (this.state.discount == 1) {
      return <Redirect to='/admin/Notification' />;
    }
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=' mt--7' fluid>
          <Card>
            <CardHeader>
              <CardTitle>Add Notification</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='name'>Name</Label>
                      <Input
                        type='name'
                        required
                        name='name'
                        id='name'
                        value={this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                        placeholder='Name'
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <h3 style={{ color: "red" }}>
                    Please try to add image of 400x400 pixels
                  </h3>
                  <Label for='File'>Add image</Label>
                  <br></br>
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    required
                    onChange={this._handleImageChange}
                  />
                  <Row>
                    <Col md={4} xs={2}>
                      <div style={imagestyle}>{$imagePreview}</div>
                    </Col>
                  </Row>
                </FormGroup>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='description'>Description</Label>
                      <Input
                        type='text'
                        name='description'
                        required
                        id='description'
                        placeholder='Enter the decription'
                        value={this.state.description}
                        onChange={(e) => {
                          this.setState({ description: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for='description'>Description in Arabic</Label>
                      <Input
                        type='text'
                        name='description'
                        required
                        id='description'
                        placeholder='Enter the decription'
                        value={this.state.description_ar}
                        onChange={(e) => {
                          this.setState({ description_ar: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <FormGroup>
                      <Label for='description'> Link</Label>
                      <Input
                        type='text'
                        name='tnc'
                        required
                        id='tnc'
                        placeholder='Enter the Link'
                        value={this.state.link}
                        onChange={(e) => {
                          this.setState({ link: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br></br>
                <Button color='primary' onClick={this.convert}>
                  CONVERT TO ARABIC
                </Button>
                <br></br>
                <br></br>
                <Button onClick={this.navigate}>CANCEL</Button>{" "}
                <Button color='success' value='Submit'>
                  ADD
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation Notification added Successfully</ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default AddNotification;
