import React, { Component } from "react";
import "./SnackBar.css";

class SnackBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setState({ showSnackbar: this.props.showSnackbar });
  }
  // componentDidUpdate(prevprops) {
  //   if (this.state.showSnackbar)
  //     setTimeout(() => {
  //       this.hideSnackbar();
  //     }, 4000);
  // }
  hideSnackbar = () => {
    this.setState({ showSnackbar: false });
  };

  render() {
    const { snackbarMessage, color } = this.props;
    return this.state.showSnackbar ? (
      <div id="snackbar" style={{ backgroundColor: this.props.color }}>
        {snackbarMessage}
      </div>
    ) : null;
  }
}
export default SnackBar;
