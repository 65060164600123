import { Button, Row, Col } from "reactstrap";
import Select from "react-select";
import PageLoader from "components/PageLoader/PageLoader";
import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  withScriptjs,
  Circle,
} from "react-google-maps";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import { WhatsappShareButton } from "react-share";
import { WhatsappIcon } from "react-share";
import { compose, withProps } from "recompose";
import RealTime from "services/RealTime";

var greenIcon = "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
var redIcon = "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
    
//   "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAiklEQVR42mNgQIAoIF4NxGegdCCSHAMzEC+NUlH5v9rF5f+ZoCAwHaig8B8oPhOmKC1NU/P//7Q0DByrqgpSGAtSdOCAry9WRXt9fECK9oIUPXwYFYVV0e2ICJCi20SbFAuyG5uiECUlkKIQmOPng3y30d0d7Lt1bm4w301jQAOgcNoIDad1yOEEAFm9fSv/VqtJAAAAAElFTkSuQmCC";
class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      modal: false,
      LocationList: [],
      isInfoWindoOpen: false,
      isHeatMapOpen: false,
      isMarkerShown: true,
      infoWindowPosition: "",
      center: { lat: 24.39059670988973, lng: 54.60693744656942 },
        zoomLevel: 8,
        selectedSpName: "SELECT SERVICE PROVIDER",
        spList:[]
    };
    this.handleHeatMapMode = this.handleHeatMapMode.bind(this);
  }
  async componentDidMount() {
      await this.getData();
    await  this.handleSpSelect("all" ,  "SELECT SERVICE PROVIDER");
  }
  getData = async () => {
    this.setState({
      isloaded: false,
    });
    let spList = [{ value: "all", label: "All" }];
    let sps = await RealTime.getDetails(`service_providers`);
    for (const key in sps) {
      if (sps[key].personal_information.name)
        spList.push({ value: key, label: sps[key].personal_information.name });
    }

    this.setState({
      spList: spList,
      sps: sps,
      isloaded: true,
    });
  };
  handleSpSelect = async (spId , name) => {
     let allDriverData = [];
    let driverList = [];
    let locationList = [];
    let driverIds = {};
      this.setState({
      selectedSpName:name,
      selectedSpId: null,
      isloaded: false,
      LocationList: [],
      position: null,
      isInfoWindoOpen: false,
      center: { lat: 24.39059670988973, lng: 54.60693744656942 },
      zoomLevel: 8,
    });
    if (spId === "all") {
      driverIds = await RealTime.getDetails(`drivers`);
    } else {
      driverIds = await RealTime.getDetails(
        `service_providers/${spId}/drivers_info/drivers_id`
      );
    }
      for (const key in driverIds) {
        let driverData
          if (spId === "all") driverData = driverIds[key];
            else 
         driverData = await RealTime.getDetails(`drivers/${key}/`);
            driverData["id"] = key;
            allDriverData.push(driverData);
      }
    for (const key in allDriverData) {
         driverList.push({
        value: allDriverData[key].id,
        label: allDriverData[key].personal_information.name,
      });
      locationList.push({
        id: allDriverData[key].id,
        lat: allDriverData[key].live_location.latitude,
        lng: allDriverData[key].live_location.longitude,
        driverName: allDriverData[key].personal_information.name,
        driverPhone: allDriverData[key].personal_information.phone_number,
        spName: await RealTime.getDetails(`service_providers/${allDriverData[key].sp_related[0].sp_id}/personal_information/name`),
        status: allDriverData[key].status === 1 ? true : false,
       });
     
    }
     
    console.log(locationList);
    this.setState({
      selectedSpId: spId,
      isloaded: true,
      driverList: driverList,
      allDriverData: allDriverData,
      LocationList: locationList,
      selectedSpName: name,
    });
  };
  handleDriverSelect = async (driverId) => {
    let LocationList = this.state.LocationList;
    LocationList.map((driver) => {
      if (driver.id === driverId) {
        this.handleMarkerClick(driver);
      }
    });
  };
  handleMarkerClick = (location) => {
    this.setState({
      isInfoWindoOpen: true,
      position: location,
      center: { lat: location.lat, lng: location.lng },
      zoomLevel: 15,
    });
  };
  handleHeatMapMode = () => {
    this.setState({
      isHeatMapOpen: !this.state.isHeatMapOpen,
      isInfoWindoOpen: false,
      center: { lat: 24.39059670988973, lng: 54.60693744656942 },
      zoomLevel: 8,
    });
    this.getAllOrderDetails();
  };
  render() {
    const GoogleHeatMap = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&libraries=visualization",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `550px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={this.state.zoomLevel}
        zoom={this.state.zoomLevel}
        defaultCenter={this.state.center}
        onClick={props.onMapClick}
        center={this.state.center}
      >
        {this.state.LocationList &&
          this.state.LocationList.map((location) => {
            return (
              <>
                <Marker
                  key={this.state.LocationList.indexOf(location)}
                  position={{ lat: location.lat, lng: location.lng }}
                  icon={location.status? greenIcon : redIcon}
                  onClick={() => {
                    this.handleMarkerClick(location);
                  }}
                ></Marker>
              </>
            );
          })}

        {this.state.isInfoWindoOpen && !this.state.isHeatMapOpen && (
          <InfoWindow position={this.state.position} maxWidth={`200px`}>
            <>
              <h3>{this.state.position.driverName}</h3>
              <b>Mobile :</b>
              <small>{this.state.position.driverPhone}</small>
              <br />
              <b>SP :</b>
              <small>{this.state.position.spName}</small>
              <br />
              <b>Location :</b>
              <a
                target="_blank"
                href={`https://maps.google.com/?q=${this.state.position.lat},${this.state.position.lng}`}
              >
                View in google map
              </a>
              <br />
            </>
          </InfoWindow>
        )}
      </GoogleMap>
    ));

    return (
      <div>
        <PageLoader loading={!this.state.isloaded} />

        {this.state.isloaded && (
          <>
            <Row className="tm-7">
              <Col sm={12} md={4} lg={4}>
                <Select
                    id={"sp"}
                    onChange={(sp) => {
                        this.handleSpSelect(sp.value , sp.label);
                    }}
                    value={this.state.selectedSpName}
                    isSearchable={true}
                    options={this.state.spList}
                    placeholder={`${this.state.selectedSpName}`}
                />
              </Col>
              <Col sm={12} md={4} lg={4}>
                {this.state.selectedSpId && (
                                <Select
                                id={"driver"}
                    onChange={(driver) => {
                      this.handleDriverSelect(driver.value);
                    }}
                    isSearchable={true}
                    options={this.state.driverList}
                    placeholder={"SELECT DRIVER"}
                  />
                )}
              </Col>
            </Row>
            <GoogleHeatMap
              // isMarkerShown={this.state.isMarkerShown}
              latLong={this.state.center}
              zoomLevel={this.state.zoomLevel}
              onMarkerClick={this.handleMarkerClick}
              onMapClick={this.handleMapClick}
            />
          </>
        )}
        {this.state.LocationList.length > 0 && this.state.isloaded && (
          <>
            {this.state.isInfoWindoOpen && this.state.position && (
              <Button style={{ backgroundColor: "#5CD354", color: "#ffffff" }}>
                <WhatsappShareButton
                  title={`Location of *${this.state.position.driverName}*(${this.state.position.driverPhone})`}
                  separator=" Click link:  "
                  url={`https://maps.google.com/?q=${this.state.position.lat},${this.state.position.lng}`}
                >
                  <WhatsappIcon size={25}></WhatsappIcon> DRIVER LOCATION
                </WhatsappShareButton>
              </Button>
            )}
          </>
        )}
      </div>
    );
  }
}
export default HeatMap;
