import { Button } from "reactstrap";
import Header from "components/Headers/Header";
import PageLoader from "components/PageLoader/PageLoader";
import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  withScriptjs,
  Circle,
} from "react-google-maps";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import { WhatsappShareButton } from "react-share";
import { WhatsappIcon } from "react-share";
import { compose, withProps } from "recompose";
import RealTime from "services/RealTime";
const mapData = [
  { position: " 25.782,  55.447 ", weight: 0.5 },
  { position: { lat: 25.782, lng: 55.443 }, weight: 2 },
  { position: { lat: 25.782, lng: 55.441 }, weight: 3 },
  { position: { lat: 25.782, lng: 55.439 }, weight: 2 },
  { position: { lat: 25.782, lng: 55.435 }, weight: 0.5 },
  { position: { lat: 25.785, lng: 55.447 }, weight: 3 },
  { position: { lat: 25.785, lng: 55.445 }, weight: 2 },
  { position: { lat: 25.785, lng: 55.441 }, weight: 0.5 },
  { position: { lat: 25.785, lng: 55.425 }, weight: 2 },
  { position: { lat: 25.785, lng: 55.435 }, weight: 3 },
];
const gradient = [
  "rgba(0, 255, 255, 0)",
  "rgba(0, 255, 255, 1)",
  "rgba(0, 191, 255, 1)",
  "rgba(0, 127, 255, 1)",
  "rgba(0, 63, 255, 1)",
  "rgba(0, 0, 255, 1)",
  "rgba(0, 0, 223, 1)",
  "rgba(0, 0, 191, 1)",
  "rgba(0, 0, 159, 1)",
  "rgba(0, 0, 127, 1)",
  "rgba(63, 0, 91, 1)",
  "rgba(127, 0, 63, 1)",
  "rgba(191, 0, 31, 1)",
  "rgba(255, 0, 0, 1)",
];
var image =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAiklEQVR42mNgQIAoIF4NxGegdCCSHAMzEC+NUlH5v9rF5f+ZoCAwHaig8B8oPhOmKC1NU/P//7Q0DByrqgpSGAtSdOCAry9WRXt9fECK9oIUPXwYFYVV0e2ICJCi20SbFAuyG5uiECUlkKIQmOPng3y30d0d7Lt1bm4w301jQAOgcNoIDad1yOEEAFm9fSv/VqtJAAAAAElFTkSuQmCC";
class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: true,
      modal: false,
      LocationList: [],
      isInfoWindoOpen: false,
      isHeatMapOpen: false,
      isMarkerShown: true,
      infoWindowPosition: "",
      center: { lat: 24.39059670988973, lng: 54.60693744656942 },
      zoomLevel: 8,
    };
    this.handleHeatMapMode = this.handleHeatMapMode.bind(this);
  }
  async componentDidMount() {
    this.getActiveOrderDetails();
  }
  getActiveOrderDetails = async () => {
    this.setState({
      isloaded: false,
      isHeatMapOpen: false,
    });
    let LocationList = [];
    const activeOrders = await RealTime.getDetails(`orders/active_orders`);
    if (activeOrders) {
      Object.entries(activeOrders).forEach(async ([keyOrder, order]) => {
        if (keyOrder) {
          const orderDetails = await RealTime.getDetails(
            `orders/all_orders/${keyOrder}`
          );
          if (orderDetails) {
            LocationList.push({
              lat: orderDetails.customer_latitude,
              lng: orderDetails.customer_longitude,
              customer_name_english: orderDetails.customer_name_english,
              customer_mobile_number: orderDetails.customer_mobile_number,
              sp_name_english: orderDetails.sp_name_english,
            });
          }
          this.setState({
            LocationList: LocationList,
          });
        }
      });
    }
    this.setState({
      LocationList: LocationList,
      isloaded: true,
    });
  };
  getAllOrderDetails = async () => {
    this.setState({
      isloaded: false,
      isHeatMapOpen: true,
    });
    let LocationList = [];
    const activeOrders = await RealTime.getDetails(`orders/all_orders`);
    if (activeOrders) {
      Object.entries(activeOrders).forEach(async ([keyOrder, order]) => {
        if (order) {
          LocationList.push({
            lat: order.customer_latitude,
            lng: order.customer_longitude,
            customer_name_english: order.customer_name_english,
            customer_mobile_number: order.customer_mobile_number,
            sp_name_english: order.sp_name_english,
          });
        }
      });
    }
    this.setState({
      LocationList: LocationList,
      isloaded: true,
    });
  };
  handleMarkerClick = (location) => {
    if (!this.state.isHeatMapOpen) {
      this.setState({
        isInfoWindoOpen: true,
        position: location,
        center: { lat: location.lat, lng: location.lng },
        zoomLevel: 15,
      });
    }
  };
  handleHeatMapMode = () => {
    this.setState({
      isHeatMapOpen: !this.state.isHeatMapOpen,
      isInfoWindoOpen: false,
      center: { lat: 24.39059670988973, lng: 54.60693744656942 },
      zoomLevel: 8,
    });
    this.getAllOrderDetails();
  };
  render() {
    const GoogleHeatMap = compose(
      withProps({
        googleMapURL:
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&libraries=visualization",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `600px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
      }),
      withScriptjs,
      withGoogleMap
    )((props) => (
      <GoogleMap
        defaultZoom={this.state.zoomLevel}
        zoom={this.state.zoomLevel}
        defaultCenter={this.state.center}
        onClick={props.onMapClick}
        center={this.state.center}
      >
        {this.state.isMarkerShown &&
          this.state.LocationList.map((location) => {
            return (
              <>
                <Marker
                  key={this.state.LocationList.indexOf(location)}
                  position={{ lat: location.lat, lng: location.lng }}
                  icon={this.state.isHeatMapOpen ? image : ""}
                  onClick={() => {
                    this.handleMarkerClick(location);
                  }}
                ></Marker>
              </>
            );
          })}

        {this.state.isInfoWindoOpen && !this.state.isHeatMapOpen && (
          <InfoWindow position={this.state.position} maxWidth={`200px`}>
            <>
              <h3>{this.state.position.customer_name_english}</h3>
              <b>Mobile :</b>
              <small>{this.state.position.customer_mobile_number}</small>
              <br />
              <small>{this.state.position.sp_name_english}</small>
            </>
          </InfoWindow>
        )}
      </GoogleMap>
    ));

    return (
      <div>
        <PageLoader loading={!this.state.isloaded} />
        {this.state.LocationList.length > 0 && this.state.isloaded && (
          <GoogleHeatMap
            // isMarkerShown={this.state.isMarkerShown}
            latLong={this.state.center}
            zoomLevel={this.state.zoomLevel}
            onMarkerClick={this.handleMarkerClick}
            onMapClick={this.handleMapClick}
          />
        )}
        {this.state.LocationList.length > 0 && this.state.isloaded && (
          <>
            {/* <Button
              color={this.state.isHeatMapOpen ? "" : "primary"}
              onClick={this.getActiveOrderDetails}
            >
              ACTIVE ORDER MAP
            </Button> */}
            {/* <Button
              color={this.state.isHeatMapOpen ? "primary" : ""}
              onClick={this.handleHeatMapMode}
            >
              HEAT MAP
            </Button> */}
            {this.state.isInfoWindoOpen && this.state.position && (
              <Button style={{ backgroundColor: "#5CD354", color: "#ffffff" }}>
                <WhatsappShareButton
                  title={`Location of *${this.state.position.customer_name_english}*(${this.state.position.customer_mobile_number})`}
                  separator=" Click link:  "
                  url={`https://maps.google.com/?q=${this.state.position.lat},${this.state.position.lng}`}
                >
                  <WhatsappIcon size={25}></WhatsappIcon> USER LOCATION
                </WhatsappShareButton>
              </Button>
            )}
          </>
        )}
        {this.state.LocationList.length === 0 && this.state.isloaded && (
          <h1>NO ACTIVE ORDERS</h1>
        )}
      </div>
    );
  }
}
export default HeatMap;
