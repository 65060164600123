import React from "react";
import RealTime from "../../services/RealTime";
import firebase from "firebaseConfig";
import firebaseConfig from "../../firebaseConfig";
import moment from "moment";
import "moment-timezone";
import Select from "react-select";
import jsPDF from "jspdf";
import logo from "./twaddan-react.0a3a7e5e.jpg";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import Header from "components/Headers/Header.jsx";
import { Redirect } from "react-router-dom";
import StaticCard from "../../components/statisticsCard/StatisticsCard.jsx";
import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/ActualUser.css";
const columns = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Name",
    dataField: "name",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Phone Number",
    dataField: "phoneNumber",
    footer: false,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {
      minWidth: "300px",
    },
  },
  {
    text: "Email",
    dataField: "email",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Emirate",
    dataField: "emirate",
    align: "center",
    headerAlign: "center",
    sort: true,
    filter: textFilter(),
    footerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Area",
    dataField: "area",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Cancelled Order",
    dataField: "totalCancelled",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Total Orders",
    dataField: "totalOrders",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Total Price",
    dataField: "totalPrice",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSorted = [
  {
    dataField: "Name",
    order: "desc",
  },
];
const details = (text, data) => {
  return (
    <>
      <Card className="cards">
        <CardBody>
          <Row>
            <Col md="3">
              <p>
                <b>{text}</b>
              </p>
            </Col>
            <Col md="9">
              <p>
                {data.map((item) => {
                  return item + ",";
                })}
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
const detailsText = (text, data) => {
  return (
    <>
      <Card className="cards">
        <CardBody>
          <Row>
            <Col md="3">
              <p>
                <b>{text}</b>
              </p>
            </Col>
            <Col md="9">
              <p>{data}</p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
class ActualUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptionID: "",
      popUp: false,
      emirates: [],
      isLoaded: false,
    };
  }
  getUsers = async (users) => {
    // const users = await firebase.functions().httpsCallable("getUserReport");
    console.log("user", users);
    let allUsers = [];
    let i = 1;
    if (users) {
      Object.entries(users).forEach(([key, user]) => {
        allUsers.push({
          i: i++,
          id: key,
          name: user.names[0] + ",....",
          names: user.names,
          phoneNumber: user.number[0],
          phoneNumbers: user.number,
          email: user.emails[0] + ",....",
          emails: user.emails,
          area: user.areas[0] + ",....",
          areas: user.areas,
          emirate: user.emirates[0] + ",....",
          emirates: user.emirates,
          totalCancelled: user.totalCancelled,
          totalOrders: user.totalOrders,
          totalPrice: user.totalPrice,
        });
      });
    }
    // allUsers.map(obj => (obj.i = allUsers.length - obj.i + 1));
    return allUsers;
  };
  getEmirates = async () => {
    let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");
    let newEmirates = [];
    newEmirates.push({
      label: "All",
      value: "All",
    });
    Object.entries(emirates).forEach(([key, emirate]) => {
      newEmirates.push({
        label: emirate.name,
        value: emirate.name,
      });
    });
    return newEmirates;
  };

  async componentDidMount() {
    let user = localStorage.getItem("admin_name");
    const axios = require("axios").default;

    let users = await RealTime.getDetails("/Users/");
    let allUsers = [];
    let emirates;
    if (user) {
      const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/getUserReport`;
      const reply = await axios.get(url);
      console.log("reply", reply);
      allUsers = await this.getUsers(reply.data);
      emirates = await this.getEmirates();
      this.setState({
        users: reply.data,
        totalUser: reply.data.length,
        allUsers: allUsers,
        emirates: emirates,
        isLoaded: true,
      });
    }
  }
  toggleDetail = () => {
    this.setState({
      popUp: false,
    });
  };
  pdfGenerator = async () => {
    const doc = new jsPDF("l");
    let outerArray = [];
    let i = 0;
    for (var keyOut in this.state.allUsers) {
      let elementOut = await this.state.allUsers[keyOut];
      let innerArray = [];
      for (var keyIn in elementOut)
        if (
          !(
            keyIn === "id" ||
            keyIn === "name" ||
            keyIn === "phoneNumber" ||
            keyIn === "email" ||
            // keyIn === "i" ||
            // keyIn === "totalCancelled" ||
            keyIn === "emirate" ||
            keyIn === "area"
          )
        ) {
          if (
            keyIn === "names" ||
            keyIn === "phoneNumbers" ||
            keyIn === "emails" ||
            keyIn === "emirates" ||
            keyIn === "areas"
          ) {
            console.log("elementOut[keyIn]", elementOut[keyIn]);

            let val = "";
            elementOut[keyIn].map((item) => {
              val = val + item + ", ";
            });
            console.log("val", val);

            innerArray.push(val);
          } else {
            innerArray.push(elementOut[keyIn]);
          }
        }
      outerArray.push(innerArray);
    }
    var header = function (data) {
      doc.setFontSize(10);
      doc.addImage(logo, "JPEG", 13, 3, 32, 12);
      doc.setLineWidth(1);
      doc.setDrawColor(62, 142, 222);
      doc.line(14, 14, 281, 14);
      doc.text("Actual Users Report", 14, 19);
      //   doc.text(this.state.allUsers.length, 180, 19);
    };

    var options = {
      beforePageContent: header,
      margin: {
        top: 100,
      },
      startY: doc.autoTableEndPosY() + 20,
    };
    doc.autoTable([], [], options);
    doc.autoTable({
      columnStyles: {
        0: { halign: "left", cellWidth: 10 },
        1: { halign: "left", cellWidth: 50 },
        2: { halign: "left", cellWidth: 30 },
        3: { halign: "left", cellWidth: 47 },
        4: { halign: "left", cellWidth: 55 },
        5: { halign: "left", cellWidth: 30 },
        6: { halign: "left", cellWidth: 15 },
        7: { halign: "left", cellWidth: 15 },
        8: { halign: "left", cellWidth: 15 },
      },
      head: [
        [
          "SL.No",
          "NAMES",
          "PHONE NUMBER",
          "EMAILS",
          "AREAS",
          "EMIRATES",
          "CANCELLED ORDER",
          "TOTAL ORDERS",
          "TOTAL PRICE",
        ],
      ],
      body: outerArray,
    });
    // var option2 = {
    //   columnStyles: {
    //     0: { valign: "top" },
    //     1: {
    //       halign: "center",
    //     },
    //     2: {
    //       halign: "right",
    //     },
    //   },
    //   headStyles: { halign: "right" },
    //   willDrawCell: (data) => {
    //     if (data.section === "head" && data.column.index === 0) {
    //       data.halign = "left";
    //       data.cell.halign = "left";
    //       data.cell.styles.halign = "left";
    //       data.column.halign = "left";
    //     }
    //   },
    // };
    // doc.autoTable(res.columns, res.data, option2);

    doc.save("Actual user report.pdf");
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          popUp: true,
          user: row,
          userId: row.id,
        });
      },
    };
    return (
      <>
        <PageLoader loading={!this.state.isLoaded}></PageLoader>
        <Header />
        <Container className="mt-1" fluid>
          <br />
          <Container fluid>
            <div className="header-body">
              <br></br>
              <Row>
                <Col md="9">
                  <h1>Actual Users List - {this.state.totalUser}</h1>
                </Col>
                <Col md="">
                  <Button className="printButton" onClick={this.pdfGenerator}>
                    {" "}
                    PDF Report
                  </Button>
                </Col>
              </Row>

              <div>
                {this.state.allUsers && (
                  <BootstrapTable
                    keyField="id"
                    data={this.state.allUsers}
                    columns={columns}
                    filter={filterFactory()}
                    filterPosition="top"
                    pagination={paginationFactory()}
                    defaultSorted={defaultSorted}
                    bordered={false}
                    hover
                    wrapperClasses="table-responsive"
                    condensed
                    rowEvents={rowEvents}
                  />
                )}
              </div>
            </div>
          </Container>
          <Modal isOpen={this.state.popUp} toggle={this.toggleDetail} size="lg">
            <ModalHeader toggle={this.toggleDetail}>
              <h1>User Details</h1>
            </ModalHeader>
            <ModalBody>
              <Container fluid>
                {this.state.user && (
                  <>
                    {details("Name", this.state.user.names)}
                    {details("Phone Number", this.state.user.phoneNumbers)}
                    <Card style={{ marginTop: "3px" }}>
                      <CardBody>
                        <Row>
                          <Col md="3">
                            <p>
                              <b>Email</b>
                            </p>
                          </Col>
                          <Col md="9">
                            {this.state.user.emails.map((item) => {
                              return <p>{item} </p>;
                            })}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    {details("Emirates", this.state.user.emirates)}
                    {details("Areas", this.state.user.areas)}
                    {detailsText(
                      "Cancelled Order",
                      this.state.user.totalCancelled
                    )}
                    {detailsText("Total Order", this.state.user.totalOrders)}
                    {detailsText("Total Price", this.state.user.totalPrice)}
                  </>
                )}
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.toggleDetail} color="danger">
                CLOSE
              </Button>
            </ModalFooter>
          </Modal>
          {
            <Container className="mt--7" fluid>
              <div className="h5 mt-8">
                <Row></Row>
              </div>
            </Container>
          }
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default ActualUsers;
