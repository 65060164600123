import React from 'react';
import RealTime from '../../services/RealTime';
import moment from 'moment';
import 'moment-timezone';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Container, Row, Col, Input, FormGroup } from 'reactstrap';
import Header from 'components/Headers/Header.jsx';
import { Redirect } from 'react-router-dom';
import StaticCard from '../../components/statisticsCard/StatisticsCard.jsx';
import PageLoader from '../../components/PageLoader/PageLoader';

const columns = [
    {
        text: 'Si No',
        dataField: 'i',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Name',
        dataField: 'name',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        footer: false,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Phone Number',
        dataField: 'phoneNumber',
        footer: false,
        align: 'center',
        filter: textFilter(),
        headerAlign: 'center',
        sort: true,
        headerStyle: {
            backgroundColor: '#00acf3',
        },
        style: {
            minWidth: '300px',
        },
    },
    {
        text: 'Email',
        dataField: 'email',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Type Of User',
        dataField: 'typeOfUser',
        footer: false,
        sort: true,
        align: 'center',
        filter: textFilter(),
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
        style: {},
    },
    {
        text: 'Emirate',
        dataField: 'emirate',
        align: 'center',
        headerAlign: 'center',
        sort: true,
        filter: textFilter(),
        footerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Number of Order',
        dataField: 'numberOfOrder',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Date Of Registration',
        dataField: 'dateOfRegString',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Language',
        dataField: 'language',
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'OS',
        dataField: 'os',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
    {
        text: 'Device Name',
        dataField: 'deviceName',
        footer: false,
        sort: true,
        align: 'center',
        headerAlign: 'center',
        filter: textFilter(),
        headerStyle: {
            backgroundColor: '#00acf3',
        },
    },
];
const defaultSorted = [
    {
        dataField: 'Name',
        order: 'desc',
    },
];
class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            descriptionID: '',
            modal: false,
            searchBarID: '',
            totalOrderID: Number(0),
            totalActiveOrderID: Number(0),
            totalPastOrderID: Number(0),
            totalUsers: Number(0),
            totalRegUsers: Number(0),
            totalActiveRegUsers: Number(0),
            totalGuestUsers: Number(0),
            totalActiveGuestUsers: Number(0),
            emirates: [],
            isLoaded: false,
            daysOfActive: 7,
            daysOfActiveList: [
                { label: '1 Days', value: 1 },
                { label: '2 Days', value: 2 },
                { label: '7 Days', value: 7 },
                { label: '15 Days', value: 15 },
                { label: '25 Days', value: 25 },
                { label: '30 Days', value: 30 },
                { label: '6 Month', value: 182 },
                { label: '1 Year', value: 365 },
            ],
        };
    }
    getUsers = async (users) => {
        let allUsers = [];
        let i = 1;
        let savedAddressLength = 0;
        let emirate = '';
        // let users = await RealTime.getDetails("/Users/");
        let pastOrder = 0;
        let activeOrder = 0;
        if (users) {
            Object.entries(users).forEach(([key, user]) => {
                savedAddressLength = user.saved_address !== undefined ? Object.keys(user.saved_address).length : 0;
                if (savedAddressLength !== 0) {
                    var prop = Object.keys(user.saved_address)[savedAddressLength - 1];
                    emirate = user.saved_address[prop].emirate !== undefined ? user.saved_address[prop].emirate : 'Nil';
                }
                pastOrder = user.past_orders !== undefined ? Object.keys(user.past_orders.order_ids).length : 0;
                activeOrder = user.active_orders !== undefined ? Object.keys(user.active_orders.order_ids).length : 0;
                var date1 = new Date();
                var lopt =
                    user.personal_details !== undefined
                        ? user.personal_details.LOPT !== undefined
                            ? user.personal_details.LOPT
                            : null
                        : null;
                // To calculate the time difference of two dates
                var Difference_In_Time = date1.getTime() - lopt;
                var Difference_In_Days = null;
                // To calculate the no. of days between two dates
                if (lopt !== null) {
                    Difference_In_Days = parseInt(Difference_In_Time / (1000 * 3600 * 24));
                }
                console.log('user ID:', key);

                allUsers.push({
                    i: i++,
                    id: key,
                    name:
                        user.personal_details && user.personal_details !== undefined && user.personal_details !== null
                            ? user.personal_details.name &&
                              user.personal_details.name !== undefined &&
                              user.personal_details.name !== null &&
                              user.personal_details.lastName &&
                              user.personal_details.lastName !== undefined &&
                              user.personal_details.lastName !== null
                                ? user.personal_details.name.concat(user.personal_details.lastName)
                                : user.personal_details.autofill_data &&
                                  user.personal_details.autofill_data !== undefined &&
                                  user.personal_details.autofill_data !== null
                                ? user.personal_details.autofill_data.first_name &&
                                  user.personal_details.autofill_data.first_name !== undefined &&
                                  user.personal_details.autofill_data.first_name !== null &&
                                  user.personal_details.autofill_data.last_name &&
                                  user.personal_details.autofill_data.last_name !== undefined &&
                                  user.personal_details.autofill_data.last_name !== null
                                    ? user.personal_details.autofill_data.first_name.concat(
                                          user.personal_details.autofill_data.last_name
                                      )
                                    : 'nill'
                                : 'Nil'
                            : 'Nil',
                    phoneNumber:
                        user.personal_details !== undefined
                            ? user.personal_details.phoneNumber !== undefined
                                ? user.personal_details.phoneNumber
                                : user.personal_details.autofill_data !== undefined
                                ? user.personal_details.autofill_data.phoneNumber
                                : 'Nil'
                            : 'Nil',

                    email:
                        user.personal_details !== undefined
                            ? user.personal_details.email !== undefined && user.personal_details.email !== ''
                                ? user.personal_details.email
                                : user.personal_details.autofill_data !== undefined &&
                                  user.personal_details.autofill_data.email
                                ? user.personal_details.autofill_data.email
                                : 'Nil'
                            : 'Nil',
                    typeOfUser:
                        user.personal_details !== undefined
                            ? user.personal_details.guest !== undefined
                                ? user.personal_details.guest
                                    ? Difference_In_Days !== null && Difference_In_Days <= this.state.daysOfActive
                                        ? 'Active Guest user'
                                        : 'Guest user'
                                    : Difference_In_Days !== null && Difference_In_Days <= this.state.daysOfActive
                                    ? 'Active Registered user'
                                    : 'Registered user'
                                : 'Nil'
                            : 'Nil',
                    emirate: emirate,
                    numberOfOrder: pastOrder + activeOrder,
                    dateOfReg:
                        user.personal_details !== undefined
                            ? user.personal_details.registeredDate != undefined
                                ? moment(user.personal_details.registeredDate)
                                      .tz('Asia/Dubai')
                                      .format('DD/MM/YYYY hh:mm a')
                                : 'Nil'
                            : 'Nil',
                    dateOfRegString:
                        user.personal_details !== undefined
                            ? user.personal_details.registeredDate != undefined
                                ? moment(user.personal_details.registeredDate)
                                      .tz('Asia/Dubai')
                                      .format('DD/MM/YYYY hh:mm a')
                                : 'Nil'
                            : 'Nil',
                    os:
                        user.personal_details !== undefined
                            ? user.personal_details.lastLoggedInDevice != undefined
                                ? user.personal_details.Devices != undefined
                                    ? user.personal_details.Devices[user.personal_details.lastLoggedInDevice]?.os
                                    : 'Nil'
                                : 'Nil'
                            : 'Nil',
                    language:
                        user.personal_details !== undefined
                            ? user.personal_details.lastLoggedInDevice != undefined
                                ? user.personal_details.Devices != undefined
                                    ? user.personal_details.Devices[user.personal_details.lastLoggedInDevice]?.language
                                    : 'Nil'
                                : 'Nil'
                            : 'Nil',
                    deviceName:
                        user.personal_details !== undefined
                            ? user.personal_details.lastLoggedInDevice != undefined
                                ? user.personal_details.Devices != undefined
                                    ? user.personal_details.Devices[user.personal_details.lastLoggedInDevice]
                                          ?.deviceName
                                    : 'Nil'
                                : 'Nil'
                            : 'Nil',
                });
            });
        }
        // allUsers.map(obj => (obj.i = allUsers.length - obj.i + 1));
        return allUsers;
    };
    dateRangeHandler = async (event, picker) => {
        picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
        let i = 0;
        let users = await this.state.allUsers.filter(function (user) {
            return (
                moment(user.dateOfReg) >= picker.startDate.toDate() && moment(user.dateOfReg) <= picker.endDate.toDate()
            );
        });
        let newUser = [];
        users.filter(function (odr) {
            var user = Object.assign({}, odr);
            i++;
            user.i = i;
            newUser.push(user);
        });
        this.setState(
            {
                selectedEmirate: { label: 'All', value: 'All' },
                allUsersByDate: newUser,
                allUsersByDateAndEmirate: newUser,
            },
            () => {
                this.setCount();
            }
        );
    };
    handleCancel = (event, picker) => {
        picker.element.val('');
        // this.clearFilter();
    };
    handleDaysActiveUser = (event) => {
        let allUsers = [];
        let emirates;
        this.setState({ daysOfActive: event.target.value }, async () => {
            if (this.state.users) {
                allUsers = await this.getUsers(this.state.users);
                this.setState({
                    allUsers: allUsers,
                    allUsersByDate: allUsers,
                    allUsersByDateAndEmirate: allUsers,
                });
                this.setCount();
            }
        });

        console.log('event', event);
        console.log('event', event.target.value);
    };

    handleEmirateChange = async (event) => {
        var users = [];
        let i = 0;
        if (event.target.value === 'All') {
            users = this.state.allUsers;
        } else {
            let i = 0;
            users = await this.state.allUsersByDate.filter(function (user) {
                return user.emirate == event.target.value;
            });
        }
        let newUser = [];
        users.filter(function (odr) {
            var user = Object.assign({}, odr);
            i++;
            user.i = i;
            newUser.push(user);
        });
        this.setState(
            {
                selectedEmirate: event.value,
                allUsersByDateAndEmirate: newUser,
            },
            () => {
                this.setCount();
            }
        );
    };

    setCount = () => {
        let totalUsers = this.state.allUsersByDateAndEmirate.length;
        let totalRegUsers = 0;
        let totalGuestUsers = 0;
        let totalActiveRegUsers = 0;
        let totalActiveGuestUsers = 0;
        this.state.allUsersByDateAndEmirate.map((user) => {
            totalRegUsers += user.typeOfUser === 'Registered user' ? 1 : 0;
            totalGuestUsers += user.typeOfUser === 'Guest user' ? 1 : 0;
            totalActiveGuestUsers += user.typeOfUser === 'Active Guest user' ? 1 : 0;
            totalActiveRegUsers += user.typeOfUser === 'Active Registered user' ? 1 : 0;
        });
        this.setState({
            totalUsers: totalUsers,
            totalRegUsers: totalRegUsers + totalActiveRegUsers,
            totalGuestUsers: totalGuestUsers + totalActiveGuestUsers,
            totalActiveGuestUsers: totalActiveGuestUsers,
            totalActiveRegUsers: totalActiveRegUsers,
        });
    };
    getEmirates = async () => {
        let emirates = await RealTime.getDetails('/twaddan_admin/emirates/');
        let newEmirates = [];
        newEmirates.push({
            label: 'All',
            value: 'All',
        });
        await Object.entries(emirates).forEach(([key, emirate]) => {
            newEmirates.push({
                label: emirate.name,
                value: emirate.name,
            });
        });
        return newEmirates;
    };

    async componentDidMount() {
        let user = localStorage.getItem('admin_name');
        console.log('user', user);
        let users = await RealTime.getDetails('/Users/');

        let allUsers = [];
        let emirates;
        if (user) {
            allUsers = await this.getUsers(users);
            emirates = await this.getEmirates();
            this.setState({
                users: users,
                allUsers: allUsers,
                allUsersByDate: allUsers,
                allUsersByDateAndEmirate: allUsers,
                emirates: emirates,
                isLoaded: true,
            });
            this.setCount();
        }
    }
    render() {
        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: '/admin/userdetails',
                        state: { userId: this.state.userId },
                    }}
                />
            );
        }
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({
                    redirect: true,
                    user: row,
                    userId: row.id,
                });
            },
        };
        return (
            <>
                <PageLoader loading={!this.state.isLoaded}></PageLoader>
                <Header />
                <Container className="mt--7" fluid>
                    <br />
                    <Container fluid>
                        <div className="header-body">
                            {/* Card stats */}
                            <Row>
                                <Col lg="6" xl="" key={1}>
                                    <StaticCard
                                        iconRight={true}
                                        hideChart={true}
                                        iconBg="danger"
                                        icon="user"
                                        statTitle={
                                            <span>
                                                <br></br>TOTAL<br></br> USERS
                                            </span>
                                        }
                                        stat={this.state.totalUsers}
                                    />
                                </Col>
                                <Col lg="6" xl="" key={2}>
                                    <StaticCard
                                        iconRight={true}
                                        hideChart={true}
                                        iconBg="primary"
                                        icon="user"
                                        statTitle={
                                            <span>
                                                <br></br> REGISTERED<br></br> USERS
                                            </span>
                                        }
                                        stat={this.state.totalRegUsers}
                                    />
                                </Col>
                                <Col lg="6" xl="" key={3}>
                                    <StaticCard
                                        iconRight={true}
                                        hideChart={true}
                                        iconBg="success"
                                        icon="user"
                                        statTitle={
                                            <span>
                                                <br></br>GUEST<br></br> USERS
                                            </span>
                                        }
                                        stat={this.state.totalGuestUsers}
                                    />
                                </Col>
                                <Col lg="6" xl="" key={5}>
                                    <StaticCard
                                        iconRight={true}
                                        hideChart={true}
                                        iconBg="info"
                                        icon="user"
                                        stat={this.state.totalActiveRegUsers}
                                        statTitle={
                                            <span>
                                                ACTIVE <br></br> REGISTERED <br></br> USERS
                                            </span>
                                        }
                                    />
                                </Col>
                                <Col lg="6" xl="" key={6}>
                                    <StaticCard
                                        iconRight={true}
                                        hideChart={true}
                                        iconBg="warning"
                                        icon="user"
                                        statTitle={
                                            <span>
                                                ACTIVE <br></br> GUEST <br></br> USERS
                                            </span>
                                        }
                                        stat={this.state.totalActiveGuestUsers}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Container>

                    <Container fluid>
                        <div className="header-body">
                            <br></br>
                            <h1>Twaddan Users List</h1>
                            <Row>
                                <div className="h5 mt-3 col-md-4 col-lg-4">
                                    <DateRangePicker
                                        block
                                        autoApply={true}
                                        onApply={this.dateRangeHandler}
                                        onCancel={this.handleCancel}
                                        showOneCalendar
                                        initialSettings={{
                                            autoUpdateInput: false,
                                            locale: {
                                                format: 'DD/MM/YYYY',
                                                cancelLabel: 'Clear',
                                            },
                                            startDate: moment(new Date()).tz('Asia/Dubai').format('DD MM YYYY hh:mm a'),
                                            endDate: moment(new Date()).tz('Asia/Dubai').format('DD MM YYYY hh:mm a'),
                                            ranges: {
                                                Today: [
                                                    moment().tz('Asia/Dubai').toDate(),
                                                    moment().tz('Asia/Dubai').toDate(),
                                                ],
                                                Yesterday: [
                                                    moment().subtract(1, 'days').toDate(),
                                                    moment().subtract(1, 'days').toDate(),
                                                ],
                                                'Last 7 Days': [
                                                    moment().subtract(6, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'Last 30 Days': [
                                                    moment().subtract(29, 'days').toDate(),
                                                    moment().toDate(),
                                                ],
                                                'This Month': [
                                                    moment().startOf('month').toDate(),
                                                    moment().endOf('month').toDate(),
                                                ],
                                                'Last Month': [
                                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                                ],
                                            },
                                        }}
                                    >
                                        <input
                                            type="text"
                                            style={{ maxWidth: '300px', display: 'inline-block' }}
                                            className="form-control"
                                            defaultValue=""
                                            placeholder="Select Date Range"
                                        />
                                    </DateRangePicker>
                                </div>

                                <div className="h5 mt-3 col-md-4 col-lg-4">
                                    <FormGroup row>
                                        <Col>
                                            <Input
                                                type="select"
                                                name="select"
                                                id="exampleSelect"
                                                value={this.state.selectedEmirate}
                                                onChange={this.handleEmirateChange}
                                            >
                                                {this.state.emirates.length > 0 &&
                                                    this.state.emirates.map((emirate) => {
                                                        return <option>{emirate.value}</option>;
                                                    })}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </div>
                                <div className="h5 mt-3 col-md-4 col-lg-4">
                                    <FormGroup row>
                                        <Col>
                                            <Input
                                                type="select"
                                                name="select"
                                                id="exampleSelect"
                                                value={this.state.daysOfActive}
                                                onChange={this.handleDaysActiveUser}
                                            >
                                                {this.state.daysOfActiveList.map((days) => {
                                                    return <option value={days.value}>{days.label}</option>;
                                                })}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </div>
                                {/* <div className="h5 mt-3 col-md-3">
                  <Button
                    style={{
                      maxWidth: "300px",
                      display: "inline-block",
                      marginLeft: "20px"
                    }}
                    color="info"
                    // onClick={this.pdfGenerator}
                  >
                    EXPORT
                  </Button>
                  </div> */}
                            </Row>
                            <div>
                                {this.state.allUsersByDateAndEmirate && (
                                    <BootstrapTable
                                        keyField="id"
                                        data={this.state.allUsersByDateAndEmirate}
                                        columns={columns}
                                        filter={filterFactory()}
                                        filterPosition="top"
                                        pagination={paginationFactory()}
                                        defaultSorted={defaultSorted}
                                        bordered={false}
                                        hover
                                        wrapperClasses="table-responsive"
                                        condensed
                                        rowEvents={rowEvents}
                                    />
                                )}
                            </div>
                        </div>
                    </Container>

                    {
                        <Container className="mt--7" fluid>
                            <div className="h5 mt-8">
                                <Row></Row>
                            </div>
                        </Container>
                    }
                    {/* {(!userDetails || Object.keys(userDetails).length <= 0) && (
            <Container fluid>
              <div className="header-body">
                <div>
                  <h1>No records found</h1>
                </div>
              </div>
            </Container>
          )} */}
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default Users;
