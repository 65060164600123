import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Container,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  CardBody,
} from "reactstrap";
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/AddService.css";
import ConvertArabic from "../../services/ConvertArabic";
import RealTime from "services/RealTime";
var spid = "";
var spName = "";
// super admin logged in user id
var saID;
// super admin logged in name
var saName;
firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    saID = user.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");
    firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/")
      .on("value", function (snapshot) {
        saName = snapshot.val();
        saName = saName.personal_information.name;
      });
  }
});
export default class AddService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spidd: "",
      sparray: [],
      booking: 0,
      cancel: 0,
      loaderStatus: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  handleSubmit(event) {
    event.preventDefault();
    this.storeCurrentSpid();
  }
  getData = async () => {
    this.setState({ loaderStatus: true });

    const sps = await RealTime.getDetails("service_providers/");
    let newService = [];
    newService.push({
      name: "select",
    });
    Object.entries(sps).forEach(([key, sp]) => {
      newService.push({
        name: sp.personal_information.name,
        id: key,
      });
    });
    this.setState(
      {
        sparray: newService,
        loaderStatus: false,
      },
      () => {
        if (this.props.location.state?.spId) {
          this.setState({ spidd: this.props.location.state?.spId }, () => {
            this.storeCurrentSpid();
          });
        }
      }
    );
  };
  storeCurrentSpid = () => {
    this.state.sparray.map((i) => {
      if (i.id === this.state.spidd) {
        spid = i.id;
        spName = i.name;
        this.setState({
          booking: 1,
        });
      }
    });
  };
  navigate = () => {
    this.setState({
      cancel: 1,
    });
  };
  render() {
    const { sparray } = this.state;
    let sparrayList =
      sparray.length > 0 &&
      sparray.map((i) => {
        return (
          <option value={i.id} key={i.id}>
            {i.name}
          </option>
        );
      }, this);
    if (this.state.booking === 1) {
      return <AddServices />;
    }
    if (this.state.cancel === 1) {
      return <Redirect to="/admin/services_new" />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={this.state.loaderStatus}></PageLoader>
        <Container className="mt--7" fluid>
          <Card>
            <CardHeader>
              <CardTitle>Add Service</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={8}>
                    <FormGroup>
                      <Label for="spid">Select Service Provider</Label>
                      <Input
                        type="select"
                        name="spid"
                        id="spid"
                        value={this.state.spidd}
                        onChange={(e) => {
                          this.setState({ spidd: e.target.value });
                        }}
                      >
                        {sparrayList}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" onClick={this.navigate}>
                  CANCEL
                </Button>
                <Button color="success" value="Submit">
                  NEXT
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

class AddServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      name_ar: "",
      timeRequired: "",
      description: "",
      description_ar: "",
      downloadurl: "",
      file: "",
      imagePreviewUrl: "",
      modal: false,
      service: 0,
      carType: [],
      priceForCartype: {},
      timeRequiredCarType: "",
      statusForCartype: {},
      isUploading: false,
      notSelected: false,
    };
  }
  componentDidMount() {
    this.getCarType();
  }
  getCarType = () => {
    const dr1 = firebase
      .database()
      .ref("service_providers/" + spid + "/new_services/");
    dr1.once("value", (snapshot) => {
      var drs = snapshot.val();
      let newService = [];
      let price = {};
      let status = {};
      let time = {};
      if (drs != null) {
        var keys = Object.keys(drs);
        for (var dr = 0; dr < keys.length; dr++) {
          var k = keys[dr];
          newService.push({
            id: k,
          });
          price[k] = null;
          time[k] = null;
          status[k] = false;
        }
        this.setState({
          carType: newService,
          priceForCartype: price,
          timeRequiredCarType: time,
          statusForCartype: status,
        });
      }
    });
  };
  uploadimage = async () => {
    let selectStatus = true;
    await Object.entries(this.state.statusForCartype).forEach(
      ([key, vehicleType]) => {
        if (vehicleType) {
          selectStatus = false;
        }
      }
    );
    if (selectStatus) {
      this.setState({ notSelected: true });
      return;
    }
    this.setState({ isUploading: true });
    var d = new Date();
    var idd = d.getTime();
    var id = d.getTime();
    id = id + ".png";
    var storageRef = firebase.storage().ref();
    var ImagesRef = storageRef.child(
      "/service_provider/" + spid + "/services/" + id
    );
    ImagesRef.put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })
      .then((downloadURL) => {
        this.setState({
          downloadurl: downloadURL,
        });
        this.pushdata(idd);
      })
      .catch((error) => {
        this.setState({ isUploading: false });
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  pushdata = (id) => {
    var spName;
    firebase
      .database()
      .ref("service_providers/" + spid + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Added New Service named " +
      this.state.name +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spid;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });
    this.state.carType.map((i) => {
      if (this.state.statusForCartype[i.id] == true) {
        var ref = firebase
          .database()
          .ref(
            "service_providers/" +
              spid +
              "/new_services/" +
              i.id +
              "/major_services/"
          );
        ref.child(id).set({
          description: this.state.description,
          description_ar: this.state.description_ar,
          image_url: this.state.downloadurl,
          created_on: time,
          name: this.state.name,
          name_ar: this.state.name_ar,
          price: Number(this.state.priceForCartype[i.id]),
          time_required: Number(this.state.timeRequiredCarType[i.id]),
          status: this.state.statusForCartype[i.id],
        });
      }
    });
    this.toggle();
  };
  _handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    console.log(this.state.file);
    this.uploadimage();
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      isUploading: false,
    });
  };
  onTimeChange = (id, value) => {
    var time = this.state.timeRequiredCarType;
    console.log(id, value);
    time[id] = value;
    this.setState({
      timeRequiredCarType: time,
    });
  };
  onChange = (id, value) => {
    var price = this.state.priceForCartype;

    price[id] = value;

    this.setState({
      priceForCartype: price,
    });
  };
  updateCheckbox = (id, value) => {
    var status = this.state.statusForCartype;
    status[id] = value;
    this.setState({
      statusForCartype: status,
    });
  };
  convert = async () => {
    if (this.state.name_ar === "")
      this.setState({
        name_ar: await ConvertArabic(this.state.name),
      });
    if (this.state.description_ar === "")
      this.setState({
        description_ar: await ConvertArabic(this.state.description),
      });
  };
  navigate = () => {
    this.setState({
      service: 1,
    });
  };
  addMoreService = () => {
    this.fileInput.value = "";
    this.setState({
      name: "",
      name_ar: "",
      timeRequired: "",
      description: "",
      description_ar: "",
      downloadurl: "",
      file: "",
      imagePreviewUrl: "",
      modal: false,
      service: 0,
      carType: [],
      priceForCartype: {},
      timeRequiredCarType: "",
      statusForCartype: {},
      loaderStatus: false,
      notSelected: false,
    });
  };

  render() {
    if (this.state.service == 1) {
      return <Redirect to="/admin/services_new" />;
    }
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} className="img-fluid" />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={this.state.isUploading}></PageLoader>
        {/* Page content */}
        <Container className=" mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card>
                <CardHeader>
                  <CardTitle>Add Service</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row form>
                    <Col lg={6}>
                      <h3>Selected SP : {spName}</h3>
                    </Col>
                  </Row>
                  <Form onSubmit={this.handleSubmit}>
                    <Row form>
                      <Col lg={6}>
                        <FormGroup>
                          <Label for="name">Name</Label>
                          <Input
                            type="name"
                            name="name"
                            required
                            id="name"
                            value={this.state.name}
                            onChange={(e) => {
                              this.setState({ name: e.target.value });
                            }}
                            placeholder="Name"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label for="name">Name in Arabic</Label>
                          <Input
                            type="name"
                            name="name"
                            required
                            id="name"
                            value={this.state.name_ar}
                            onChange={(e) => {
                              this.setState({ name_ar: e.target.value });
                            }}
                            placeholder="Name"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row></Row>
                    {this.state.carType.map((i) => {
                      return (
                        <Row form>
                          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormGroup row check>
                              <Label xs={12} check>
                                <Input
                                  type="checkbox"
                                  checked={this.state.statusForCartype[i.id]}
                                  onChange={(e) => {
                                    this.updateCheckbox(i.id, e.target.checked);
                                  }}
                                />
                                Enable for {i.id.replace("_", " ")}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                            <FormGroup row>
                              <Label
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                for="price"
                              >
                                Price
                              </Label>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Input
                                  type="number"
                                  disabled={!this.state.statusForCartype[i.id]}
                                  name="price"
                                  required
                                  id="price"
                                  placeholder="Price"
                                  value={this.state.priceForCartype[i.id]}
                                  onChange={(e) => {
                                    this.onChange(i.id, e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                            <FormGroup row>
                              <Label
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                for="price"
                              >
                                Time
                              </Label>
                              <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                <Input
                                  type="number"
                                  name="timeRequired"
                                  id="timeRequired"
                                  disabled={!this.state.statusForCartype[i.id]}
                                  required
                                  placeholder="Time Required"
                                  value={this.state.timeRequiredCarType[i.id]}
                                  onChange={(e) =>
                                    this.onTimeChange(i.id, e.target.value)
                                  }
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                      );
                    })}
                    <FormGroup>
                      <Label for="File">Add image</Label>
                      <br></br>
                      <input
                        type="file"
                        name="image"
                        accept="image/png, image/jpeg"
                        required
                        onChange={this._handleImageChange}
                        ref={(ref) => (this.fileInput = ref)}
                      />
                      <Row>
                        <Col xs={12}>
                          <div>{$imagePreview}</div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row>
                      <Col lg={12}>
                        <FormGroup>
                          <Label for="description">Description</Label>
                          <Input
                            type="text"
                            name="description"
                            required
                            id="description"
                            placeholder="Enter the decription"
                            value={this.state.description}
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup>
                          <Label for="description">Description in Arabic</Label>
                          <Input
                            type="text"
                            name="description"
                            required
                            id="description"
                            placeholder="Enter the decription"
                            value={this.state.description_ar}
                            onChange={(e) => {
                              this.setState({ description_ar: e.target.value });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        className="buttonCancelSubmitCol"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={4}
                        xl={4}
                      >
                        <Button
                          className="buttonConvert"
                          color="primary"
                          block
                          onClick={this.convert}
                        >
                          CONVERT TO ARABIC
                        </Button>
                      </Col>
                      <Col
                        className="buttonCancelSubmitCol"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={4}
                        xl={4}
                      >
                        <Button block onClick={this.navigate}>
                          CANCEL
                        </Button>
                      </Col>
                      <Col
                        className="buttonCancelSubmitCol"
                        xs={12}
                        sm={12}
                        md={12}
                        lg={4}
                        xl={4}
                      >
                        <Button block color="success" value="Submit">
                          ADD
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation service added Successfully</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.navigate}>
              CLOSE
            </Button>
            <Button color="primary" onClick={this.addMoreService}>
              ADD MORE
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.notSelected} toggle={this.toggle}>
          <ModalBody>Please select atleast one vehicle type</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.notSelectedCloss}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
