import React, { Component } from "react";
import firebaseConfig from "../../firebaseConfig";
import Select from "react-select";
// reactstrap components
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  CardImg,
  Row,
  Col,
  FormText,
  CardTitle,
  Badge,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import RealTime from "services/RealTime";

// sp ID
var spID;
// current sp data from spID node in database
var spData;
// total vehicles count
var totalVehicles = 0;
// active vehicles count
var unassignedVehicles = 0;

// total drivers
var totalDrivers = 0;
// active drivers
var activeDrivers = 0;

// driver details of current sp
var driversDetails = [];

// total drivers list
var totalDriversList = [];
// active drivers list
var activeDriversList = [];

// vehicle list
var vehicleList = [];

var spid = "";
// super admin logged in user id
var saID;
// super admin logged in name
var saName;

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);
    saID = user.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");
    firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/")
      .on("value", function (snapshot) {
        saName = snapshot.val();
        saName = saName.personal_information.name;
      });
  }
});

export default class AddDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      username_ar: "",
      selectedSp: [],
      email: "",
      driverID: "",
      phoneNumber: "",
      password: "",
      passwordRetype: "",
      description: "",
      isChecked: false,
      downloadURL: "",
      file: "",
      imagePreviewUrl: "",
      spemail: "",
      additionalNote: "",
      modal: false,
      vehicleNumber: "",
      vehicleList: "",
      status: "1",
      driverStatus: [],
      latitude: "",
      longitude: "",
    };
  }
  async componentDidMount() {
    this.setState({
      spemail: localStorage.getItem("email"),
    });
    let newService = [];
    let sps = await RealTime.getDetails("service_providers/");
    Object.entries(sps).forEach(([key, sp]) => {
      newService.push({
        label: sp.personal_information.name,
        value: key,
        totalDriversCount: sp.drivers_info.total_drivers,
      });
    });
    this.setState({
      sparray: newService,
    });
    // this.getDriverStatus();
  }
  onSelect = (selectedList, selectedItem) => {
    console.log("this.state.selectedSp", this.state.selectedSp);
    this.setState({ selectedSp: selectedList });
  };
  onRemove = (selectedList, removedItem) => {
    console.log("this.state.selectedSp", this.state.selectedSp);
    this.setState({ selectedSp: selectedList });
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
    if (stateName == "email") {
      let driverID = value;
      driverID = driverID.replace("@", "_");
      driverID = driverID.replace(".", "_");
      this.setState({
        driverID: driverID,
      });
    }
  };
  _handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };
  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  navigate = () => {
    this.setState({
      driver: 1,
    });
  };

  createDriverAccount = async () => {
    const uid = await this.createAuthUser(
      this.state.email,
      this.state.password
    );
    var urlLink;
    const {
      username,
      username_ar,
      email,
      driverID,
      phoneNumber,
      password,
      passwordRetype,
      description,
      isChecked,
      downloadURL,
      file,
      imagePreviewUrl,
      spemail,
      additionalNote,
      modal,
      vehicleNumber,
      vehicleList,
      status,
      driverStatus,
      latitude,
      longitude,
    } = this.state;
    if (uid) {
      var time = new Date();
      time = time.getTime();
      const extension = file.name.split(".").pop();
      let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");
      let emirateSelected = "";
      Object.entries(emirates).forEach(([key, emirate]) => {
        if (emirate.service_providers)
          Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
            if (key2 === spID) {
              emirateSelected = key;
            }
          });
      });

      firebase
        .storage()
        .ref(
          "/service_providers/" +
            spID +
            "/drivers_info/" +
            "driver_" +
            phoneNumber +
            "." +
            extension
        )
        .put(file)
        .then((snapshot) => {
          // Will return a promise with the download link
          return snapshot.ref.getDownloadURL();
        })
        .then((downloadURL) => {
          console.log(
            `Successfully uploaded file and got download link - ${downloadURL}`
          );
          urlLink = downloadURL;
          firebase
            .database()
            .ref("drivers/" + driverID + "/")
            .set({
              sp_related: [
                {
                  sp_id: spID,
                  emirate: emirateSelected,
                  status: true,
                },
              ],
              status: Number(0),
              created_on: time,
              personal_information: {
                userId: uid,
                name: username,
                username_ar: username_ar,
                times_rated: Number(0),
                description: description,
                email: email,
                phone_number: phoneNumber,
                image_url: urlLink,
                times_rated: 0,
                rating: "0",
                vehicle_number: "",
                employeeBehaviourRating: "",
                professionalAppearanceRating: "",
                qualityOfServiceRating: "",
                valueOfServiceRating: "",
                arrivalTimeRating: "",
              },
              live_location: {
                latitude: Number(25.7863988),
                longitude: Number(87.4658145),
                lc_lat: Number(25.7863988),
                lc_lng: Number(87.4658145),
                time_driver_engaged: 0,
              },
            });

          firebase
            .database()
            .ref("service_providers/" + spID + "/drivers_info/drivers_id")
            .update({
              [driverID]: false,
            });
          var totalDriversCount =
            Number(this.state.selectedSp.totalDriversCount) + 1;
          firebase
            .database()
            .ref("service_providers/" + spID + "/drivers_info/")
            .update({
              total_drivers: Number(totalDriversCount),
            });
          var logAction =
            "Added New Driver named " +
            username +
            " with Driver ID " +
            driverID +
            " under " +
            this.state.selectedSp.name +
            " Service Provider with SP ID " +
            this.state.selectedSp.id;
          var logs = firebase
            .database()
            .ref(
              "/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/"
            );
          logs.update({
            action: logAction,
          });
        });

      this.toggle();
    } else {
      alert("Email already Exist");
    }
  };
  createAuthUser = async (email, password) => {
    var uid = "";
    const axios = require("axios").default;
    const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/createUser`;
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios
      .post(url, {
        data: { email, password },
      })
      .catch((error) => {
        console.log("error: ", error.response.data);
        return (uid = false);
      });
    if (reply && reply.data && reply.data.result && reply.data.result.uid)
      uid = reply.data.result.uid;
    else uid = false;
    return uid;
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    if (this.state.driver == 1) {
      return <Redirect to="/admin/drivers" />;
    }

    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    let vehicleListNumber =
      vehicleList.length > 0 &&
      vehicleList.map((key) => {
        return <option>{key}</option>;
      }, this);
    const {
      username,
      email,
      password,
      passwordRetype,
      description,
      phoneNumber,
      isChecked,
      error,
      vehicleNumber,
    } = this.state;
    const isInvalid =
      password !== passwordRetype ||
      password === "" ||
      email === "" ||
      !isChecked ||
      username === "" ||
      phoneNumber === "" ||
      !/^\d{10}$/.test(phoneNumber);
    const passwordMisMatch =
      password !== passwordRetype && passwordRetype.length > 0;
    const incorrectPhoneNumber =
      phoneNumber !== "" && !/^\d{10}$/.test(phoneNumber);
    const passwordLength_LT6 = password.length < 6;
    const passwordLength_GT6_LT9 = password.length >= 6 && password.length < 9;
    const passwordLength_GT9 = password.length >= 9;
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Enter Driver Credentials to create account</small>
              </div>
              <Form role="form">
                <FormGroup>
                  <Label for="File">Add image</Label>
                  <br></br>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    required
                    onChange={this._handleImageChange}
                  />
                  <FormText color="muted">
                    Select the picture of the Driver you wanted to display.
                  </FormText>
                  <Row>
                    <Col md={4} xs={2}>
                      <div style={imagestyle}>{$imagePreview}</div>
                    </Col>
                  </Row>
                </FormGroup>
                <Col md={4}>
                  <FormGroup>
                    <Label>Select Service Provider</Label>
                    <Select
                      onChange={(sp) => {
                        this.setState({ selectedSp: sp });
                        console.log("sp: ", sp);
                        spID = sp.value;
                      }}
                      options={this.state.sparray}
                      isSearchable={true}
                    />
                  </FormGroup>
                </Col>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="User Name"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("username", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="User Name In Arabic"
                      type="text"
                      onChange={(e) =>
                        this.onChange("username_ar", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-bullet-list-67" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Description"
                      type="text"
                      onChange={(e) =>
                        this.onChange("description", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone number"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("phoneNumber", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      required
                      type="email"
                      onChange={(e) => this.onChange("email", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      required
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retype Password"
                      required
                      type="password"
                      onChange={(e) =>
                        this.onChange("passwordRetype", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    {passwordLength_LT6 && (
                      <span className="text-danger font-weight-700">Low</span>
                    )}
                    {passwordLength_GT6_LT9 && (
                      <span className="text-warning font-weight-700">
                        Medium
                      </span>
                    )}
                    {passwordLength_GT9 && (
                      <span className="text-success font-weight-700">High</span>
                    )}
                  </small>
                  <div>
                    <small>
                      {incorrectPhoneNumber && (
                        <span className="text-danger font-weight-700">
                          Incorrect Phone Number
                        </span>
                      )}
                    </small>
                  </div>
                  <div>
                    <small>
                      {passwordMisMatch && (
                        <span className="text-danger font-weight-700">
                          Password Mismatch
                        </span>
                      )}
                    </small>
                  </div>
                </div>
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        onClick={() => {
                          this.toggleChange();
                        }}
                        checked={this.state.isChecked}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    disabled={isInvalid}
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={this.createDriverAccount}
                  >
                    CREATE DRIVER ACCOUNT
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation new driver added Successfully</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
