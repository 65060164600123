import React, { Component } from 'react';

// reactstrap components
import {
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    Container,
    CardHeader,
    CardTitle,
    Modal,
    ModalBody,
    ModalFooter,
    CardBody,
} from 'reactstrap';

// core components
import Header from 'components/Headers/Header.jsx';
import firebase from '../../firebaseConfig';
import { Redirect } from 'react-router-dom';
import RealTime from 'services/RealTime';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import PageLoader from 'components/PageLoader/PageLoader';

var spid = '';

// super admin logged in user id
var saID;
// super admin logged in name
var saName;

firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
        // User is signed in.

        saID = user.email;
        saID = saID.replace('@', '_');
        saID = saID.replace('.', '_');

        firebase
            .database()
            .ref('/twaddan_admin/super_admin_users/' + saID + '/')
            .on('value', function (snapshot) {
                saName = snapshot.val();
                saName = saName.personal_information.name;
            });
    }
});

export default class AddDiscount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spidd: '',
            sparray: [],
            booking: 0,
            cancel: 0,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.getData();
    }
    handleSubmit(event) {
        this.storeCurrentSpid();
        event.preventDefault();
    }
    getData = () => {
        const dr = firebase.database().ref('service_providers/');
        let newDiscount = [];
        newDiscount.push({
            name: 'All',
            id: 'all',
        });
        dr.on('value', (snapshot) => {
            var drs = snapshot.val();
            if (drs != null) {
                var keys = Object.keys(drs);
                for (var dr = 0; dr < keys.length; dr++) {
                    var k = keys[dr];
                    newDiscount.push({
                        name: drs[k].personal_information.name,
                        id: k,
                    });
                }
            }
            this.setState({
                sparray: newDiscount,
            });
        });
    };
    storeCurrentSpid = () => {
        this.state.sparray.map((i) => {
            if (i.name === this.state.spidd) {
                spid = i.id;
                this.setState({
                    booking: 1,
                });
            }
        });
    };
    navigate = () => {
        this.setState({
            discount: 1,
        });
    };
    render() {
        const { sparray } = this.state;

        let sparrayList =
            sparray.length > 0 &&
            sparray.map((i) => {
                return <option>{i.name}</option>;
            }, this);
        if (this.state.booking === 1) {
            return <AddDiscounts />;
        }
        if (this.state.discount === 1) {
            return <Redirect to="/admin/discount" />;
        }
        return (
            <>
                <Header />
                {/* Page content */}
                <Container className="mt--7" fluid>
                    <Card>
                        <CardHeader>
                            <CardTitle>Add Coupon</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={this.handleSubmit}>
                                <Row form>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="spid">Select Service Provider</Label>
                                            <Input
                                                type="select"
                                                name="spid"
                                                id="spid"
                                                value={this.state.spidd}
                                                onChange={(e) => {
                                                    this.setState({ spidd: e.target.value });
                                                }}
                                            >
                                                <option hidden>Select Service Provider</option>
                                                {sparrayList}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button color="primary" onClick={this.navigate}>
                                    CANCEL
                                </Button>
                                <Button color="success" value="Submit">
                                    NEXT
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </>
        );
    }
}

class AddDiscounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmited: false,
            name: '',
            percentage: 0,
            cartvalue: 0,
            description: '',
            description_ar: '',
            downloadurl: '',
            file: '',
            imagePreviewUrl: '',
            spemail: '',
            tnc: '',
            tnc_ar: '',
            share_link: '',
            modal: false,
            discount: 0,
            CouponId: '',
            allSp: [],
            starting_date: '',
            ending_date: '',
            twaddanPercentage: null,
            maxDiscount: null,
            oneTimeUse:false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this._handleImageChange = this._handleImageChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateCheckbox = this.updateCheckbox.bind(this);
    }
    componentDidMount() {
        this.getdiscount();
        this.getAllServiceProviders();
    }
    getAllServiceProviders = async () => {
        let allSp = [];
        const service_providers = await RealTime.getDetails('service_providers');
        if (service_providers) {
            Object.keys(service_providers).map((sp) => {
                allSp.push(sp);
            });
            this.setState({
                allSp: allSp,
            });
        }
    };
    getdiscount = () => {
        const dr1 = firebase.database().ref('service_providers/' + spid + '/offers/');
        dr1.once('value', (snapshot) => {
            var drs = snapshot.val();
            let newDiscount = [];
            let price = {};
            let status = {};
            if (drs != null) {
                var keys = Object.keys(drs);
                for (var dr = 0; dr < keys.length; dr++) {
                    var k = keys[dr];
                    newDiscount.push({
                        id: k,
                    });
                    price[k] = 0;
                    status[k] = false;
                }
                this.setState({
                    discount: newDiscount,
                    priceFordiscount: price,
                    statusFordiscount: status,
                });
            }
        });
    };
    uploadimage = async (spidFromParams) => {
        this.setState({
            isSubmited: false,
        });
        var id = this.state.name.toLowerCase();
        id = id.trim();
        id = id.replace(' ', '_');
        id = id + '.png';
        if (this.state.file) {
            var storageRef = firebase.storage().ref();
            var ImagesRef = storageRef.child('/service_provider/' + spidFromParams + '/offers/' + id);
            ImagesRef.put(this.state.file)
                .then((snapshot) => {
                    return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
                })

                .then((downloadURL) => {
                    console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
                    this.setState({
                        downloadurl: downloadURL,
                    });
                    this.pushdata(spidFromParams);
                })

                .catch((error) => {
                    // Use to signal error if something goes wrong.
                });
        } else {
            await this.pushdata(spidFromParams);
        }
        this.setState({
            isSubmited: true,
        });
    };
    pushdata = async (spidFromParams) => {
        var spName;
        firebase
            .database()
            .ref('service_providers/' + spidFromParams + '/personal_information/')
            .on('value', function (snapshot) {
                spName = snapshot.val();
                spName = spName.name;
            });

        var time = new Date();
        time = time.getTime();
        var logAction =
            'Added New Service named ' +
            this.state.name +
            ' under ' +
            spName +
            ' Service Provider with SP ID ' +
            spidFromParams;
        var logs = firebase.database().ref('/twaddan_admin/super_admin_users/' + saID + '/logs/' + time + '/');
        logs.update({
            action: logAction,
        });

        var id = this.state.name.toLowerCase();
        id = id.trim();
        id = id.replace(' ', '_');
        var ref = firebase.database().ref('service_providers/' + spidFromParams + '/offers/');
        ref.child(id).set({
            description: this.state.description,
            description_ar: this.state.description_ar,
            image: this.state.downloadurl ? this.state.downloadurl : '',
            percentage: Number(this.state.percentage),
            promo_code: this.state.name,
            terms_and_conditions: this.state.tnc,
            terms_and_conditions_ar: this.state.tnc_ar,
            min_cart_value: Number(this.state.cartvalue),
            start_date: this.state.starting_date,
            end_date: this.state.ending_date,
            share_link: this.state.share_link,
            twaddanPercentage: this.state.twaddanPercentage ? Number(this.state.twaddanPercentage) : null,
            maxDiscount: this.state.maxDiscount ? Number(this.state.maxDiscount) : null,
            oneTimeUse: Boolean(this.state.oneTimeUse) ,
            status: true,
        });

        this.toggle();
        return true;
    };
    _handleImageChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        };

        reader.readAsDataURL(file);
    }

    async handleSubmit(event) {
        this.setState({
            isSubmited: false,
        });
        event.preventDefault();
        if (spid === 'all') {
            this.state.allSp.map(async (sp) => {
                await this.uploadimage(sp);
                event.preventDefault();
            });
        } else {
            await this.uploadimage(spid);
            event.preventDefault();
        }
        this.setState({
            isSubmited: true,
        });
    }
    toggle() {
        this.setState({
            modal: !this.state.modal,
        });
    }
    onChange = (id, value) => {
        var price = this.state.priceFordiscount;
        price[id] = value;
        this.setState({
            priceFordiscount: price,
        });
    };
    updateCheckbox = (id, value) => {
        var status = this.state.statusFordiscount;
        status[id] = value;
        this.setState({
            statusFordiscount: status,
        });
    };
    getArabic = async (text, value) => {
        const axios = require('axios').default;
        const url = 'https://translation.googleapis.com/language/translate/v2';
        const apiKey = 'AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g';
        const reply = await axios.get(url, {
            params: {
                q: text,
                target: 'ar',
                key: apiKey,
            },
        });

        this.setState({
            [value]: reply.data.data.translations[0].translatedText,
        });
    };
    convert = () => {
        this.getArabic(this.state.name, 'name_ar');
        this.getArabic(this.state.description, 'description_ar');
        this.getArabic(this.state.tnc, 'tnc_ar');
    };
    navigate = () => {
        this.setState({
            offers: 1,
        });
    };
    dateRangeHandler = async (event, picker) => {
        // if (
        //     moment(picker.startDate).toDate() <= moment().toDate() ||
        //     moment(picker.endDate).toDate() <= moment().toDate()
        // ) {
        //     alert('Please choose a valid date');
        // } else {
        this.setState({
            date: picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'),
            starting_date: picker.startDate.format('DD/MM/YYYY'),
            ending_date: picker.endDate.format('DD/MM/YYYY'),
        });
        // }
    };
    handleCancel = (event, picker) => {
        picker.element.val('');
    };
    render() {
        if (this.state.offers == 1) {
            return <Redirect to="/admin/discount" />;
        }
        const imagestyle = {
            textalign: 'center',
            margin: '5px 15px',
            height: '200px',
            width: '300px',
            borderleft: '1px solid',
        };
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
        }
        return (
            <>
                <Header />
                <PageLoader loading={this.state.isSubmited}></PageLoader>
                {/* Page content */}
                <Container className=" mt--7" fluid>
                    <Card>
                        <CardHeader>
                            <CardTitle>Add Promo code</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={this.handleSubmit}>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="name">Promo Code</Label>
                                            <Input
                                                type="name"
                                                required
                                                name="name"
                                                id="name"
                                                value={this.state.name}
                                                onChange={(e) => {
                                                    this.setState({ name: e.target.value });
                                                }}
                                                placeholder="Name"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="price">Percentage</Label>
                                            <Input
                                                type="number"
                                                required
                                                name="percentage"
                                                id="percentage"
                                                placeholder="Percentage"
                                                value={this.state.percentage}
                                                onChange={(e) => {
                                                    this.setState({ percentage: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <h3 style={{ color: 'red' }}>Please try to add image of 400x400 pixels</h3>
                                    <Label for="File">Add image</Label>
                                    <br></br>
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={this._handleImageChange}
                                    />
                                    <Row>
                                        <Col md={4} xs={2}>
                                            <div style={imagestyle}>{$imagePreview}</div>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="description">Description</Label>
                                            <Input
                                                type="text"
                                                name="description"
                                                required
                                                id="description"
                                                placeholder="Enter the decription"
                                                value={this.state.description}
                                                onChange={(e) => {
                                                    this.setState({ description: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="description_ar">Description in Arabic</Label>
                                            <Input
                                                type="text"
                                                name="description_ar"
                                                required
                                                id="description_ar"
                                                placeholder="Enter the decription"
                                                value={this.state.description_ar}
                                                onChange={(e) => {
                                                    this.setState({ description_ar: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cartvalue">Minimun cart value</Label>
                                            <Input
                                                type="number"
                                                required
                                                name="cartvalue"
                                                id="cartvalue"
                                                placeholder="mincartvalue"
                                                value={this.state.cartvalue}
                                                onChange={(e) => {
                                                    this.setState({ cartvalue: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cartvalue">Select Time Period</Label>
                                            <DateRangePicker
                                                block
                                                autoApply={true}
                                                onApply={this.dateRangeHandler}
                                                onCancel={this.handleCancel}
                                                showOneCalendar
                                                initialSettings={{
                                                    autoUpdateInput: false,
                                                    locale: {
                                                        format: 'DD/MM/YYYY',
                                                        cancelLabel: 'Clear',
                                                    },
                                                    startDate: moment(new Date())
                                                        .tz('Asia/Dubai')
                                                        .format('DD MM YYYY hh:mm a'),
                                                    endDate: moment(new Date())
                                                        .tz('Asia/Dubai')
                                                        .format('DD MM YYYY hh:mm a'),
                                                    ranges: {
                                                        Tomorrow: [
                                                            moment().add(1, 'days').toDate(),
                                                            moment().add(1, 'days').toDate(),
                                                        ],
                                                        'Next 3 Days': [
                                                            moment().add(1, 'days').toDate(),
                                                            moment().add(3, 'days').toDate(),
                                                        ],
                                                        'Next 7 Days': [
                                                            moment().add(1, 'days').toDate(),
                                                            moment().add(7, 'days').toDate(),
                                                        ],
                                                        'Next 14 Days': [
                                                            moment().add(1, 'days').toDate(),
                                                            moment().add(14, 'days').toDate(),
                                                        ],
                                                        'Next 30 Days': [
                                                            moment().add(1, 'days').toDate(),
                                                            moment().add(30, 'days').toDate(),
                                                        ],

                                                        'Next Month': [
                                                            moment().add(1, 'month').startOf('month').toDate(),
                                                            moment().add(1, 'month').endOf('month').toDate(),
                                                        ],
                                                    },
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    style={{ display: 'inline-block' }}
                                                    className="form-control"
                                                    defaultValue=""
                                                    value={this.state.date}
                                                    placeholder="Select Date Range"
                                                />
                                            </DateRangePicker>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="tnc">Maximum Discount Amount</Label>
                                            <Input
                                                type="number"
                                                name="maxDiscount"
                                                id="maxDiscount"
                                                placeholder="Enter max. discount amount"
                                                value={this.state.maxDiscount}
                                                onChange={(e) => {
                                                    this.setState({ maxDiscount: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cartvalue">TWADDAN PERCENTAGE</Label>
                                            <Input
                                                type="number"
                                                name="twaddanPercentage"
                                                id="twaddanPercentage"
                                                placeholder="TWADDAN PERCENTAGE"
                                                value={this.state.twaddanPercentage}
                                                onChange={(e) => {
                                                    this.setState({ twaddanPercentage: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cartvalue">Share URL</Label>
                                            <Input
                                                type="text"
                                                name="share_link"
                                                id="share_link"
                                                placeholder="Share URL"
                                                value={this.state.share_link}
                                                onChange={(e) => {
                                                    this.setState({ share_link: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cartvalue">One Time Code</Label>
                                            <Input
                                                type="select"
                                                name="oneTimeUse"
                                                id="oneTimeUse"
                                                placeholder="One Time Code"
                                                value={this.state.oneTimeUse}
                                                onChange={(e) => {
                                                    this.setState({ oneTimeUse: e.target.value });
                                                }}
                                            >
                                                <option value={true}>YES</option>
                                                <option value={false}>NO</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="tnc">Terms and Conditions</Label>
                                            <Input
                                                type="text"
                                                name="tnc"
                                                required
                                                id="tnc"
                                                placeholder="Enter the Terms and Conditions"
                                                value={this.state.tnc}
                                                onChange={(e) => {
                                                    this.setState({ tnc: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="tnc_ar">Terms and Conditions in Arabic</Label>
                                            <Input
                                                type="text"
                                                name="tnc_ar"
                                                required
                                                id="tnc_ar"
                                                placeholder="Enter the Terms and Conditions"
                                                value={this.state.tnc_ar}
                                                onChange={(e) => {
                                                    this.setState({ tnc_ar: e.target.value });
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <br></br>
                                <Button color="primary" onClick={this.convert}>
                                    CONVERT TO ARABIC
                                </Button>
                                <br></br>
                                <br></br>
                                <Button onClick={this.navigate}>CANCEL</Button>{' '}
                                <Button color="success" value="Submit">
                                    ADD
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalBody>Congratulation Promo Code added Successfully</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.navigate}>
                            CLOSE
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}
