import "./Orders.css";
import React, { Component } from "react";
import Header from "components/Headers/Header.jsx";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Row,
  BreadcrumbItem,
  Breadcrumb,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

import Stepper from "react-stepper-horizontal";
import userIcon from "../../assets/img/icons/orders/user.svg";
import briefCase from "../../assets/img/icons/orders/briefcase.svg";
import Driver from "../../assets/img/icons/orders/driver.svg";
import confirm from "../../assets/img/icons/orders/confirm.svg";
import car from "../../assets/img/icons/orders/car.svg";
import reached from "../../assets/img/icons/orders/reached.svg";
import started from "../../assets/img/icons/orders/started.svg";
import finished from "../../assets/img/icons/orders/finished.svg";
import Select from "react-select";
import firebase from "firebaseConfig";
import moment from "moment";
import StarRatings from "react-star-ratings";
import { Link, Redirect } from "react-router-dom";

const BreadCrumb = (props) => {
  return (
    <div>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem>
          <Link to="/index">Home</Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Link to="Orders1">Orders</Link>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};
const options = [
  { value: "Driver Not Avialable", label: "Driver not available" },
  { value: "Order made by mistake", label: "Order made by mistake" },
  { value: "other", label: "Others" },
];
var activeOrderDetails = [];
var activeServiceDetails = [];
var serviceDetails = [];
var serviceTotalPrice = 0;
var addonTotalPrice = 0;
var arabic = /[\u0600-\u06FF]/;
const translateToEn = async (text, value) => {
  if (arabic.test(text)) {
    console.log("text ", arabic.test, text);
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "en",
        key: apiKey,
      },
    });
    // console.log('reply ', reply);
    // console.log("transated ",reply.data.data.translations[0].translatedText);
    return reply.data.data.translations[0].translatedText || "";
  } else return text;
};

class ActiveOrderTracking extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Confirm",
          key: 1,
          icon: ["/static/media/confirm.776e6845.svg"],
        },
        {
          title: "Heading",
          key: 2,
          icon: ["/static/media/car.fd2ef083.svg"],
        },
        {
          title: "Reached",
          key: 3,
          icon: ["/static/media/reached.6b96f276.svg"],
        },
        {
          title: "Started",
          key: 4,
          icon: ["/static/media/started.feff6e69.svg"],
        },
        {
          title: "Finished",
          key: 6,
          icon: ["/static/media/finished.bc60195a.svg"],
        },
      ],
      currentStep: 0,
      orderData: "",
      modal: false,
      modals: false,
      seviceData: "",
      cancelOrderRedirect: false,
      showReasonTextfield: false,
      cancel_reason: null,
      cancelOrderRedirect: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggles = this.toggles.bind(this);
  }

  getOrderDetails = async () => {
    var orderDetails;
    if (this.props.location) {
      await firebase
        .database()
        .ref("/orders/all_orders/" + this.props.location.state.orderId)
        .once("value", async (snapshot) => {
          orderDetails = snapshot.val();
          activeOrderDetails["id"] = this.props.location.state.orderId.split(
            " "
          )[0];
          activeOrderDetails["status"] = orderDetails.status;
          activeOrderDetails["customer_name"] = orderDetails.customer_name;
          activeOrderDetails[
            "customer_name_english"
          ] = orderDetails.customer_name_english
            ? orderDetails.customer_name_english
            : null;
          activeOrderDetails["customer_id"] = orderDetails.customer_id;
          activeOrderDetails["customer_latitude"] =
            orderDetails.customer_latitude;
          activeOrderDetails["customer_longitude"] =
            orderDetails.customer_longitude;
          activeOrderDetails["sp_id"] = orderDetails.sp_id;
          activeOrderDetails["sp_name"] = orderDetails.sp_name;
          activeOrderDetails["orderRecievedDate"] = moment(
            orderDetails.time_order_accepted
          ).format("DD MM  YYYY hh:mm a");
          activeOrderDetails["driver_id"] = orderDetails.driver_id || "";
          activeOrderDetails["customer_raw_address"] =
            orderDetails.customer_raw_address;
          activeOrderDetails["customer_mobile_number"] =
            orderDetails.customer_mobile_number;
          activeOrderDetails[
            "order_id_number"
          ] = orderDetails.order_id_number.includes("_")
            ? orderDetails.order_id_number
                .split(" ")[2]
                .replace("_", "@")
                .replace("_", ".")
                .replace("_", ".")
            : "";
          activeOrderDetails["payment_mode"] =
            orderDetails.payment_mode === 0 ? "cash" : "online";
          activeOrderDetails["payment_method"] = orderDetails.payment_mode;
          activeOrderDetails["service_charge"] = orderDetails.serviceCharge
            ? orderDetails.serviceCharge
            : 0;
          activeOrderDetails["total_due"] = orderDetails.total_due;
          activeOrderDetails["coupon"] = "";
          activeOrderDetails["rating"] = Number(orderDetails.rating);

          await firebase
            .database()
            .ref(
              "/drivers/" + orderDetails.driver_id + "/personal_information/"
            )
            .once("value", (snapshot) => {
              var driverData = snapshot.val();
              activeOrderDetails["driver_name"] = driverData
                ? driverData.name
                : "";
              activeOrderDetails["driver_phone"] = driverData
                ? driverData.phone_number
                : "";
            });
        });
      {
        activeOrderDetails.customer_name = await translateToEn(
          activeOrderDetails.customer_name
        );
        activeOrderDetails.sp_name = await translateToEn(
          activeOrderDetails.sp_name
        );
        activeOrderDetails.customer_raw_address = await translateToEn(
          activeOrderDetails.customer_raw_address
        );
      }
      return activeOrderDetails;
    } else return false;
  };

  handleSpChange = (event) => {
    console.log(event);
    if (event.value === "other") {
      this.setState({
        showReasonTextfield: true,
        cancel_reason: null,
      });
      console.log(event.value);
    } else {
      this.setState({
        cancel_reason: event.value,
        showReasonTextfield: false,
      });
    }
  };
  checkCancel = () => {
    console.log(this.state.cancel_reason);
  };
  getServiceDetails = async () => {
    await firebase
      .database()
      .ref(
        "/orders/all_orders/" + this.props.location.state.orderId + "/services/"
      )
      .once("value", (snapshot) => {
        activeServiceDetails = snapshot.val();
      });
    return activeServiceDetails;
  };

  cancelOrder = async () => {
    console.log(
      "this.props.location.state.orderId ",
      this.props.location.state.orderId
    );
    console.log("this.state.cancel_reason ", this.state.cancel_reason);
    const callableReturnMessage = await firebase
      .functions()
      .httpsCallable("cancelOrder");
    callableReturnMessage({
      orderId: this.props.location.state.orderId,
      cancel_reason: this.state.cancel_reason,
    })
      .then((result) => {
        console.log("result ", result);
        console.log(result.data.output);
      })
      .catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
      });
    this.setState({
      cancelOrderRedirect: true,
    });
  };

  componentWillUnmount() {
    if (this.props.location) {
      this.setdata();
    }
  }

  componentDidMount() {
    if (this.props.location) {
      this.setdata();
    }
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  toggles() {
    this.setState({
      modals: !this.state.modals,
    });
  }
  handleReasonText = (e) => {
    this.setState({
      cancel_reason: e.target.value,
    });
  };
  setdata = async () => {
    serviceDetails = [];
    serviceTotalPrice = 0;
    addonTotalPrice = 0;
    // this.getOrderDetails().then((response) => {
    this.setState({
      orderData: await this.getOrderDetails(),
    });
    // });
    this.getServiceDetails().then((response) => {
      for (var key in response) {
        serviceDetails.push({
          ...response[key],
          vehicle: key,
        });
        for (var services in response[key].services) {
          serviceTotalPrice += response[key].services[services].price;
        }
        if (response[key].add_on_services) {
          for (var addonServices in response[key].add_on_services) {
            addonTotalPrice +=
              response[key].add_on_services[addonServices].price;
          }
        }
      }

      this.setState({
        seviceData: response,
      });
    });
  };

  render() {
    if (this.state.cancelOrderRedirect) {
      return <Redirect to="Orders1" />;
    }

    return (
      <div className="trackingComponent">
        <Header />

        <BreadCrumb />

        <Col md="12">
          <div className="orderId">
            <h3>{this.state.orderData.id}</h3>
            <p>
              <big>(Active Orders)</big>
            </p>
          </div>
        </Col>

        <Col md="12">
          <div>
            <Card>
              <CardHeader>Order Status</CardHeader>
              <CardBody>
                <div className="stepper">
                  <Stepper
                    steps={this.state.steps}
                    activeStep={this.state.orderData.status}
                    completeBarColor="#1D568E"
                  />
                </div>
              </CardBody>
              <div hidden={this.state.orderData.status > 3}>
                <CardBody className="text-center">
                  <div className="trackButtons">
                    <Button
                      size="md"
                      outline
                      color="primary"
                      onClick={() =>
                        this.setState({
                          cancel_reason: null,
                          modals: true,
                        })
                      }
                    >
                      CANCEL ORDER
                    </Button>{" "}
                  </div>
                </CardBody>
              </div>
            </Card>
          </div>
        </Col>

        <Col md="12">
          <div className="allCards">
            <Card>
              <CardHeader className="secondCard">
                <Row>
                  <Col md="4">
                    <p>Customer info</p>
                    <Row>
                      <Col md="2">
                        <img height={40} src={userIcon} alt="" />
                      </Col>
                      <Col md="6">
                        <h4>{this.state.orderData.customer_name}</h4>{" "}
                        {this.state.orderData.customer_name_english && (
                          <h4 style={{ textAlign: "center" }}>
                            {this.state.orderData.customer_name_english}
                          </h4>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <p>Service Provider info</p>
                    <Row>
                      <Col md="2">
                        <img height={40} src={briefCase} alt="" />
                      </Col>
                      <Col md="6">
                        <h4>{this.state.orderData.sp_name}</h4>
                        <h5>{this.state.orderData.orderRecievedDate}</h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="4">
                    <p>Technician info</p>
                    <Row>
                      <Col md="2">
                        <img height={40} src={Driver} alt="" />
                      </Col>
                      <Col md="6">
                        <h4>{this.state.orderData.driver_name || ""}</h4>
                        <h5>{this.state.orderData.driver_phone || ""}</h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <p>
                      <b>Address:</b>{" "}
                      {this.state.orderData.customer_raw_address}
                    </p>
                    <p>
                      <b>Phone:</b>{" "}
                      {this.state.orderData.customer_mobile_number}
                    </p>
                    <p>
                      <b>Email:</b> {this.state.orderData.order_id_number}
                    </p>
                  </Col>
                  <Col md="4">
                    <p>
                      <b>Mode of Payment::</b>{" "}
                      {this.state.orderData.payment_mode}
                    </p>
                    <p>
                      <b>Amount:</b> {this.state.orderData.total_due}
                    </p>
                    <p>
                      <b>Coupon:</b> Get50
                    </p>
                  </Col>
                  <Col md="4">
                    <p>
                      <b>Rating</b>
                      <StarRatings
                        rating={this.state.orderData.rating}
                        starRatedColor="#12ABEA"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="5px"
                        name="rating"
                      />
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Col>

        <Col md="12">
          <div className="allCards">
            {serviceDetails.map((serviceDetail) => (
              <Card>
                <CardHeader className="main-service-vehicle-header">
                  <h1>
                    {serviceDetail.vehicle
                      .replace("&", ", Vehicle No:")
                      .replace("_", " ")
                      .replace("“", " ")
                      .replace("”", " ")}
                  </h1>
                </CardHeader>
                <CardHeader>
                  <Row>
                    <p>Main services</p>
                    {(() => {
                      let servicesList = [];
                      for (var service in serviceDetail.services)
                        servicesList.push(
                          <tr className="col-md-12">
                            <td className="col-md-2">
                              {serviceDetail.services[service].name}
                            </td>
                            <td className="col-md-8">
                              {serviceDetail.services[service].price}
                            </td>
                          </tr>
                        );
                      return servicesList;
                    })()}
                  </Row>
                  {serviceDetail.add_on_services ? (
                    <Row className="AddOn">
                      <tr className="col-md-12">
                        <p>Add on’s</p>
                        <div>
                          {(() => {
                            let addonList = [];
                            for (var addon in serviceDetail.add_on_services) {
                              addonList.push(
                                <tr className="col-md-12">
                                  <td className="col-md-2">
                                    {serviceDetail.add_on_services[addon].name}
                                  </td>
                                  <td className="col-md-8">
                                    {serviceDetail.add_on_services[addon].price}
                                  </td>
                                </tr>
                              );
                            }
                            return addonList;
                          })()}
                        </div>
                      </tr>
                    </Row>
                  ) : (
                    ""
                  )}
                </CardHeader>
              </Card>
            ))}
            <Card>
              <CardBody>
                <tr className="col-md-12">
                  <th className="col-md-2">Total Amount</th>
                  <td className="col-md-8">
                    {serviceTotalPrice + addonTotalPrice}
                  </td>
                </tr>
              </CardBody>
            </Card>
            <Modal isOpen={this.state.modals} toggle={this.toggles}>
              <ModalBody>
                <p>Select Reason of Cancelation</p>
                <Select
                  style={{}}
                  onChange={this.handleSpChange}
                  options={options}
                />
                {this.state.showReasonTextfield ? (
                  <Input
                    placeholder="Enter your reason"
                    value={this.state.cancel_reason}
                    onChange={this.handleReasonText}
                  />
                ) : null}
              </ModalBody>
              <ModalFooter>
                <Button color="info" onClick={this.toggles}>
                  N0
                </Button>
                <Button
                  color="danger"
                  disabled={!this.state.cancel_reason}
                  onClick={this.cancelOrder}
                >
                  YES
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Col>
      </div>
    );
  }
}

export default ActiveOrderTracking;
