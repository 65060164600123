import React, { Component } from "react";
import Select from "react-select";

// reactstrap components
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
  CardTitle,
  Badge,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  ModalHeader,
} from "reactstrap";

import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/ResponsiveIFrame.css";
// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import RealTime from "../../services/RealTime";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import QRious from "qrious";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import ConvertArabic from "../../services/ConvertArabic";
// sp ID
var spID;
// current sp data from spID node in database
var spData;
// total vehicles count
var totalVehicles = 0;
// active vehicles count
var unassignedVehicles = 0;
// total earning
var totalEarning = 0;
let selectedVehicleType = [];

var spid = "";
var qrNumber;
const searchOptions = {
  componentRestrictions: { country: ["us"] },
  types: ["city"],
};
// super admin logged in user id
var saID = null;
// super admin logged in name
var saName;

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);

    saID = user.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");

    firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/")
      .on("value", function (snapshot) {
        saName = snapshot.val();
        saName = saName.personal_information.name;
      });
  }
});
var location = [];
var locationEdit = false;
const columns = [
  {
    text: "Vehicle Type",
    dataField: "vehicle_type",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service ID",
    dataField: "service_id",
    footer: false,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {
      minWidth: "300px",
    },
  },
  {
    text: "Service Name",
    dataField: "service_name",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Price",
    dataField: "price",
    footer: false,
    sort: true,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {},
  },
];
const defaultSorted = [
  {
    dataField: "vehicle_type",
    order: "desc",
  },
];

var maplocation;
export default class AssIgnSpToVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spemail: "",
      modal: false,
      disableVehicleField: false,
      count: 1,
      area: "",
      price: 0,
      locationDetails: "",
      selectedLocation: "",
      services: null,
      selected: [],
      selectedId: [],
      selectedAddOn: [],
      selectedIdAddOn: [],
      selectedVehicleType: [],
      selectedServices: [],
      selectedAddOnServices: [],
      vehicle_ids: [],
      selectedVehicle: null,
      number: null,
      loaderStatus: false,
      serviceableAreas: "",
      sparray: [],
      driver_ids: [],
      selected_vehicle_status: "",
      previewModal: false,
      selectEmirate: "",
      map_preview: "",
      map_preview_name: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentWillUnmount() {
    selectedVehicleType = [];
    spID = "";
  }
  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
      loaderStatus: false,
    });
  }
  getEmirate = (emirates) => {
    let emi = "";
    Object.entries(emirates).forEach(([key, emirate]) => {
      if (emirate.service_providers)
        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
          if (key2 === spID) {
            this.setState({ emirate: emirate });
            emi = emirate;
            return emi;
          }
          console.log("key2", key2);
        });
    });
  };
  getSP = async () => {
    this.setState({ loaderStatus: true });
    const services = await RealTime.getDetails("/service_providers/");
    let newService = [];
    newService.push({
      label: "select",
      value: null,
    });
    Object.entries(services).forEach(([key, service]) => {
      newService.push({
        label: service.personal_information
          ? service.personal_information.name
          : key,
        value: key,
      });
    });
    this.setState({
      sparray: newService,
      loaderStatus: false,
    });
  };
  selectSp = (e) => {
    spID = e.value;
    this.getData();
  };
  selectVehicle = (e) => {
    this.state.vehicle_ids.map((vh) => {
      if (vh.id === e.target.value) {
        console.log("vh", vh);
        this.setState({
          number: e.target.value,
          selectedVehicle: vh.vehicle,
          selected_vehicle_status: vh.vehicle.status,
        });
      }
    });
  };
  selectNextSP = async (e) => {
    this.setState({ loaderStatus: true });
    let vehicle_ids = [];
    const vehicles = await RealTime.getDetails("vehicles");
    Object.entries(vehicles).forEach(([key, vehicle]) => {
      let status = false;
      vehicle.sp_related.map((sp) => {
        if (sp.sp_id === e.value) status = true;
      });
      if (status)
        vehicle_ids.push({ name: vehicle.name, id: key, vehicle: vehicle });
    });
    this.setState({ vehicle_ids: vehicle_ids, loaderStatus: false });
  };
  getData = async () => {
    this.setState({
      loaderStatus: true,
    });
    const area = await RealTime.getDetails(
      "service_providers/" + spID + "/serviceable_areas"
    );
    if (area) {
      this.setState({
        serviceableAreas: Object.keys(area),
      });
    }
    var spNext = this.state.sparray.filter((item) => item.value !== spID);
    this.setState({
      spemail: localStorage.getItem("email"),
    });

    let servicesFromDB = await RealTime.getDetails(
      "/service_providers/" + spID + "/new_services/"
    );
    let vehicle_types = [];
    Object.entries(servicesFromDB).forEach(([key, vehhicle_type]) => {
      if (vehhicle_type.status) {
        vehicle_types.push(key);
      }
    });
    let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");
    let emirateSelected = "";
    Object.entries(emirates).forEach(([key, emirate]) => {
      if (emirate.service_providers)
        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
          if (key2 === spID) {
            this.setState({ emirate: key });
            emirateSelected = key;
            return emirateSelected;
          }
        });
    });
    let servicesAreasFromDB = await RealTime.getDetails(
      "/twaddan_admin/emirates/" + emirateSelected + "/serviceable_areas"
    );
    let addOnService = [];
    let services = [];
    if (servicesFromDB) {
      Object.entries(servicesFromDB).forEach(([key, vehicle_type]) => {
        if (vehicle_type.major_services)
          Object.entries(vehicle_type.major_services).forEach(
            ([key2, service]) => {
              if (service.status)
                services.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
        if (vehicle_type.add_on_services)
          Object.entries(vehicle_type.add_on_services).forEach(
            ([key2, service]) => {
              if (service.status)
                addOnService.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
      });
    }

    this.setState({
      services: services,
      servicesCopy: services,
      servicesFromDB: servicesFromDB,
      vehicle_types: vehicle_types,
      addOnServices: addOnService,
      addOnServicesCopy: addOnService,
      servicesAreasFromDB: servicesAreasFromDB,
      loaderStatus: false,
      spNext: spNext,
    });
  };

  async componentDidMount() {
    this.getSP();
    this.setState({ loaderStatus: true });
  }
  validate = () => {
    if (this.state.selected.length < 1) {
      alert("Please select atleast one major service");
      return;
    } else if (this.state.locationDetails.length < 1) {
      alert("Please select atleast one serviceable area");
      return;
    } else if (this.state.number === null) {
      alert("Please select vehicle");
      return;
    } else {
      this.pushData();
    }
  };
  convert = async () => {
    if (this.state.name_ar === "")
      this.setState({
        name_ar: await ConvertArabic(this.state.name),
      });
    if (this.state.description_ar === "")
      this.setState({
        description_ar: await ConvertArabic(this.state.description),
      });
    if (this.state.additionalNote_ar === "")
      this.setState({
        additionalNote_ar: await ConvertArabic(this.state.additionalNote),
      });
    if (this.state.helperName_ar === "")
      this.setState({
        helperName_ar: await ConvertArabic(this.state.helperName),
      });
  };

  pushData = async () => {
    this.setState({ loaderStatus: true });
    console.log(this.state.number);
    let sp_related = await RealTime.getDetails(
      "vehicles/" + this.state.number + "/sp_related"
    );
    sp_related.push({
      sp_id: spID,
      emirate: this.state.emirate,
      status: true,
    });
    let servicesFromDB = this.state.servicesFromDB;
    let vts = {};
    this.state.selected.map(async (item) => {
      if (!(item.vehicle_type in vts)) {
        vts[item.vehicle_type] = {
          name: servicesFromDB[item.vehicle_type].name,
          name_ar: servicesFromDB[item.vehicle_type].name_ar,
          status: servicesFromDB[item.vehicle_type].status,
          major_services: {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].major_services[item.service_id],
          },
        };
      } else {
        if (vts[item.vehicle_type].major_services)
          if (!(item.service_id in vts[item.vehicle_type].major_services)) {
            vts[item.vehicle_type].major_services[item.service_id] =
              servicesFromDB[item.vehicle_type].major_services[item.service_id];
          }
      }
    });
    this.state.selectedAddOn.map((item) => {
      console.log("vts[item.vehicle_type]", vts[item.vehicle_type]);
      if (vts[item.vehicle_type])
        if (!("add_on_services" in vts[item.vehicle_type])) {
          vts[item.vehicle_type].add_on_services = {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].add_on_services[
                item.service_id
              ],
          };
        } else {
          vts[item.vehicle_type].add_on_services[item.service_id] =
            servicesFromDB[item.vehicle_type].add_on_services[item.service_id];
        }
    });
    let servicesAreasFromDB = this.state.servicesAreasFromDB;
    this.state.locationDetails.map((location) => {
      Object.entries(servicesAreasFromDB).forEach(([key, locationFromDB]) => {
        if (location.area === key) {
          if (!("service_providers" in locationFromDB)) {
            servicesAreasFromDB[location.area].service_providers = {
              [spID]: {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: this.state.selected_vehicle_status,
                    price: Number(location.price),
                  },
                },
              },
            };
          } else {
            if (spID in servicesAreasFromDB[location.area].service_providers) {
              servicesAreasFromDB[location.area].service_providers[
                spID
              ].vehicles[this.state.number] = {
                status: this.state.selected_vehicle_status,
                price: Number(location.price),
              };
            } else {
              servicesAreasFromDB[location.area].service_providers[spID] = {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: this.state.selected_vehicle_status,
                    price: Number(location.price),
                  },
                },
              };
            }
          }
        }
      });
    });
    console.log("servicesAreasFromDB", servicesAreasFromDB);
    firebase
      .database()
      .ref(
        "/twaddan_admin/emirates/" + this.state.emirate + "/serviceable_areas"
      )
      .update(servicesAreasFromDB);
    RealTime.updateNode(
      "vehicles/" + this.state.number + "/sp_related",
      sp_related
    );
    if (this.state.selectedVehicle.driver !== "")
      RealTime.updateNode(
        "drivers/" + this.state.selectedVehicle.driver + "/sp_related",
        sp_related
      );
    var time = new Date();
    time = time.getTime();
    firebase
      .database()
      .ref("service_providers/" + spID + "/vehicles/" + this.state.number + "/")
      .set({
        serviceable_areas: this.state.locationDetails,
        vehicle_type: vts,
        time_stamp: time,
      });

    var spName;

    firebase
      .database()
      .ref("service_providers/" + spID + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Assigned existing Vehicle named " +
      this.state.name +
      " with Vehicle Number " +
      this.state.number +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spID;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });
    this.toggle();
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log(this.state.file);
    this.validate();
  }

  navigate = () => {
    this.setState({
      vehicle: 1,
    });
  };

  toggleMapPreview = () => {
    this.setState({
      previewModal: !this.state.previewModal,
    });
  };

  handleMapPreview = async (area) => {
    if (area) {
      this.setState({
        map_preview: "",
        map_preview_name: "",
      });
      const emirates = await RealTime.getDetails("twaddan_admin/emirates");
      if (emirates) {
        Object.entries(emirates).map(([key, value]) => {
          if (value.service_providers) {
            Object.keys(value.service_providers).map((value1, key1) => {
              if (value1 === spID) {
                if (value.serviceable_areas) {
                  Object.entries(value.serviceable_areas).map(
                    ([key2, value2]) => {
                      if (key2 === area) {
                        this.setState({
                          map_preview: value2.map_preview,
                          map_preview_name: area,
                          previewModal: true,
                        });
                      }
                    }
                  );
                }
              }
            });
          }
        });
      }
    }
    console.log(this.state.map_preview);
  };

  handleSubmitLocation = (event) => {
    var flag = true;
    if (this.state.area !== "" && this.state.price !== "") {
      location.map((loc) => {
        if (loc.area === this.state.area) {
          flag = false;
        }
      });
      if (flag) {
        location.unshift({
          area: this.state.area,
          price: Number(this.state.price),
          status: true,
        });
      }
      this.setState({
        locationDetails: location,
      });
      locationEdit = false;
    }
    let dropDownItem = this.state.serviceableAreas;
    let index = dropDownItem.indexOf(this.state.area);
    dropDownItem.splice(index, 1);
    this.setState({
      area: "",
      price: 0,
      serviceableAreas: dropDownItem,
    });
    event.preventDefault();
  };

  removeLocation = (area) => {
    var locationDetails = location;
    const filteredLocation = locationDetails.filter(
      (item) => item.area !== area
    );
    location = filteredLocation;
    this.setState({
      locationDetails: location,
    });
    let addRemoved = this.state.serviceableAreas;
    addRemoved.push(area);
    this.setState({
      serviceableAreas: addRemoved,
    });
  };
  handleSelectAllAreas = () => {
    this.state.serviceableAreas.map((item) => {
      location.push({
        area: item,
        price: Number(0),
        status: true,
      });
    });
    this.setState({
      locationDetails: location,
      serviceableAreas: [],
    });
  };
  editLocation = (location) => {
    this.setState({
      area: location.area,
      price: Number(location.price),
    });
    this.removeLocation(location.area);
  };

  handleChangePlace = (area) => {
    this.setState({
      area,
    });
  };

  handleSelectPlace = (area) => {
    this.setState({
      area: area,
    });
    geocodeByAddress(area)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  toggleCheckBoxChange = (name) => {
    let selectedServices = [];
    let selectedAddOnServices = [];
    const { servicesCopy, addOnServicesCopy } = this.state;
    let isSelect = false;
    if (!selectedVehicleType.includes(name)) {
      isSelect = true;
      selectedVehicleType = [...selectedVehicleType, name];
    } else {
      selectedVehicleType = selectedVehicleType.filter((x) => x !== name);
    }
    this.setState(
      {
        selectedVehicleType: selectedVehicleType,
      },
      () => {
        if (this.state.selectedVehicleType.length > 0) {
          Object.entries(servicesCopy).forEach(([key, service]) => {
            this.state.selectedVehicleType.map((vehicle_type) => {
              if (service.vehicle_type === vehicle_type) {
                selectedServices.push(service);
              }
            });
          });
          Object.entries(addOnServicesCopy).forEach(([key, addOnService]) => {
            this.state.selectedVehicleType.map((vehicle_type) => {
              if (addOnService.vehicle_type === vehicle_type) {
                selectedAddOnServices.push(addOnService);
              }
            });
          });
          this.setState({
            services: selectedServices,
            addOnServices: selectedAddOnServices,
            isVehicleTypeSelected: true,
          });
        } else {
          this.setState({
            services: [],
            addOnServices: [],
            isVehicleTypeSelected: false,
          });
        }
      }
    );
  };
  handleOnSelect = (row, isSelect) => {
    console.log("row");
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row],
        selectedId: [...this.state.selectedId, row.id],
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x !== row),
        selectedId: this.state.selectedId.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selected: row,
        selectedId: ids,
      }));
    } else {
      this.setState(() => ({
        selected: [],
        selectedId: [],
      }));
    }
  };
  handleOnSelectAddOn = (row, isSelect) => {
    console.log("row", row);

    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: [...this.state.selectedAddOn, row],
        selectedIdAddOn: [...this.state.selectedIdAddOn, row.id],
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: this.state.selectedAddOn.filter((x) => x !== row),
        selectedIdAddOn: this.state.selectedIdAddOn.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAllAddOn = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: row,
        selectedIdAddOn: ids,
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: [],
        selectedIdAddOn: [],
      }));
    }
  };

  render() {
    if (this.state.vehicle == 1) {
      return <Redirect to="/admin/vehicles" />;
    }
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl, sparray, spNext, vehicle_ids } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    let { qrCodeLink } = this.state;
    let $qrcodePreview = null;
    if (qrCodeLink) {
      $qrcodePreview = <img src={qrCodeLink} style={imagestyle} />;
    }
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedId,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    const selectRowAddOn = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedIdAddOn,
      onSelect: this.handleOnSelectAddOn,
      onSelectAll: this.handleOnSelectAllAddOn,
    };
    let areaList = [];
    this.state.serviceableAreas.length > 0 &&
      this.state.serviceableAreas.map((i) => {
        areaList.push({ value: i, label: i });
      }, this);
    let vehicle_ids_list =
      vehicle_ids.length > 0 &&
      vehicle_ids.map((i) => {
        return <option value={i.id}>{i.name}</option>;
      }, this);
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={this.state.loaderStatus}></PageLoader>
        <Container className=" mt--7" fluid>
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                  <h3 className="mb-0">Vehicle Details</h3>
                </Col>
                <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                  <Button
                    block
                    color="primary"
                    className="button_back"
                    onClick={this.navigate}
                  >
                    BACK
                  </Button>
                </Col>
              </Row>
              {/* <CardTitle>Add Vehicle</CardTitle> */}
            </CardHeader>
            <CardBody>
              <Row form>
                <Col xs={12}>
                  <FormGroup row>
                    <Label xs={12} sm={6} md={6} lg={6} xl={4} for="spid">
                      Vehicle Assigned To Service Provider
                    </Label>
                    <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                      <Select
                        onChange={(e) => this.selectSp(e)}
                        options={sparray}
                        isSearchable={true}
                      />
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              {spID && (
                <Form onSubmit={this.handleSubmit}>
                  <Row form>
                    <Col xs={12}>
                      <FormGroup row>
                        <Label xs={12} sm={6} md={6} lg={6} xl={4} for="spid">
                          Vehicle Assigned From Service Provider
                        </Label>
                        <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                          <Select
                            onChange={(e) => this.selectNextSP(e)}
                            options={spNext}
                            isSearchable={true}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="spid">Select Vehicle</Label>
                    <Input
                      type="select"
                      name="spid"
                      id="spid"
                      disabled={vehicle_ids.length === 0}
                      value={this.state.number}
                      onChange={(e) => this.selectVehicle(e)}
                    >
                      <option hidden>Select vehicle</option>
                      {vehicle_ids_list}
                    </Input>
                  </FormGroup>
                  {/* Services adding */}
                  <h6 className="heading-small text-muted mb-4">
                    Select Vehicle Type
                  </h6>
                  <div className="pl-lg-4">
                    {this.state.vehicle_types &&
                      this.state.vehicle_types.map((line, key) => {
                        return (
                          <>
                            <Col lg="4">
                              <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={line}
                                  // defaultChecked={this.state.vehicleTypesList[line]}
                                  onClick={() => {
                                    this.toggleCheckBoxChange(line);
                                  }}
                                />

                                <label
                                  className="custom-control-label"
                                  htmlFor={line}
                                >
                                  {line.replace("_", " ")}
                                </label>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </div>
                  <br />
                  <h6 className="heading-small text-muted mb-4">
                    {this.state.isVehicleTypeSelected ? "Select Services" : ""}
                  </h6>
                  <div>
                    {this.state.isVehicleTypeSelected && (
                      <BootstrapTable
                        keyField="id"
                        data={this.state.services}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition="top"
                        //pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        selectRow={selectRow}
                        hover
                        wrapperClasses="table-responsive"
                        condensed
                        // rowEvents={rowEvents}
                      />
                    )}
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    {this.state.isVehicleTypeSelected
                      ? "Select Add On Services"
                      : ""}{" "}
                  </h6>
                  <div>
                    {this.state.isVehicleTypeSelected && (
                      <BootstrapTable
                        keyField="id"
                        data={this.state.addOnServices}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition="top"
                        //pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        selectRow={selectRowAddOn}
                        hover
                        wrapperClasses="table-responsive"
                        condensed
                        // rowEvents={rowEvents}
                      />
                    )}
                  </div>
                  {/* --------------------------------------------------------------------------------------------------------- */}
                  <Form name="placeCharge" id="placeCharge">
                    <Row form>
                      <Col md={4}>
                        <Select
                          onChange={(area) => {
                            this.setState({ area: area.label });
                          }}
                          options={areaList}
                          isSearchable={true}
                        />
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-credit-card" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              name="price"
                              id="price"
                              placeholder="Enter price"
                              required
                              value={this.state.price}
                              onChange={(e) =>
                                this.onChange("price", e.target.value)
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Row>
                            <Col md={4}>
                              <Button
                                type="button"
                                color="primary"
                                disabled={this.state.area ? false : true}
                                onClick={() => {
                                  this.handleMapPreview(this.state.area);
                                }}
                                block
                              >
                                PREVIEW
                              </Button>
                            </Col>
                            <Col md={4}>
                              <Button
                                type="button"
                                color="success"
                                onClick={this.handleSubmitLocation}
                                block
                              >
                                {locationEdit ? "UPDATE" : "ADD"}
                              </Button>
                            </Col>

                            <Col md={4}>
                              <Button
                                type="button"
                                color="info"
                                onClick={this.handleSelectAllAreas}
                                block
                                // disabled={location.length > 0 ? true : false}
                              >
                                ADD ALL AREAS
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <FormGroup>
                    {this.state.locationDetails.length > 0 && (
                      <div>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Area</th>
                              <th>Price</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          {this.state.locationDetails.map((location) => (
                            <tbody>
                              <tr>
                                <td>{location.area}</td>
                                <td>{location.price}</td>
                                <td>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      this.handleMapPreview(location.area);
                                    }}
                                  >
                                    PREVIEW
                                  </Button>
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      this.removeLocation(location.area);
                                    }}
                                  >
                                    REMOVE
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          ))}{" "}
                        </Table>
                      </div>
                    )}

                    {this.state.number && (
                      <Row>
                        {/* 
                        <Col>
                          <QRCode
                            id="qrcode"
                            value={this.state.number}
                            size={290}
                            level={"H"}
                            includeMargin={true}
                          />
                        </Col> 
                      */}
                        <Col>
                          <Col md={4} xs={4}>
                            <div style={imagestyle}>{$qrcodePreview}</div>
                          </Col>
                        </Col>
                        <Col>
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className="col">
                            <h3 className="mb-0">
                              QR Code will be generated for the vehicle ...
                            </h3>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </FormGroup>
                  <Row>
                    <Col
                      className="buttonCancelSubmitCol"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}
                    >
                      <Button block onClick={this.navigate}>
                        CANCEL
                      </Button>
                    </Col>
                    <Col
                      className="buttonCancelSubmitCol"
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={2}
                    >
                      <Button block color="success" value="Submit">
                        ADD VEHICLE
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button onClick={this.navigate}>CANCEL</Button>{" "}
                  <Button color="success" value="Submit">
                    {" "}
                    ADD VEHICLE{" "}
                  </Button> */}
                </Form>
              )}
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation new vehicle added Successfully</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.previewModal}
          toggle={this.toggleMapPreview}
          size="lg"
        >
          <ModalHeader toggle={this.toggleMapPreview}>
            {this.state.map_preview_name}
          </ModalHeader>
          <ModalBody>
            <>
              <div className="containerIframe">
                <iframe
                  class="responsive-iframe"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.map_preview}`}
                  allowfullscreen
                ></iframe>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleMapPreview}>CLOSE</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
