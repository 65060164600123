import React, { Component } from "react";
import firebaseConfig from "../../firebaseConfig";
import Select from "react-select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  InfoWindow,
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import Switch from "react-switch";
// reactstrap components
import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormText,
  CardTitle,
  Badge,
  FormFeedback,
  CardFooter,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  ModalHeader,
} from "reactstrap";

import PageLoader from "../../components/PageLoader/PageLoader";
import "../styles/ResponsiveIFrame.css";
// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import RealTime from "../../services/RealTime";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import QRious from "qrious";
import DatePicker from "react-datepicker";
import ConvertArabic from "../../services/ConvertArabic";
const axios = require("axios").default;
// sp ID
var spID;
// current sp data from spID node in database
var spData;
// total vehicles count
var totalVehicles = 0;
// active vehicles count
var unassignedVehicles = 0;
// total earning
var totalEarning = 0;
let selectedVehicleType = [];

var spid = "";
var qrNumber;
const searchOptions = {
  componentRestrictions: { country: ["us"] },
  types: ["city"],
};
// super admin logged in user id
var saID = null;
// super admin logged in name
var saName;

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);
    saID = user.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");
    firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/")
      .on("value", function (snapshot) {
        saName = snapshot.val();
        saName = saName.personal_information.name;
      });
  }
});
var location = [];
var locationEdit = false;
const columns = [
  {
    text: "Vehicle Type",
    dataField: "vehicle_type",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service ID",
    dataField: "service_id",
    footer: false,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {
      minWidth: "300px",
    },
  },
  {
    text: "Service Name",
    dataField: "service_name",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Price",
    dataField: "price",
    footer: false,
    sort: true,
    align: "center",
    filter: textFilter(),
    headerAlign: "center",
    headerStyle: {
      backgroundColor: "#00acf3",
    },
    style: {},
  },
];
const defaultSorted = [
  {
    dataField: "vehicle_type",
    order: "desc",
  },
];
var master_schedule = [
  {
    day: "sunday",
    shift1_end: null,
    shift1_start: null,
    shift2_end: null,
    shift2_start: null,
    start_location: {
      lat: null,
      lng: null,
    },
  },
  {
    day: "Monday",
    shift1_end: null,
    shift1_start: null,
    shift2_end: null,
    shift2_start: null,
    start_location: {
      lat: null,
      lng: null,
    },
  },
  {
    day: "Tuesday",
    shift1_end: null,
    shift1_start: null,
    shift2_end: null,
    shift2_start: null,
    start_location: {
      lat: null,
      lng: null,
    },
  },
  {
    day: "Wednesday",
    shift1_end: null,
    shift1_start: null,
    shift2_end: null,
    shift2_start: null,
    start_location: {
      lat: null,
      lng: null,
    },
  },
  {
    day: "Thursday",
    shift1_end: null,
    shift1_start: null,
    shift2_end: null,
    shift2_start: null,
    start_location: {
      lat: null,
      lng: null,
    },
  },
  {
    day: "Friday",
    shift1_end: null,
    shift1_start: null,
    shift2_end: null,
    shift2_start: null,
    start_location: {
      lat: null,
      lng: null,
    },
  },
  {
    day: "Saturday",
    shift1_end: null,
    shift1_start: null,
    shift2_end: null,
    shift2_start: null,
    start_location: {
      lat: null,
      lng: null,
    },
  },
];

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={props.zoomLevel}
    zoom={props.zoomLevel}
    defaultCenter={props.latLong}
    onClick={props.onMapClick}
    center={props.latLong}
  >
    {props.isMarkerShown && (
      <Marker position={props.latLong} onClick={props.onMarkerClick} />
    )}
  </GoogleMap>
));
var maplocation;
export default class AddVehicle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loderStatus: false,
      name: "",
      name_ar: "",
      description: " ",
      description_ar: "",
      additionalNote: "",
      additionalNote_ar: "",
      helperName: "",
      helperName_ar: "",
      helperNumber: "",
      downloadURL: "",
      file: "",
      imagePreviewUrl: "",
      spemail: "",
      additionalNote: "",
      number: "",
      modal: false,
      qrCodeLink: "",
      qrURL: "",
      disableVehicleField: false,
      count: 1,
      area: "",
      price: 0,
      locationDetails: "",
      selectedLocation: "",
      services: null,
      selected: [],
      selectedId: [],
      selectedAddOn: [],
      selectedIdAddOn: [],
      selectedVehicleType: [],
      selectedServices: [],
      selectedAddOnServices: [],
      isUploading: false,
      serviceableAreas: "",
      sparray: [],
      driver_ids: [],
      selected_driver_id: "",
      previewModal: false,
      selectEmirate: "",
      map_preview: "",
      map_preview_name: "",
      numberValidator: false,
      locationModal: false,
      scheduleInde: null,
      isMarkerShown: false,
      zoomLevel: 7,
      latLong: { lat: 25.547944, lng: 55.54828 },
      checked: true,
      mapArea: "",
      master_schedule: master_schedule,
      majorAreas: [],
      selected_major_area: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.generateQR = this.generateQR.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentWillUnmount() {
    selectedVehicleType = [];
    spID = "";
  }
  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };
  generateQR = () => {
    const qr = new QRious();
    qr.value = qrNumber;
    qr.padding = 10;
    this.setState({ qrCodeLink: qr.toDataURL() });
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
    if (stateName == "number") {
      qrNumber = spID + "-" + value;
      this.generateQR();
    }

    if (stateName == "description" && this.state.count == 1) {
      this.setState({
        count: 2,
        disableVehicleField: true,
      });
      this.generateQR();
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
      isUploading: false,
    });
  }
  getEmirate = (emirates) => {
    let emi = "";
    Object.entries(emirates).forEach(([key, emirate]) => {
      if (emirate.service_providers)
        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
          if (key2 === spID) {
            this.setState({ emirate: emirate });
            emi = emirate;
            return emi;
          }
        });
    });
  };
  getSP = async () => {
    this.setState({ loderStatus: true });

    const services = await RealTime.getDetails("/service_providers/");
    let newService = [];
    newService.push({
      name: "select",
      id: null,
    });
    Object.entries(services).forEach(([key, service]) => {
      newService.push({
        name: service.personal_information
          ? service.personal_information.name
          : key,
        id: key,
      });
    });
    let vehicleIds = await RealTime.getDetails(`/vehicles/`);
    this.setState({
      allVehiclesIds: Object.keys(vehicleIds),
      sparray: newService,
      loderStatus: false,
    });
  };
  selectSp = (e) => {
    spID = e.target.value;
    this.getData();
    e.preventDefault();
  };
  selectDriver = (e) => {
    this.setState({ selected_driver_id: e.target.value });
  };

  getData = async () => {
    this.setState({
      loderStatus: true,
    });
    const area = await RealTime.getDetails(
      "service_providers/" + spID + "/serviceable_areas"
    );
    let drivers = await RealTime.getDetails("drivers");
    let driver_ids = [{ id: "", name: "Select Driver" }];
    if (drivers) {
      Object.entries(drivers).forEach(([key, driver]) => {
        if (driver.sp_related)
          driver.sp_related.map((sp) => {
            if (
              driver.personal_information &&
              driver.personal_information.vehicle_number === "" &&
              sp.sp_id === spID
            ) {
              driver_ids.push({
                id: key,
                name: driver.personal_information.name,
              });
            }
          });
      });
    }
    if (area) {
      let activeArea = [];
      Object.entries(area).forEach(([key, value]) => {
        if (value) {
          activeArea.push(key);
        }
      });
      this.setState({
        serviceableAreas: activeArea,
      });
    }
    this.setState({
      spemail: localStorage.getItem("email"),
    });
    let servicesFromDB = await RealTime.getDetails(
      "/service_providers/" + spID + "/new_services/"
    );
    let vehicle_types = [];
    Object.entries(servicesFromDB).forEach(([key, vehhicle_type]) => {
      if (vehhicle_type.status) {
        vehicle_types.push(key);
      }
    });

    let emirates = await RealTime.getDetails("/twaddan_admin/emirates/");

    let emirateSelected = "";
    Object.entries(emirates).forEach(([key, emirate]) => {
      if (emirate.service_providers)
        Object.entries(emirate.service_providers).forEach(([key2, sp]) => {
          if (key2 === spID) {
            this.setState({
              emirate: key,
              majorAreas: emirate.majorAreas
                ? Object.keys(emirate.majorAreas)
                : [],
            });
            emirateSelected = key;
            return emirateSelected;
          }
        });
    });

    let servicesAreasFromDB = await RealTime.getDetails(
      "/twaddan_admin/emirates/" + emirateSelected + "/serviceable_areas"
    );
    let addOnService = [];
    let services = [];
    if (servicesFromDB) {
      Object.entries(servicesFromDB).forEach(([key, vehicle_type]) => {
        if (vehicle_type.major_services)
          Object.entries(vehicle_type.major_services).forEach(
            ([key2, service]) => {
              if (service.status)
                services.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
        if (vehicle_type.add_on_services)
          Object.entries(vehicle_type.add_on_services).forEach(
            ([key2, service]) => {
              if (service.status)
                addOnService.push({
                  id: key + "_" + key2,
                  vehicle_type: key,
                  service_id: key2,
                  service_name: service.name,
                  price: service.price,
                });
            }
          );
      });
    }
    this.setState({
      services: services,
      servicesCopy: services,
      servicesFromDB: servicesFromDB,
      vehicle_types: vehicle_types,
      addOnServices: addOnService,
      addOnServicesCopy: addOnService,
      servicesAreasFromDB: servicesAreasFromDB,
      loderStatus: false,
      driver_ids: driver_ids,
    });
  };

  async componentDidMount() {
    this.getSP();
    this.setState({ loderStatus: true });
  }
  validate = () => {
    let alreadyExist = false;
    this.state.allVehiclesIds.map((id) => {
      if (id === this.state.number.trim()) {
        alreadyExist = true;
      }
    });
    var scheduleStatus = false;
    console.log("master_schedule: ", this.state.master_schedule);
    this.state.master_schedule.map((item) => {
      if (
        item.shift1_end === null ||
        item.shift1_start === null ||
        item.shift2_end === null ||
        item.shift2_start === null ||
        item.start_location.lat === null
      ) {
        scheduleStatus = true;
        return 0;
      }
    });
    if (alreadyExist) {
      alert("This vehicle number is already exist");
    } else if (this.state.selected.length < 1) {
      alert("Please select atleast one major service");
      return;
    } else if (this.state.locationDetails.length < 1) {
      alert("Please select atleast one serviceable area");
      return;
    } else if (scheduleStatus) {
      alert("Please Fill up master schedules completly");
      return;
    } else {
      this.uploadImage();
    }
  };
  convert = async () => {
    if (this.state.name_ar === "")
      this.setState({
        name_ar: await ConvertArabic(this.state.name),
      });
    if (this.state.description_ar === "")
      this.setState({
        description_ar: await ConvertArabic(this.state.description),
      });
    if (this.state.additionalNote_ar === "")
      this.setState({
        additionalNote_ar: await ConvertArabic(this.state.additionalNote),
      });
    if (this.state.helperName_ar === "")
      this.setState({
        helperName_ar: await ConvertArabic(this.state.helperName),
      });
  };
  uploadImage = () => {
    const extension = this.state.file.name?.split(".").pop();
    this.setState({ isUploading: true });
    firebase
      .storage()
      .ref(
        "/service_providers/" +
          spID +
          "/vehicles/" +
          "qrcode_" +
          this.state.number +
          ".png"
      )
      .putString(this.state.qrCodeLink, "data_url")
      .then(function (snapshot) {
        return snapshot.ref.getDownloadURL();
      })
      .then((qrURL) => {
        this.setState({
          qrURL: qrURL,
        });
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
    // this.pushData();

    firebase
      .storage()
      .ref(
        "/service_providers/" +
          spID +
          "/vehicles/" +
          "vehicle_" +
          this.state.number +
          "." +
          extension
      )
      .put(this.state.file)
      .then((snapshot) => {
        // Will return a promise with the download link
        return snapshot.ref.getDownloadURL();
      })
      .then((downloadURL) => {
        this.setState({
          downloadURL: downloadURL,
        });
        this.pushData();
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  getTimestampWithTime = (timestamp, hours, minutes, seconds) => {
    var d;
    if (timestamp) {
      d = new Date(timestamp);
    } else {
      d = new Date();
    }
    var year = d.getFullYear();
    var month = d.getMonth();
    var date = d.getDate();
    var datum = new Date(Date.UTC(year, month, date, hours, minutes, seconds));
    return datum.getTime();
  };
  addAdctualSchedule = async () => {
    var errors = false;
    var vehicle_nuber = this.state.number;
    console.log("vehicle_nuber: ", vehicle_nuber);
    const axios = require("axios").default;
    const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/vehicleAddShiftScheduler`;
    const reply = await axios
      .post(url, {
        data: { vehicleId: vehicle_nuber },
      })
      .catch((error) => {
        console.log("error: ", error);
        errors = true;
      });
    if (errors) {
      this.addAdctualSchedule();
    }
    console.log("reply: ", reply);
    if (reply) this.toggle();
  };

  pushData = async () => {
    let servicesFromDB = this.state.servicesFromDB;
    let driver_assigned = this.state.selected_driver_id !== "";
    let vts = {};
    this.state.selected.map(async (item) => {
      if (!(item.vehicle_type in vts)) {
        vts[item.vehicle_type] = {
          name: servicesFromDB[item.vehicle_type].name,
          name_ar: servicesFromDB[item.vehicle_type].name_ar,
          status: servicesFromDB[item.vehicle_type].status,
          major_services: {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].major_services[item.service_id],
          },
        };
      } else {
        if (vts[item.vehicle_type].major_services)
          if (!(item.service_id in vts[item.vehicle_type].major_services)) {
            vts[item.vehicle_type].major_services[item.service_id] =
              servicesFromDB[item.vehicle_type].major_services[item.service_id];
          }
      }
    });
    this.state.selectedAddOn.map((item) => {
      if (vts[item.vehicle_type])
        if (!("add_on_services" in vts[item.vehicle_type])) {
          vts[item.vehicle_type].add_on_services = {
            [item.service_id]:
              servicesFromDB[item.vehicle_type].add_on_services[
                item.service_id
              ],
          };
        } else {
          vts[item.vehicle_type].add_on_services[item.service_id] =
            servicesFromDB[item.vehicle_type].add_on_services[item.service_id];
        }
    });
    let servicesAreasFromDB = this.state.servicesAreasFromDB;
    this.state.locationDetails.map((location) => {
      Object.entries(servicesAreasFromDB).forEach(([key, locationFromDB]) => {
        if (location.area === key) {
          if (!("service_providers" in locationFromDB)) {
            servicesAreasFromDB[location.area].service_providers = {
              [spID]: {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: driver_assigned,
                    price: Number(location.price),
                  },
                },
              },
            };
          } else {
            if (spID in servicesAreasFromDB[location.area].service_providers) {
              servicesAreasFromDB[location.area].service_providers[
                spID
              ].vehicles[this.state.number] = {
                status: driver_assigned,
                price: Number(location.price),
              };
            } else {
              servicesAreasFromDB[location.area].service_providers[spID] = {
                status: true,
                vehicles: {
                  [this.state.number]: {
                    status: driver_assigned,
                    price: Number(location.price),
                  },
                },
              };
            }
          }
        }
      });
    });
    firebase
      .database()
      .ref(
        "/twaddan_admin/emirates/" + this.state.emirate + "/serviceable_areas"
      )
      .update(servicesAreasFromDB);
    var time = new Date();
    var dataSync = false;
    time = time.getTime();
    firebase
      .database()
      .ref("service_providers/" + spID + "/vehicles/" + this.state.number + "/")
      .set({
        serviceable_areas: this.state.locationDetails,
        vehicle_type: vts,
        time_stamp: time,
      });
    await firebase
      .database()
      .ref("vehicles/" + this.state.number + "/")
      .set({
        // actual_schedule: actual_schedule,
        auto_schedule_status: this.state.checked,
        name: this.state.name,
        name_ar: this.state.name_ar,
        number: this.state.number,
        helperName: this.state.helperName,
        helperName_ar: this.state.helperName_ar,
        helperNumber: this.state.helperNumber,
        driver: this.state.selected_driver_id,
        driver_status: false,
        status: true,
        image_url: this.state.downloadURL,
        qrcode_url: this.state.qrURL,
        registeredDate: time,
        emiateArea: this.state.selected_major_area,
        master_schedule: this.state.master_schedule,
        sp_related: [
          {
            sp_id: spID,
            emirate: this.state.emirate,
            status: true,
            emiateArea: this.state.selected_major_area,
          },
        ],
      });
    var spName;
    //updating vehicle number in driver node
    if (this.state.selected_driver_id !== "") {
      let driver_history = [
        {
          driver_id: this.state.selected_driver_id,
          time_stamp: time,
          action: "driver assigned",
        },
      ];
      firebase
        .database()
        .ref("vehicles/" + this.state.number + "/")
        .update({
          driver_history: driver_history,
          emiateArea: this.state.selected_major_area,
        });
      RealTime.updateNode(
        "drivers/" + this.state.selected_driver_id + "/personal_information/",
        {
          vehicle_number: this.state.number,
          emiateArea: this.state.selected_major_area,
        }
      );
      RealTime.updateNode("drivers/" + this.state.selected_driver_id + "/", {
        sp_related: [
          {
            sp_id: spID,
            emirate: this.state.emirate,
            status: true,
            emiateArea: this.state.selected_major_area,
          },
        ],
      });
    }
    firebase
      .database()
      .ref("service_providers/" + spID + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Added New Vehicle named " +
      this.state.name +
      " with Vehicle Number " +
      this.state.number +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spID;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });
    this.addAdctualSchedule();
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleSubmit(event) {
    event.preventDefault();
    this.validate();
  }

  navigate = () => {
    this.setState({
      vehicle: 1,
    });
  };

  toggleMapPreview = () => {
    this.setState({
      previewModal: !this.state.previewModal,
    });
  };

  handleMapPreview = async (area) => {
    if (area) {
      this.setState({
        map_preview: "",
        map_preview_name: "",
      });
      const emirates = await RealTime.getDetails("twaddan_admin/emirates");
      if (emirates) {
        Object.entries(emirates).map(([key, value]) => {
          if (value.service_providers) {
            Object.keys(value.service_providers).map((value1, key1) => {
              if (value1 === spID) {
                if (value.serviceable_areas) {
                  Object.entries(value.serviceable_areas).map(
                    ([key2, value2]) => {
                      if (key2 === area) {
                        this.setState({
                          map_preview: value2.map_preview,
                          map_preview_name: area,
                          previewModal: true,
                        });
                      }
                    }
                  );
                }
              }
            });
          }
        });
      }
    }
  };

  handleSubmitLocation = (event) => {
    var flag = true;
    if (this.state.area !== "" && this.state.price !== "") {
      location.map((loc) => {
        if (loc.area === this.state.area) {
          flag = false;
        }
      });
      if (flag) {
        location.unshift({
          area: this.state.area,
          price: Number(this.state.price),
          status: true,
        });
      }
      this.setState({
        locationDetails: location,
      });
      locationEdit = false;
    }
    let dropDownItem = this.state.serviceableAreas;
    let index = dropDownItem.indexOf(this.state.area);
    dropDownItem.splice(index, 1);
    this.setState({
      area: "",
      price: 0,
      serviceableAreas: dropDownItem,
    });
    event.preventDefault();
  };

  removeLocation = (area) => {
    var locationDetails = location;
    const filteredLocation = locationDetails.filter(
      (item) => item.area !== area
    );
    location = filteredLocation;
    this.setState({
      locationDetails: location,
    });
    let addRemoved = this.state.serviceableAreas;
    addRemoved.push(area);
    this.setState({
      serviceableAreas: addRemoved,
    });
  };
  handleSelectAllAreas = () => {
    this.state.serviceableAreas.map((item) => {
      location.push({
        area: item,
        price: Number(0),
        status: true,
      });
    });
    this.setState({
      locationDetails: location,
      serviceableAreas: [],
    });
  };
  editLocation = (location) => {
    this.setState({
      area: location.area,
      price: Number(location.price),
    });
    this.removeLocation(location.area);
  };

  handleChangePlace = (area) => {
    this.setState({
      area,
    });
  };

  handleSelectPlace = (area) => {
    this.setState({
      area: area,
    });
    geocodeByAddress(area)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };
  toggleCheckBoxChange = (name) => {
    let selectedServices = [];
    let selectedAddOnServices = [];
    const { servicesCopy, addOnServicesCopy } = this.state;
    let isSelect = false;
    if (!selectedVehicleType.includes(name)) {
      isSelect = true;
      selectedVehicleType = [...selectedVehicleType, name];
    } else {
      selectedVehicleType = selectedVehicleType.filter((x) => x !== name);
    }
    this.setState(
      {
        selectedVehicleType: selectedVehicleType,
      },
      () => {
        if (this.state.selectedVehicleType.length > 0) {
          Object.entries(servicesCopy).forEach(([key, service]) => {
            this.state.selectedVehicleType.map((vehicle_type) => {
              if (service.vehicle_type === vehicle_type) {
                selectedServices.push(service);
              }
            });
          });
          Object.entries(addOnServicesCopy).forEach(([key, addOnService]) => {
            this.state.selectedVehicleType.map((vehicle_type) => {
              if (addOnService.vehicle_type === vehicle_type) {
                selectedAddOnServices.push(addOnService);
              }
            });
          });
          this.setState({
            services: selectedServices,
            addOnServices: selectedAddOnServices,
            isVehicleTypeSelected: true,
          });
        } else {
          this.setState({
            services: [],
            addOnServices: [],
            isVehicleTypeSelected: false,
          });
        }
      }
    );
  };
  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row],
        selectedId: [...this.state.selectedId, row.id],
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x !== row),
        selectedId: this.state.selectedId.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selected: row,
        selectedId: ids,
      }));
    } else {
      this.setState(() => ({
        selected: [],
        selectedId: [],
      }));
    }
  };
  handleOnSelectAddOn = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: [...this.state.selectedAddOn, row],
        selectedIdAddOn: [...this.state.selectedIdAddOn, row.id],
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: this.state.selectedAddOn.filter((x) => x !== row),
        selectedIdAddOn: this.state.selectedIdAddOn.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAllAddOn = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    const row = rows.map((r) => r);
    if (isSelect) {
      this.setState(() => ({
        selectedAddOn: row,
        selectedIdAddOn: ids,
      }));
    } else {
      this.setState(() => ({
        selectedAddOn: [],
        selectedIdAddOn: [],
      }));
    }
  };
  validateNumber = (number) => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    this.setState({
      numberValidator: format.test(number) ? true : false,
    });
  };
  onScheduleChange = (time, index, ob) => {
    master_schedule[index][ob] = time;
    this.setState({ master_schedule: master_schedule });
  };
  handleSetLocation = (e, index) => {
    e.preventDefault();
    this.setState({ locationModal: true, scheduleInde: index });
    if (master_schedule[index].start_location.lng !== null)
      this.setState({
        latLong: {
          lat: master_schedule[index].start_location.lat,
          lng: master_schedule[index].start_location.lng,
        },
        master_schedule: master_schedule,
      });
  };
  toggleLocationModal = () => {
    this.setState({ locationModal: false });
  };

  handleMapClick = async (e) => {
    this.fetchAddress(e.latLng.lat(), e.latLng.lng());
    this.setState({
      zoomLevel: 14,
      isMarkerShown: true,
    });
  };
  handleChangePlace = (mapArea) => {
    this.setState({
      mapArea,
    });
  };
  handleSelectPlace = async (mapArea) => {
    this.setState({
      loderStatus: true,
    });
    this.setState({
      mapArea: mapArea,
      mapPreview: mapArea,
    });
    await geocodeByAddress(mapArea)
      .then(async (results) => await getLatLng(results[0]))
      .then((latLng) => {
        this.setState(
          {
            latLng: latLng,
            latLong: { lat: latLng.lat, lng: latLng.lng },
            zoomLevel: 14,
            isMarkerShown: true,
          },
          () => {
            this.fetchAddress(this.state.latLng.lat, this.state.latLng.lng);
          }
        );
      });
  };
  fetchAddress = async (lat, lng) => {
    this.setState({
      loderStatus: true,
    });
    var address = null;
    const url =
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      lat +
      "," +
      lng +
      "&key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0";
    const reply = await axios.get(url);
    if (reply.data.results.length === 0) {
      this.setState({
        loderStatus: false,
        showSnackbar: true,
        snackbarMessage: "Select a valid place",
        snackBarColor: "#e3331b",
      });
    } else {
      address =
        "Address from Location :" + reply.data.results[0].formatted_address;
    }
    this.setState({
      loderStatus: false,
      customer_raw_address: address,
      latLong: { lat: lat, lng: lng },
    });
    return address;
  };
  setSameLocation = (e) => {
    e.preventDefault();
    if (this.state.scheduleInde !== null) {
      master_schedule[this.state.scheduleInde].start_location.lat =
        this.state.latLong.lat;
      master_schedule[this.state.scheduleInde].start_location.lng =
        this.state.latLong.lng;
      this.setState({ locationModal: false });
    }
  };

  render() {
    if (this.state.vehicle == 1) {
      return <Redirect to="/admin/vehicles" />;
    }
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl, sparray, driver_ids } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    let { qrCodeLink } = this.state;
    let $qrcodePreview = null;
    if (qrCodeLink) {
      $qrcodePreview = <img src={qrCodeLink} style={imagestyle} />;
    }
    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedId,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
    const selectRowAddOn = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selectedIdAddOn,
      onSelect: this.handleOnSelectAddOn,
      onSelectAll: this.handleOnSelectAllAddOn,
    };
    let areaList = [];
    this.state.serviceableAreas.length > 0 &&
      this.state.serviceableAreas.map((i) => {
        areaList.push({ value: i, label: i });
      }, this);
    let driver_list =
      driver_ids.length > 0 &&
      driver_ids.map((i) => {
        return <option value={i.id}>{i.name}</option>;
      }, this);
    let major_areas =
      this.state.majorAreas.length > 0 &&
      this.state.majorAreas.map((i) => {
        return <option value={i}>{i}</option>;
      }, this);
    let sparrayList =
      sparray.length > 0 &&
      sparray.map((i) => {
        return <option value={i.id}>{i.name}</option>;
      }, this);
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={this.state.loderStatus}></PageLoader>
        <PageLoader loading={this.state.isUploading}></PageLoader>
        <Container className=" mt--7" fluid>
          <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                  <h3 className="mb-0">Vehicle Details</h3>
                </Col>
                <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                  <Button
                    block
                    color="primary"
                    className="button_back"
                    onClick={this.navigate}
                  >
                    BACK
                  </Button>
                </Col>
              </Row>
              {/* <CardTitle>Add Vehicle</CardTitle> */}
            </CardHeader>
            <CardBody>
              <Row form>
                <Col xs={12}>
                  <FormGroup row>
                    <Label xs={12} sm={4} md={4} lg={4} xl={3} for="spid">
                      Select Service Provider
                    </Label>
                    <Col xs={12} sm={8} md={8} lg={8} xl={9}>
                      <Input
                        type="select"
                        name="spid"
                        id="spid"
                        value={this.state.spidd}
                        onChange={(e) => this.selectSp(e)}
                      >
                        {sparrayList}
                      </Input>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              {spID && (
                <Form onSubmit={this.handleSubmit}>
                  {/* <Button onClick={this.test}>test</Button> */}
                  <FormGroup>
                    <Label for="File">Add image</Label>
                    <br></br>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={this._handleImageChange}
                    />
                    <FormText color="muted">
                      Select the picture of the vehicle that you wanted to
                      display.
                    </FormText>
                    <Row>
                      <Col md={4} xs={2}>
                        <div style={imagestyle}>{$imagePreview}</div>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-delivery-fast" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Vehicle Name"
                        type="text"
                        value={this.state.name}
                        required
                        onChange={(e) => this.onChange("name", e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-delivery-fast" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Vehicle Name Arabic"
                        type="text"
                        value={this.state.name_ar}
                        required
                        onChange={(e) =>
                          this.onChange("name_ar", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-credit-card" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        // disabled = {this.state.disableVehicleField}
                        placeholder="Vehicle Number"
                        type="text"
                        required
                        invalid={this.state.numberValidator}
                        onChange={(e) => {
                          this.onChange("number", e.target.value);
                          this.validateNumber(e.target.value);
                        }}
                      />
                      <FormFeedback>
                        invalid vehicle number, hint: don't use special
                        characters
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  {false && (
                    <>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-bullet-list-67" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            // disabled={this.state.number ===""?true:false}
                            placeholder="Vehicle Description"
                            type="text"
                            required
                            value={this.state.description}
                            onChange={(e) =>
                              this.onChange("description", "service vehilce")
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-bullet-list-67" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            // disabled={this.state.number ===""?true:false}
                            placeholder="Vehicle Description arabic"
                            type="text"
                            value={this.state.description_ar}
                            required
                            onChange={(e) =>
                              this.onChange("description_ar", e.target.value)
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-ruler-pencil" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="textarea"
                            name="note"
                            value={this.state.additionalNote}
                            id="note"
                            placeholder="Additional Information to know more about vehicle"
                            onChange={(e) =>
                              this.onChange("additionalNote", "e.target.value")
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-ruler-pencil" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="textarea"
                            name="note"
                            id="note"
                            value={this.state.additionalNote_ar}
                            placeholder="Additional Information to know more about vehicle arabic"
                            onChange={(e) =>
                              this.onChange("additionalNote_ar", e.target.value)
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    </>
                  )}

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-delivery-fast" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Helper Name"
                        type="text"
                        value={this.state.helperName}
                        required
                        onChange={(e) =>
                          this.onChange("helperName", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-delivery-fast" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Helper Name Arabic"
                        type="text"
                        value={this.state.helperName_ar}
                        required
                        onChange={(e) =>
                          this.onChange("helperName_ar", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-delivery-fast" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Helper Number"
                        type="text"
                        value={this.state.helperNumber}
                        required
                        onChange={(e) =>
                          this.onChange("helperNumber", e.target.value)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <Label for="spid">Select Driver</Label>
                    <Input
                      type="select"
                      name="spid"
                      id="spid"
                      value={this.state.selected_driver_id}
                      onChange={(e) => this.selectDriver(e)}
                    >
                      {driver_list}
                    </Input>
                  </FormGroup>
                  {major_areas.length > 0 && (
                    <FormGroup>
                      <Label for="spid">Select Major Area</Label>
                      <Input
                        type="select"
                        name="spid"
                        id="spid"
                        value={this.state.selected_major_area}
                        onChange={(e) =>
                          this.setState({ selected_major_area: e.target.value })
                        }
                      >
                        <option hidden>Select</option>
                        {major_areas}
                      </Input>
                    </FormGroup>
                  )}
                  {/* Services adding */}
                  <h6 className="heading-small text-muted mb-4">
                    Select Vehicle Type
                  </h6>
                  <div className="pl-lg-4">
                    {this.state.vehicle_types &&
                      this.state.vehicle_types.map((line, key) => {
                        return (
                          <>
                            <Col lg="4">
                              <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  type="checkbox"
                                  id={line}
                                  // defaultChecked={this.state.vehicleTypesList[line]}
                                  onClick={() => {
                                    this.toggleCheckBoxChange(line);
                                  }}
                                />

                                <label
                                  className="custom-control-label"
                                  htmlFor={line}
                                >
                                  {line.replace("_", " ")}
                                </label>
                              </div>
                            </Col>
                          </>
                        );
                      })}
                  </div>
                  <br />
                  <h6 className="heading-small text-muted mb-4">
                    {this.state.isVehicleTypeSelected ? "Select Services" : ""}
                  </h6>
                  <div>
                    {this.state.isVehicleTypeSelected && (
                      <BootstrapTable
                        keyField="id"
                        data={this.state.services}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition="top"
                        //pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        selectRow={selectRow}
                        hover
                        wrapperClasses="table-responsive"
                        condensed
                        // rowEvents={rowEvents}
                      />
                    )}
                  </div>
                  <h6 className="heading-small text-muted mb-4">
                    {this.state.isVehicleTypeSelected
                      ? "Select Add On Services"
                      : ""}{" "}
                  </h6>
                  <div>
                    {this.state.isVehicleTypeSelected && (
                      <BootstrapTable
                        keyField="id"
                        data={this.state.addOnServices}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition="top"
                        //pagination={paginationFactory()}
                        defaultSorted={defaultSorted}
                        bordered={false}
                        selectRow={selectRowAddOn}
                        hover
                        wrapperClasses="table-responsive"
                        condensed
                        // rowEvents={rowEvents}
                      />
                    )}
                  </div>
                  {/* Scheduler on or off status */}
                  <Row>
                    <Col lg={5} md={8} sm={10}>
                      <h2>{`Auto Schedule ${
                        this.state.checked ? "On" : "Off"
                      }`}</h2>
                    </Col>
                    <Col lg={3} md={4} sm={2}>
                      <Switch
                        onChange={() => {
                          this.setState({
                            checked: !this.state.checked,
                          });
                        }}
                        checked={this.state.checked}
                      />
                    </Col>
                  </Row>
                  {/* ------Master schedule-------------------- */}
                  <Row form>
                    <Col md={12}>
                      <h2>Master schedule</h2>
                    </Col>
                  </Row>
                  <Form name="placeCharge" id="placeCharge">
                    {this.state.master_schedule.map((schedule, index) => {
                      return (
                        <>
                          <Row form>
                            <Col
                              md={4}
                              style={{ marginTop: "44px", textAlign: "center" }}
                            >
                              <h4>{schedule.day}</h4>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label>Schedule 1 Start Time</Label>
                                <Input
                                  type="time"
                                  name="Star_Time1"
                                  id="Star_Time1"
                                  required
                                  value={schedule.shift1_start}
                                  onChange={(e) =>
                                    this.onScheduleChange(
                                      e.target.value,
                                      index,
                                      "shift1_start"
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label>Schedule 1 End Time</Label>
                                <Input
                                  type="time"
                                  name="end_time1"
                                  id="end_time1"
                                  required
                                  value={schedule.shift1_end}
                                  onChange={(e) =>
                                    this.onScheduleChange(
                                      e.target.value,
                                      index,
                                      "shift1_end"
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4} style={{ marginTop: "34px" }}>
                              <FormGroup>
                                <Button
                                  type="button"
                                  color={
                                    master_schedule[index].start_location
                                      .lat === null
                                      ? "danger"
                                      : "info"
                                  }
                                  onClick={(e) => {
                                    this.handleSetLocation(e, index);
                                  }}
                                  block
                                >
                                  {master_schedule[index].start_location.lat ===
                                  null
                                    ? " Set start location"
                                    : " Change start location"}
                                </Button>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label>Schedule 2 Start Time</Label>
                                <Input
                                  type="time"
                                  name="start_time2"
                                  id="start_time2"
                                  required
                                  value={schedule.shift2_start}
                                  onChange={(e) =>
                                    this.onScheduleChange(
                                      e.target.value,
                                      index,
                                      "shift2_start"
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>{" "}
                            <Col md={4}>
                              <FormGroup>
                                <Label>Schedule 2 End Time</Label>
                                <Input
                                  type="time"
                                  name="end_time2"
                                  id="end_time2"
                                  required
                                  value={schedule.shift2_end}
                                  onChange={(e) =>
                                    this.onScheduleChange(
                                      e.target.value,
                                      index,
                                      "shift2_end"
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </Form>
                  <Modal isOpen={this.state.locationModal}>
                    <ModalBody>
                      <Row>
                        <Col md={12} hidden={true}>
                          <PlacesAutocomplete
                            value={this.state.area}
                            onChange={this.handleChangePlace}
                            searchOptions={{
                              componentRestrictions: { country: ["ae"] },
                            }}
                            // searchOptions={searchOptions}
                            onSelect={this.handleSelectPlace}
                          >
                            {({
                              getInputProps,
                              getLatLng,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <FormGroup>
                                  <InputGroup className="input-group-alternative mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ni ni-square-pin" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      value={this.state.area}
                                      onChange={(e) =>
                                        this.setState({
                                          area: e.target.value,
                                        })
                                      }
                                      {...getInputProps({
                                        placeholder: "Search Places ...",
                                        className: "location-search-input",
                                      })}
                                    />
                                  </InputGroup>
                                </FormGroup>
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#88ebfc",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <MyMapComponent
                            isMarkerShown={this.state.isMarkerShown}
                            latLong={this.state.latLong}
                            zoomLevel={this.state.zoomLevel}
                            onMarkerClick={this.handleMarkerClick}
                            onMapClick={this.handleMapClick}
                          />
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        disabled={!this.state.isMarkerShown}
                        onClick={(e) => {
                          this.setSameLocation(e);
                        }}
                      >
                        Set location
                      </Button>
                      <Button
                        color="primary"
                        onClick={this.toggleLocationModal}
                      >
                        CLOSE
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* -----Area and price management */}
                  <Form name="placeCharge" id="placeCharge">
                    <Row form>
                      <Col md={4}>
                        <Select
                          onChange={(area) => {
                            this.setState({ area: area.label });
                          }}
                          options={areaList}
                          isSearchable={true}
                        />
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-credit-card" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="number"
                              name="price"
                              id="price"
                              placeholder="Enter price"
                              required
                              value={this.state.price}
                              onChange={(e) =>
                                this.onChange("price", e.target.value)
                              }
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Row>
                            <Col md={4}>
                              <Button
                                type="button"
                                color="primary"
                                disabled={this.state.area ? false : true}
                                onClick={() => {
                                  this.handleMapPreview(this.state.area);
                                }}
                                block
                              >
                                PREVIEW
                              </Button>
                            </Col>
                            <Col md={4}>
                              <Button
                                type="button"
                                color="success"
                                onClick={this.handleSubmitLocation}
                                block
                              >
                                {locationEdit ? "UPDATE" : "ADD"}
                              </Button>
                            </Col>
                            <Col md={4}>
                              <Button
                                type="button"
                                color="info"
                                onClick={this.handleSelectAllAreas}
                                block
                                // disabled={location.length > 0 ? true : false}
                              >
                                ADD ALL AREAS
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <FormGroup>
                    {this.state.locationDetails.length > 0 && (
                      <div>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Area</th>
                              <th>Price</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          {this.state.locationDetails.map((location) => (
                            <tbody>
                              <tr>
                                <td>{location.area}</td>
                                <td>{location.price}</td>
                                <td>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={() => {
                                      this.handleMapPreview(location.area);
                                    }}
                                  >
                                    PREVIEW
                                  </Button>
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      this.removeLocation(location.area);
                                    }}
                                  >
                                    REMOVE
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          ))}{" "}
                        </Table>
                      </div>
                    )}

                    {this.state.number && (
                      <Row>
                        {/* 
                        <Col>
                          <QRCode
                            id="qrcode"
                            value={this.state.number}
                            size={290}
                            level={"H"}
                            includeMargin={true}
                          />
                        </Col> 
                      */}
                        <Col>
                          <Col md={4} xs={4}>
                            <div style={imagestyle}>{$qrcodePreview}</div>
                          </Col>
                        </Col>
                        <Col>
                          <br />
                          <br />
                          <br />
                          <br />
                          <div className="col">
                            <h3 className="mb-0">
                              QR Code will be generated for the vehicle ...
                            </h3>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </FormGroup>
                  <Row>
                    <Col xs={12} sm={12} md={5} lg={4} xl={2}>
                      <Button
                        className="buttonConvert"
                        color="primary"
                        block
                        onClick={this.convert}
                      >
                        CONVERT TO ARABIC
                      </Button>
                    </Col>
                    <Col
                      className="buttonCancelSubmitCol"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={3}
                      xl={2}
                    >
                      <Button block onClick={this.navigate}>
                        CANCEL
                      </Button>
                    </Col>
                    <Col
                      className="buttonCancelSubmitCol"
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={2}
                    >
                      <Button block color="success" value="Submit">
                        ADD VEHICLE
                      </Button>
                    </Col>
                  </Row>
                  {/* <Button onClick={this.navigate}>CANCEL</Button>{" "}
                  <Button color="success" value="Submit">
                    {" "}
                    ADD VEHICLE{" "}
                  </Button> */}
                </Form>
              )}
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>Congratulation new vehicle added Successfully</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.previewModal}
          toggle={this.toggleMapPreview}
          size="lg"
        >
          <ModalHeader toggle={this.toggleMapPreview}>
            {this.state.map_preview_name}
          </ModalHeader>
          <ModalBody>
            <>
              <div className="containerIframe">
                <iframe
                  class="responsive-iframe"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${this.state.map_preview}`}
                  allowfullscreen
                ></iframe>
              </div>
            </>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.toggleMapPreview}>CLOSE</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
