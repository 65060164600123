import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.jsx";
import AuthFooter from "components/Footers/AuthFooter.jsx";
import firebase from "firebaseConfig";

import routes from "routes.js";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    if (this.props.authState.loggedIn) {

      /* 
      var permissionPages = [];
      var userID;
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in.
          console.log('The current users: ', user.email);
      
          userID = user.email;
          userID = userID.replace("@","_");
          userID = userID.replace(".","_");
          
          var userDetails;
          firebase.database().ref('/twaddan_admin/userPermission/'+userID).once('value', function(snapshot) {
            userDetails = snapshot.val();
      
            permissionPages = [];
            if(userDetails.page_permission.addVehicle == true) {
              permissionPages.push("addVehicle");
            }
            if(userDetails.page_permission.addBooking == true) {
              permissionPages.push("addBooking");
            }
            if(userDetails.page_permission.addServices == true) {
              permissionPages.push("addServices");
            }
            if(userDetails.page_permission.addServiceCenter == true) {
              permissionPages.push("addServiceCenter");
            }
            if(userDetails.page_permission.addDriver == true) {
              permissionPages.push("addDriver");
            }
            permissionPages.push("All")
          }).then(() => {
            console.log("inininininininininin"); 
          });          
        }
      })  
      */
      
      return <Redirect to="/admin/index" />;

    }
    return (
      <>
        <div className="main-content">
          <AuthNavbar />
          <div className="header bg-gradient-info py-7 py-lg-7">
          </div>

          <div className="header bg-gradient-info py-7 py-lg-5">
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Switch>{this.getRoutes(routes)}</Switch>
              </Row>
            </Container>
          </div>
        </div>
        <AuthFooter />
      </>
    );
  }
}

const mapStateToProps = state => ({
  ...state
});
export default connect(
  mapStateToProps,
  {}
)(Auth);
