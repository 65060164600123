import React from "react";
import firebase from "firebaseConfig";
import storage from "firebaseConfig";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import firebaseConfig from "../../firebaseConfig";
import Select from "react-select";
import Switch from "react-switch";

// reactstrap components
import {
  Container,
  Button,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Row,
  Col,
  FormText,
  Modal,
  ModalBody,
  ModalFooter,
  CardHeader,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import QRious, { bind } from "qrious";
import PageLoader from "components/PageLoader/PageLoader";
import RealTime from "services/RealTime";

import "../styles/ResponsiveIFrame.css";
var vehicleTypes = [];
var saID;
let location = [];
let areaList;

// sp details in firebase Auth
var superAdminDetails = firebase.auth().currentUser;
firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);
    superAdminDetails = user;

    saID = superAdminDetails.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");

    // console.log('The current user email: ', this.state.sp_email);
    console.log("The current user id: ", saID);

    firebase
      .database()
      .ref("/twaddan_admin/vehicle_type/")
      .on("value", function (snapshot) {
        vehicleTypes = snapshot.val();

        if (vehicleTypes) {
          Object.keys(vehicleTypes).map((line, key) => {
            vehicleTypes[line] = !vehicleTypes[line];
          });
        }
      });
  } else {
    // No user is signed in.
  }
});

class AddServiceCenters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      username: "",
      username_ar: "",
      ownerName: "",
      ownerEmail: "",
      email: "",
      serviceProviderID: "",
      phoneNumber: "",
      phoneNumber_owner: "",
      phoneNumber_manager: "",
      password: "",
      passwordRetype: "",
      description: "",
      address: "",
      address_ar: "",
      postalcode: "",
      description_ar: "",
      isChecked: false,
      downloadURL: "",
      file: "",
      imagePreviewUrl: "",
      image: "",
      modal: false,
      spemail: "",
      bikePrice: "",
      caravanPrice: "",
      saloonPrice: "",
      suvPrice: "",
      superSuvPrice: "",
      disabledButton: true,
      additionalVehicleTypes: [],
      qrCode: "",
      qrURL: "",
      emirates: "",
      vehicle_types: "",
      isLoaded: false,
      vehicleTypesList: vehicleTypes,
      selectedEmirate: "",
      selectedAreas: "",
      serviceable_areas: "",
      map_preview: "",
      selected_map: "",
      location: "",
      addNewArea: false,
      area: "",
      locationDetails: "",
      lat: "",
      lng: "",
      scheduledOnTime: "",
      scheduledOffTime: "",
      checked: false,
    };

    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleChange = this.toggleChange.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.navigate = this.navigate.bind(this);
    this.toggleCheckBoxChange = this.toggleCheckBoxChange.bind(this);
    this.generateQR = this.generateQR.bind(this);
    this.uploadQrCode = this.uploadQrCode.bind(this);
  }

  handleChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };

  toggleChange() {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  }

  generateQR = () => {
    const qr = new QRious();
    qr.value = this.state.serviceProviderID;
    qr.padding = 10;
    this.setState({ qrCode: qr.toDataURL() });
  };
  // validate Emirate , Serving area and vehicle type selection
  validateAndSumbit = (e) => {
    if (this.state.selectedEmirate.length < 1) {
      alert("Please select Serving emirate and area");
    } else if (this.state.location.length < 1) {
      alert("Please select at least 1 Serving areas ");
    } else if (
      this.state.vehicleTypesList.length < 1 ||
      this.state.vehicleTypesList.length === undefined
    ) {
      alert("Please select at least 1 serving vehicle type ");
    } else if (String(this.state.password).length < 8) {
      alert("Password must be at least 8 characters long");
    } else {
      this.uploadImage(); // upload sp image
    }
    e.preventDefault();
  };
  // upload service provider image

  uploadImage = () => {
    this.setState({
      isLoaded: false,
    });

    firebase
      .storage()
      .ref(
        "/service_provider/" +
          this.state.serviceProviderID +
          "/personal_information/" +
          "profile_" +
          this.state.phoneNumber +
          "." +
          this.state.file?.name?.split(".").pop()
      )
      .put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      })
      .then((downloadURL) => {
        if (downloadURL) {
          console.log(
            `Successfully uploaded file and got download link - ${downloadURL}`
          );
          this.setState({
            image: downloadURL,
          });
          this.uploadQrCode(); // function to upload QR code
        } else {
          console.log(`Failed to upload file and get link `);
          this.setState({
            isLoaded: true,
          });
          alert("Failed to upload Image , Retry");
        }
      });
  };
  // upload service provider QR code

  uploadQrCode = () => {
    this.generateQR();
    firebase
      .storage()
      .ref(
        "/service_provider/" +
          this.state.serviceProviderID +
          "/personal_information/" +
          "qrcode_" +
          this.state.serviceProviderID +
          ".png"
      )
      .putString(this.state.qrCode, "data_url")
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL();
      })
      .then((qrURL) => {
        if (qrURL) {
          console.log(
            `Successfully uploaded file and got download link - ${qrURL}`
          );
          this.setState({
            qrURL: qrURL,
          });
          this.getlatLng(this.state.address); // function to find lat and lng of address
        } else {
          console.log(`Failed to upload file and get link -  `);
          this.setState({
            isLoaded: true,
          });
          alert("Failed to upload QR code , Retry");
        }
      });
  };

  // to get the lat and lng of address from reverse geocode
  getlatLng = async (address) => {
    const axios = require("axios").default;
    const url = "https://maps.googleapis.com/maps/api/geocode/json?";
    const apiKey = "AIzaSyC73FXvsbaH0gxh1lHY5CUjpAfmIGFx7KQ";
    const reply = await axios.get(url, {
      params: {
        address: address,
        key: apiKey,
      },
    });
    if (
      reply.data.status === "OK" &&
      reply.data &&
      reply.data.results[0] &&
      reply.data.results[0].geometry.location &&
      reply.data.results[0].geometry.location.lat
    ) {
      this.setState({
        lat: reply.data.results[0].geometry.location.lat,
        lng: reply.data.results[0].geometry.location.lng,
      });
      console.log(`lat and lng fetched ${this.state.lat}and ${this.state.lng}`);
      this.pushData(); // function to create SP and push data to DB
    } else {
      alert(
        "Unable to fetch lat and lng from address. Please provide google address"
      );
      this.setState({
        isLoaded: true,
      });
    }
  };
  // creating auth user
  createAuthUser = async (email, password) => {
    var uid = "";
    const axios = require("axios").default;
    const url = `https://us-central1-${firebaseConfig.options.projectId}.cloudfunctions.net/createUser`;
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios
      .post(url, {
        data: { email, password },
      })
      .catch(() => {
        return (uid = false);
      });
    if (reply && reply.data && reply.data.result && reply.data.result.uid)
      uid = reply.data.result.uid;
    else uid = false;
    return uid;
  };
  // pushing data to realtime db
  pushData = async () => {
    const vehicle_types = await RealTime.getDetails(
      "twaddan_admin/vehicle_type"
    );

    const uid = await this.createAuthUser(
      this.state.email,
      this.state.password
    );
    if (uid) {
      var time = new Date();
      time = time.getTime();
      var personal_information = {
        userId: uid,
        name: this.state.username,
        name_ar: this.state.username_ar,
        address: this.state.address,
        address_ar: this.state.address_ar,
        description: this.state.description,
        description_ar: this.state.description_ar,
        image: this.state.image,
        qr_url: this.state.qrURL,
        email_attachment: "",
        email: this.state.email,
        ownerEmail: this.state.ownerEmail,
        ownerName: this.state.ownerName,
        phone_number: this.state.phoneNumber,
        phone_number_owner: this.state.phoneNumber_owner,
        phone_number_manager: this.state.phoneNumber_manager,
        postalcode: this.state.postalcode,
        times_rated: Number(0),
        ratings: Number(0),
        employeeBehaviourRating: Number(0),
        professionalAppearanceRating: Number(0),
        qualityOfServiceRating: Number(0),
        valueOfServiceRating: Number(0),
        arrivalTimeRating: Number(0),
        registeredDate: time,
        emirate: this.state.selectedEmirate,
      };
      var location = {
        address: this.state.address,
        lat: this.state.lat,
        lng: this.state.lng,
        serving_distance: Number(10000),
      };
      var drivers_info = {
        total_active_drivers: Number(0),
        total_drivers: Number(0),
      };
      firebase
        .database()
        .ref("service_providers/" + this.state.serviceProviderID)
        .set({
          personal_information: personal_information,
          location: location,
          drivers_info: drivers_info,
          admin_status: true,
          status: Number(0),
          isSuspended: false,
          scheduledOffTime: this.state.scheduledOffTime,
          scheduledOnTime: this.state.scheduledOnTime,
          isAutoScheduled: this.state.checked,
        });

      if (this.state.selectedEmirate) {
        firebase
          .database()
          .ref(
            "twaddan_admin/emirates/" +
              this.state.selectedEmirate +
              "/service_providers"
          )
          .update({
            [this.state.serviceProviderID]: true,
          });
      }

      if (this.state.location) {
        this.state.location.map((loc) => {
          firebase
            .database()
            .ref(
              "service_providers/" +
                this.state.serviceProviderID +
                "/serviceable_areas/"
            )

            .update({ [loc]: true });
        });
      }

      if (this.state.vehicleTypesList) {
        Object.keys(this.state.vehicleTypesList).map((line, key) => {
          if (this.state.vehicleTypesList[line] == true) {
            Object.entries(vehicle_types).forEach(([keyVt, vt]) => {
              if (keyVt === line) {
                firebase
                  .database()
                  .ref(
                    "service_providers/" +
                      this.state.serviceProviderID +
                      "/new_services/"
                  )
                  .update({
                    [line]: {
                      name: vt.name,
                      name_ar: vt.name_ar,
                      status: true,
                    },
                  });
              }
            });
          }
        });
      }

      var logAction =
        "Added New Service Provider " +
        this.state.username +
        "with SP ID " +
        this.state.serviceProviderID;
      var logs = firebase
        .database()
        .ref(
          "/twaddan_admin/super_admin_users/" +
            localStorage.getItem("admin_email") +
            "/logs/" +
            time +
            "/"
        );
      logs.update({
        action: logAction,
      });
      this.toggle();
    } else {
      this.setState({
        isLoaded: true,
      });
      alert("Email already Exist");
    }
  };
  // for selecting vehicle types
  toggleCheckBoxChange = (name) => {
    var list = this.state.vehicle_types;
    list[name] = !list[name];
    this.setState({ vehicleTypesList: list });

    console.log("vehicle types selected = ", list);
  };

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });

    // console.log(stateName,value);
    if (stateName == "email") {
      let serviceProviderID = value;
      serviceProviderID = serviceProviderID.replace("@", "_");
      serviceProviderID = serviceProviderID.replace(".", "_");
      serviceProviderID = serviceProviderID.replace(".", "_");
      serviceProviderID = serviceProviderID.replace(".", "_");
      serviceProviderID = serviceProviderID.replace(".", "_");
      serviceProviderID = serviceProviderID.replace(".", "_");

      this.setState({
        serviceProviderID: serviceProviderID,
      });
    }
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
      isLoaded: true,
    });
  }
  // componentDidMount
  async componentDidMount() {
    this.setState({
      isLoaded: false,
    });
    let timeRange = [];
    for (let index = 0; index < 24; index++)
      timeRange.push({
        value: index,
        label: `${index}:00`,
      });
    this.setState({
      timeRange: timeRange,
    });
    const emirates = await RealTime.getDetails("twaddan_admin/emirates");
    const vehicle_types = await RealTime.getDetails(
      "twaddan_admin/vehicle_type"
    );
    this.getData(emirates, vehicle_types);
    this.setState({
      spemail: localStorage.getItem("email"),
    });
  }
  // to store the emirate and vehicle type
  getData = (emirates, vehicle_types) => {
    this.setState({
      emirates: Object.keys(emirates),
      vehicle_types: Object.keys(vehicle_types),
    });
    if (this.state.emirates && this.state.vehicle_types) {
      this.setState({
        isLoaded: true,
      });
    }
  };
  // to fetch the serviceable areas under the selected emirate
  getServiceableArea = async (emirate) => {
    location.length = 0;
    var map_preview = [];
    this.setState({ isLoaded: false });
    const area = await RealTime.getDetails(
      "twaddan_admin/emirates/" + emirate + "/serviceable_areas"
    );
    if (area) {
      Object.entries(area).forEach(([key, area]) => {
        map_preview.push({
          area: key,
          map_preview: area.map_preview,
        });
      });
      this.setState({
        serviceable_areas: Object.keys(area),
        map_preview: map_preview,
        isLoaded: true,
      });
    } else {
      this.setState({
        isLoaded: true,
        serviceable_areas: "",
      });
    }
  };
  // to store the map preview address of selected location
  getMapPreview = () => {
    this.state.map_preview.map((map) => {
      if (map.area === this.state.selectedAreas) {
        this.setState({
          selected_map: map.map_preview,
        });
      }
    });
  };
  // to add a new location to the list Button: ADD AREA (With the drop down)
  addServiceArea = () => {
    if (
      !location.includes(this.state.selectedAreas) &&
      this.state.selectedAreas !== ""
    ) {
      location.unshift(this.state.selectedAreas);
    }
    let dropDownItem = this.state.serviceable_areas;
    let index = dropDownItem.indexOf(this.state.selectedAreas);
    dropDownItem.splice(index, 1);
    this.setState({
      location: location,
      serviceable_areas: dropDownItem,
      selected_map: "",
      selectedAreas: "",
    });
  };
  // to remove a location from the list Button: REMOVE
  removeLocation = (area) => {
    var locationDetails = location;
    const filteredLocation = locationDetails.filter((item) => item !== area);
    location = filteredLocation;
    this.setState({
      location: location,
      selected_map: "",
    });
    let addRemoved = this.state.serviceable_areas;
    addRemoved.push(area);
    this.setState({
      serviceable_areas: addRemoved,
    });
  };
  // handle image submission
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  //  translate english to arabic
  getArabic = async (text, value) => {
    console.log(text);
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "ar",
        key: apiKey,
      },
    });
    this.setState({
      [value]: reply.data.data.translations[0].translatedText,
    });
  };

  //convert user name , address and description to arabic
  convert = () => {
    this.getArabic(this.state.username, "username_ar");
    this.getArabic(this.state.description, "description_ar");
    this.getArabic(this.state.address, "address_ar");
  };
  //navigate back to listing page
  navigate = () => {
    this.setState({
      service: 1,
    });
  };
  handleSelectAllAreas = () => {
    location = [];
    this.state.serviceable_areas.map((item) => {
      location.push(item);
    });
    this.setState({
      location: location,
      serviceable_areas: [],
    });
  };
  // render
  render() {
    if (this.state.service == 1) {
      return <Redirect to="/admin/serviceCenters" />;
    }

    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }

    var vehicleTypeSelectionCheck = false;
    if (vehicleTypes) {
      Object.keys(vehicleTypes).map((line, key) => {
        if (vehicleTypes[line] == true) {
          vehicleTypeSelectionCheck = true;
          return;
        }
      });
    }

    let emiratesList =
      this.state.emirates.length > 0 &&
      this.state.emirates.map((i) => {
        return <option>{i}</option>;
      }, this);

    let areaList = [];
    this.state.serviceable_areas.length > 0 &&
      this.state.serviceable_areas.map((i) => {
        areaList.push({ value: i, label: i });
      }, this);

    const {
      username,
      email,
      password,
      passwordRetype,
      description,
      phoneNumber,
      isChecked,
      error,
      disabledButton,
    } = this.state;

    const isInvalid =
      password !== passwordRetype ||
      password === "" ||
      email === "" ||
      !isChecked ||
      username === "" ||
      phoneNumber === "" ||
      !/^\d{10}$/.test(phoneNumber) ||
      !vehicleTypeSelectionCheck;

    const passwordMisMatch =
      password !== passwordRetype && passwordRetype.length > 0;

    const incorrectPhoneNumber =
      phoneNumber !== "" && !/^\d{10}$/.test(phoneNumber);

    const passwordLength_LT6 = password.length < 7;
    const passwordLength_GT6_LT9 = password.length >= 7 && password.length < 9;
    const passwordLength_GT9 = password.length >= 9;

    return (
      <>
        <Header />

        {/* <PageLoader loading={this.state.isSubmitted}></PageLoader> */}
        <PageLoader loading={!this.state.isLoaded}></PageLoader>

        {/* Page content */}

        <Container className="mt--7" fluid>
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs={8} sm={6} md={7} lg={8} xl={10}>
                  <h3 className="mb-0">Enter Service Provider Details</h3>
                  <h3 className="mb-0">{this.state.sp_id}</h3>
                </Col>
                <Col xs={4} sm={6} md={5} lg={4} xl={2}>
                  <Button
                    block
                    color="primary"
                    className="button_back"
                    onClick={this.navigate}
                  >
                    BACK
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <form onSubmit={this.validateAndSumbit}>
                {/* <Button onClick={this.test} color='danger'>
                  TEST
                </Button> */}
                <FormGroup>
                  <Label for="File">Add image</Label>
                  <br></br>
                  <input
                    type="file"
                    required
                    accept="image/png, image/jpeg"
                    onChange={this._handleImageChange}
                  />
                  <FormText color="muted">
                    Select the picture of the Service Provider Profile you
                    wanted to display.
                  </FormText>
                  <Row>
                    <Col md={4} xs={2}>
                      <div style={imagestyle}>{$imagePreview}</div>
                    </Col>
                  </Row>
                </FormGroup>

                <h6 className="heading-small text-muted mb-4">
                  Service Provider Details
                </h6>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Service Provicer Name"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("username", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Service Provicer Name in Arabic "
                      required
                      defaultValue={this.state.username_ar}
                      type="text"
                      onChange={(e) =>
                        this.onChange("username_ar", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Address "
                      required
                      defaultValue={this.state.address}
                      type="text"
                      onChange={(e) => this.onChange("address", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Address in Arabic "
                      required
                      defaultValue={this.state.address_ar}
                      type="text"
                      onChange={(e) =>
                        this.onChange("address_ar", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-square-pin" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Postal Code"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("postalcode", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-bullet-list-67" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Description"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("description", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-bullet-list-67" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Description in Arabic"
                      required
                      defaultValue={this.state.description_ar}
                      type="text"
                      onChange={(e) =>
                        this.onChange("description_ar", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Contact Details
                </h6>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Owner Name"
                      required
                      defaultValue={this.state.ownerName}
                      type="text"
                      onChange={(e) =>
                        this.onChange("ownerName", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Owner Email "
                      required
                      defaultValue={this.state.ownerEmail}
                      type="text"
                      onChange={(e) =>
                        this.onChange("ownerEmail", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Owner Phone number"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("phoneNumber_owner", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Phone number"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("phoneNumber", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Manager Phone number"
                      required
                      type="text"
                      onChange={(e) =>
                        this.onChange("phoneNumber_manager", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Select Business Time
                </h6>
                <Row>
                  <Col lg={3} md={4} sm={12}>
                    <Select
                      onChange={(time) => {
                        this.setState({ scheduledOnTime: time.value });
                      }}
                      options={this.state.timeRange}
                      isSearchable={true}
                      placeholder="Select On Time"
                    />
                  </Col>

                  <Col lg={3} md={4} sm={12}>
                    <Select
                      onChange={(time) => {
                        this.setState({ scheduledOffTime: time.value });
                      }}
                      options={this.state.timeRange}
                      isSearchable={true}
                      placeholder="Select Off Time"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <h2>{`Auto Schedule ${
                      this.state.checked ? "On" : "Off"
                    }`}</h2>
                  </Col>
                  <Col lg={3} md={4} sm={12}>
                    <Switch
                      onChange={() => {
                        this.setState({
                          checked: !this.state.checked,
                        });
                      }}
                      checked={this.state.checked}
                    />
                  </Col>
                </Row>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Select Vehicle Type
                </h6>
                <div className="pl-lg-4">
                  {this.state.vehicle_types &&
                    this.state.vehicle_types.map((line, key) => {
                      return (
                        <>
                          <Col lg="4">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                id={line}
                                onClick={() => {
                                  this.toggleCheckBoxChange(line);
                                }}
                              />

                              <label
                                className="custom-control-label"
                                htmlFor={line}
                              >
                                {line.replace("_", " ")}
                              </label>
                            </div>
                          </Col>
                        </>
                      );
                    })}
                </div>

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Select Serving Emirates
                </h6>
                <Row>
                  <Col>
                    <Input
                      type="select"
                      name="emirateId"
                      id="emirateId"
                      required
                      value={this.state.selectedEmirate}
                      onChange={(e) => {
                        this.setState({ selectedEmirate: e.target.value });
                        this.getServiceableArea(e.target.value);
                      }}
                    >
                      <option hidden>Select Emirate</option>
                      {emiratesList}
                    </Input>
                  </Col>
                </Row>

                {this.state.selectedEmirate && (
                  <>
                    <hr className="my-4" width="0" />
                    <h6 className="heading-small text-muted mb-4">
                      Select Serving Area under {this.state.selectedEmirate}
                    </h6>
                    <Row>
                      <Col>
                        <Select
                          onChange={(area) => {
                            this.setState({ selectedAreas: area.label });
                          }}
                          options={areaList}
                          isSearchable={true}
                        />
                      </Col>

                      <Col xs="auto">
                        <Button color="primary" onClick={this.getMapPreview}>
                          PREVIEW
                        </Button>
                        <Button color="success" onClick={this.addServiceArea}>
                          ADD AREA
                        </Button>
                        <Button
                          color="success"
                          disabled={location.length > 0 ? true : false}
                          onClick={this.handleSelectAllAreas}
                        >
                          ADD ALL
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        {this.state.selected_map && (
                          <>
                            <div className="containerIframe">
                              <iframe
                                class="responsive-iframe"
                                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBKyYgItJNEylLo2PthYns9tPgOJZbOiD0&q=${
                                  this.state.selected_map
                                    ? this.state.selected_map
                                    : ""
                                }`}
                                allowfullscreen
                              ></iframe>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {location.length > 0 && (
                          <div>
                            <Table responsive>
                              <thead>
                                <tr></tr>
                              </thead>
                              {location.map((location) => (
                                <tbody>
                                  <tr>
                                    <td>{location}</td>
                                    <td>
                                      <Button
                                        color="danger"
                                        onClick={() => {
                                          this.removeLocation(location);
                                        }}
                                      >
                                        REMOVE
                                      </Button>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}{" "}
                            </Table>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </>
                )}

                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Choose Email And Password
                </h6>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      required
                      required
                      type="email"
                      onChange={(e) => this.onChange("email", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      required
                      type="password"
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Retype Password"
                      required
                      type="password"
                      onChange={(e) =>
                        this.onChange("passwordRetype", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    {passwordLength_LT6 && (
                      <span className="text-danger font-weight-700">Low</span>
                    )}
                    {passwordLength_GT6_LT9 && (
                      <span className="text-warning font-weight-700">
                        Medium
                      </span>
                    )}
                    {passwordLength_GT9 && (
                      <span className="text-success font-weight-700">High</span>
                    )}
                  </small>
                  <div>
                    <small>
                      {incorrectPhoneNumber && (
                        <span className="text-danger font-weight-700">
                          Incorrect Phone Number
                        </span>
                      )}
                    </small>
                  </div>
                  <div>
                    <small>
                      {passwordMisMatch && (
                        <span className="text-danger font-weight-700">
                          Password Mismatch
                        </span>
                      )}
                    </small>
                  </div>
                </div>

                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        onClick={() => {
                          this.toggleChange();
                        }}
                        checked={this.state.isChecked}
                      />

                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <Button color="primary" onClick={this.convert}>
                  CONVERT TO ARABIC
                </Button>
                <br></br>
                <br></br>
                <div className="text-center">
                  <Button className="mt-4" onClick={this.navigate}>
                    CANCEL
                  </Button>
                  <Button
                    disabled={!this.state.isChecked}
                    className="mt-4"
                    color="primary"
                    type="submit"
                  >
                    CREATE SP ACCOUNT
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Container>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            Congratulation new service provider added Successfully
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.navigate}>
              CLOSE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceCenters);
