import React, { Component } from "react";

// reactstrap components
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  Container,
  CardHeader,
  CardTitle,
  InputGroupAddon,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  CardBody,
  FormFeedback,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import RealTime from "services/RealTime";

// super admin logged in user id
var saID;
// super admin logged in name
var saName;

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    console.log("The current user: ", user.email);

    saID = user.email;
    saID = saID.replace("@", "_");
    saID = saID.replace(".", "_");

    firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/")
      .on("value", function (snapshot) {
        saName = snapshot.val();
        saName = saName.personal_information.name;
      });
  }
});

export default class AddVehicleType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      name_ar: "",
      downloadurl: "",
      file: "",
      imagePreviewUrl: "",
      cancel: 0,
      count: 0,
      invalid: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const dr = firebase.database().ref("twaddan_admin/vehicle_type/");
    dr.on("value", (snapshot) => {
      var drs = snapshot.val();
      if (drs != null) {
        var keys = Object.keys(drs);
        console.log(keys.length);
        this.setState({
          count: keys.length,
        });
      }
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    var flag = true;
    var id = this.state.name.toLowerCase().trim().replace(" ", "_");
    const vehicle_types = await RealTime.getDetails(
      "twaddan_admin/vehicle_type"
    );
    Object.keys(vehicle_types).map((vehicle_type) => {
      if (vehicle_type === id) {
        flag = false;
      }
    });
    if (flag) {
      this.uploadimage();
    } else {
      this.setState({ invalid: true });
    }
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  navigate = () => {
    this.setState({
      cancel: 1,
    });
  };
  addVehicleType = () => {
    var time = new Date();
    time = time.getTime();
    var logAction = "Added New Vehicle Type  " + this.state.name;
    var logs = firebase
      .database()
      .ref("/twaddan_admin/super_admin_users/" + saID + "/logs/" + time + "/");
    logs.update({
      action: logAction,
    });

    var vehicle_type = this.state.name;
    vehicle_type = vehicle_type.toLowerCase();
    vehicle_type = vehicle_type.trim();
    vehicle_type = vehicle_type.replace(" ", "_");
    var ref = firebase.database().ref("twaddan_admin/vehicle_type/");
    ref.child(vehicle_type).set({
      name: this.state.name,
      name_ar: this.state.name_ar,
      created_on: time,
      image: this.state.downloadurl,
      index: this.state.count + 1,
    });
    this.toggle();
  };
  uploadimage = () => {
    var id = this.state.name.toLowerCase();
    id = id.trim();
    id = id.replace(" ", "_");
    id = id + ".png";
    console.log(id);
    var storageRef = firebase.storage().ref();
    var ImagesRef = storageRef.child("/twaddan_admin/vehicle_type/" + id);
    ImagesRef.put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })

      .then((downloadURL) => {
        console.log(
          `Successfully uploaded file and got download link - ${downloadURL}`
        );
        this.setState({
          downloadurl: downloadURL,
        });
        this.addVehicleType();
      })

      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  getArabic = async (text, value) => {
    const axios = require("axios").default;
    const url = "https://translation.googleapis.com/language/translate/v2";
    const apiKey = "AIzaSyDz3kn1bKPVNAkfraz8jIAVKtSaova2z5g";
    const reply = await axios.get(url, {
      params: {
        q: text,
        target: "ar",
        key: apiKey,
      },
    });
    console.log(reply.data.data.translations[0].translatedText);
    this.setState({
      [value]: reply.data.data.translations[0].translatedText,
    });
  };
  convert = () => {
    this.getArabic(this.state.name, "name_ar");
  };
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };
  render() {
    if (this.state.cancel === 1) {
      return <Redirect to="/admin/vehicleTypeNew" />;
    }
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Card>
            <CardHeader>
              <CardTitle>Add Vehicle Type</CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={this.handleSubmit}>
                <Row form>
                  <Col md={8}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          {/* <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText> */}
                        </InputGroupAddon>
                        <Input
                          placeholder="Name"
                          maxLength="40"
                          required
                          type="text"
                          invalid={this.state.invalid}
                          onChange={(e) => {
                            this.setState({ invalid: false });

                            this.onChange("name", e.target.value);
                          }}
                        />
                        <FormFeedback>
                          {" "}
                          This Vehicle type already exist
                        </FormFeedback>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          {/* <InputGroupText>
                                        <i className="ni ni-email-83" />
                                    </InputGroupText> */}
                        </InputGroupAddon>
                        <Input
                          placeholder="Name In Arabic"
                          type="text"
                          defaultValue={this.state.name_ar}
                          onChange={(e) =>
                            this.onChange("name_ar", e.target.value)
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <Label for="File">Add image</Label>
                      <br></br>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        required
                        onChange={this._handleImageChange}
                      />
                      <Row>
                        <Col md={4} xs={2}>
                          <div style={imagestyle}>{$imagePreview}</div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" onClick={this.convert}>
                  CONVERT TO ARABIC
                </Button>
                <br></br>
                <br></br>
                <Button color="primary" onClick={this.navigate}>
                  CANCEL
                </Button>
                <Button color="success" value="Submit">
                  ADD
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Container>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            Congratulation New VehicleType added Successfully
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.navigate}>
              CONTINUE
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

// class VehicleType extends React.Component {
//     constructor(props){
//         super(props)
//         this.state={
//             name:'',
//             spemail:'',
//             modal:false,
//             addon:0,
//             carType:"",
//             service:[],
//             priceForServices:{},
//         }
//         this.handleSubmit = this.handleSubmit.bind(this);
//         this.toggle = this.toggle.bind(this);
//     }
//     componentDidMount(){
//       this.setState({
//         spemail:localStorage.getItem('email'),
//       });
//       this.getData();
//     }
//     getData=()=>{
//         const dr2 =firebase.database().ref('service_providers/'+spid+"/services/");
//              dr2.on('value',(snapshot)=>{
//                var drs =snapshot.val()
//                if(drs!=null)
//                {
//                var keys =Object.keys(drs);
//                for(var dr = 0 ;dr<keys.length;dr++){
//                  var k=keys[dr];
//                   this.setState({
//                       carType:k,
//                   })
//                   this.getService(k);
//                   break;
//                }
//              }
//              })
//     }
//     getService=(id)=>{
//         const dr2 =firebase.database().ref('service_providers/'+spid+"/services/"+id+"/");
//              dr2.once('value',(snapshot)=>{
//                  let services=[];
//                  let price={};
//                var drs =snapshot.val()
//                if(drs!=null)
//                {
//                var keys =Object.keys(drs);
//                for(var dr = 0 ;dr<keys.length;dr++){
//                  var k=keys[dr];
//                   services.push({
//                       id:k,
//                       name:drs[k].name,
//                       image_url:drs[k].image_url,
//                       description:drs[k].description,
//                       time_required:drs[k].time_required,
//                   })
//                  price[k]=0;
//                }
//                this.setState({
//                    service:services,
//                    priceForServices:price,
//                })
//              }
//              })

//     }
//       pushdata=()=>{
//         var id=(this.state.name).toLowerCase();
//         id=id.trim();
//         id=id.replace(" ","_");
//         this.state.service.map((i)=>{
//                 var ref = firebase.database().ref("service_providers/"+spid+"/services/");
//                 ref.child(id).child(i.id).set({
//                  description:i.description,
//                 image_url:i.image_url,
//                 name:i.name,
//                 price:Number(this.state.priceForServices[i.id]),
//                 time_required:Number(i.time_required),
//                 })
//         })
//           this.toggle();
//       }
//       handleSubmit(event) {
//         this.pushdata();
//         event.preventDefault();
//       }
//       toggle() {
//         this.setState({
//           modal: !this.state.modal
//         });
//       }
//       navigate=()=>{
//         this.setState({
//           addon:1,
//         })
//       }
//       onChange=(value,id)=>{
//           var price=this.state.priceForServices;
//           price[id]=value
//          this.setState({
//              priceForServices:price,
//          })
//       }
//   render() {
//       console.log(this.state.priceForServices);
//       console.log(this.state.service);
//     if(this.state.addon==1)
//     {
//       return <Redirect to="/admin/vehicleType"/>
//     }
//     return (
//       <>
//         <Header />
//         {/* Page content */}
//         <Container className=" mt--7" fluid>
//         <Card>
//         <CardHeader>
//           <CardTitle>Add Vehicle Type</CardTitle>
//         </CardHeader>
//         <CardBody>
//         <Form onSubmit={this.handleSubmit}>
//          <Row form>
//           <Col md={4}>
//             <FormGroup>
//               <Label for="name">Name</Label>
//               <Input type="name" required name="name" id="name" value={this.state.name} onChange={e =>{this.setState({name: e.target.value})}}  placeholder="Name" />
//             </FormGroup>
//           </Col>
//         </Row>
//         <Row>
//             {
//                 this.state.service.map((i)=>{
//                 return(
//                     <Col md={4}>
//                     <FormGroup>
//                           <Label for="price">Price for {i.name}</Label>
//                         <Input type="number" name="price" required id="price" placeholder="Price" value={this.state.priceForServices[i.id]} onChange={e=>{this.onChange(e.target.value,i.id)}}/>
//                     </FormGroup>
//                     </Col>
//                     )
//                 })
//             }
//         </Row>
//             <br>
//             </br>
//         <Button onClick={this.navigate}>CANCEL</Button>{' '}
//         <Button color="success" value='Submit'
//         >ADD</Button>
//       </Form>
//         </CardBody>
//         </Card>
//         </Container>
//         <Modal isOpen={this.state.modal} toggle={this.toggle}>
//                 <ModalBody>
//                 Congratulation New VehicleType added Successfully
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button color="primary" onClick={this.navigate}>Close</Button>
//                 </ModalFooter>
//             </Modal>
//       </>
//     );
//   }
// }
