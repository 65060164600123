import "./Services.css";
import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardTitle,
  Card,
  CardBody,
  CardImg,
  CardHeader,
  Form,
  Input,
  FormText,
  FormGroup,
  Label,
} from "reactstrap";
import { Edit } from "react-feather";
import Header from "components/Headers/Header.jsx";
import firebase from "../../firebaseConfig";
import { Redirect } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import RealTime from "../../services/RealTime";
import "../styles/Service.css";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import PageLoader from "../../components/PageLoader/PageLoader";
import ConvertArabic from "../../services/ConvertArabic";
const columns = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider ID",
    dataField: "spId",
    sort: true,
    hidden: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Provider",
    dataField: "serviceProvider",
    sort: true,
    align: "left",
    headerAlign: "center",
    filter: textFilter({
      style: {
        minWidth: "500px",
        placeholder: "Enter Service Provider Name ",
      },
    }),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Major Services",
    dataField: "majorServices",
    footer: false,
    align: "center",
    filter: false,
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Add On Services",
    dataField: "addOnServices",
    footer: false,
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Major Vehicle Type",
    dataField: "totalMajorVehicleType",
    footer: false,
    align: "center",
    filter: false,
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Add On Vehicle Type",
    dataField: "totalAddOnVehicleType",
    footer: false,
    align: "center",
    filter: false,
    headerAlign: "center",
    sort: true,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const defaultSorted = [
  {
    dataField: "Service Provider",
    order: "desc",
  },
];
const rowStyle = (row, rowIndex) => {
  const style = {};
  if (rowIndex % 2 === 0) {
    style.backgroundColor = "#e6e6e6";
  }
  style.cursor = "pointer";
  return style;
};
const table1Columns = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service ID",
    dataField: "serviceId",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Name",
    dataField: "serviceName",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const table1defaultSorted = [
  {
    dataField: "Service Name",
    order: "desc",
  },
];
const table2Columns = [
  {
    text: "Si No",
    dataField: "i",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service ID",
    dataField: "serviceId",
    sort: true,
    hidden: false,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Service Name",
    dataField: "serviceName",
    sort: true,
    align: "center",
    headerAlign: "center",
    filter: textFilter(),
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const table2defaultSorted = [
  {
    dataField: "Service Name",
    order: "desc",
  },
];
const table3Columns = [
  {
    text: "Vehicle Type",
    dataField: "vehicleTypeDisplay",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Price",
    dataField: "price",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Time",
    dataField: "time",
    sort: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
  {
    text: "Status",
    dataField: "status",
    sort: true,
    hidden: true,
    align: "center",
    headerAlign: "center",
    footer: false,
    headerStyle: {
      backgroundColor: "#00acf3",
    },
  },
];
const table3defaultSorted = [
  {
    dataField: "Vehicle Type",
    order: "desc",
  },
];
const rowStyleTable3 = (row, rowIndex) => {
  const style = {};
  if (row.status) {
    style.backgroundColor = "#33ff33";
  } else {
    style.backgroundColor = "#ff4d4d";
  }
  return style;
};
var spid = "";
var editService = {};
// CRUD permission details
var crudPermission = JSON.parse(localStorage.getItem("crudPermissions"));

// super admin ID
var saID = localStorage.getItem("admin_email");
class EditServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: editService["name"],
      name_ar: editService["name_ar"],
      bike_price: editService["bike_price"],
      time: editService["time"],
      description: editService["description"],
      description_ar: "",
      file: "",
      imagePreviewUrl: "",
      image_url: editService["image_url"],
      downloadURL: "",
      edit: 1,
      carType: [],
      priceForCartype: {},
      statusForCartype: {},
      timeRequired: {},
      update: false,
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
  }
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };
  onTimeChange = (id, value) => {
    var time = this.state.timeRequired;
    time[id] = value;
    this.setState({
      timeRequired: time,
    });
  };
  onPriceChnage = (id, value) => {
    var price = this.state.priceForCartype;
    price[id] = value;
    this.setState({
      priceForCartype: price,
    });
  };
  updateCheckbox = (id, value) => {
    var status = this.state.statusForCartype;
    status[id] = value;
    this.setState({
      statusForCartype: status,
    });
  };
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    let price = {};
    let type = [];
    let status = {};
    let time = {};
    let major_service = null;
    const vehicle_types = await RealTime.getDetails(
      "service_providers/" + spid + "/new_services/"
    );
    console.log("vehicle_types", vehicle_types);

    if (vehicle_types)
      Object.entries(vehicle_types).forEach(async ([key, vehicle_type]) => {
        major_service = await RealTime.getDetails(
          "service_providers/" +
            spid +
            "/new_services/" +
            key +
            "/major_services/" +
            editService["id"] +
            "/"
        );
        console.log("major_services", major_service);
        if (major_service) {
          var idd = key + editService["id"];
          price[idd] = major_service.price;
          status[idd] = major_service.status;
          time[idd] = major_service.time_required;
        }
        type.push({
          id: key,
        });
        this.setState({
          priceForCartype: price,
          carType: type,
          statusForCartype: status,
          timeRequired: time,
        });
      });

    this.setState({
      description_ar: editService["description_ar"],
    });
  };
  pushData = async () => {
    var id = editService["id"];
    var spName;
    let vehicles = await RealTime.getDetails(
      "service_providers/" + spid + "/vehicles/"
    );
    if (vehicles)
      Object.entries(vehicles).forEach(([key, vehicle]) => {
        if (vehicle.vehicle_type)
          Object.entries(vehicle.vehicle_type).forEach(
            ([key2, vehicle_types]) => {
              if (vehicle_types.major_services)
                Object.entries(vehicle_types.major_services).forEach(
                  ([key3, major_service]) => {
                    if (key3 === id) {
                      console.log("id", id);
                      console.log(
                        "major_service-" + key + "--" + key2 + "--" + key3,
                        major_service
                      );
                      this.state.carType.map((i) => {
                        if (i.id === key2) {
                          console.log(
                            "this.state.statusForCartype[i.id + id]",
                            this.state.statusForCartype[i.id + id]
                          );
                          firebase
                            .database()
                            .ref(
                              "service_providers/" +
                                spid +
                                "/vehicles/" +
                                key +
                                "/vehicle_type/" +
                                i.id +
                                "/major_services/" +
                                id +
                                "/"
                            )
                            .update({
                              description: this.state.description,
                              description_ar: this.state.description_ar,
                              image_url: this.state.image_url,
                              name: this.state.name,
                              name_ar: this.state.name_ar,
                              price: Number(
                                this.state.priceForCartype[i.id + id]
                              ),
                              time_required: Number(
                                this.state.timeRequired[i.id + id]
                              ),
                              status: this.state.statusForCartype[i.id + id],
                            });
                        }
                      });
                    }
                  }
                );
            }
          );
      });
    console.log("this.state.carType", this.state.carType);

    firebase
      .database()
      .ref("service_providers/" + spid + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Edited Service named " +
      id +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spid;
    var logs = firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          this.state.saID +
          "/logs/" +
          time +
          "/"
      );
    logs.update({
      action: logAction,
    });
    let data = null;
    this.state.carType.map((i) => {
      const dr1 = firebase
        .database()
        .ref(
          "service_providers/" +
            spid +
            "/new_services/" +
            i.id +
            "/major_services/" +
            id +
            "/"
        );
      dr1.once("value", (snapshot) => {
        var drs = snapshot.val();
        if (drs != null) {
          dr1.update({
            description: this.state.description,
            description_ar: this.state.description_ar,
            image_url: this.state.image_url,
            name: this.state.name,
            name_ar: this.state.name_ar,
            price: Number(this.state.priceForCartype[i.id + id]),
            time_required: Number(this.state.timeRequired[i.id + id]),
            status: this.state.statusForCartype[i.id + id],
          });
        } else if (this.state.statusForCartype[i.id + id] == true) {
          var ref = firebase
            .database()
            .ref(
              "service_providers/" +
                spid +
                "/new_services/" +
                i.id +
                "/major_services/"
            );
          ref.child(id).set({
            description: this.state.description,
            description_ar: this.state.description_ar,
            image_url: this.state.image_url,
            name: this.state.name,
            name_ar: this.state.name_ar,
            price: Number(this.state.priceForCartype[i.id + id]),
            time_required: Number(this.state.timeRequired[i.id + id]),
            status: this.state.statusForCartype[i.id + id],
          });
        }
      });
    });
    this.setState({
      update: true,
    });
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  uploadImage = () => {
    var id = editService["id"];
    var imgId = id + ".png";
    var storageRef = firebase.storage().ref();
    var ImagesRef = storageRef.child(
      "/service_provider/" + spid + "/services/" + imgId
    );
    ImagesRef.put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })

      .then((downloadURL) => {
        this.setState({
          image_url: downloadURL,
        });
        editService["image_url"] = downloadURL;
        this.toggle();
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleProfileSubmit(event) {
    this.uploadImage();
    event.preventDefault();
  }
  handleSubmit(event) {
    this.pushData();
    event.preventDefault();
  }
  convert = async () => {
    this.setState({
      name_ar: await ConvertArabic(this.state.name),
      description_ar: await ConvertArabic(this.state.description),
    });
  };
  done = () => {
    this.setState({ update: false, edit: 0 });
  };
  condinueUpdate = () => {
    this.setState({ update: false });
  };
  render() {
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    if (this.state.edit === 0) {
      return <ServiceBySP />;
    }
    return (
      <>
        <Header />
        <>
          <Container className=" mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card>
                  <CardHeader>
                    <CardTitle>Update Major Service</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row form>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                              type="name"
                              name="name"
                              required
                              id="name"
                              defaultValue={editService["name"]}
                              value={this.state.name}
                              onChange={(e) => {
                                this.setState({ name: e.target.value });
                              }}
                              placeholder="Name"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="name">Name in Arabic</Label>
                            <Input
                              type="name"
                              name="name"
                              required
                              id="name"
                              defaultValue={editService["name_ar"]}
                              value={this.state.name_ar}
                              onChange={(e) => {
                                this.setState({ name_ar: e.target.value });
                              }}
                              placeholder="Name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                      {this.state.carType.map((i) => {
                        return (
                          <Row form>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                              <FormGroup row check>
                                <Label xs={12} check>
                                  <Input
                                    type="checkbox"
                                    checked={
                                      this.state.statusForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    onChange={(e) => {
                                      this.updateCheckbox(
                                        i.id + editService["id"],
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  Enable for {i.id.replace("_", " ")}
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                              <FormGroup row>
                                <Label
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  for="price"
                                >
                                  Price
                                </Label>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                  <Input
                                    type="number"
                                    defaultValue={
                                      this.state.priceForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    disabled={
                                      !this.state.statusForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    name="price"
                                    required
                                    id="price"
                                    placeholder="Price"
                                    value={
                                      this.state.priceForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    onChange={(e) => {
                                      this.onPriceChnage(
                                        i.id + editService["id"],
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                              <FormGroup row>
                                <Label
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  for="price"
                                >
                                  Time
                                </Label>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                  <Input
                                    type="number"
                                    name="timeRequired"
                                    id="timeRequired"
                                    disabled={
                                      !this.state.statusForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    defaultValue={
                                      this.state.timeRequired[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    placeholder="Time Required"
                                    value={
                                      this.state.timeRequired[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    onChange={(e) =>
                                      this.onTimeChange(
                                        i.id + editService["id"],
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        );
                      })}
                      <CardImg
                        top
                        className="img-fluid"
                        style={{ width: "250px", height: "250px" }}
                        src={editService["image_url"]}
                        alt="card image cap"
                      />
                      <div className="d-flex justify-content-between">
                        <Button
                          className="mr-4"
                          color="info"
                          href="#pablo"
                          onClick={this.toggle}
                          size="sm"
                        >
                          CHANGE IMAGE
                        </Button>
                      </div>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                              type="text"
                              name="description"
                              required
                              id="description"
                              placeholder="Enter the decription"
                              defaultValue={editService["description"]}
                              value={this.state.description}
                              onChange={(e) => {
                                this.setState({ description: e.target.value });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label for="description">
                              Description in Arabic
                            </Label>
                            <Input
                              type="text"
                              name="description"
                              required
                              id="description"
                              placeholder="Enter the decription"
                              value={this.state.description_ar}
                              defaultValue={this.state.description_ar}
                              onChange={(e) => {
                                this.setState({
                                  description_ar: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={4} xl={2}>
                          <Button
                            className="buttonConvert"
                            color="primary"
                            block
                            onClick={this.convert}
                          >
                            CONVERT TO ARABIC
                          </Button>
                        </Col>
                        <Col
                          className="buttonCancelSubmitCol"
                          xs={6}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={2}
                        >
                          <Button block onClick={this.done}>
                            CANCEL
                          </Button>
                        </Col>
                        <Col
                          className="buttonCancelSubmitCol"
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                        >
                          <Button block color="success" value="Submit">
                            UPDATE
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Modal isOpen={this.state.update} toggle={this.done}>
            <ModalBody>Congratulations service updated Successfully</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.condinueUpdate}>
                CONTINUE UPDATE
              </Button>
              <Button color="success" onClick={this.done}>
                DONE
              </Button>
            </ModalFooter>
          </Modal>
        </>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Container className="mt--7" fluid>
            <Row>
              <Card className="shadow">
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggle}>
                    <h1>Update Image Below</h1>
                  </ModalHeader>
                  <ModalBody>
                    <>
                      <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                          <Form onSubmit={this.handleProfileSubmit}>
                            <FormGroup>
                              <Label for="File">Add image</Label>
                              <br></br>
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={this._handleImageChange}
                              />
                              <FormText color="muted">
                                Select the Image that you wanted to display.
                              </FormText>
                              <Row>
                                <Col md={4} xs={2}>
                                  <div style={imagestyle}>{$imagePreview}</div>
                                </Col>
                              </Row>
                            </FormGroup>
                            <Button color="primary" value="Submit">
                              UPDATE IMAGE
                            </Button>
                          </Form>
                        </CardBody>
                      </Card>
                    </>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      CANCEL
                    </Button>
                  </ModalFooter>
                </Modal>
              </Card>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}

class EditAddOnService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: editService["name"],
      name_ar: editService["name_ar"],
      bike_price: editService["bike_price"],
      time: editService["time"],
      description: editService["description"],
      limit: editService["limit"],
      description_ar: "",
      file: "",
      imagePreviewUrl: "",
      image_url: editService["image_url"],
      downloadURL: "",
      edit: 1,
      carType: [],
      priceForCartype: {},
      statusForCartype: {},
      timeRequired: {},
      update: false,
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProfileSubmit = this.handleProfileSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
  }
  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };
  onTimeChange = (id, value) => {
    var time = this.state.timeRequired;
    time[id] = value;
    this.setState({
      timeRequired: time,
    });
  };
  onPriceChnage = (id, value) => {
    var price = this.state.priceForCartype;
    price[id] = value;
    this.setState({
      priceForCartype: price,
    });
  };
  updateCheckbox = (id, value) => {
    var status = this.state.statusForCartype;
    status[id] = value;
    this.setState({
      statusForCartype: status,
    });
  };
  componentDidMount() {
    this.getData();
    this.setState({
      description_ar: editService["description_ar"],
    });
  }
  getData = async () => {
    let price = {};
    let type = [];
    let status = {};
    let time = {};
    let add_on_service = null;
    const vehicle_types = await RealTime.getDetails(
      "service_providers/" + spid + "/new_services/"
    );
    console.log("vehicle_types", vehicle_types);

    if (vehicle_types)
      Object.entries(vehicle_types).forEach(async ([key, vehicle_type]) => {
        add_on_service = await RealTime.getDetails(
          "service_providers/" +
            spid +
            "/new_services/" +
            key +
            "/add_on_services/" +
            editService["id"] +
            "/"
        );
        console.log("add_on_services", add_on_service);
        if (add_on_service) {
          var idd = key + editService["id"];
          price[idd] = add_on_service.price;
          status[idd] = add_on_service.status;
          time[idd] = add_on_service.time_required;
        }
        type.push({
          id: key,
        });
        this.setState({
          priceForCartype: price,
          carType: type,
          statusForCartype: status,
          timeRequired: time,
        });
      });

    this.setState({
      description_ar: editService["description_ar"],
    });
  };
  pushData = () => {
    var id = editService["id"];
    var spName;
    firebase
      .database()
      .ref("service_providers/" + spid + "/personal_information/")
      .on("value", function (snapshot) {
        spName = snapshot.val();
        spName = spName.name;
      });
    var time = new Date();
    time = time.getTime();
    var logAction =
      "Edited Service named " +
      id +
      " under " +
      spName +
      " Service Provider with SP ID " +
      spid;
    var logs = firebase
      .database()
      .ref(
        "/twaddan_admin/super_admin_users/" +
          this.state.saID +
          "/logs/" +
          time +
          "/"
      );
    logs.update({
      action: logAction,
    });
    let data = null;
    this.state.carType.map((i) => {
      const dr1 = firebase
        .database()
        .ref(
          "service_providers/" +
            spid +
            "/new_services/" +
            i.id +
            "/add_on_services/" +
            id +
            "/"
        );
      dr1.once("value", (snapshot) => {
        var drs = snapshot.val();
        if (drs != null) {
          dr1.update({
            description: this.state.description,
            description_ar: this.state.description_ar,
            image_url: this.state.image_url,
            name: this.state.name,
            limit: Number(this.state.limit),
            name_ar: this.state.name_ar,
            price: Number(this.state.priceForCartype[i.id + id]),
            time_required: Number(this.state.timeRequired[i.id + id]),
            status: this.state.statusForCartype[i.id + id],
          });
        } else if (this.state.statusForCartype[i.id + id] == true) {
          var ref = firebase
            .database()
            .ref(
              "service_providers/" +
                spid +
                "/new_services/" +
                i.id +
                "/add_on_services/"
            );
          ref.child(id).set({
            description: this.state.description,
            description_ar: this.state.description_ar,
            image_url: this.state.image_url,
            name: this.state.name,
            limit: Number(this.state.limit),
            name_ar: this.state.name_ar,
            price: Number(this.state.priceForCartype[i.id + id]),
            time_required: Number(this.state.timeRequired[i.id + id]),
            status: this.state.statusForCartype[i.id + id],
          });
        }
      });
    });
    this.setState({
      update: true,
    });
  };
  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }
  uploadImage = () => {
    var id = editService["id"];
    var imgId = id + ".png";
    var storageRef = firebase.storage().ref();
    var ImagesRef = storageRef.child(
      "/service_provider/" + spid + "/services/" + imgId
    );
    ImagesRef.put(this.state.file)
      .then((snapshot) => {
        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
      })

      .then((downloadURL) => {
        this.setState({
          image_url: downloadURL,
        });
        editService["image_url"] = downloadURL;
        this.toggle();
      })
      .catch((error) => {
        // Use to signal error if something goes wrong.
        console.log(`Failed to upload file and get link - ${error}`);
      });
  };
  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  }
  handleProfileSubmit(event) {
    this.uploadImage();
    event.preventDefault();
  }
  handleSubmit(event) {
    this.pushData();
    event.preventDefault();
  }
  convert = async () => {
    this.setState({
      name_ar: await ConvertArabic(this.state.name),
      description_ar: await ConvertArabic(this.state.description),
    });
  };
  done = () => {
    this.setState({ update: false, edit: 0 });
  };
  condinueUpdate = () => {
    this.setState({ update: false });
  };
  render() {
    const imagestyle = {
      textalign: "center",
      margin: "5px 15px",
      height: "200px",
      width: "300px",
      borderleft: "1px solid",
    };
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} style={imagestyle} />;
    }
    if (this.state.edit === 0) {
      return <ServiceBySP />;
    }
    return (
      <>
        <Header />
        <>
          <Container className=" mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card>
                  <CardHeader>
                    <CardTitle>Update Add On Service</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.handleSubmit}>
                      <Row form>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                              type="name"
                              name="name"
                              required
                              id="name"
                              defaultValue={editService["name"]}
                              value={this.state.name}
                              onChange={(e) => {
                                this.setState({ name: e.target.value });
                              }}
                              placeholder="Name"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="name">Name in Arabic</Label>
                            <Input
                              type="name"
                              name="name"
                              required
                              id="name"
                              defaultValue={editService["name_ar"]}
                              value={this.state.name_ar}
                              onChange={(e) => {
                                this.setState({ name_ar: e.target.value });
                              }}
                              placeholder="Name"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                      {this.state.carType.map((i) => {
                        return (
                          <Row form>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                              <FormGroup row check>
                                <Label xs={12} check>
                                  <Input
                                    type="checkbox"
                                    checked={
                                      this.state.statusForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    onChange={(e) => {
                                      this.updateCheckbox(
                                        i.id + editService["id"],
                                        e.target.checked
                                      );
                                    }}
                                  />
                                  Enable for {i.id.replace("_", " ")}
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                              <FormGroup row>
                                <Label
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  for="price"
                                >
                                  Price
                                </Label>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                  <Input
                                    type="number"
                                    defaultValue={
                                      this.state.priceForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    disabled={
                                      !this.state.statusForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    name="price"
                                    required
                                    id="price"
                                    placeholder="Price"
                                    value={
                                      this.state.priceForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    onChange={(e) => {
                                      this.onPriceChnage(
                                        i.id + editService["id"],
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                              <FormGroup row>
                                <Label
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  for="price"
                                >
                                  Time
                                </Label>
                                <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                  <Input
                                    type="number"
                                    name="timeRequired"
                                    id="timeRequired"
                                    disabled={
                                      !this.state.statusForCartype[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    defaultValue={
                                      this.state.timeRequired[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    placeholder="Time Required"
                                    value={
                                      this.state.timeRequired[
                                        i.id + editService["id"]
                                      ]
                                    }
                                    onChange={(e) =>
                                      this.onTimeChange(
                                        i.id + editService["id"],
                                        e.target.value
                                      )
                                    }
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        );
                      })}
                      <CardImg
                        top
                        className="img-fluid"
                        style={{ width: "250px", height: "250px" }}
                        src={editService["image_url"]}
                        alt="card image cap"
                      />
                      <div className="d-flex justify-content-between">
                        <Button
                          className="mr-4"
                          color="info"
                          href="#pablo"
                          onClick={this.toggle}
                          size="sm"
                        >
                          CHANGE IMAGE
                        </Button>
                      </div>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                              type="text"
                              name="description"
                              required
                              id="description"
                              placeholder="Enter the decription"
                              defaultValue={editService["description"]}
                              value={this.state.description}
                              onChange={(e) => {
                                this.setState({ description: e.target.value });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label for="description">
                              Description in Arabic
                            </Label>
                            <Input
                              type="text"
                              name="description"
                              required
                              id="description"
                              placeholder="Enter the decription"
                              value={this.state.description_ar}
                              defaultValue={this.state.description_ar}
                              onChange={(e) => {
                                this.setState({
                                  description_ar: e.target.value,
                                });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <FormGroup>
                            <Label for="limit">
                              limit of service [Minimum 1]
                            </Label>
                            <Input
                              type="number"
                              name="limit"
                              required
                              id="limit"
                              placeholder="Enter the decription"
                              value={this.state.limit}
                              defaultValue={this.state.limit}
                              onChange={(e) => {
                                this.setState({ limit: e.target.value });
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12} md={5} lg={4} xl={2}>
                          <Button
                            className="buttonConvert"
                            color="primary"
                            block
                            onClick={this.convert}
                          >
                            CONVERT TO ARABIC
                          </Button>
                        </Col>
                        <Col
                          className="buttonCancelSubmitCol"
                          xs={6}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={2}
                        >
                          <Button block onClick={this.done}>
                            CANCEL
                          </Button>
                        </Col>
                        <Col
                          className="buttonCancelSubmitCol"
                          xs={6}
                          sm={6}
                          md={3}
                          lg={3}
                          xl={2}
                        >
                          <Button block color="success" value="Submit">
                            UPDATE
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Modal isOpen={this.state.update} toggle={this.done}>
            <ModalBody>Congratulations service updated Successfully</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.condinueUpdate}>
                CONTINUE UPDATE
              </Button>
              <Button color="success" onClick={this.done}>
                DONE
              </Button>
            </ModalFooter>
          </Modal>
        </>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Container className="mt--7" fluid>
            <Row>
              <Card className="shadow">
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                  size="lg"
                >
                  <ModalHeader toggle={this.toggle}>
                    <h1>Update Image Below</h1>
                  </ModalHeader>
                  <ModalBody>
                    <>
                      <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                          <Form onSubmit={this.handleProfileSubmit}>
                            <FormGroup>
                              <Label for="File">Add image</Label>
                              <br></br>
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={this._handleImageChange}
                              />
                              <FormText color="muted">
                                Select the Image that you wanted to display.
                              </FormText>
                              <Row>
                                <Col md={4} xs={2}>
                                  <div style={imagestyle}>{$imagePreview}</div>
                                </Col>
                              </Row>
                            </FormGroup>

                            <Button color="primary" value="Submit">
                              UPDATE IMAGE
                            </Button>
                          </Form>
                        </CardBody>
                      </Card>
                    </>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={this.toggle}>
                      CANCEL
                    </Button>
                  </ModalFooter>
                </Modal>
              </Card>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}

class ServiceBySP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      modal: false,
      spid: "",
      service: [],
      addOn: [],
      addService: 0,
      addAddonService: 0,
      edit: 0,
      editAddOn: 0,
      searchBarID: "",
      serviceDetails: [],
      spName: "",
      majorServicesByVehicleType: [],
      majorServices: [],
      deleTetoggle: false,
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
  }
  async componentDidMount() {
    await this.getserviceDetails(spid);
  }
  getserviceDetails = async (id) => {
    let sp = await RealTime.getDetails("/service_providers/" + id + "/");
    let spName = sp.personal_information.name;
    let majorServices = [];
    let addOnServices = [];
    let serviceStatus = false;
    let i = 1;
    if (sp.new_services) {
      i = 1;
      Object.entries(sp.new_services).forEach(([key, vehicleType]) => {
        if (vehicleType.major_services)
          Object.entries(vehicleType.major_services).forEach(
            ([key2, service]) => {
              serviceStatus = false;
              if (majorServices.length > 0) {
                majorServices.map((serviceMap) => {
                  if (serviceMap.serviceId === key2) {
                    serviceStatus = true;
                    serviceMap.vehicleDetails.push({
                      vehicleType: key,
                      vehicleTypeDisplay: key.replace("_", " "),
                      price: service.price,
                      time: service.time_required,
                      status: service.status,
                    });
                  }
                });
              }
              if (!serviceStatus) {
                majorServices.push({
                  i: i++,
                  serviceId: key2,
                  serviceName: service.name,
                  name_ar: service.name_ar,
                  description: service.description,
                  description_ar: service.description_ar,
                  image_url: service.image_url,
                  vehicleDetails: [
                    {
                      vehicleType: key,
                      vehicleTypeDisplay: key.replace("_", " "),
                      price: service.price,
                      time: service.time_required,
                      status: service.status,
                    },
                  ],
                });
              }
            }
          );
      });
    }
    if (sp.new_services) {
      i = 1;
      Object.entries(sp.new_services).forEach(([key, vehicleType]) => {
        if (vehicleType.add_on_services)
          Object.entries(vehicleType.add_on_services).forEach(
            ([key2, service]) => {
              serviceStatus = false;
              if (addOnServices.length > 0) {
                addOnServices.map((serviceMap) => {
                  if (serviceMap.serviceId === key2) {
                    serviceStatus = true;
                    serviceMap.vehicleDetails.push({
                      vehicleType: key,
                      vehicleTypeDisplay: key.replace("_", " "),
                      price: service.price,
                      time: service.time_required,
                      status: service.status,
                    });
                  }
                });
              }
              if (!serviceStatus) {
                addOnServices.push({
                  i: i++,
                  serviceId: key2,
                  serviceName: service.name,
                  name_ar: service.name_ar,
                  description: service.description,
                  description_ar: service.description_ar,
                  image_url: service.image_url,
                  vehicleDetails: [
                    {
                      vehicleType: key,
                      vehicleTypeDisplay: key.replace("_", " "),
                      price: service.price,
                      time: service.time_required,
                      status: service.status,
                    },
                  ],
                });
              }
            }
          );
      });
    }
    this.setState({
      isloaded: true,
      spName: spName,
      majorServices: majorServices,
      addOnServices: addOnServices,
    });
  };
  navigate = () => {
    this.setState({
      addService: 1,
    });
  };
  navigatetoaddon = () => {
    this.setState({
      addAddonService: 1,
    });
  };
  navigateServicePage = () => {
    this.setState({
      servicePage: 1,
    });
  };
  toggle = () => {
    this.setState({ modal: false });
  };
  deleTetoggle = () => {
    this.setState({ deleTetoggle: !this.state.deleTetoggle });
  };
  storeCurrentService = (
    img,
    name,
    id,
    price,
    time,
    description,
    name_ar,
    description_ar,
    limit
  ) => {
    editService["image_url"] = img;
    editService["name"] = name;
    editService["id"] = id;
    editService["bike_price"] = price;
    editService["time"] = time;
    editService["description"] = description;
    editService["description_ar"] = description_ar;
    editService["name_ar"] = name_ar;
    editService["limit"] = limit;
    if (this.state.type === "Major") {
      this.setState({
        edit: 1,
      });
    } else {
      this.setState({
        editAddOn: 1,
      });
    }
  };
  details = (text, data) => {
    return (
      <>
        <Col md="5">
          <p>
            <b>{text}</b>
          </p>
        </Col>
        <Col md="7">
          <p>: {data}</p>
        </Col>
      </>
    );
  };

  test = async () => {
    // console.log(spid);
    // const vehicles = await RealTime.getDetails(
    //   `service_providers/${spid}/vehicles`
    // );
    // Object.entries(vehicles).forEach(([keyVehicle, vehicle]) => {
    //   if (vehicle.vehicle_type) {
    //     Object.entries(vehicle.vehicle_type).forEach(
    //       ([keyVehicleType, vehicleType]) => {
    //         if (vehicleType.major_services) {
    //           Object.entries(vehicleType.major_services).forEach(
    //             ([keyMajorServices, majorServices]) => {
    //               console.log(keyMajorServices);
    //             }
    //           );
    //         }
    //         if (vehicleType.add_on_services) {
    //           Object.entries(vehicleType.add_on_services).forEach(
    //             ([keyAddonServices, majorServices]) => {
    //               console.log(keyAddonServices);
    //             }
    //           );
    //         }
    //       }
    //     );
    //   }
    // });
    // let active_orders = [];
    // const all_orders = await RealTime.getDetails("orders/all_orders");
    // Object.entries(all_orders).forEach(([key, value]) => {
    //   if (value.status >= 0 && value.status < 5) {
    //     active_orders.push(key);
    //   }
    // });
    // if (active_orders) {
    //   active_orders.map(async (order) => {
    //     console.log(order);
    //     const orders = await RealTime.getDetails("orders/all_orders/" + order);
    //     console.log("orders: ", orders);
    //     if (orders) {
    //       firebase
    //         .database()
    //         .ref("orders/all_orders/" + order)
    //         .set(null);
    //     }
    //   });
    // }
    // console.log(active_orders.length);
  };

  deleteService = async (serviceFromParams) => {
    console.log(serviceFromParams);
    // delete from new_nervice
    const vehicle_types = await RealTime.getDetails(
      `service_providers/${spid}/new_services`
    );
    Object.entries(vehicle_types).forEach(([key, value]) => {
      Object.entries(value).forEach(([keyService, service]) => {
        if (keyService === "major_services" && this.state.type === "Major") {
          Object.entries(service).forEach(async ([keyServiceId, serviceId]) => {
            if (keyServiceId === serviceFromParams.serviceId) {
              const serviceToBeDeleted = await RealTime.getDetails(
                `service_providers/${spid}/new_services/${key}/${keyService}/${serviceFromParams.serviceId}`
              );
              if (serviceToBeDeleted) {
                let deleteRef = firebase
                  .database()
                  .ref(
                    `service_providers/${spid}/new_services/${key}/${keyService}/${serviceFromParams.serviceId}`
                  );
                deleteRef.remove();
              }
            }
          });
        }
        if (keyService === "add_on_services" && this.state.type === "Add On") {
          Object.entries(service).forEach(async ([keyServiceId, serviceId]) => {
            if (keyServiceId === serviceFromParams.serviceId) {
              const serviceToBeDeleted = await RealTime.getDetails(
                `service_providers/${spid}/new_services/${key}/${keyService}/${serviceFromParams.serviceId}`
              );
              if (serviceToBeDeleted) {
                let deleteRef = firebase
                  .database()
                  .ref(
                    `service_providers/${spid}/new_services/${key}/${keyService}/${serviceFromParams.serviceId}`
                  );
                deleteRef.remove();
              }
            }
          });
        }
      });
    });
    // delete from vehicles
    const vehicles = await RealTime.getDetails(
      `service_providers/${spid}/vehicles`
    );
    Object.entries(vehicles).forEach(([keyVehicle, vehicle]) => {
      if (vehicle.vehicle_type) {
        Object.entries(vehicle.vehicle_type).forEach(
          ([keyVehicleType, vehicleType]) => {
            if (vehicleType.major_services) {
              Object.entries(vehicleType.major_services).forEach(
                ([keyMajorServices, majorServices]) => {
                  if (keyMajorServices === serviceFromParams.serviceId) {
                    let deleteFromVehicleRef = firebase
                      .database()
                      .ref(
                        `service_providers/${spid}/vehicles/${keyVehicle}/vehicle_type/${keyVehicleType}/major_services/${keyMajorServices}`
                      );
                    deleteFromVehicleRef.remove();
                  }
                }
              );
            }
            if (vehicleType.add_on_services) {
              Object.entries(vehicleType.add_on_services).forEach(
                ([keyAddonServices, majorServices]) => {
                  if (keyAddonServices === serviceFromParams.serviceId) {
                    let deleteFromVehicleRef = firebase
                      .database()
                      .ref(
                        `service_providers/${spid}/vehicles/${keyVehicle}/vehicle_type/${keyVehicleType}/add_on_services/${keyAddonServices}`
                      );
                    deleteFromVehicleRef.remove();
                  }
                }
              );
            }
          }
        );
      }
    });
    // fetching new service list
    await this.getserviceDetails(spid);
    // unmount dialogs
    this.deleTetoggle();
    this.toggle();
  };

  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          modal: true,
          selectedService: this.state.majorServices[row.i - 1],
          type: "Major",
        });
      },
    };
    const rowEvents2 = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          modal: true,
          selectedService: this.state.addOnServices[row.i - 1],
          type: "Add On",
        });
      },
    };
    if (this.state.addService === 1) {
      return (
        <Redirect
          to={{
            pathname: "/admin/addService_new",
            state: {
              spId: spid,
            },
          }}
        />
      );
    }
    if (this.state.addAddonService === 1) {
      return (
        <Redirect
          to={{
            pathname: "/admin/addAddonService_new",
            state: {
              spId: spid,
            },
          }}
        />
      );
    }
    if (this.state.servicePage === 1) {
      return <ServiceNew />;
    }
    if (this.state.edit == 1) {
      return <EditServices />;
    }
    if (this.state.editAddOn == 1) {
      return <EditAddOnService />;
    }
    return (
      <>
        <Header />
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-8">
            {this.state.crudPermission.createPermission && (
              <>
                <Col className="visibilityMediumDeviceSp" xl={6}>
                  <h1 className="mb-0">{this.state.spName}</h1>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={{ size: 2 }}>
                  <Button
                    block
                    color="primary"
                    onClick={this.navigateServicePage}
                    className="buttonPadding"
                  >
                    BACK TO SERVICE
                  </Button>
                </Col>
                <Col xs={12} sm={6} md={3} lg={4} xl={2}>
                  <Button
                    block
                    color="primary"
                    className="addOnButton buttonPadding"
                    onClick={this.navigate}
                  >
                    ADD SERVICE
                  </Button>
                </Col>
                <Col xs={12} sm={6} md={5} lg={4} xl={2}>
                  <Button
                    block
                    color="primary"
                    className="addOnButton buttonPadding"
                    onClick={this.navigatetoaddon}
                  >
                    ADD ADD-ON SERVICE
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="visibilitySmallDeviceSp" xs={12}>
              <h1 className="mb-0 mt-3">{this.state.spName}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <h3 className="mb-0 mt-3">Major Services</h3>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              {this.state.majorServices &&
              this.state.majorServices.length > 0 ? (
                <BootstrapTable
                  keyField="id"
                  data={this.state.majorServices}
                  columns={table2Columns}
                  filter={filterFactory()}
                  filterPosition="top"
                  defaultSorted={table2defaultSorted}
                  rowStyle={rowStyle}
                  bordered={false}
                  hover
                  wrapperClasses="table-responsive"
                  condensed
                  rowEvents={rowEvents}
                />
              ) : (
                <p className="massage">
                  There is no major services for {this.state.spName}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <h3 className="mb-0 mt-3">Add On Services</h3>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              {this.state.addOnServices &&
              this.state.addOnServices.length > 0 ? (
                <BootstrapTable
                  keyField="id"
                  data={this.state.addOnServices}
                  columns={table1Columns}
                  filter={filterFactory()}
                  rowStyle={rowStyle}
                  filterPosition="top"
                  defaultSorted={table1defaultSorted}
                  bordered={false}
                  hover
                  wrapperClasses="table-responsive"
                  condensed
                  rowEvents={rowEvents2}
                />
              ) : (
                <p className="massage">
                  There is no add on services for {this.state.spName}
                </p>
              )}
            </Col>
          </Row>
          {this.state.selectedService && (
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              size="md"
            >
              <ModalHeader toggle={this.toggle}>
                <h1>{this.state.type} Service Details</h1>
              </ModalHeader>
              <ModalBody className="p-0">
                {
                  <Row>
                    <Col>
                      <Card>
                        <CardImg
                          top
                          className="img-fluid"
                          src={this.state.selectedService.image_url}
                          alt="card image cap"
                        />
                        <CardBody className="pb-0">
                          <Row>
                            {this.details(
                              "Name",
                              this.state.selectedService.serviceName
                            )}
                            {this.details(
                              "",
                              this.state.selectedService.name_ar
                            )}
                            {this.details(
                              "Description",
                              this.state.selectedService.description
                            )}
                            {this.details(
                              " ",
                              this.state.selectedService.description_ar
                            )}
                          </Row>
                          <Row>
                            <Col>
                              {this.state.selectedService.vehicleDetails && (
                                <BootstrapTable
                                  keyField="id"
                                  data={
                                    this.state.selectedService.vehicleDetails
                                  }
                                  columns={table3Columns}
                                  filter={filterFactory()}
                                  filterPosition="top"
                                  defaultSorted={table3defaultSorted}
                                  rowStyle={rowStyleTable3}
                                  bordered={false}
                                  hover
                                  wrapperClasses="table-responsive"
                                  condensed
                                  rowEvents={rowEvents}
                                />
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                }
              </ModalBody>
              <ModalFooter className="py-1">
                {this.state.crudPermission.updatePermission && (
                  <>
                    <Button
                      color="info"
                      onClick={() => {
                        this.storeCurrentService(
                          this.state.selectedService.image_url,
                          this.state.selectedService.serviceName,
                          this.state.selectedService.serviceId,
                          0,
                          0,
                          this.state.selectedService.description,
                          this.state.selectedService.name_ar,
                          this.state.selectedService.description_ar,
                          this.state.selectedService?.limit
                        );
                      }}
                    >
                      <Edit />
                    </Button>
                  </>
                )}
                <Button color="secondary" onClick={this.toggle}>
                  CLOSE
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    this.deleTetoggle();
                  }}
                >
                  DELETE
                </Button>
              </ModalFooter>
            </Modal>
          )}
          <Modal isOpen={this.state.deleTetoggle} toggle={this.deleTetoggle}>
            <ModalBody>Are you sure you want to delete the service ?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.deleTetoggle}>
                CANCEL
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.deleteService(this.state.selectedService);
                }}
              >
                DELETE
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </>
    );
  }
}

class ServiceNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isloaded: false,
      addService: 0,
      addAddonService: 0,
      selectedSpId: "",
      redirect: false,
      saID: localStorage.getItem("admin_email"),
      crudPermission: JSON.parse(localStorage.getItem("crudPermissions")),
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = async () => {
    let serviceDetails = [];
    let sps = await RealTime.getDetails("/service_providers/");
    let spName = "";
    let majorServices = 0;
    let majorServicesArray = [];
    let addOnServices = 0;
    let addOnServicesArray = [];
    let totalMajorVehicleType = 0;
    let totalAddOnVehicleType = 0;
    let spId = "";
    let i = 1;
    let j = 1;
    let vehicleTypeStatus = false;
    if (sps) {
      Object.entries(sps).forEach(([key, sp]) => {
        majorServices = 0;
        addOnServices = 0;
        totalMajorVehicleType = 0;
        totalAddOnVehicleType = 0;
        spName = sp.personal_information ? sp.personal_information.name : key;
        spId = key;
        if (sp.new_services) {
          Object.entries(sp.new_services).forEach(([key, vehicleType]) => {
            if (vehicleType.add_on_services) {
              vehicleTypeStatus = false;
              Object.entries(vehicleType.add_on_services).forEach(
                ([key2, service]) => {
                  let serviceStatus = false;
                  if (addOnServicesArray.length > 0) {
                    addOnServicesArray.map((serviceMap) => {
                      if (serviceMap.serviceId === key2) serviceStatus = true;
                    });
                  }
                  if (service.status) vehicleTypeStatus = true;

                  if (!serviceStatus && service.status) {
                    addOnServices += 1;
                    addOnServicesArray.push({
                      i: i++,
                      serviceId: key2,
                      serviceName: service.name,
                    });
                  }
                }
              );
              if (vehicleTypeStatus) {
                totalAddOnVehicleType += 1;
              }
            }
          });
        }
        if (sp.new_services) {
          Object.entries(sp.new_services).forEach(([key, vehicleType]) => {
            if (vehicleType.major_services) {
              vehicleTypeStatus = false;
              Object.entries(vehicleType.major_services).forEach(
                ([key2, service]) => {
                  let serviceStatus = false;
                  if (majorServicesArray.length > 0) {
                    majorServicesArray.map((serviceMap) => {
                      if (serviceMap.serviceId === key2) serviceStatus = true;
                    });
                  }
                  if (service.status) vehicleTypeStatus = true;
                  if (!serviceStatus && service.status) {
                    majorServices += 1;
                    majorServicesArray.push({
                      i: i++,
                      serviceId: key2,
                      serviceName: service.name,
                    });
                  }
                }
              );
              if (vehicleTypeStatus) {
                totalMajorVehicleType += 1;
              }
            }
          });
        }
        serviceDetails.push({
          i: j++,
          spId: spId,
          serviceProvider: spName,
          majorServices: majorServices,
          addOnServices: addOnServices,
          totalMajorVehicleType: totalMajorVehicleType,
          totalAddOnVehicleType: totalAddOnVehicleType,
        });
      });
    }
    this.setState({
      isloaded: true,
      serviceDetails: serviceDetails,
    });
  };
  navigate = () => {
    this.setState({
      addService: 1,
    });
  };
  navigatetoaddon = () => {
    this.setState({
      addAddonService: 1,
    });
  };
  render() {
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        this.setState({
          redirect: true,
          selectedSpId: row.spId,
        });
        spid = row.spId;
      },
    };
    if (this.state.addService === 1) {
      return <Redirect to="/admin/addService_new" />;
    }
    if (this.state.addAddonService === 1) {
      return <Redirect to="/admin/addAddonService_new" />;
    }
    if (this.state.redirect) {
      return <ServiceBySP spId={this.state.selectedSpId} />;
    }
    return (
      <>
        <Header />
        {/* Page content */}
        <PageLoader loading={!this.state.isloaded}></PageLoader>
        <Container className="mt--7" fluid>
          <Row className="mt-8">
            <Col sm={12} md={12} lg={5} xl={7}>
              <h1 className="mb-0">Services</h1>
            </Col>
            {this.state.crudPermission.createPermission && (
              <>
                <Col sm={6} md={6} lg={3} xl={2}>
                  <Button block color="primary" onClick={this.navigate}>
                    ADD SERVICE
                  </Button>
                </Col>
                <Col sm={6} md={6} lg={4} xl={3}>
                  <Button
                    block
                    color="primary"
                    className="addOnButton"
                    onClick={this.navigatetoaddon}
                  >
                    ADD ADD-ON SERVICE
                  </Button>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              {this.state.serviceDetails && (
                <BootstrapTable
                  keyField="id"
                  data={this.state.serviceDetails}
                  columns={columns}
                  rowStyle={rowStyle}
                  filter={filterFactory()}
                  filterPosition="top"
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  bordered={false}
                  hover
                  wrapperClasses="table-responsive"
                  condensed
                  rowEvents={rowEvents}
                />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ServiceNew;
