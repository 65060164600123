import firebase from "firebaseConfig";
import GenerateUsername from "services/GenerateUsername";
import RealTime from "services/RealTime";

const loginAction = (email, password) => async (dispatch) => {
  // firebase offers us this function signInWithEmailAndPassword
  // which will automatically create the user for us
  // it only has two arguments, the email and the password
  var userIDCheck = GenerateUsername(email);
  firebase
    .database()
    .ref("/twaddan_admin/super_admin_users/" + userIDCheck)
    .once("value")
    .then(function (snapshot) {
      const condition = snapshot.exists();
      if (condition) {
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          // then() function is used to know when the async call has ended
          // that way, we can notify our reducers that login was succesful
          .then(async function (user) {
            // if the login was succesful, then
            // we dispatch to our reducers the fact that
            // login was succesful by sending true
            localStorage.setItem(
              "admin_email",
              GenerateUsername(user.user.email)
            );
            localStorage.setItem(
              "admin_email_actual",
              user.user.email
            );
            await firebase
              .database()
              .ref(
                "/twaddan_admin/super_admin_users/" +
                  localStorage.getItem("admin_email") +
                  "/personal_information/name"
              )
              .on("value", function (snapshot) {
                localStorage.setItem(
                  "admin_name",
                  snapshot.val() || "Anonymous"
                );
              });

            // permissions
            await RealTime.setPermission(localStorage.getItem("admin_email"));
            localStorage.setItem("userLoggedIn", "true");
            dispatch({ type: "login", payload: "true" });
          })
          // if the login was not succesful we can catch the erros here
          .catch(function (error) {
            // if we have any erros, we'll throw an allert with that error
            alert(error);
          });
      } else {
        var message = "Your Super Admin User ID is not Registered";
        alert(message);
      }
    });
};
export default loginAction;
