/*eslint-disable*/
import React from "react";
import moment from 'moment';
// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  render() {
    var year = moment().tz("Asia/Dubai").year();
    return (
      <>
        <br></br>
        <br></br>
        <br></br>
        <footer className="py-2">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                  © {year}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="/auth/login"
                    target="_blank"
                  >
                    Twaddan
                  </a>
                </div>
              </Col>
              <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink href="/auth/login" target="_blank">
                      Twaddan
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="" target="_blank">
                      About Us
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
