import React from "react";
import firebase from "firebaseConfig";
import { Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Alert,
  Row,
  Col,
} from "reactstrap";
import RealTime from "services/RealTime";
// super admin image url
var superAdminImageURL =
  "https://firebasestorage.googleapis.com/v0/b/twaddan-87437.appspot.com/o/twaddan_admin%2Fuser.png?alt=media&token=806fd29e-e9c9-4add-893a-7077f4d484f9";
class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      message: "",
    };
  }
  async componentDidMount() {
    var reviewRef = await firebase.database().ref(`twaddan_admin/reviews/`);
    reviewRef.on("value", async (snapshot) => {
      const data = await snapshot.val();
      let order;
      let rating;
      if (data) {
        order = await RealTime.getDetails(
          `/orders/all_orders/${data.latestRating}/`
        );
        if (order) {
          rating = await RealTime.getDetails(
            `service_providers_ratings/${order.sp_id}/${data.latestRating}/`
          );
        }
        if (rating) {
          if (!data.isDisplayed) {
            this.setState({
              isOpen: true,
              message: `New Review !! "${rating.review}" with  ${
                rating.rating
              } Star rating for Order ${data.latestRating.split("&")[0]}`,
            });
          }
        }
      }
    });
  }

  doSignOut = () => {
    localStorage.clear();
    firebase
      .auth()
      .signOut()
      .then(() => console.log("User signed out!"));
  };
  handleClose = () => {
    RealTime.updateNode(`twaddan_admin/reviews/`, { isDisplayed: true });
    this.setState({
      isOpen: false,
    });
  };
  search = (e) => {
    e.preventDefault();
  };
  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase  d-lg-inline-block"
              to="/"
            >
              Dashboard
              {/* {this.props.brandText} */}
            </Link>

            <Row>
              <Col>
                <Alert
                  color="success"
                  isOpen={this.state.isOpen}
                  toggle={this.handleClose}
                  fade={true}
                >
                  {this.state.message}
                </Alert>
              </Col>
            </Row>

            <Nav className="align-items-center d-md-flex " navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={superAdminImageURL} />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {localStorage.getItem("admin_name")}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem to="/admin/profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem to="/admin/employeeLogs" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Activity Logs</span>
                  </DropdownItem>
                  {/* <DropdownItem to='/admin/pageNotFound' tag={Link} disabled>
                    <i className='ni ni-settings-gear-65' />
                    <span>Settings</span>
                  </DropdownItem>
                  <DropdownItem to='/admin/pageNotFound' tag={Link} disabled>
                    <i className='ni ni-support-16' />
                    <span>Support</span>
                  </DropdownItem> */}
                  <DropdownItem divider />
                  <DropdownItem href="/auth/login" onClick={this.doSignOut}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
