import React from "react";
import { connect } from "react-redux";
import loginAction from "actions/loginAction";
import firebase from "../../firebaseConfig";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Col,
} from "reactstrap";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      resetemail: "",
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  onChange = (stateName, value) => {
    this.setState({
      [stateName]: value,
    });
  };

  resetPassword = () => {
    var auth = firebase.auth();
    var emailAddress = this.state.resetemail;
    console.log(emailAddress);
    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
        console.log("email sent");
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  toggle() {
    this.setState({
      modal: !this.state.modal,
    });
  }

  noUserToggle() {
    this.setState({
      noUserModal: !this.state.noUserModal,
    });
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with Super Admin Credentials</small>
              </div>

              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      onChange={(e) => this.onChange("email", e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={(e) =>
                        this.onChange("password", e.target.value)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>

                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() =>
                      this.props.loginAction(
                        this.state.email,
                        this.state.password
                      )
                    }
                  >
                    SIGN IN
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="#pablo"
                onClick={() => {
                  this.toggle();
                }}
              >
                <small>Forgot password ?</small>
              </a>
            </Col>
            {/* <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
                onClick={e => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col> */}
          </Row>
        </Col>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            <h1>Enter your email address to reset your password</h1>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    onChange={(e) =>
                      this.onChange("resetemail", e.target.value)
                    }
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="info"
              onClick={() => {
                this.toggle();
              }}
            >
              CLOSE
            </Button>
            <Button
              color="primary"
              onClick={() => {
                this.toggle();
                this.resetPassword();
              }}
            >
              SEND
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  loginAction: (email, password) => dispatch(loginAction(email, password)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
